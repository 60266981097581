import { Component, OnInit } from '@angular/core';
import { TokenService } from 'src/app/Services/token.service';
import { FirmFormComponent } from 'src/app/components/firm-form/firm-form.component';
import { Router } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { AuthService } from 'src/app/Services/auth.service';
import { FormControl, FormGroup } from '@angular/forms';
import { ViewContainerRef } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { LawyersService } from 'src/app/Services/lawyers/lawyers.service';
import { SwalService } from 'src/app/Services/swal/swal.service';
/**
 * Clase de firma con los atributos correspondientes.
 * @author Pablo Morales <pablo.morales@holdingvml.net>
 */
class Firm {
  id: number;
  firm: string;
  status: number;
}
/**
 * Componente para listar firmas, y eliminarlas.
 * @author Pablo Morales <pablo.morales@holdingvml.net>
 */
@Component({
  selector: 'app-firm-list',
  templateUrl: './firm-list.component.html',
  styleUrls: ['./firm-list.component.css']
})
export class FirmListComponent implements OnInit {
  firms: Firm[];
  dtOptions: DataTables.Settings;
  private idDelete;
  public user = { profile: null, permission: false };
  loading: boolean = false
  pageSize : number = 10
  listOfData: { data: Firm[], total: number, current_page:number } = {} as any

  formpol: FormGroup = new FormGroup({
    value: new FormControl('')
  })

  constructor(
    private token: TokenService,
    private router: Router,
    private notify: SnotifyService,
    private Auth: AuthService,
    private LawyerService: LawyersService,
    private swalService: SwalService,
    private modal: NzModalService, private viewContainerRef: ViewContainerRef
  ) { }

  ngOnInit(): void {
    //this.loadTable();
    this.loadData()
    this.formpol.controls['value'].valueChanges.subscribe(
      data => this.loadData(1, data)
    )
    this.checkUser();
  }
  handleResponse(data){
    this.notify.remove()
    this.ngOnInit()
  }

  adjustPageSize(pageSize : number): void{
    this.pageSize = pageSize;
    this.loadData();
  }
/**
 * Método para cargar las firmas.
 * @param value
 * @returns Arreglo con las firmas que tienen estado 1 en la base de datos
 * @author Pablo Morales <pablo.morales@holdingvml.net>
 */
  loadData(current_page: number = 1, value: string = ''): void {
    this.loading = true
    this.LawyerService.getFirmsNgzorro(current_page, value,this.pageSize).subscribe(data => {
      this.listOfData = data;
      this.loading = false
    }
    )
  }
/**
 * Método para desactivar las firmas.
 * @param event
 * @param id
 * @returns Cambio de estado de la firma a 2, el ususario final no podra visualizar la firma seleccionada una vez se haya ejecutado el método
 * @author Pablo Morales <pablo.morales@holdingvml.net>
 */
  async deletefirm(e: MouseEvent, id) {
    e.preventDefault();
    let data = {
      id: id
    }
    this.idDelete = data.id;
    let option = await this.swalService.swalAlert("Advertencia", "¿Está seguro de que quiere eliminar este registro?", 'warning');
    if(option){
      this.LawyerService.deleteFirm(data.id).subscribe(
        res => this.handleResponse(res),
        err => this.handleError(err)
      );
      this.loadData();
    }
  }
/**
 * Método para registrar nuevas firmas, actualizar nombre de  firmas registradas.
 * @param event
 * @param value
 * @param name
 * @returns Registro de nueva firma en base de datos utilizando una componente aparte, esto solo será posible si los parametros son nulos
 * @returns Si los parametros tienen algun valor diferente a nulo, los parametros de la otra componente serán reemplazados
 * por los parametros que entren en el método, así retornara la componente para realizar cambios en la firma seleccionada
 * @author Pablo Morales <pablo.morales@holdingvml.net>
 */
  addFirm(e: MouseEvent, value: any = null, name: any = null): void {
    e.preventDefault();
    const id = value;
    const nameFirm = name;
    const modal = this.modal.create({
      nzClosable: false,
      nzContent: FirmFormComponent,//componente aparte
      nzViewContainerRef: this.viewContainerRef,
      nzComponentParams: {
        title: 'Agregar Nueva Firma ',
        subtitle: '',
        firm: null,
        nameFirm: null,
      },
      nzOnOk: () => new Promise(resolve => setTimeout(resolve, 1000)),
      nzFooter: [
      ]
    });
    const instance = modal.getContentComponent();
    // Reemplazando los parametros de la componente aparte por  los parametros ingresados al método
    if (id != null) {
      instance.firm = id;
      instance.nameFirm = nameFirm;
      instance.title = 'Modificar Firma'
    };
    modal.afterOpen.subscribe(() => {});
    // Return a result when closed
    //modal.afterClose.subscribe(result => {});
    modal.afterClose.subscribe(() =>this.loadData());
    // delay until modal instance created
    setTimeout(() => {
      instance.subtitle = 'sub title is changed';
    }, 2000);
  }

  processDelete() {
    let c = 0;
    this.firms.forEach((element) => {
      if (element.id == this.idDelete) {
        this.firms.splice(c, 1);
        this.idDelete = null;
      }
      c++;
    });
  }

  handleError(err): void {
    this.modal.error({
      nzClosable: false,
      nzTitle: 'Error',
      nzContent: '<p>No se Pudo Borrar</p>',
      nzOnOk: () => {}
    });
  }
  logout() {
    this.token.remove();
    this.Auth.changeAuthStatus(false);
    this.router.navigateByUrl('/login');
  }

  checkUser() {
    this.token.searchUser().subscribe(
      data => this.fillData(data)
    );
  }

  fillData(data) {
    this.user.profile = data.user.profile_ID;
    if (this.user.profile == 1) {
      this.user.permission = true;
    }
  };

}
