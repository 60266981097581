import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Permission } from 'src/app/models/permission';
import { ProfilePermission } from 'src/app/models/profile-permission';
import { Rol } from 'src/app/models/rol';
import { environment } from 'src/environments/environment';
import { TokenService } from '../token.service';

@Injectable({
  providedIn: 'root'
})
export class RolService {

  /**
   * Variable para almacenar la url base de endpoints
   */
  private baseUrl: string = environment.url

  constructor(
    private httpClient: HttpClient,
    private tokenService: TokenService
  ) { }


  /**
   * Metodo para establecer encabezados de petición http
   * 
   * @returns 
   */
  private header(): Object {
    return {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer `+this.tokenService.get())
    }
  }

  /**
   * Servicio para consumir el endpoint de listar roles
   * 
   * @returns Observable lista de roles
   * @author Joe Sierra <joe.sierra@holdingvml.net> 11/05/2022
   */
  get(): Observable<Rol[]> {
    return this.httpClient.get<Rol[]>(`${this.baseUrl}/getProfile`, this.header())
  }

  /**
   * Servicio para consumir endpoint de listar permisos
   * 
   * @returns Observable lista de permisos
   * @author Joe Sierra <joe.sierra@holdingvml.net> 11/05/2022
   */
  getPermissions(): Observable<Permission[]> {
    return this.httpClient.get<Permission[]>(`${this.baseUrl}/getPermissions`, this.header())
  }

  /**
   * Servicio para consumir endpoint de listar permisos relacionados a un rol
   * 
   * @param role id de rol
   * @param data lista de permisos actualizados
   * @returns 
   */
  updateRolePermission(role: number, data: { data: Object }): Observable<string> {
    return this.httpClient.post<string>(`${this.baseUrl}/createPermissionshasprofile?profile_id=${role}`, data, this.header())
  }

  /**
   * Método para actualizar los permisos de un usuario
   * 
   * @param role id de rol a actualizar
   * @returns string de exito
   */
  getProfilePermission(role: number): Observable<ProfilePermission[]> {
    return this.httpClient.get<ProfilePermission[]>(`${this.baseUrl}/getPermissionshasprofile?profile_id=${role}`, this.header())
  }

}
