import { Component, OnInit } from '@angular/core';
import {Input} from '@angular/core';
import { NzModalRef} from 'ng-zorro-antd/modal';
import { LawyersService } from 'src/app/Services/lawyers/lawyers.service';
/**
 * Componente para agregar nuevas firmas y para modificarlas.
 * @author Pablo Morales <pablo.morales@holdingvml.net>
 */
@Component({
  selector: 'app-firm-form',
  templateUrl: './firm-form.component.html',
  styleUrls: ['./firm-form.component.css']
})
export class FirmFormComponent implements OnInit {
 //cuando el método addFirm que se encuentra en la lista de firmas tiene parametros diferentes a null estos se envian a esta componente con  Input()
  @Input() title?: string;
  @Input() subtitle?: string;
  @Input() firm?: any;
  @Input() nameFirm?: any;
  public form = {
    id: null,
    firm: null,
  }
  constructor(
    private LawyerService: LawyersService,
    private modal: NzModalRef
    ) {}
  ngOnInit(): void {
    this.form.id = this.firm;
    this.form.firm  = this.nameFirm;
  }
/**
 * Método para agregar nueva firma o actualizarla segun sean los parametros.
 * @param data
 * @returns Modal con la intrucción requerida
 * @author Pablo Morales <pablo.morales@holdingvml.net>
 */
  destroyModal(data): void {
    if(data.id==null){
      this.LawyerService.addFirm(data).subscribe();
    }else if(data.id!=null){
      this.LawyerService.updateFirm(data).subscribe();
    }
    this.modal.destroy({ data: data });
  }
  closeModal(): void {
    this.modal.close();
  }

}
