import { Component, OnInit } from '@angular/core';
import { ExcelService } from 'src/app/Services/reports/excel.service';
import { ExcelOtherService } from 'src/app/Services/reports/excel-other.service';
import { ExcelRecordingsService } from 'src/app/Services/reports/excel-recordings.service';
import { ExcelPendingService } from 'src/app/Services/reports/excel-pending.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent {

  public form = { date: null, month: null, year: null };
  public formOtherNature = { date: null, month: null, year: null };
  public formRecordings = { date: null, month: null, year: null, state: 1 };

  public error = { date: null };
  public errorOtherNature = { date: null };
  public errorRecordings = { date: null };

  constructor(
      private excelService: ExcelService,
      private ExcelOtherNature: ExcelOtherService,
      private ExcelRecordings: ExcelRecordingsService,
      private ExcelRecordingsPending: ExcelPendingService
  ) {
  }
  generateExcel() {
    (this.form.date) ? this.verifyInformation() : this.error.date = 'Required';
  }

  generateExcelOtherNature() {
    (this.formOtherNature.date) ? this.verifyInformationOtherNature() : this.errorOtherNature.date = 'Required';
  }

  verifyInformationOtherNature(){
    this.formOtherNature.year = this.formOtherNature.date.split('-')[0];
    this.formOtherNature.month = this.formOtherNature.date.split('-')[1];
    this.ExcelOtherNature.generateExcel(this.formOtherNature);
  }

  verifyInformation(){
    this.form.year = this.form.date.split('-')[0];
    this.form.month = this.form.date.split('-')[1];
    this.excelService.generateExcel(this.form);
  }

  generateExcelRecordings() {
    (this.formRecordings.date) ? this.verifyInformationRecordings() : this.errorRecordings.date = 'Required';
  }

  verifyInformationRecordings(){
    this.formRecordings.year = this.formRecordings.date.split('-')[0];
    this.formRecordings.month = this.formRecordings.date.split('-')[1];
    if(this.formRecordings.state == 1){
      this.ExcelRecordings.generateExcel(this.formRecordings);
    }else if(this.formRecordings.state == 2){
      this.ExcelRecordingsPending.generateExcel(this.formRecordings);
    }
  }

}
