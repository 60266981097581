<div>
  <div class="float-left">
    <h4>Inf. General de Casos</h4>
  </div>
  <div class="btn-group float-right" role="group" aria-label="Basic example">
    <a href="#" routerLink="/report-accident" class="btn btn-primary">Registrar Nuevo</a>
    <a href="#" class="btn btn-success" (click)="allowCases($event)" title="Activar casos"><i class="fa fa-check-square"
        aria-hidden="true"></i></a>
  </div>
</div>
<br>
<hr>
<nz-input-group [nzSuffix]="suffixIconSearch" [formGroup]="form" style="width: 550px;">
  <input type="text" #searchText nz-input placeholder="Buscar coincidencias (#evento ó placa), ej: #caso"
    formControlName="value">
</nz-input-group>
<ng-template #suffixIconSearch>
  <i nz-icon nzType="search"></i>
</ng-template>
<nz-table [nzData]="listOfData.data" [nzFrontPagination]="false" [nzShowSizeChanger]="true" [nzTotal]="listOfData.total"
  (nzPageIndexChange)="loadData($event)" (nzPageSizeChange)="adjustPageSize($event)" nzLoading="{{loading}}">
  <thead>
    <tr>
      <th># Evento</th>
      <th>Placa</th>
      <th>Dirección Actual</th>
      <th>Tiempo Transcurrido</th>
      <th>Tipo Siniestro</th>
      <th>Estado</th>
      <th>Recibio Llamada</th>
      <th>Fecha de Registro</th>
      <th>Fecha de Fin</th>
      <th>Cliente</th>
      <th style="width: 1px;">Acción</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let case of listOfData.data">
      <td>{{ case.id }}</td>
      <td *ngIf="!case.aurora_case">{{ case.plate }}</td>
      <td *ngIf="case.aurora_case" nz-tooltip nzTooltipTitle="Caso tomado de Aurora boot"
        style="background-color: rgb(225, 226, 236);">{{ case.plate }}
        <span class="caso-aurora-text">Caso&nbsp;Aurora</span>
      </td>
      <td>{{ case.currentAddress }}</td>
      <td>{{ case.time }}</td>
      <td>{{ case.typeSinister }}</td>
      <td>
        <div class="alert alert-{{case.color}}" role="alert">
          {{case.case_state}}
        </div>
      </td>
      <td>{{ case.received_call }}</td>
      <td>{{ case.registration_date }}</td>
      <td>{{ case.end_date }}</td>
      <td>{{ case.clientName }} - {{ case.beneficiarieName }}</td>
      <td>
        <div class="row justify-content-md-center align-items-center">
          <div class="btn-group" role="group">
            <small id="smallNameModify{{case.idC}}">{{ case.userModify }}</small>
          </div>
          <div class="btn-group" role="group" aria-label="Basic example">
            <button [disabled]="case.allow === 0" id="idCase{{case.idC}}" class="btn btn-info btn-sm"
              routerLink="/report-accident/{{case.id}}"><i class="fas fa-edit"></i></button>
            <button *ngIf="user.permission" class="btn btn-light btn-sm" style="color: red"
              (click)="deleteCase($event, case.id)"><i class="fas fa-minus-circle"></i></button>
          </div>
        </div>
      </td>
    </tr>
  </tbody>
</nz-table>
<br><br>
<br><br>
<br><br>
<br><br>
<br><br>
<small>Pablo Developer.</small>
