import { Component, OnInit } from '@angular/core';
import { RowsFciiService } from 'src/app/Services/report-accidents/rows-fcii.service';
import { SnotifyService } from 'ng-snotify';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';
import { Coordinator } from 'src/app/models/coordinator';

@Component({
  selector: 'app-form-fcii',
  templateUrl: './form-fcii.component.html',
  styleUrls: ['./form-fcii.component.css']
})
export class FormFciiComponent implements OnInit {

  // public form = {
  //   id: null, coordinator: <any> '', arrivalTimeFcii: null, state: '', endTimeFcii: null,
  //   reportTimeFcii: null, availabilityFcii: ''
  // }

  public formGroup: FormGroup = new FormGroup({
    id: new FormControl(),
    coordinator: new FormControl(),
    arrivalTimeFcii: new FormControl(),
    state: new FormControl(),
    endTimeFcii: new FormControl(),
    reportTimeFcii: new FormControl(),
    availabilityFcii: new FormControl()
  })

  public error = {coordinator: null, lawyer: null, availabilityFcii: null, error: null};
  public coordinators: Observable<Coordinator[]> = this.FciiService.coordinators;
  public lawyers: any[];
  public fcii: any;
  constructor(private FciiService: RowsFciiService, private Notify: SnotifyService, private route: ActivatedRoute,private datePipe: DatePipe) {
    this.formGroup.controls['id'].setValue(parseInt(route.snapshot.params["id"]))
    this.fcii = route.snapshot.params["service"] == 'fcii' ? true : false;
  }

  ngOnInit() {
    this.sendFill();
    if(this.fcii){
      $("html, body").animate({ scrollTop: $('#fcii').offset().top - 20 }, 1000);
    }
  }

  onSubmit(e: Event){
    e.preventDefault();
    this.Notify.info('Cargando...', {timeout:0});
    this.FciiService.update({
      ...this.formGroup.value,
      reportTimeFcii: this.datePipe.transform(this.formGroup.controls.reportTimeFcii.value, 'YYYY-MM-dd HH:mm:ss'),
      endTimeFcii: this.datePipe.transform(this.formGroup.controls.endTimeFcii.value, 'YYYY-MM-dd HH:mm:ss'),
      arrivalTimeFcii: this.datePipe.transform(this.formGroup.controls.arrivalTimeFcii.value, 'YYYY-MM-dd HH:mm:ss')
    // se formatea las fechas con datePipe y asi tomando los campos de formAsista y darles un formato amigable.
    }).subscribe(
      data => this.handleResponse(data)
    );
  }

  sendFill(){
    if(this.formGroup.controls['id'].value != null){
      let data = {
        id: this.formGroup.controls['id'].value
      }
      this.FciiService.select(data).subscribe(
        data => this.fillData(data),
        err => this.fillDataError()
      );
    }
  }

  fillData(data){
    this.Notify.remove();
    if(data.id){
      console.log(data);
      this.formGroup.patchValue({
        ...data,
        reportTimeFcii: data.reportTimeFcii ?new Date(data.reportTimeFcii) : '',
        endTimeFcii: data.endTimeFcii ?new Date(data.endTimeFcii) : '',
        arrivalTimeFcii: data.arrivalTimeFcii ?new Date(data.arrivalTimeFcii) : ''
        //Se crea un nuevo objeto Date para convertirlo de nuevo a fecha de todo lo de data.
      });
    }
  }

  fillDataError(){
    this.Notify.remove();
    this.Notify.error('Algo ha salido mal', {timeout: 5000});
  }

  handleResponse(data){
    this.Notify.remove();
    this.Notify.success('Exito!');
  }

  handleError(err){
    this.Notify.remove();
    // this.error = err.error.errors;
    console.log(err)
    //console.clear();
  }

}
