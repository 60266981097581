import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenService } from '../token.service';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Injectable({
  providedIn: 'root'
})
export class BeneficiariesService {

  public baseUrl = environment.url;

  header(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer `+this.token.get())
    }
  }

  constructor(
    private http: HttpClient,
    private token: TokenService
  ) { }

  getBeneficiaries(data): Observable<DataTablesResponse> {
    return this.http.post<DataTablesResponse>(`${this.baseUrl}/beneficiaries`, data, this.header());
  }

  getBeneficiariesNgzorro(nextPage, value, pageSize){
    return this.http.get<any>(`${this.baseUrl}/beneficiaries?page=${nextPage}&value=${value}&pageSize=${pageSize}`, this.header());
  }

  getBeneficiariesByClient(data): Promise<DataTablesResponse>{
    return (this.http.post<DataTablesResponse>(`${this.baseUrl}/beneficiariesClient`, data, this.header())as Observable<any>).toPromise();
  }
  getBeneficiariesbydigipat(data){
    return this.http.post(`${this.baseUrl}/getBeneficiariesbydigipat`, data, this.header());
  }

  create(data){
    return this.http.post(`${this.baseUrl}/beneficiarie/create`, data, this.header());
  }

  update(data){
    return this.http.post(`${this.baseUrl}/beneficiarie/update`, data, this.header());
  }

  select(data){
    return this.http.post(`${this.baseUrl}/beneficiarie/select`, data, this.header());
  }

  delete(data){
    return this.http.post(`${this.baseUrl}/beneficiarie/delete`, data, this.header());
  }

}
