<div class="wrapper">
  <!-- Sidebar Holder -->
  <nav id="sidebar" class="menu_fixed" *ngIf="loggedIn && ocultus==true">
    <div class="sidebar-header">
      <h3 style="color:#f39340"><i class="site_title fa fa-hands-helping"></i><span> Kronos</span></h3>
    </div>
    <div class="profile clearfix" style="margin-left: 5px" *ngIf="user">
      Bienvenid@
      {{user.name}}
    </div>
    <ul nz-menu nzTheme="dark" nzMode="inline">
      <span *ngFor="let nav of navigator">
        <li *ngIf="validatePermission(nav)" nz-submenu [nzTitle]="nav.name" [nzIcon]="nav.icon">
          <ul>
            <span *ngFor="let menu of nav.children">
              <li *ngIf="menu.submenu && validatePermission(menu)" nz-submenu [nzTitle]="menu.name"
                (click)="menu.route()">
                <ul>

                  <span *ngFor="let submenu of menu.children">
                    <li nz-menu-item (click)="submenu.route()" *ngIf="validatePermission(submenu)">
                      <i nz-icon [ngClass]="submenu.class"></i>
                      <span>{{ submenu.name }}</span>
                    </li>
                  </span>
                </ul>

              </li>
              <li *ngIf="!menu.submenu && validatePermission(menu)" nz-menu-item (click)="menu.route()">
                <i nz-icon [ngClass]="menu.class"></i>
                <span>{{ menu.name }}</span>
              </li>
            </span>

          </ul>

        </li>
      </span>
      <span *ngFor="let nav of navigator2">
        <nz-badge *ngIf="flag==true" [nzCount]="count" style="margin-left: 15px">
          <a class="head-example"></a>
        </nz-badge>
        <li  nz-submenu [nzTitle]="nav.name" [nzIcon]="nav.icon">
          <ul>
            <span *ngFor="let menu of nav.children">
              <li *ngIf="menu.submenu " nz-submenu [nzTitle]="menu.name"
                (click)="menu.route()">
                <ul>
                  <span *ngFor="let submenu of menu.children">
                    <li nz-menu-item (click)="submenu.route()" >
                      <i nz-icon [ngClass]="submenu.class"></i>
                      <span>{{ submenu.name }}</span>
                    </li>
                  </span>
                </ul>
              </li>
              <li *ngIf="!menu.submenu " nz-menu-item (click)="clearNotification()">
                <nz-badge *ngIf="flag==true" [nzCount]="count" >
                  <a class="head-example"></a>
                </nz-badge>
                <i nz-icon [ngClass]="menu.class"></i>
                <span>{{ menu.name }}</span>
              </li>
            </span>
          </ul>
        </li>
      </span>
      <li *ngIf="profile_ID==1">
        <a (click)="onNavigate()"><i class="fas fa-rocket"></i> Backup</a>
      </li>
    </ul>
  </nav>

  <!-- Page Content Holder -->
  <div id="content">
    <nav class="navbar navbar-expand-lg navbar-light bg-light" style="background-color: #006f6fd4 !important;"
      *ngIf="loggedIn">
      <div class="container-fluid">

        <button class="btn btn-dark d-inline-block d-lg-none ml-auto" type="button" data-toggle="collapse"
          data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
          aria-label="Toggle navigation">
          <i class="fas fa-align-justify"></i>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="nav navbar-nav">
            <li class="nav-item dropdown active">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="color: white">
                <span *ngIf="user">{{user.name}}</span>
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <a class="dropdown-item" routerLink="/login" *ngIf="!loggedIn">Login</a>
                <a class="dropdown-item" href="#" (click)="logout($event)" *ngIf="loggedIn">
                  <i nz-icon nzType="logout" nzTheme="outline"></i>
                  Cerrar sesión
                </a>
                <a class="dropdown-item" href="#" (click)="changePassword($event)" *ngIf="loggedIn">
                  <i nz-icon nzType="radius-setting" nzTheme="outline"></i>
                  Cambiar contraseña
                </a>
                <a class="dropdown-item" href="#" (click)="window.location.reload(true)" *ngIf="loggedIn">
                  <i nz-icon nzType="retweet" nzTheme="outline"></i>
                  Eliminar cache
                </a>
                <a class="dropdown-item" href="#" (click)="reloadCacheData($event)" *ngIf="loggedIn">
                  <i nz-icon nzType="reload" nzTheme="outline"></i>
                  Recargar datos
                </a>
              </div>
            </li>
          </ul>
          &nbsp;
          <button nzTooltipTitle="{{msg}}" nzTooltipPlacement="rightTop" (click)="hideNav($event)"
            style="background-color: #f39340;color:  #006f6fd4 !important;" nz-button nzType="primary" nzShape="circle"
            nz-tooltip><span nz-icon nzType="{{msg2}}"></span></button>
        </div>
      </div>
    </nav>
    <div style="padding: 10px">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
