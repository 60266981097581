  <div class="card col-md-10 col-lg-10 col-xl-12" style="margin-left: 2px">
    <div class="card-header header-color">
    </div>
    <div class="card-body">
      <div class="tab-content" id="myTabContent">
          <div class="float-left">
            <h4>Lista Seguros del Estado</h4>
          </div>
          <div class="btn-group float-right" role="group" aria-label="Basic example">
            <button nz-button nzType="primary" href="#" routerLink="/state-insurance-form">
              <span>Registrar Nuevo</span>
            </button>
          </div>
        <br>
        <hr>
        <nz-table #basicTable [nzData]="listOfData.data" [nzFrontPagination]="false" [nzTotal]="listOfData.total"
        (nzPageIndexChange)="loadData($event)" [nzLoading]="loading">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Apellido</th>
              <th>Presupuesto</th>
              <th>Placa</th>
              <th>Analista</th>
              <th>Ramo</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let solicitud of listOfData.data">
              <td>{{ solicitud.nombres }}</td>
              <td>{{ solicitud.apellidos }}</td>
              <td>{{ solicitud.presupuesto }}</td>
              <td>{{ solicitud.placa }}</td>
              <td>{{ solicitud.analista}}</td>
              <td>{{ solicitud.ramo }}</td>
            </tr>
          </tbody>
        </nz-table>
      </div>
    </div>
  </div>