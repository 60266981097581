import { Component, OnInit } from '@angular/core';
import { TokenService } from 'src/app/Services/token.service';
import { Router } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { ClientService } from 'src/app/Services/clients/client.service';
import { SwalService } from 'src/app/Services/swal/swal.service';
import { Client } from 'src/app/models/client';
import { FormControl, FormGroup } from '@angular/forms';
class client{
  id: any
  name: any
  address: any
  nit: any
  phone: any
}

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css']
})
export class ClientsComponent implements OnInit {
  listOfData: { data: client[], total: number, current_page:number } = {} as any
  loading: boolean = false;
  clients: Client[];
  clients2: client[]
  pageSize : number = 10
  dtOptions: DataTables.Settings;
  private idDelete;
  formpol: FormGroup = new FormGroup({
    value: new FormControl('')
  })
  constructor(
    private token: TokenService,
    private router: Router,
    private ClientService: ClientService,
    private swalService: SwalService,
    private notify: SnotifyService
  ) { }

  ngOnInit(): void {
    //this.loadTable()
    this.loadData()
    this.formpol.controls['value'].valueChanges.subscribe(
      data => this.loadData(1, data)
    )
  }
  loadData(current_page: number = 1, value: string = ''): void {
    this.loading = true
    this.ClientService.getClientsngzorro(current_page, value,this.pageSize).subscribe(data => {
      this.clients2 = data.data;
      this.listOfData = data;
      this.loading = false
    }
    )
  }

  loadTable(){
    const that = this;
    this.dtOptions = {
      serverSide: true,
      processing: true,
      ajax: async (dataTablesParameters: any, callback) => {
        this.notify.info('Cargando...', {timeout: 0});
        let resp = await new Promise<any>((resolve) => {
          that.ClientService.getClients(dataTablesParameters).subscribe(
            data =>{
              console.log(data)
              resolve(data)
            }
          )
        })
        this.notify.remove();
          if(!resp){
            this.token.remove();
            this.router.navigateByUrl('/login');
            setTimeout(()=>{
              window.location.reload();
            }, 100);
          }
          that.clients = resp;
          callback({
            recordsTotal: resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data: []
          });
      },
      columns: [{ data: 'name' }, { data: 'address' }, { data: 'nit' }, { data: 'phone' }, { data: 'id' }],
      "language": {
        "url": "https://cdn.datatables.net/plug-ins/1.10.20/i18n/Spanish.json"
    }
    };
  }

  async delete(e: MouseEvent, id){
    e.preventDefault();
    let option = await this.swalService.swalAlert("Advertencia", "¿Está seguro de que quiere eliminar este registro?", 'warning');
    if(option){
      //this.notify.info('Espere...', {timeout: 0});
      const data = {
        id: id
      }
      this.idDelete = data.id;
      this.ClientService.delete(data).subscribe(
        () => this.handleResponse(),
        err => alert()
      );
    }
  }

  handleResponse(){
    this.ngOnInit()
    this.notify.remove();
    this.notify.success('Exito!', {timeout: 2000});
    this.processDelete();
  }

  processDelete(){
    let c = 0;
    this.clients.forEach((element)=>{
        if(element.id == this.idDelete){
          this.clients.splice(c, 1);
          this.idDelete = null;
        }
        c++;
    });
  }
  adjustPageSize(pageSize : number): void{
    this.pageSize = pageSize;
    this.loadData();
  }
}
