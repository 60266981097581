import { Component, OnInit } from '@angular/core';
import { SnotifyService } from 'ng-snotify';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';
import { Coordinator } from 'src/app/models/coordinator';
import { RowsPwaService } from 'src/app/Services/report-accidents/rows-pwa.service';

@Component({
  selector: 'app-form-pwa',
  templateUrl: './form-pwa.component.html',
  styleUrls: ['./form-pwa.component.css']
})
export class FormPwaComponent implements OnInit {

  // public form = {
  //   id: null, coordinator: <any> '', arrivalTimePwa: null, state: '', endTimePwa: null,
  //   reportTimePwa: null, availabilityPwa: ''
  // }

  public formGroup: FormGroup = new FormGroup({
    id: new FormControl(),
    coordinator: new FormControl(),
    arrivalTimePwa: new FormControl(),
    state: new FormControl(),
    endTimePwa: new FormControl(),
    reportTimePwa: new FormControl(),
    availabilityPwa: new FormControl()
  })

  public error = {coordinator: null, lawyer: null, availabilityPwa: null, error: null};
  public coordinators: Observable<Coordinator[]> = this.PwaService.coordinators;
  public lawyers: any[];
  public pwa: any;
  constructor(private PwaService: RowsPwaService, private Notify: SnotifyService, private route: ActivatedRoute,private datePipe: DatePipe) {
    this.formGroup.controls['id'].setValue(parseInt(route.snapshot.params["id"]))
    this.pwa = route.snapshot.params["service"] == 'pwa' ? true : false;
  }

  ngOnInit() {
    this.sendFill();
    if(this.pwa){
      $("html, body").animate({ scrollTop: $('#pwa').offset().top - 20 }, 1000);
    }
  }

  onSubmit(e: Event){
    e.preventDefault();
    this.Notify.info('Cargando...', {timeout:0});
    this.PwaService.update({
      ...this.formGroup.value,
      reportTimePwa: this.datePipe.transform(this.formGroup.controls.reportTimePwa.value, 'YYYY-MM-dd HH:mm:ss'),
      endTimePwa: this.datePipe.transform(this.formGroup.controls.endTimePwa.value, 'YYYY-MM-dd HH:mm:ss'),
      arrivalTimePwa: this.datePipe.transform(this.formGroup.controls.arrivalTimePwa.value, 'YYYY-MM-dd HH:mm:ss')
    // se formatea las fechas con datePipe y asi tomando los campos de formAsista y darles un formato amigable.
    }).subscribe(
      data => this.handleResponse(data)
    );
  }

  sendFill(){
    if(this.formGroup.controls['id'].value != null){
      let data = {
        id: this.formGroup.controls['id'].value
      }
      this.PwaService.select(data).subscribe(
        data => this.fillData(data),
        err => this.fillDataError()
      );
    }
  }

  fillData(data){
    this.Notify.remove();
    if(data.id){
      console.log(data);
      this.formGroup.patchValue({
        ...data,
        reportTimePwa: data.reportTimePwa ?new Date(data.reportTimePwa) : '',
        endTimePwa: data.endTimePwa ?new Date(data.endTimePwa) : '',
        arrivalTimePwa: data.arrivalTimePwa ?new Date(data.arrivalTimePwa) : ''
        //Se crea un nuevo objeto Date para convertirlo de nuevo a fecha de todo lo de data.
      });
    }
  }

  fillDataError(){
    this.Notify.remove();
    this.Notify.error('Algo ha salido mal', {timeout: 5000});
  }

  handleResponse(data){
    this.Notify.remove();
    this.Notify.success('Exito!');
  }

  handleError(err){
    this.Notify.remove();
    // this.error = err.error.errors;
    console.log(err)
    //console.clear();
  }

}
