<div class="row">
  <div class="col-sm-3">
    <h6>Respuesta:</h6>
  </div>
  <div class="col-sm-6">
    <strong>
      <p *ngIf="modalData" style="color: rgb(7, 138, 14);">{{response}}</p>
    </strong>
    <strong>
      <p *ngIf="!modalData" style="color: rgb(255, 0, 0);">{{response}}</p>
    </strong>
  </div>
</div>
<div *ngIf="modalData" class="card" style="margin-left: 1px">
  <div class="card-header header-color text-center">
    <h6>DATOS DEL CLIENTE</h6>
  </div>
  <div class="card-body">
    <nz-table #basicTable [nzData]="modalData" style="width:100%">
      <tr>
        <th>Nombre Cliente:</th>
        <td>{{basicTable.data[0]?.cliente}}</td>
      </tr>
      <tr>
        <th>Identificación:</th>
        <td>{{basicTable.data[0]?.doc_propietario}}</td>
      </tr>
      <tr>
        <th>Teléfono:</th>
        <td>{{basicTable.data[0]?.telefono}}</td>
      </tr>
      <tr>
        <th>Ciudad:</th>
        <td>{{basicTable.data[0]?.ciudad}}</td>
      </tr>
      <tr>
        <th>Empresa:</th>
        <td>{{basicTable.data[0]?.empresa}}</td>
      </tr>
      <tr>
        <th>Ramo:</th>
        <td>{{basicTable.data[0]?.ramo}}</td>
      </tr>
      <tr>
        <th>Estado:</th>
        <td>{{basicTable.data[0]?.estado}}</td>
      </tr>
      <tr>
        <th>Fecha Inicio:</th>
        <td>{{basicTable.data[0]?.fechaIni}}</td>
      </tr>
      <tr>
        <th>Fecha Fin:</th>
        <td>{{basicTable.data[0]?.fechaFin}}</td>
      </tr>
      <tr>
        <th>Placa:</th>
        <td>{{basicTable.data[0]?.placa}}</td>
      </tr>
    </nz-table>
  </div>
</div>
<nz-result *ngIf="!modalData" nzStatus="warning" nzTitle="No se encontró información para la placa ingresada.">
</nz-result>

<!-- <div class="row">
  <div class="col-sm-3">
    <h6>Respuesta:</h6>
  </div>
  <div class="col-sm-6">
    <strong>
      <p *ngIf="colorhub==true" style="color: rgb(7, 138, 14);">CORRECTO</p>
    </strong>
    <strong>
      <p *ngIf="colorhub==false" style="color: rgb(255, 0, 0);">SIN INFORMACIÓN</p>
    </strong>
  </div>
</div>
<div *ngIf="isvisibleHub==true" class="card" style="margin-left: 1px">
  <div class="card-header header-color text-center">
    <h6>DATOS DEL CLIENTE</h6>
  </div>
  <div class="card-body">
    <nz-table style="width:100%">
      <tr>
        <th>Nombre Cliente:</th>
        <td>{{array_hub.propietario.nom_propietario}}</td>
      </tr>
      <tr>
        <th>Identificación:</th>
        <td>{{array_hub.propietario.id_propietario}}</td>
      </tr>
      <tr>
        <th>Correo:</th>
        <td>{{array_hub.propietario.mail_propietario}}</td>
      </tr>
      <tr>
        <th>Ciudad:</th>
        <td>{{array_hub.propietario.dir_propietario}}</td>
      </tr>
      <tr>
        <th>Ramo:</th>
        <td>{{array_hub.policy_origin}}</td>
      </tr>
      <tr>
        <th>Estado:</th>
        <td>Incluido</td>
      </tr>
      <tr>
        <th>Fecha Inicio:</th>
        <td>{{array_hub.expedition_date}}</td>
      </tr>
      <tr>
        <th>Fecha Fin:</th>
        <td>{{array_hub.policy_end_date}}</td>
      </tr>
      <tr>
        <th>Placa:</th>
        <td>{{array_hub.vehicle.placa}}</td>
      </tr>
    </nz-table>
  </div>
</div>
<nz-result *ngIf="colorhub==false" nzStatus="warning" nzTitle="No se encontró información para la placa ingresada.">
</nz-result> -->
