import { Injectable } from '@angular/core';
import * as fs from 'file-saver';
import { RowsGeneralService } from '../report-accidents/rows-general.service';
import { SnotifyService } from 'ng-snotify';
import { TokenService } from '../token.service';
import * as logoFile from './carlogo.js';
import * as Excel from "exceljs/dist/exceljs.min.js";
import * as ExcelProper from "exceljs";
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ExcelPendingService {


  private data = [];

  constructor(
    private ReportService: RowsGeneralService, private Notify: SnotifyService, private Token: TokenService
  ) {
  }

  continueExcel(data){
    this.Notify.remove();
    this.data = data;
    if(!data.status){
      this.generate();
    }else{
      this.Token.logout();
    }
  }

  generateExcel(datita) {
    this.Notify.info('Cargando...', {timeout:0});
    this.ReportService.getDataReportRecordingsPending(datita).subscribe(
      data => this.continueExcel(data)
    );
  }

  generate(){
    //Excel: Titulo, Encabezado y Datos
    const title = 'Reporte de Kronos - Grabaciones Siniestro';
    const header = [
      "#", "Fecha de accidente", "Nombre", "Placa",
      "Placa del tercero", "Amparo", "Tipo de solicitud", "Concesionario de origen", "Taller elegido por cliente",
      "Agente que recibe", "Persupuesto", "Siniestro", "Analista de Radicación", "Observaciones", "Nombre quien gestiona", "Ciudad", "Estado"
    ];
    //Creando workbook y worksheet
    let workbook:ExcelProper.Workbook = new Excel.Workbook();
    let worksheet = workbook.addWorksheet('Kronos Hoja 1');
    //Agregar fila y dando estilo
    let titleRow = worksheet.addRow([title]);
    titleRow.font = { name: 'Comic Sans MS', family: 4, size: 16, underline: 'double', bold: true }
    worksheet.addRow([]);
    worksheet.addRow(['Fecha : ' + moment().format('MMMM Do YYYY, h:mm:ss a')]);
    //Para agregar una imagen -- Dede de estar en base64 --
    let logo = workbook.addImage({
      base64: logoFile.logoBase64,
      extension: 'png',
    });
    //
    worksheet.addImage(logo, 'E1:E4');
    worksheet.mergeCells('A1:D2');
    //Agregar una fila en blanco
    worksheet.addRow([]);
    //Agregar la fila con el encabezado
    let headerRow = worksheet.addRow(header);
    
    // Estilo de celda : Rellenar y Bordear
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '04ECB7' },
        bgColor: { argb: 'FF0000FF' }
      }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    });
    let c = 0;
    // worksheet.addRows(data);
    // Add Data and Conditional Formatting
    this.data.forEach(d => {
      c++;
      let row = worksheet.addRow([
        c,
        d.accident_date,
        d.name,
        d.plate_insured,
        d.plate_third,
        d.protection,
        d.nameTypeRequestClaim,
        d.home_dealership,
        d.customer_shop,
        d.agent_receive,
        d.budget,
        d.sinister,
        d.file_analyst,
        d.observations,
        d.nameManages,
        d.city,
        d.state
      ]);
    }
    );
    // -- Aumentarle el tamaño a las columnas --
    for(let i = 1; i <= 36; i++){
      worksheet.getColumn(i).width = 30;
    }

    worksheet.addRow([]);
    //Fila del Footer
    let footerRow = worksheet.addRow(['This is system Kronos generated excel sheet.']);
    footerRow.getCell(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFCCFFE5' }
    };
    footerRow.getCell(1).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    //Unir Celdas
    worksheet.mergeCells(`A${footerRow.number}:F${footerRow.number}`);
    //Generar el archivo Excel y dar un nombre
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'reportegrabaciones.xlsx');
    })
  }

}
