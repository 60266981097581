<div class="container">
  <h2>Novedades</h2>
  <div id="caras">
    <div id="carouselExampleIndicators" class="carousel slide" data-wrap="false" data-interval="20000">
      <div class="carousel-inner">
        <div class="carousel-item {{new.active }}" *ngFor="let new of news">
          <div class="row">
            <div class="col-md-12 column-novelty">
              <div class="row">
                <p style="margin-bottom: 5px">{{ new.title }}</p>
              </div>
            </div>
            <div class="col-md-12  col-xs-12 column-novelty-title">
              <p>{{ new.subtitle }}</p>
            </div>
            <div *ngIf="new.news_point.length>0" class="col-md-12  col-xs-12 column-novelty-description">
              <p></p>
              <ul *ngFor="let point of new.news_point">
                <li>{{ point.point }}</li>
              </ul>
              <p></p>
            </div>
            <div class="col-md-12  col-xs-12 column-novelty-description"  *ngIf="new.img !=null || new.file !=null">
              <div class="form-group row">
                <div class="col-sm-1"  *ngIf="new.img !=null">
                </div>
                <div class="col-sm-5" *ngIf="new.img !=null">
                  <p></p>
                  <p>
                    <img nz-image nz-popover nzPopoverTitle="click sobre la imagen para ver en tamaño real"
                      width="100px" height="100px" nzSrc="{{url+new.img}}" alt="" />
                  </p>
                </div>
                <div class="col-sm-4" *ngIf="new.file !=null">
                  <p></p>
                  <p></p>
                  <button style="background-color: coral;" (click)="downloadFileById($event, new.id, new.file)" nz-button nzType="primary" [nzSize]="size" nzShape="round">
                    <span nz-icon nzType="cloud-download"></span>
                    Descargar Documento
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </div>
</div>
