import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenService } from 'src/app/Services/token.service';
import { Router } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { CoordinatorsService } from 'src/app/Services/coordinators/coordinators.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { SwalService } from 'src/app/Services/swal/swal.service';
import { FormControl, FormGroup } from '@angular/forms';
class Coordinator {
  id: number;
  name: string;
  nameResponsability: number;
}

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Component({
  selector: 'app-coordinators',
  templateUrl: './coordinators.component.html',
  styleUrls: ['./coordinators.component.css']
})
export class CoordinatorsComponent implements OnInit {

  coordinators: Coordinator[];
  dtOptions: DataTables.Settings;
  private idDelete;
  pageSize : number = 10
  listOfData: { data: Coordinator[], total: number, current_page:number } = {} as any
  loading: boolean = false
  formpol: FormGroup = new FormGroup({
    value: new FormControl('')
  })

  constructor(
    private http: HttpClient,
    private token: TokenService,
    private router: Router,
    private CoordinatorService: CoordinatorsService,
    private swalService: SwalService,
    private notify: SnotifyService,
    private nzMessageService: NzMessageService
  ) { }

  /**
       * eliminar un coordinador por id
       * @param id
       * @return "el id del coodinador para hacer la ejecucion"
       * @author Esteban Rodriguez <senavcmtech@holdingvml.com> 13-04-2022
       */
  async deletecordinador(id) {
    let option = await this.swalService.swalAlert("Advertencia", "¿Está seguro de que quiere eliminar este registro?", 'warning');
    if (option) {
      let data = {
        id: id
      }
      this.idDelete = data.id;
      //this.notify.info('Espere...', { timeout: 0 });
      this.CoordinatorService.deleteCoordinator(data).subscribe(
        res => this.handleResponse(data.id),
        err => this.handleError(err)
      );
    }
  }

  cancel(): void {
    this.nzMessageService.info('Se cancelo la eliminación del coordinador');
  }

  confirm(): void {
    this.nzMessageService.info('Se confirmo la eliminación del coordinador');
  }

  /*
    deleteUser(e: MouseEvent, id){
      e.preventDefault();
      let data = {
        id: id
      }
      this.idDelete = data.id;
      let option = confirm('Are you sure?');
      if(option){
        this.notify.info('Espere...', {timeout: 0});
        this.CoordinatorService.delete(data).subscribe(
          res => this.handleResponse(data.id),
          err => this.handleError(err)
        );
      }
    } */

  processDelete() {
    let c = 0;
    this.coordinators.forEach((element) => {
      if (element.id == this.idDelete) {
        this.coordinators.splice(c, 1);
        this.idDelete = null;
      }
      c++;
    });
  }

  handleResponse(data){
    this.notify.remove()
    this.ngOnInit()
  }

  handleError(err) {

  }

  loadTable() {
    const that = this;
    this.dtOptions = {
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.CoordinatorService.getCoordinators(dataTablesParameters).subscribe(resp => {
          if (!resp.data) {
            this.token.remove();
            this.router.navigateByUrl('/login');
            setTimeout(() => {
              window.location.reload();
            }, 100);
          }
          that.coordinators = resp.data;
          callback({
            recordsTotal: resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data: []
          });
        });
      },
      order: [1, 'ASC'],
      columns: [{ data: 'name' }, { data: 'nameResponsability' }, { data: 'action' }],
      "language": {
        "url": "https://cdn.datatables.net/plug-ins/1.10.20/i18n/Spanish.json"
      }
    };
  }
  ngOnInit(): void {
    //this.loadTable();
    this.loadData()
    this.formpol.controls['value'].valueChanges.subscribe(
      data => this.loadData(1, data)
    )
  }

  loadData(current_page: number = 1, value: string = ''): void {
    this.loading = true
    this.CoordinatorService.getCoordinatorsNgzorro(current_page, value,this.pageSize).subscribe(data => {
      this.listOfData = data;
      this.loading = false
    }
    )
  }

  adjustPageSize(pageSize : number): void{
    this.pageSize = pageSize;
    this.loadData();
  }
}
