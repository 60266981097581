<nz-page-header
      class="site-page-header"
      nzBackIcon
      nzTitle="Roles"
      nzSubtitle="Aquí podrá gestionar los roles de la plataforma"
></nz-page-header>
<nz-card style="width: 40%;" nzTitle="Roles" [nzExtra]="extraTemplate">
    <form [formGroup]="formRol">
        <nz-select formControlName="role" nzShowSearch nzAllowClear nzPlaceHolder="Seleccione un rol" style="width: 100%;">
            <nz-option *ngFor="let rol of roles" [nzLabel]="rol.name" [nzValue]="rol.id"></nz-option>
        </nz-select>
    </form>
</nz-card>
<ng-template #extraTemplate>
    <a>More</a>
</ng-template>
<br>
<nz-card style="width: 40%;" nzTitle="Permisos" *ngIf="this.formRol.value.role">
    <nz-table #basicTable [nzData]="permissions" [nzLoading]="loading">
        <thead>
          <tr>
            <th>Name</th>
            <th>Activo</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of basicTable.data; let i = index">
            <td>{{data.permiso}}</td>
            <td>
                <nz-switch nzSize="small" [(ngModel)]="data.check" (ngModelChange)="changePermission(data.permiso)" [nzLoading]="data.loading"></nz-switch>
            </td>
          </tr>
        </tbody>
      </nz-table>
</nz-card>
