import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TokenService } from '../token.service';

@Injectable({
  providedIn: 'root'
})

// Esta clase almacena los metodos de agregar y listar seguros del estado y sus dependencias.

export class StateInsuranceService {

  private baseUrl = environment.url;

  header(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer `+this.token.get())
    }
  }

  constructor(private http:HttpClient, private token: TokenService) { }

  /**
  * Funcion para agregar.
  * @returns Se retorna la api con funcion post mandando los valores de data.
  * @author Esteban Rodriguez <johan.rodriguez@holdingvml.com>
  */
  postStateInsurance(data){
    return this.http.post(`${this.baseUrl}/createInsurance`, data, this.header());
  }

 /**
  * Funcion para obtener el Ramo.
  * @returns Se retorna la api con funcion get obteniendo todo de ello.
  * @author Esteban Rodriguez <johan.rodriguez@holdingvml.com>
  */
  obtenerRamo(){
    return this.http.get<any>(`${this.baseUrl}/getRamo`, this.header())
  }

  /**
  * Funcion para obtener las solicitudes de seguro.
  * @returns Se retorna la api con funcion get obteniendo todo de ello y dos parametros importantes.
  * @author Esteban Rodriguez <johan.rodriguez@holdingvml.com>
  */
  obtenerRequestInsurance(next_page,value){
    return this.http.get<any>(`${this.baseUrl}/getRequestInsurance?page=${next_page}&value=${value}`, this.header())
  }

}
