import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tecni-hub',
  templateUrl: './tecni-hub.component.html',
  styleUrls: ['./tecni-hub.component.css']
})
export class TecniHubComponent implements OnInit {
  @Input() modalData: any;

  constructor() { }

  ngOnInit(): void {
    console.log(this.modalData)
  }

}
