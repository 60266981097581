<div class="col-sm-12" id="asista">
    <div class="card" style="margin-left: 1px">
        <div class="card-header header-color text-center">
            <h6>ASISTENCIA JURÍDICA</h6>
        </div>
        <div class="card-body">
            <form action="" [formGroup]="formAsista" >
                <div class="form-row">
                    <div class="form-group col-md-3">
                        <label for="selectFirm">Firma de Abogado:</label>
                        <select name="selectFirm" id="selectFirm" class="form-control form-control-sm"
                            formControlName="firm" (change)="fillLawyers()">
                            <option value="">Seleccionar...</option>
                            <option *ngFor="let firm of firms" value="{{firm.id}}">{{firm.firm}}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-3">
                        <label for="selectLawyer">Abogado:</label>
                        <select name="selectLawyer" id="selectLawyer" class="form-control form-control-sm" required
                            formControlName="lawyer" (change)="fillPhoneLawyer(this.formAsista.controls.lawyer.value)">
                            <option value="">Seleccionar...</option>
                            <option *ngFor="let lawyer of lawyers" value="{{lawyer.id}}">{{lawyer.name}}</option>
                        </select>
                        <div class="text-danger" [hidden]="!error.lawyer">
                            {{error.lawyer}}
                        </div>
                    </div>
                    <div class="form-group col-md-3">
                        <label for="inputPhoneLawyer">Teléfono de Abogado:</label>
                        <input type="text" class="form-control form-control-sm" id="inputPhoneLawyer" name="inputPhoneLawyer"
                            formControlName="phoneLawyer" readonly>
                    </div>
                    <div class="form-group col-md-3">
                        <label for="selectTypeAs">Tipo de Asistencia:</label>
                        <select name="selectTypeAs" id="selectTypeAs" class="form-control form-control-sm" required
                                formControlName="typeAsista">
                            <option value="">Seleccionar...</option>
                            <option value="1">Teléfonica</option>
                            <option value="2">Presencial</option>
                        </select>
                        <div class="text-danger" [hidden]="!error.typeAsista">
                            {{error.typeAsista}}
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-3">
                        <label for="inputReportTime">Hora de Reporte:</label>
                        <input type="text" class="datePick form-control form-control-sm" id="inputReportTime" name="inputReportTime"
                            formControlName="reportTime" [owlDateTime]="dt3" [owlDateTimeTrigger]="dt3">
                            <owl-date-time #dt3></owl-date-time>
                    </div>
                    <div class="form-group col-md-3">
                        <label for="inputEndTime">Hora de Contacto:</label>
                        <input type="text" class=" datePick form-control form-control-sm" id="inputEndTime" name="inputEndTime"
                            formControlName="endTime" [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2">
                            <owl-date-time #dt2></owl-date-time>
                    </div>
                    <div class="form-group col-md-3">
                        <label for="inputDateArrival">Agente en Sitio:</label>
                        <input type="text" class="datePick form-control form-control-sm" id="inputDateArrival" name="inputDateArrival"
                            formControlName="arrivalTime" [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1">
                            <owl-date-time #dt1></owl-date-time>
                    </div>
                    <div class="form-group col-md-3" *ngIf="data.client === '21'">
                        <label for="inputConsecutive">Consecutivo App</label>
                        <input type="text" class="datePick form-control form-control-sm" id="inputConsecutive" name="inputConsecutive"
                            formControlName="consecutive_app" required>
                    </div>
                    <div class="form-group col-md-3" *ngIf="data.client === '21'">
                        <label for="inputBudget">Presupuesto</label>
                        <input type="text" class="datePick form-control form-control-sm" id="inputBudget" name="inputBudget"
                            formControlName="budget" required>
                    </div>
                    <div class="form-group col-md-3" *ngIf="data.client === '21'">
                        <label for="inputSinister">Siniestro</label>
                        <input type="text" class="datePick form-control form-control-sm" id="inputSinister" name="inputSinister"
                            formControlName="sinister" required>
                    </div>
                    <div class="col-md-1">
                        <label for="inputEndTime"><hr></label>
                        <a href="#" (click)="onSubmit($event)" class="btn btn-info btn-sm">Guardar</a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
