import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientService } from 'src/app/Services/clients/client.service';
import { SnotifyService } from 'ng-snotify';
import { TokenService } from 'src/app/Services/token.service';
import { BeneficiariesService } from 'src/app/Services/beneficiaries/beneficiaries.service';
import { PersonsService } from 'src/app/Services/persons/persons.service';

@Component({
  selector: 'app-persons-form',
  templateUrl: './persons-form.component.html',
  styleUrls: ['./persons-form.component.css']
})
export class PersonsFormComponent implements OnInit {
  public error = {
    nit: null,
    name: null,
    address: null,
    phone: null,
    plate: null,
    document_number: null,
    policy: null
  };
  public form = {
    id:null,
    plate: null,
    name: null,
    id_type: '',
    document_number: null,
    policy: null,
    phone: null,
    address: null,
    bonding: 1,
    client: '',
    beneficiarie: ''
  }
  public clients: any[];
  public beneficiaries: any[];
  public id_types: any[];
  private dataTablesParameters: any;
  constructor(
    private route: ActivatedRoute,
    private Client: ClientService,
    private Notify: SnotifyService,
    private Token: TokenService,
    private router: Router,
    private Beneficiarie: BeneficiariesService,
    private Person: PersonsService
  ) {
    this.form.id = route.snapshot.params["id"];
  }

  ngOnInit() {
    this.sendFill();
    this.loadDataClient();
    this.loadDataBeneficiarie();
    this.loadDataType();
  }

  onSubmit(){
    this.Notify.info('Espere...', {timeout:0});
    if(this.form.id != null){
      this.Person.update(this.form).subscribe(
        data => this.handleResponse(data),
        err => this.handleError(err)
      );
    }else{
      this.Person.create(this.form).subscribe(
        data => this.handleResponse(data),
        err => this.handleError(err)
      );
    }
  }

  handleResponse(data){
    data.status ? this.logout() : '';
    this.Notify.remove();
    this.Notify.success('Exito!', {timeout: 2000});
  }

  logout(){
    this.Token.remove();
    this.router.navigateByUrl('/login');
    setTimeout(()=>{
      window.location.reload();
    }, 100);
  }

  handleError(error){
    this.Notify.remove();
    this.error = error.error.errors;
  }

  sendFill(){
    if(this.form.id != null){
      this.Notify.info('Espere...', {timeout:0});
      let data = {
        id: this.form.id
      }
      this.Person.select(data).subscribe(
        data => this.fillData(data),
        err => this.fillDataError(2)
      );
    }
  }

  fillData(data){
    this.Notify.remove();
    if(data.name){
      this.form.plate = data.plate;
      this.form.name = data.name;
      this.form.id_type = data.id_type;
      this.form.document_number = data.document_number;
      this.form.policy = data.policy;
      this.form.phone = data.phone;
      this.form.address = data.address;
      this.form.client = data.client_ID;
      this.form.beneficiarie = data.beneficiaries_ID;
      this.form.beneficiarie == null ? this.form.bonding = 2 : this.form.bonding = 1;
    }else{
      this.fillDataError(1);
    }
    if(data.status){
      this.logout();
    }
  }

  fillDataError(n){
    this.Notify.remove();
    this.Notify.error('Algo ha salido mal: '+n, {timeout: 5000});
  }

  async loadDataClient(): Promise<void> {
    this.clients  =await (await this.Client.getClients(this.dataTablesParameters).toPromise()).data
  }

  loadDataBeneficiarie(){
    this.Beneficiarie.getBeneficiaries(this.dataTablesParameters).subscribe(resp => {
      this.beneficiaries = resp.data;
    });
  }

  async loadDataType(): Promise<any> {
    this.id_types = await (await this.Person.getTypeId(this.dataTablesParameters).toPromise()).data
  }
}
