export const object = {
  id: '',
  created_at: 'FECHA CREACIÓN',
  accident_date: 'FECHA ACCIDENTE',
  name: 'NOMBRE',
  plate_insured: 'PLACA DEL VEHÍCULO ASEGURADO',
  plate_third: 'PLACA DEL TERCERO',
  protection: 'AMPARO',
  type_request:'TIPO DE SOLICITUD',
  home_dealership:'CONCESIONARIO DE ORIGEN',
  customer_shop:'TALLER ELEGIDO',
  agent_receives:'AGENTE QUE RECEPCIONA',
  state:'ESTADO',
  observations:'OBSERVACIONES',
  citys_departament_id: 'DEPARTAMENTO',
  citys_cod_mun: 'CIUDAD',
  phones: 'TELEFONO',
  error_rcl:'ERROR RCL'
}
