import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root'
})
export class BeforeLoginService implements CanActivate{

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | 
  Observable<boolean> | Promise <boolean> {
    return this.checkLoggedIn(state.url);
  }

  checkLoggedIn(url: string): boolean {
    if (!this.Token.loggedIn()) {
      return true;
    }

    // Retain the attempted URL for redirection
    // this.authService.redirectUrl = url;
    this.router.navigate(['/']);
    return false;
  }

  constructor(private Token: TokenService, private router: Router) { }
}
