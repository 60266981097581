import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as Ajap from './../lPsAcAsSaStAsSaSpopjgAsva.js';
import { TokenService } from 'src/app/Services/token.service';

@Injectable({
  providedIn: 'root'
})
export class WolkvoxService {

  private baseUrl = Ajap.baseUrl;

  constructor(
    private httpClient: HttpClient,
    private token: TokenService
  ) { }

  setHeader(): Object {
    return {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer `+this.token.get())
    }
  }

  get(code, dateIni, dateEnd): Observable<any> {
    return this.httpClient.get(`${this.baseUrl}/wolkvox?code=${code}&dateIni=${dateIni}&dateEnd=${dateEnd}`, this.setHeader())
  }
  getpol() {
    return this.httpClient.get(`${this.baseUrl}/wolkvox`, this.setHeader())
  }
}
