export const object = {
    id: '',
    accident_date: 'FECHA DE ACCIDENTE' ,
    name: 'NOMBRE',
    plate_insured: 'PLACA DEL VEHICULO ASEGURADO',   
    plate_third: 'PLACA DE TERCERO', 
    protection: 'AMPARO', 
    type_request: 'TIPO DE SOLICITUD', 
    home_dealership: 'CONCESIONARIO DE ORIGEN',  
    customer_shop: 'TALLER ELEGIDO POR EL CLIENTE',  
    state: 'ESTADO',  
    budget:  'PRESUPUESTO', 
    sinister: 'SINIESTRO',     
    observations: 'OBSERVACIONES',
    citys_departament_id: 'DEPARTAMENTO', 
    citys_cod_mun: 'CIUDAD', 
    duration:'DURACION' ,
    accident_zone:'ZONA DE ACCIDENTE',
    file_analyst: 'ANALISTA DE RADIACION'
}
  