<div class="card" style="margin-left: 1px">
  <div class="card-header header-color">

  </div>
  <ul class="list-group list-group-flush">
    <li class="list-group-item">
      <div class="float-left">
        <h4>Lista de Clientes</h4>
      </div>
      <div class="float-right">
        <a href="#" routerLink="/client" class="btn btn-primary">Registrar Nuevo</a>
      </div>
    </li>
  </ul>
  <div class="card-body table-responsive">
    <!-- <table datatable [dtOptions]="dtOptions" class="table table-striped table-bordered row-border hover">
      <thead>
        <tr>
          <th>Nit</th>
          <th>Nombre</th>
          <th>Dirección</th>
          <th>Teléfono</th>
          <th style="width: 1px;">Acción</th>
        </tr>
      </thead>
      <tbody *ngIf="clients?.length > 0">
        <tr *ngFor="let client of clients">
          <td>{{ client.nit }}</td>
          <td>{{ client.name }}</td>
          <td>{{ client.address }}</td>
          <td>{{ client.phone }}</td>
          <td>
            <a href="#" routerLink="/client/{{client.id}}"><i class="fas fa-edit"></i></a>
            <a href="#" style="color: red" (click)="delete($event, client.id)"><i class="fas fa-minus-circle"></i></a>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="clients?.length < 1">

      <tbody>
    </table> -->
    <nz-input-group [nzSuffix]="suffixIconSearch" [formGroup]="formpol">
      <input type="text" #searchText nz-input
        placeholder="Buscar coincidencias (NIT, Nombre, Teléfono)" formControlName="value">
    </nz-input-group>
    <ng-template #suffixIconSearch>
      <i nz-icon nzType="search"></i>
    </ng-template>
    <nz-table [nzData]="listOfData.data" [nzFrontPagination]="false" [nzShowSizeChanger]="true"
    [nzTotal]="listOfData.total" (nzPageIndexChange)="loadData($event)" (nzPageSizeChange)="adjustPageSize($event)"
    nzLoading="{{loading}}">
    <thead>
      <tr>
        <th>NIT</th>
        <th>Nombre</th>
        <th>Dirección</th>
        <th>Teléfono</th>
        <th>Acción</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let other of listOfData.data">
        <td>{{ other.nit }}</td>
        <td>{{ other.name }}</td>
        <td>{{ other.address }}</td>
        <td>{{ other.phone }}</td>
        <td>
          <a href="#" routerLink="/client/{{other.id}}"><i class="fas fa-edit"></i></a>
          <a href="#" style="color: red" (click)="delete($event, other.id)"><i class="fas fa-minus-circle"></i></a></td>
      </tr>
    </tbody>
  </nz-table>
  </div>
</div>
