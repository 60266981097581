import { Component, OnInit, OnDestroy } from '@angular/core';
import { JarwisService } from 'src/app/Services/jarwis.service';
import { TokenService } from 'src/app/Services/token.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/Services/auth.service';
import { SwalService } from 'src/app/Services/swal/swal.service';
import { UserInformationService } from 'src/app/Services/users/user-information.service';
import { CountAuroraService } from 'src/app/Services/count-aurora.service';
import { User } from 'src/app/models/user';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

  public form = {
    email: null,
    password: null
  }

  public error = null;

  constructor(
      public countAuroraService: CountAuroraService,
      private Jarwis:JarwisService,
      private Token:TokenService,
      private router: Router,
      private Auth: AuthService,
      private swalService: SwalService,
      private userInformationService: UserInformationService
    ) {
     }

    /**
     * Método para consumir el servicio de login
     *
     * @author Joe Sierra
     */
  async onSubmit(): Promise<void> {
    try {
      this.swalService.loading()
      this.Jarwis.login(this.form).pipe(map((e) => {
        return {
          token: e.token,
          user: {
            name: e.user.name,
            profile: e.profile,
            profile_ID: e.user.profile_ID
          }
        }
      })).subscribe((data) => {
        this.handleResponse(data)
      },
      err => {
        this.handleError(err)
      })
    } catch (e) {
      this.handleError(e)
    }
  }

  handleResponse(data: { token: string, user: User }){
    this.Token.handle(data.token)
    this.Token.setUser(data.user)
    this.userInformationService.updateInformation(data.user)
    this.Auth.changeAuthStatus(true)
    //this.rowCount()
    this.router.navigateByUrl('/home')
  }

  handleError(error){
    this.swalService.close()
    this.error = error.error.error;
  }

  ngOnInit() {
    $('body').css({"background-color": '#20B2AA'});
  }

  ngOnDestroy(){
    $('body').css({"background-color": '#fff'});
  }
  rowCount(){
    this.countAuroraService.getRowCount().subscribe(dta=>{
      this.countAuroraService.set(dta)
    })
  }


}
