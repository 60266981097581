import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenService } from '../token.service';
import { environment } from 'src/environments/environment';

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}


@Injectable({
  providedIn: 'root'
})
export class ClaimService {

  public baseUrl = environment.url;

  header(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer `+this.token.get())
    }
  }

    constructor(private http:HttpClient, private token: TokenService) { }

    getClaims(data){
      return this.http.post<DataTablesResponse>(`${this.baseUrl}/claims`, data, this.header());
    }

    create(data){
      return this.http.post(`${this.baseUrl}/j0DbgkGhl8HIWqQio6cW`, data, this.header());
    }

    /**
 * Función para guardar las observaciones de reclamaciones.
 * Recibe el parámetro que se obtiene al recorrer el arreglo de observaciones
 * @author Pablo Morales <pablo.morales@holdingvml.net>
 */
    createObservation(data){
      return this.http.post(`${this.baseUrl}/createObservation`, data, this.header());
    }
   /**
 * Función para obtener las observaciones de reclamación.
 * Recibe un parámetro de identificación de la reclamación
 * @returns Arreglo con las observaciones de la reclamación que están guardadas en base de datos
 * @author Pablo Morales <pablo.morales@holdingvml.net>
 */
    getObservation(id){
      return this.http.get<any>(`${this.baseUrl}/ObservationClaim?id_claim=${id}`,this.header());
    }

    update(data){
      return this.http.post(`${this.baseUrl}/client/update`, data, this.header());
    }

    select(data){
      return this.http.post(`${this.baseUrl}/88bKEQJajHkQrnXWRSKx`, data, this.header());
    }

    getBasicInformation(){
      return this.http.post(`${this.baseUrl}/claimsInformation`, [], this.header());
    }

    getCitys(data){
      return this.http.post<any>(`${this.baseUrl}/citys`, data, this.header());
    }

    record(data){
      return this.http.post<any>(`${this.baseUrl}/ERCe2gcBfz12ZmQk5fLQ`, data, this.header());
    }

    getUsers(){
      return this.http.post(`${this.baseUrl}/UVkwNZSpkCYkTvMpeptl`, [], this.header());
    }

    deleteCase(data){
      return this.http.post(`${this.baseUrl}/WR6OaKq6yaxfln4GRTTL`, data, this.header());
    }



}
