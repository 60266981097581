<div class="card" style="margin-left: 1px">
  <div class="card-header header-color">

  </div>
  <ul class="list-group list-group-flush">
    <li class="list-group-item">
      <div class="float-left">
        <h4>Lista de Coordinadores<small><small><br>FCII - Digipat - PWA</small></small></h4>
      </div>
      <div class="float-right">
        <a href="#" routerLink="/coordinator" class="btn btn-primary">Registrar Nuevo</a>
      </div>
    </li>
  </ul>
  <div class="card-body">
    <!-- <table datatable [dtOptions]="dtOptions" class="table table-striped table-bordered row-border hover">
                <thead>
                    <tr>
                    <th>Nombre</th>
                    <th>Responsabilidad</th>
                    <th style="width: 1px;">Acción</th>
                    </tr>
                </thead>
                <tbody *ngIf="coordinators?.length > 0">
                    <tr *ngFor="let coordinator of coordinators">
                        <td>{{ coordinator.name }}</td>
                        <td>{{ coordinator.nameResponsability }}</td>
                        <td>
                            <a href="#" routerLink="/coordinator/{{coordinator.id}}"><i class="fas fa-edit"></i></a>

                            <a style="color: red"
                            (click)="deletecordinador(coordinator.id)"
                            >
                            <ng-template #iconTpl>
                                <i nz-icon nzType="question-circle-o" style="color: red;"></i>
                              </ng-template>
                            <i class="fas fa-minus-circle"></i>
                            </a>


                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="coordinators?.length < 1">
                    <tr>
                        <td colspan="4" class="no-data-available">No hay datos disponibles!</td>
                    </tr>
                <tbody>
            </table> -->
    <nz-input-group [nzSuffix]="suffixIconSearch" [formGroup]="formpol">
      <input type="text" #searchText nz-input placeholder="Buscar coincidencias (Nombre)"
        formControlName="value">
    </nz-input-group>
    <ng-template #suffixIconSearch>
      <i nz-icon nzType="search"></i>
    </ng-template>
    <nz-table [nzData]="listOfData.data" [nzFrontPagination]="false" [nzShowSizeChanger]="true"
      [nzTotal]="listOfData.total" (nzPageIndexChange)="loadData($event)" (nzPageSizeChange)="adjustPageSize($event)"
      nzLoading="{{loading}}">
      <thead>
        <tr>
          <th>Nombre</th>
          <th>Responsabilidad</th>
          <th style="width: 1px;">Acción</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let other of listOfData.data">
          <td>{{ other.name }}</td>
          <td>{{ other.nameResponsability }}</td>
          <td>
            <a href="#" routerLink="/coordinator/{{other.id}}"><i class="fas fa-edit"></i></a>

            <a style="color: red" (click)="deletecordinador(other.id)">
              <ng-template #iconTpl>
                <i nz-icon nzType="question-circle-o" style="color: red;"></i>
              </ng-template>
              <i class="fas fa-minus-circle"></i>
            </a>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>
