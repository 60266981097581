<div class="card col-md-12 col-lg-12 col-xl-12" style="margin-left: 1px">
    <div class="card-header header-color">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" id="general-tab" data-toggle="tab" href="#general" role="tab" aria-controls="general" aria-selected="true">General</a>
            </li>
            <!--<li class="nav-item">
                <a class="nav-link" id="asista-tab" data-toggle="tab" href="#asista" role="tab" aria-controls="asista" aria-selected="false">Asistencia Jurídica</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="fcii-tab" data-toggle="tab" href="#fcii" role="tab" aria-controls="fcii" aria-selected="false">Fcii</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="digipat-tab" data-toggle="tab" href="#digipat" role="tab" aria-controls="digipat" aria-selected="false">Digipat</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="movil-tab" data-toggle="tab" href="#movil" role="tab" aria-controls="movil" aria-selected="false">Movil</a>
            </li>-->
        </ul>
    </div>
    <div class="card-body">
        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="general" role="tabpanel" aria-labelledby="general-tab">
                <app-rows-general></app-rows-general>
            </div>
            <div class="tab-pane fade" id="asista" role="tabpanel" aria-labelledby="asista-tab">
                <!-- <app-rows-asista></app-rows-asista> -->
            </div>
            <div class="tab-pane fade" id="fcii" role="tabpanel" aria-labelledby="fcii-tab">
                <!-- <app-rows-fcii></app-rows-fcii> -->
            </div>
            <div class="tab-pane fade" id="digipat" role="tabpanel" aria-labelledby="digipat-tab">
                <!-- <app-rows-digipat></app-rows-digipat> -->
            </div>
            <div class="tab-pane fade" id="pwa" role="tabpanel" aria-labelledby="pwa-tab">
                <!-- <app-rows-pwa></app-rows-pwa> -->
            </div>
            <div class="tab-pane fade" id="movil" role="tabpanel" aria-labelledby="movil-tab">
                <!-- <app-movil></app-movil> -->
            </div>
        </div>
    </div>
</div>
