import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenService } from 'src/app/Services/token.service';
import { environment } from 'src/environments/environment';

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Injectable({
  providedIn: 'root'
})
export class CoordinatorsService {

  public baseUrl = environment.url;

  header(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer `+this.Token.get())
    }
  }

  constructor(private http:HttpClient, private Token: TokenService) { }

  getCoordinators(data){
    return this.http.post<DataTablesResponse>(`${this.baseUrl}/P4PlEutwyZzBctl9rO8u`, data, this.header());
  }

  getCoordinatorsNgzorro(nextPage, value, pageSize){
    return this.http.get<any>(`${this.baseUrl}/P4PlEutwyZzBctl9rO8u?page=${nextPage}&value=${value}&pageSize=${pageSize}`, this.header());
  }

  create(data){
    return this.http.post(`${this.baseUrl}/OeX4SHBsjGsSESnE2JMn`, data, this.header());
  }

  update(data){
    return this.http.post(`${this.baseUrl}/kfQKn38Qkfyn1qw8nZdF`, data, this.header());
  }

  select(data){
    return this.http.post(`${this.baseUrl}/Gh1VGanEwjvBO0fEKkSj`, data, this.header());
  }

  getResponsabilitys(){
    return this.http.get(`${this.baseUrl}/ZsmONelCC7QSmGoBIRAq`, this.header());
  }

  delete(data){
    return this.http.post(`${this.baseUrl}/lawyer/delete`, data, this.header());
  }

  deleteCoordinator(data){
    return this.http.put(`${this.baseUrl}/desactivarCoordinador?id=${data.id}`, data, this.header());
  } //se envia el parametro id del coordinador por metodo post

}
