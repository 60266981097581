import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TokenService } from '../token.service';

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Injectable({
  providedIn: 'root'
})
export class RecordingService {

  public baseUrl = environment.url;

  header(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer `+this.token.get())
    }
  }

    constructor(private http:HttpClient, private token: TokenService) { }

    getRecordings(data){
      return this.http.post<DataTablesResponse>(`${this.baseUrl}/HqhlKUWjvdVtBg6Neq5V`, data, this.header());
    }
    getRecordingsNgzorro(nextPage, value, pageSize){
      return this.http.post<any>(`${this.baseUrl}/HqhlKUWjvdVtBg6Neq5V?page=${nextPage}&value=${value}&pageSize=${pageSize}`, this.header());
    }

    create(data){
      return this.http.post(`${this.baseUrl}/ILHezSRk55OZ7Nio1y8D`, data, this.header());
    }

    select(data){
      return this.http.post(`${this.baseUrl}/gtRWDgCLlGGjZOTf2TyI`, data, this.header());
    }

    getBasicInformation(){
      return this.http.post(`${this.baseUrl}/claimsInformation`, [], this.header());
    }

    getCitys(data){
      return this.http.post<any>(`${this.baseUrl}/citys`, data, this.header());
    }
    createObservation(data){
      return this.http.post(`${this.baseUrl}/createObservationRec`, data, this.header());
    }

    getObservation(id){
      return this.http.get<any>(`${this.baseUrl}/observation_idRec?id_recording=${id}`,this.header());
    }
    deleteRecording(id){
      return this.http.post<any>(`${this.baseUrl}/deleteRecording/${id}`,this.header());
    }

}
