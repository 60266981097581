import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { SignupComponent } from './components/signup/signup.component';
import { RequestResetComponent } from './components/password/request-reset/request-reset.component';
import { ResponseResetComponent } from './components/password/response-reset/response-reset.component';
import { BeforeLoginService } from './Services/before-login.service';
import { AfterLoginService } from './Services/after-login.service';
import { UsersComponent } from './components/users/users.component';
import { ProductsComponent } from './components/products/products.component';
import { ClientsComponent } from './components/clients/clients.component';
import { DirectoryComponent } from './components/directory/directory.component';
import { ReportAccidentsComponent } from './components/report-accidents/report-accidents.component';
import { ClientFormComponent } from './components/admin/client-form/client-form.component';
import { BeneficiariesComponent } from './components/admin/beneficiaries/beneficiaries.component';
import { BeneficiarieFormComponent } from './components/admin/beneficiarie-form/beneficiarie-form.component';
import { FormGeneralComponent } from './components/form-report-accident/form-general/form-general.component';
import { PersonsComponent } from './components/admin/persons/persons.component';
import { PersonsFormComponent } from './components/admin/persons-form/persons-form.component';
import { HomeComponent } from './components/home/home/home.component';
import { ReportsComponent } from './components/reports/reports.component';
import { CallsOtherNatureComponent } from './components/calls-other-nature/calls-other-nature.component';
import { ClaimsComponent } from './components/claims/claims.component';
import { ClaimFormComponent } from './components/claims/claim-form/claim-form.component';
import { LawyerRowsComponent } from './components/lawyers/lawyer-rows/lawyer-rows.component';
import { LawyerFormComponent } from './components/lawyers/lawyer-form/lawyer-form.component';
import { RecordingsComponent } from './components/recordings/recordings.component';
import { RecordingsFormComponent } from './components/recordings/recordings-form/recordings-form.component';
import { SurveyAccidentComponent } from './components/survey-accident/survey-accident.component';
import { CoordinatorsComponent } from './components/coordinators/coordinators.component';
import { FormCoordinatorsComponent } from './components/coordinators/form-coordinators/form-coordinators.component';
import { OptionsComponent } from './components/options/options.component';
import { OptionsFormComponent } from './components/options-form/options-form.component';
import { NewsComponent } from './components/news/news.component';
import { NewsFormComponent } from './components/news/news-form/news-form.component';
import { FirmListComponent } from './components/firm-list/firm-list.component';
import { FirmFormComponent } from './components/firm-form/firm-form.component';
import { StateInsuranceComponent } from './components/state-insurance/state-insurance.component';
import { StateInsuranceFormComponent } from './components/state-insurance/state-insurance-form/state-insurance-form.component';
import { ErrorListComponent } from './components/error-list/error-list.component';
import { ErrorCaseComponent } from './components/error-case/error-case.component';
import { ProfileComponent } from './components/profile/profile.component';
import { WolkvoxComponent } from './components/wolkvox/wolkvox.component';
import { AuroraCasesComponent } from './components/aurora-cases/aurora-cases.component';
const routes: Routes = [

  { path: 'login', component: LoginComponent, canActivate: [BeforeLoginService] },
  { path: 'users/create', component:SignupComponent,  canActivate: [AfterLoginService] },
  { path: 'users/create/:id', component: SignupComponent, canActivate: [AfterLoginService] },
  { path: 'home', component:HomeComponent, canActivate: [AfterLoginService] },
  { path: 'request-password-reset', component:RequestResetComponent, canActivate: [BeforeLoginService] },
  { path: 'response-password-reset', component:ResponseResetComponent, canActivate: [BeforeLoginService] },
  { path: 'users', component:UsersComponent, canActivate: [AfterLoginService] },
  { path: 'products', component:ProductsComponent, canActivate: [AfterLoginService] },
  { path: 'clients', component:ClientsComponent, canActivate: [AfterLoginService] },
  { path: 'directory', component:DirectoryComponent, canActivate: [AfterLoginService] },
  { path: 'report-accidents', component:ReportAccidentsComponent, canActivate: [AfterLoginService] },
  { path: 'client', component:ClientFormComponent, canActivate: [AfterLoginService] },
  { path: 'client/:id', component:ClientFormComponent, canActivate: [AfterLoginService] },
  { path: 'person/:id', component:PersonsFormComponent, canActivate: [AfterLoginService] },
  { path: 'person', component:PersonsFormComponent, canActivate: [AfterLoginService] },
  { path: 'persons', component:PersonsComponent, canActivate: [AfterLoginService] },
  { path: 'beneficiaries', component:BeneficiariesComponent, canActivate: [AfterLoginService] },
  { path: 'beneficiarie', component: BeneficiarieFormComponent, canActivate: [AfterLoginService] },
  { path: 'beneficiarie/:id', component: BeneficiarieFormComponent, canActivate: [AfterLoginService] },
  { path: 'report-accident', component: FormGeneralComponent, canActivate: [AfterLoginService] },
  { path: 'report-accident/:id', component: FormGeneralComponent, canActivate: [AfterLoginService] },
  { path: 'report-accident/:id/:service', component: FormGeneralComponent, canActivate: [AfterLoginService] },
  { path: 'report-accident-aurora/:idcase', component: FormGeneralComponent, canActivate: [AfterLoginService] },
  { path: 'other/reports', component: ReportsComponent, canActivate: [AfterLoginService] },
  { path: 'other/callOtherNature', component: CallsOtherNatureComponent, canActivate: [AfterLoginService] },
  { path: 'claims', component: ClaimsComponent, canActivate: [AfterLoginService] },
  { path: 'claim', component: ClaimFormComponent, canActivate: [AfterLoginService] },
  { path: 'claim/:id', component: ClaimFormComponent, canActivate: [AfterLoginService] },
  { path: 'state-insurance', component: StateInsuranceComponent, canActivate: [AfterLoginService] },
  { path: 'state-insurance-form', component: StateInsuranceFormComponent, canActivate: [AfterLoginService] },
  { path: 'lawyers', component: LawyerRowsComponent, canActivate: [AfterLoginService] },
  { path: 'lawyer', component: LawyerFormComponent, canActivate: [AfterLoginService] },
  { path: 'lawyer/:id', component: LawyerFormComponent, canActivate: [AfterLoginService] },
  { path: 'recordings', component: RecordingsComponent, canActivate: [AfterLoginService] },
  { path: 'recording/:id', component: RecordingsFormComponent, canActivate: [AfterLoginService] },
  { path: 'survey', component: SurveyAccidentComponent, canActivate: [AfterLoginService] },
  { path: 'coordinators', component: CoordinatorsComponent, canActivate: [AfterLoginService] },
  { path: 'coordinator/:id', component: FormCoordinatorsComponent, canActivate: [AfterLoginService] },
  { path: 'coordinator', component: FormCoordinatorsComponent, canActivate: [AfterLoginService] },
  { path: 'otherservices', component: OptionsComponent, canActivate: [AfterLoginService] },
  { path: 'otherservice', component: OptionsFormComponent, canActivate: [AfterLoginService] },
  { path: 'otherservice/:id', component: OptionsFormComponent, canActivate: [AfterLoginService] },
  { path: 'other/wolkvox', component: WolkvoxComponent, canActivate: [AfterLoginService] },
  { path: 'news', component: NewsComponent, canActivate: [AfterLoginService] },
  { path: 'new/:id', component: NewsFormComponent, canActivate: [AfterLoginService] },
  { path: 'new', component: NewsFormComponent, canActivate: [AfterLoginService] },
  { path: 'list-firm', component: FirmListComponent, canActivate: [AfterLoginService] },
  { path: 'add-firm', component: FirmFormComponent, canActivate: [AfterLoginService] },
  { path: 'add-firm/:id', component: FirmFormComponent, canActivate: [AfterLoginService] },
  { path: 'list-error', component: ErrorListComponent, canActivate: [AfterLoginService] },
  { path: 'add-error', component: ErrorCaseComponent, canActivate: [AfterLoginService] },
  { path: 'add-error/:id', component: ErrorCaseComponent, canActivate: [AfterLoginService] },
  { path: '', component: HomeComponent, canActivate: [AfterLoginService] },
  { path: 'profiles', component: ProfileComponent, canActivate: [AfterLoginService] },
  { path: 'aurora-cases', component: AuroraCasesComponent, canActivate: [AfterLoginService] },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
