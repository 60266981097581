import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenService } from 'src/app/Services/token.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  private baseUrl = environment.url;

  header(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer `+this.Token.get())
    }
  }

  constructor(private http:HttpClient, private Token: TokenService) { }

/**
 * Función para obtener los registros de errores del sistema.
 * Recibe dos parámetros que uno para página y otro para filtrar por string
 * @returns Arreglo con los registros de errores del sistema que están guardadas en base de datos
 * @author Pablo Morales <pablo.morales@holdingvml.net>
 */
  getErrors(nextPage,value){
    return this.http.get<any>(`${this.baseUrl}/getRequestInsuranceError?page=${nextPage}&text=${value}`,  this.header());
  }

/**
 * Función para guardar los registros de errores del sistema.
 * Recibe un parámetro que se obtiene del valor del formulario de cración de errores
 * @author Pablo Morales <pablo.morales@holdingvml.net>
 */
  createError(data){
    return this.http.post(`${this.baseUrl}/createError`, data, this.header());
  }

  deleteError(data){
    return this.http.post(`${this.baseUrl}/deleteError`, data, this.header());
  }
}
