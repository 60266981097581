<div class="content">
  <h3>Filtro</h3>
  <form [formGroup]="form">
    <div class="row">
      <div class="col-md-4">
        <select formControlName="code" class="custom-select" id="inputGroupSelect01">
          <option selected>Choose...</option>
          <option value="4227">SEGESTADO</option>
          <option value="4230">SEGESTADO DESBORDE</option>
          <option value="4235">MUNDIAL PUBLICO</option>
          <option value="4240">MUNDIAL PESADO</option>
          <option value="4242">MUNDIAL FCCI</option>
          <option value="4237">FFCI ACCIDENTE</option>
          <option value="4239">FCII INFORMACION</option>
        </select>
      </div>
      <div class="col-md-4">
        <input formControlName="dateIni" type="date" class="form-control" placeholder="Username" aria-label="Username"
          aria-describedby="basic-addon1">
      </div>
      <div class="col-md-4">
        <input formControlName="dateEnd" type="date" class="form-control" placeholder="Username" aria-label="Username"
          aria-describedby="basic-addon1">
      </div>
    </div>
  </form>

  <br>
  <button type="button" class="btn btn-primary" [disabled]="!form.valid" (click)="find()">Buscar</button>
  <br><br>
  <nz-table #columnTable [nzData]="data" [nzScroll]="{ y: '310px' }">
    <thead>
      <th style="width: 20px;">&nbsp;</th>
      <th>Fecha</th>
      <th>Skill Name</th>
      <th>Resultado</th>
      <th style="text-align: center;">Id con</th>
      <th style="text-align: center;">ani</th>
    </thead>
    <tbody>
      <tr style="text-align: left;" *ngFor="let datita of columnTable.data">
        <td>{{datita.date | date:'medium'}}</td>
        <td>{{datita.skill_name}}</td>
        <td>{{datita.result}}</td>
        <td>{{datita.conn_id}}</td>
        <td style="text-align: center;">{{datita.ani}}</td>
      </tr>
    </tbody>
  </nz-table>
</div>
