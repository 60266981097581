<div class="container">
  <form [formGroup]="formGroup" #claimForm="ngForm" (ngSubmit)="onSubmit()" id="forma">
    <div class="row">
      <div class="col-sm-12">
        <div class="card" style="margin-left: 1px">
          <div class="card-header header-color text-center">
            <h6>DATOS GRABACIÓN</h6>
          </div>
          <div class="card-body">
            <div class="form-row">
              <div class="form-group col-md-3">
                <label for="inputCreatedAt">Fecha de registro</label>
                <input type="text" class="form-control form-control-sm" id="inputCreatedAt"
                  formControlName="created_at_recording" placeholder="" required
                  disabled>
                <div class="text-danger" [hidden]="!error.created_at_recording">
                  {{error.created_at_recording}}
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="inputDateAccident">Fecha de accidente</label>
                <input type="date" class="form-control form-control-sm" id="inputDateAccident"
                formControlName="accident_date"  placeholder="" required>
                <div class="text-danger" [hidden]="!error.accident_date">
                  {{error.accident_date}}
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="inputName">Nombre</label>
                <input type="text" class="form-control form-control-sm" id="inputName"
                   formControlName="name"
                   placeholder="Nombre titular" required>
                <div class="text-danger" [hidden]="!error.name">
                  {{error.name}}
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="inputPlateInsured">Placa del vehiculo asegurado</label>
                <input type="text" class="form-control form-control-sm" id="inputPlateInsured"
                formControlName="plate_insured"
                   placeholder="Placa asegurado" required>
                <div class="text-danger" [hidden]="!error.plate_insured">
                  {{error.plate_insured}}
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="inputPlateThird">Placa del tercero</label>
                <input type="text" class="form-control form-control-sm" id="inputPlateThird"
                formControlName="plate_third"
                  placeholder="Placa tercero">
                <div class="text-danger" [hidden]="!error.plate_third">
                  {{error.plate_third}}
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="selectProtection">Amparo</label>
                <select id="selectProtection" class="form-control form-control-sm" formControlName="protection"
                  required >
                  <option value="">Choose...</option>
                  <option *ngFor="let protection of basicData.protections" value="{{ protection.id }}">{{
                    protection.nameProtection }}</option>
                </select>
                <div class="text-danger" [hidden]="!error.protection">
                  {{error.protection}}
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="selectRequest">Tipo de solicitud</label>
                <select id="selectRequest" class="form-control form-control-sm" formControlName="type_request" required
                  >
                  <option value="">Choose...</option>
                  <option *ngFor="let type_request of basicData.type_request_claim" value="{{ type_request.id }}">{{
                    type_request.nameTypeRequestClaim }}</option>
                </select>
                <div class="text-danger" [hidden]="!error.type_request">
                  {{error.type_request}}
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="selectDepartament">Departamento</label>
                <select id="selectDepartament" class="form-control form-control-sm"
                formControlName="citys_departament_id" required
                  (change)="loadCitys()">
                  <option value="">Choose...</option>
                  <option *ngFor="let departament of basicData.departaments" value="{{ departament.id}}">{{
                    departament.name }}</option>
                </select>
                <div class="text-danger" [hidden]="!error.citys_departament_id">
                  {{error.citys_departament_id}}
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="selectCiudad">Ciudad</label>
                <select id="selectCiudad" class="form-control form-control-sm" formControlName="citys_cod_mun" required>
                  <option value="">Choose...</option>
                  <option *ngFor="let city of basicData.citys" value="{{ city.id }}">{{ city.name }}</option>
                </select>
                <div class="text-danger" [hidden]="!error.citys_cod_mun">
                  {{error.citys_cod_mun}}
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="inputDealership">Concesionario de origen</label>
                <input type="text" class="form-control form-control-sm" id="inputDealership"
                formControlName="home_dealership"
                   placeholder="Concesionario origen">
                <div class="text-danger" [hidden]="!error.home_dealership">
                  {{error.home_dealership}}
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="inputCustomerShop">Taller elegido por el cliente</label>
                <input type="text" class="form-control form-control-sm" id="inputCustomerShop"
                formControlName="customer_shop"
                  placeholder="Taller elegido">
                <div class="text-danger" [hidden]="!error.customer_shop">
                  {{error.customer_shop}}
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="inputAgent">Agente que recepciona</label>
                <select name="inputAgent" class="form-control form-control-sm" id="inputAgent" required
                formControlName="agent_receives" required disabled>
                  <option value="">Choose...</option>
                  <option *ngFor="let user of users" value="{{user.id}}">{{user.name}}</option>
                </select>
                <div class="text-danger" [hidden]="!error.agent_receives">
                  {{error.agent_receives}}
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="selectState">Estado</label>
                <select id="selectState" class="form-control form-control-sm" formControlName="state" required
                  >
                  <option value="">Choose...</option>
                  <option *ngFor="let state of basicData.states_claim" value="{{ state.id }}">{{ state.nameStateClaim }}
                  </option>
                </select>
                <div class="text-danger" [hidden]="!error.state">
                  {{error.state}}
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="inputBudget">Presupuesto</label>
                <input type="text" class="form-control form-control-sm" id="inputBudget" required
                formControlName="budget"  placeholder="Presupuesto">
                <div class="text-danger" [hidden]="!error.budget">
                  {{error.budget}}
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="inputSinister">Siniestro</label>
                <input type="text" class="form-control form-control-sm" id="inputSinister" required
                formControlName="sinister"  placeholder="Siniestro">
                <div class="text-danger" [hidden]="!error.sinister">
                  {{error.sinister}}
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="inputManager">Nombre quien gestiona</label>
                <select name="inputManager" class="form-control form-control-sm" id="inputManager" required
                formControlName="nameManages"  disabled>
                  <option value="">Choose...</option>
                  <option *ngFor="let user of users" value="{{user.id}}">{{user.name}}</option>
                </select>
                <div class="text-danger" [hidden]="!error.nameManager">
                  {{error.nameManager}}
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="inputAnalyst">Analista de radicación</label>
                <input type="text" class="form-control form-control-sm" id="inputAnalyst"
                formControlName="file_analyst"
                   placeholder="Nombre de analista" required>
                <div class="text-danger" [hidden]="!error.file_analyst">
                  {{error.file_analyst}}
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="inputAccidentZone">Zona de accidente</label>
                <input type="text" class="form-control form-control-sm" id="inputAccidentZone"
                formControlName="accident_zone"
                 placeholder="Zona de accidente" required>
                <div class="text-danger" [hidden]="!error.accident_zone">
                  {{error.accident_zone}}
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="inputDuration">Duración</label>
                <input type="text" class="form-control form-control-sm" id="inputDuration" formControlName="duration"
                  placeholder="Tiempo de duración" required>
                <div class="text-danger" [hidden]="!error.duration">
                  {{error.duration}}
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="exampleFormControlTextarea1">Añadir teléfono</label>
                <!-- <input type="text" class="form-control form-control-sm" id="exampleFormControlTextarea1" placeholder="#"
                formControlName="phones" > -->
                <input type="text" class="form-control form-control-sm" id="exampleFormControlTextarea1" placeholder="#"
                [(ngModel)]="phonesWrite" [ngModelOptions]="{standalone: true}">
              </div>
              <div class="col-md-6 scrollspy-obs">
                <p><b>Teléfonos agregados:</b></p>
                <div *ngFor="let phone of formGroup.controls['phones'].value" class="row">
                  <div class="col-sm-12">
                    <div class="phone">
                      <div class="col-sm-12">
                        &#10022;{{ phone.phoneNumber }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <a href="#" class="btn btn-primary" (click)="addPhone($event)">Agregar Teléfono</a>
          </div>
        </div>
      </div>
      <hr>
      <div *ngIf="formGroup.controls['id'].value" class="col-sm-12">
        <div class="card" style="margin-left: 1px">
          <div class="card-header header-color text-center">
            <h6>OBSERVACIONES GRABACIÓN</h6>
          </div>
          <div class="card-body">
            <div class="form-row">
              <div class="form-group col-md-6">
                <label><b>Añadir observación</b></label>
                <nz-comment>
                  <nz-avatar nz-comment-avatar nzIcon="user" style="background-color:#5488e7;"></nz-avatar>
                  <nz-comment-content>
                    <nz-form-item>
                      <textarea [(ngModel)]="obsData.comment" nz-input rows="4"
                      (keyup)="obsData.comment = obsData.comment.toUpperCase()"  [ngModelOptions]="{standalone: true}"></textarea>
                    </nz-form-item>
                    <nz-form-item>
                      <button nz-button nzType="primary" [nzLoading]="submitting" [disabled]="!obsData.comment"
                        (click)="addObservation($event)">
                        Agregar Observación
                      </button>
                    </nz-form-item>
                  </nz-comment-content>
                </nz-comment>
              </div>
              <div class="col-md-6 scrollspy-obs">
                <nz-list *ngIf="observaciones.length" [nzDataSource]="observaciones"
                  (nzPageIndexChange)="loadData($event)" [nzItemLayout]="'horizontal'">
                  <nz-list-item *ngFor="let item of observaciones">
                    <nz-comment [nzAuthor]="item.name" [nzDatetime]="item.registration_date">
                      <nz-avatar nz-comment-avatar [nzShape]="'square'" nzIcon="user" style="background-color:#59e754;">
                      </nz-avatar>
                      <nz-comment-content>
                        <p style="background: #ECECEC;padding:10px;">{{ item.observation }}</p>
                      </nz-comment-content>
                    </nz-comment>
                  </nz-list-item>
                </nz-list>
              </div>
            </div>
          </div>
        </div>
        <nz-collapse nzGhost>
          <nz-collapse-panel nzHeader="OBSERVACIONES RECLAMO">
            <div class="card" style="margin-left: 1px">
              <div class="card-header header-color text-center">
                <h6>OBSERVACIONES RECLAMO #{{idclaim}}</h6>
              </div>
              <div class="card-body">
                <div class="form-row">
                  <div class="form-group col-md-3"></div>
                  <div class="col-md-6 scrollspy-obs">
                    <nz-list [nzDataSource]="obseClaim"
                      (nzPageIndexChange)="loadData($event)" [nzItemLayout]="'horizontal'">
                      <nz-list-item *ngFor="let item of obseClaim">
                        <nz-comment [nzAuthor]="item.name" [nzDatetime]="item.registration_date">
                          <nz-avatar nz-comment-avatar [nzShape]="'square'" nzIcon="user" style="background-color:#59e754;">
                          </nz-avatar>
                          <nz-comment-content>
                            <p style="background: #ECECEC;padding:10px;">{{ item.observation }}</p>
                          </nz-comment-content>
                        </nz-comment>
                      </nz-list-item>
                    </nz-list>
                  </div>
                </div>
              </div>
            </div>
          </nz-collapse-panel>
        </nz-collapse>
      </div>
      <br>
      <div class="col-sm-12" style="margin-bottom: 20px">
        <div class="row justify-content-md-center align-items-center">
          <div class="btn-group" role="group" aria-label="Basic example">
            <a href="#" routerLink="/recordings" class="btn btn-secondary">Volver</a>
            <button class="btn btn-info" [disabled]="!claimForm.valid" *ngIf="!formGroup.controls['id'].value">Guardar</button>
            <button (click)="saveObservations()" class="btn btn-info" [disabled]="!claimForm.valid" *ngIf="formGroup.controls['id'].value">Actualizar</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
