import { Component, OnInit } from '@angular/core';
import { RowsMovilService } from 'src/app/Services/report-accidents/rows-movil.service';
import { SnotifyService } from 'ng-snotify';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-form-movil',
  templateUrl: './form-movil.component.html',
  styleUrls: ['./form-movil.component.css']
})
export class FormMovilComponent implements OnInit {

  public form = {
    id: null, arrivalTimeMovil: null, availability: '', endTimeMovil: null, reportTimeMovil: null
  }
  public error = {availability: null};
  public lawyers: any[];
  public movil: any;
  constructor(private MovilService: RowsMovilService, private Notify: SnotifyService, private route: ActivatedRoute) { 
    this.form.id = route.snapshot.params["id"];
    this.movil = route.snapshot.params["service"] == 'movil' ? true : false;
  }

  ngOnInit() {
    this.sendFill();
    if(this.movil){
      $("html, body").animate({ scrollTop: $('#movil').offset().top - 20 }, 1000);
    }
  }

  onSubmit(e: Event){
    e.preventDefault();
    this.form.arrivalTimeMovil = $('#inputDateArrivalMovil').val();
    this.form.endTimeMovil = $('#inputEndTimeMovil').val();
    this.form.reportTimeMovil = $('#inputReportTimeMovil').val();
    this.Notify.info('Cargando...', {timeout:0});
    this.MovilService.update(this.form).subscribe(
      data => this.handleResponse(data),
      err => this.handleError(err)
    );
  }

  sendFill(){
    if(this.form.id != null){
      let data = {
        id: this.form.id
      }
      this.MovilService.select(data).subscribe(
        data => this.fillData(data),
        err => this.fillDataError()
      );
    }
  }

  fillData(data){
    this.Notify.remove();
    if(data.id){
      this.form  = data;
    }
  }

  fillDataError(){
    this.Notify.remove();
    this.Notify.error('Algo ha salido mal: err MOVIL', {timeout: 5000});
  }

  handleResponse(data){
    this.Notify.remove();
    this.Notify.success('Exito!');
  }

  handleError(err){
    this.Notify.remove();
    this.error = err.error.errors;
    //console.clear();
  }

}
