import { Component, OnInit } from '@angular/core';
import { StateInsuranceService } from 'src/app/Services/state-insurance/state-insurance.service';

// state insurance class with attributes
class RequestInsurance{
  nombres: string;
  apellidos: string;
  presupuesto: string;
  placa: string;
  analista: string;
  ramo: string;
}

@Component({
  selector: 'app-state-insurance',
  templateUrl: './state-insurance.component.html',
  styleUrls: ['./state-insurance.component.css']
})
export class StateInsuranceComponent implements OnInit {
  
  loading: boolean = false;
  request: RequestInsurance[];
  listOfData: { data: RequestInsurance[], total: number, current_page:number } = {} as any

  constructor(
    private StateInsurance: StateInsuranceService
  ) { }

  ngOnInit(): void {
    this.loadData();
  }

  /**
   * Método para cargar y paginar los seguros del estado.
   * @param current_page
   * @param value
   * @returns arreglo de seguros del estado
   * @author Esteban Rodriguez <johan.rodriguez@holdingvml.com>
   */
   loadData( current_page: number = 1 , value: string = ''): void {
    this.loading = true
    this.StateInsurance.obtenerRequestInsurance(current_page,value).subscribe(data => {
      this.listOfData = data;
      this.loading = false
    }
    )
  }

}
