<div class="col-sm-12" id="pwa">
    <div class="card" style="margin-left: 1px">
        <div class="card-header header-color text-center">
            <h6>PWA</h6>
        </div>
        <div class="card-body">
            <form [formGroup]="formGroup">
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="selectCoordinator">Coordinador:</label>
                        <select name="selectCoordinator" id="selectCoordinator" class="form-control form-control-sm" required
                            formControlName="coordinator">
                            <option value="">Choose...</option>
                            <option *ngFor="let coordinator of coordinators | async" value="{{coordinator.id}}">{{coordinator.name}}</option>
                        </select>
                        <div class="text-danger" [hidden]="!error.lawyer">
                            {{error.coordinator}}
                        </div>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="inputReportTimePwa">Hora de Reporte:</label>
                        <input type="text" class=" datePick form-control form-control-sm" id="inputReportTimePwa" name="inputReportTimePwa"
                            formControlName="reportTimePwa" [owlDateTime]="dt3" [owlDateTimeTrigger]="dt3">
                            <owl-date-time #dt3></owl-date-time>
                    </div>
                    <div class="form-group col-md-3">
                        <label for="inputEndTimePwa">Hora de Contacto:</label>
                        <input type="text" class=" datePick form-control form-control-sm" id="inputEndTimePwa" name="inputEndTimePwa"
                            formControlName="endTimePwa" [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2">
                            <owl-date-time #dt2></owl-date-time>
                    </div>
                    <div class="form-group col-md-3">
                        <label for="inputDateArrivalPwa">Agente en Sitio:</label>
                        <input type="text" class="datePick form-control form-control-sm" id="inputDateArrivalPwa" name="inputDateArrivalPwa"
                            formControlName="arrivalTimePwa" [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1">
                            <owl-date-time #dt1></owl-date-time>
                    </div>
                    <div class="form-group col-md-3">
                        <label for="selectAvailabilityPwa">Disponibilidad:</label>
                        <select name="selectAvailabilityPwa" id="selectAvailabilityPwa" class="form-control form-control-sm" required
                                formControlName="availabilityPwa">
                            <option value="">Choose...</option>
                            <option value="1">Disponible</option>
                            <option value="2">No Disponible</option>
                            <option value="3">Fuera de cobertura</option>
                            <option value="5">Retiro del lugar de los hechos</option>
                            <option value="6">Cancelado</option>
                            <option value="4">NA</option>
                        </select>
                        <small class="text-danger" *ngIf="error.availabilityPwa">{{ error.availabilityPwa }}</small>
                    </div>
                    <div class="col-md-1">
                        <label for="none"><hr></label>
                        <a href="#" (click)="onSubmit($event)" class="btn btn-info btn-sm">Guardar</a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
