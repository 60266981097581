<div class="card" style="margin-left: 1px">
  <div class="card-header header-color">

  </div>
  <ul class="list-group list-group-flush">
    <li class="list-group-item">
      <div class="float-left">
        <h4>Configuración de Novedades</h4>
      </div>
      <div class="float-right">
        <a href="#" routerLink="/new" class="btn btn-primary"><i class="fas fa-plus"></i> Nuevo</a>
      </div>
    </li>
  </ul>
  <div class="card-body">
    <!-- <table datatable [dtOptions]="dtOptions" class="table-sm table table-striped table-bordered row-border hover">
      <thead>
        <tr>
          <th>#</th>
          <th>Titulo</th>
          <th>Subtitulo</th>
          <th>Cuerpo</th>
          <th style="width: 1px;">Acción</th>
        </tr>
      </thead>
      <tbody *ngIf="News?.length > 0">
        <tr *ngFor="let new of News">
          <td>{{ new.id }}</td>
          <td>{{ new.title }}</td>
          <td>{{ new.subtitle }}</td>
          <td>
            <ul *ngFor="let point of new.news_point">
              <li>{{ point.point }}</li>
            </ul>
          </td>
          <td>
            <a href="#" routerLink="/new/{{new.id}}"><i class="fas fa-edit"></i></a>
            <a href="#" style="color: red" (click)="deleteUser($event, new.id)"><i class="fas fa-minus-circle"></i></a>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="News?.length < 1">
        <tr>
          <td colspan="4" class="no-data-available">No hay datos disponibles!</td>
        </tr>
      <tbody>
    </table> -->
    <nz-input-group [nzSuffix]="suffixIconSearch" [formGroup]="formpol">
      <input type="text" #searchText nz-input placeholder="Buscar coincidencias (Título, Subtítulo)"
        formControlName="value">
    </nz-input-group>
    <ng-template #suffixIconSearch>
      <i nz-icon nzType="search"></i>
    </ng-template>
    <nz-table [nzData]="listOfData.data" [nzFrontPagination]="false" [nzShowSizeChanger]="true"
      [nzTotal]="listOfData.total" (nzPageIndexChange)="loadData($event)" (nzPageSizeChange)="adjustPageSize($event)"
      nzLoading="{{load}}">
      <thead>
        <tr>
          <th>#</th>
          <th>Titulo</th>
          <th>Subtitulo</th>
          <th>Cuerpo</th>
          <th style="width: 1px;">Acción</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let other of listOfData.data">
          <td>{{ other.id }}</td>
          <td>{{ other.title }}</td>
          <td>{{ other.subtitle }}</td>
          <td>
            <button nz-button class="buttonGbg" (click)="modalRegister.showModalNews(other.news_point,other.title)">></button>
          </td>
          <td>
            <a href="#" routerLink="/new/{{other.id}}"><i class="fas fa-edit"></i></a>
            <a href="#" style="color: red" (click)="deleteUser($event, other.id)"><i class="fas fa-minus-circle"></i></a>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>
