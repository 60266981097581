import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientService } from 'src/app/Services/clients/client.service';
import { SnotifyService } from 'ng-snotify';
import { TokenService } from 'src/app/Services/token.service';
import { RowsAsistaService } from 'src/app/Services/report-accidents/rows-asista.service';

@Component({
  selector: 'app-client-form',
  templateUrl: './client-form.component.html',
  styleUrls: ['./client-form.component.css']
})
export class ClientFormComponent implements OnInit {
  public error = {
    nit: null,
    name: null,
    address: null,
    phone: null
  };
  public form = {
    id:null,
    nit: null,
    name: null,
    address: null,
    phone: null,
    firms: []
  }
  public firmsGeneral: any;
  constructor(
    private route: ActivatedRoute,
    private Client: ClientService,
    private Notify: SnotifyService,
    private Token: TokenService,
    private router: Router,
    private AsistaService: RowsAsistaService
  ) {
    this.form.id = route.snapshot.params["id"];
  }

  ngOnInit() {
    this.sendFill();
    this.fillFirmsLawyer();
  }

  onSubmit(){
    this.Notify.info('Espere...', {timeout:0});
    if(this.form.id != null){
      this.Client.update(this.form).subscribe(
        data => this.handleResponse(data),
        err => this.handleError(err)
      );
    }else{
      this.Client.create(this.form).subscribe(
        data => this.handleResponse(data),
        err => this.handleError(err)
      );
    }
  }

  handleResponse(data){
    data.status ? this.logout() : '';
    this.Notify.remove();
    this.Notify.success('Exito!', {timeout: 2000});
  }

  logout(){
    this.Token.remove();
    this.router.navigateByUrl('/login');
    setTimeout(()=>{
      window.location.reload();
    }, 100);
  }

  handleError(error){
    this.Notify.remove();
    this.error = error.error.errors;
  }

  sendFill(){
    if(this.form.id != null){
      this.Notify.info('Espere...', {timeout:0});
      let data = {
        id: this.form.id
      }
      this.Client.select(data).subscribe(
        data => this.fillData(data),
        err => this.fillDataError()
      );
    }
  }

  fillData(data){
    this.Notify.remove();
    if(data.name){
      this.form.name = data.name;
      this.form.nit = data.nit;
      this.form.address = data.address;
      this.form.phone = data.phone;
      this.form.firms = data.firms;
    }else{
      this.fillDataError();
    }
    if(data.status){
      this.logout();
    }
  }

  fillDataError(){
    this.Notify.remove();
    this.Notify.error('Algo ha salido mal', {timeout: 5000});
  }

  fillFirmsLawyer(){
    this.AsistaService.getAllFirms().subscribe(
      data => {
        this.firmsGeneral = data.data;
        console.log(this.firmsGeneral)
      }
    );
  }

  addFirm(Event: MouseEvent){
    Event.preventDefault();
    this.form.firms.unshift({id: $('#firm').val(), firm: $('#firm option:selected').text()});
  }

}
