<div class="mt-4 col-8 offset-2">
    <div class="card">
        <div class="card-header header-color" *ngIf="!form.id">Register Here</div>
        <div class="card-header header-color" *ngIf="form.id">Update Here</div>
        <div class="card-body">
            <form #personForm="ngForm" (ngSubmit)="onSubmit()">
                <div class="form-group row">
                    <label for="inputPlate" class="col-sm-3 col-form-label">Placa</label>
                    <div class="col-sm-9">
                        <input type="text" name="plate" class="form-control" id="inputPlate"
                            placeholder="Placa" [(ngModel)]="form.plate" required>
                        <div class="text-danger" [hidden]="!error.plate">
                            {{error.plate}}
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="inputName" class="col-sm-3 col-form-label">Nombre</label>
                    <div class="col-sm-9">
                        <input type="text" name="name" class="form-control" id="inputName"
                            placeholder="Nombre" [(ngModel)]="form.name" required>
                        <div class="text-danger" [hidden]="!error.name">
                            {{error.name}}
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="typeId" class="col-sm-3 col-form-label">Tipo de Documento</label>
                    <div class="col-sm-9">
                        <select class="form-control" name="typeId" id="typeId" [(ngModel)]="form.id_type" required>
                            <option value="">Seleccionar...</option>
                            <option *ngFor="let id_type of id_types" value={{id_type.id}}>{{ id_type.name }}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="inputDocument" class="col-sm-3 col-form-label">Número de Documento</label>
                    <div class="col-sm-9">
                        <input type="number" name="inputDocument" class="form-control" id="inputDocument"
                            placeholder="Documento" [(ngModel)]="form.document_number" required>
                        <div class="text-danger" [hidden]="!error.document_number">
                            {{error.document_number}}
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="inputPolicy" class="col-sm-3 col-form-label">Póliza</label>
                    <div class="col-sm-9">
                        <input type="number" name="policy" class="form-control" id="inputPolicy"
                            placeholder="Póliza" [(ngModel)]="form.policy" required>
                        <div class="text-danger" [hidden]="!error.policy">
                            {{error.policy}}
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="inputPhone" class="col-sm-3 col-form-label">Telefono</label>
                    <div class="col-sm-9">
                        <input type="number" name="phone" class="form-control" id="inputPhone"
                            placeholder="Teléfono" [(ngModel)]="form.phone" required>
                        <div class="text-danger" [hidden]="!error.phone">
                            {{error.phone}}
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="inputAddress" class="col-sm-3 col-form-label">Dirección</label>
                    <div class="col-sm-9">
                        <input type="text" name="address" class="form-control" id="inputAddress"
                            placeholder="Dirección" [(ngModel)]="form.address" required>
                        <div class="text-danger" [hidden]="!error.address">
                            {{error.address}}
                        </div>
                    </div>
                </div>
                <hr>
                <h6>Vinculación Persona Natural</h6>
                <div class="form-group row">
                    <label for="bonding" class="col-sm-3 col-form-label">Vinculación</label>
                    <div class="col-sm-9">
                        <select class="form-control" name="bonding" id="bonding" [(ngModel)]="form.bonding" required>
                            <option value="1">Beneficiario</option>
                            <option value="2">Cliente</option>
                        </select>
                    </div>
                </div>
                <div class="form-group row" *ngIf="form.bonding === 1">
                    <label for="selectBene" class="col-sm-3 col-form-label">Beneficiario</label>
                    <div class="col-sm-9">
                        <select class="form-control" name="selectBene" id="selectBene" [(ngModel)]="form.beneficiarie" required>
                            <option value="">Seleccionar...</option>
                            <option *ngFor="let beneficiarie of beneficiaries" value={{beneficiarie.id}}>{{ beneficiarie.name }}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group row" *ngIf="form.bonding === 2">
                    <label for="selectClient" class="col-sm-3 col-form-label">Cliente</label>
                    <div class="col-sm-9">
                        <select class="form-control" name="selectClient" id="selectClient" [(ngModel)]="form.client" required>
                            <option value="">Seleccionar...</option>
                            <option *ngFor="let client of clients" value={{client.id}}>{{ client.name }}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-10 offset-2">
                        <button type="submit" class="btn btn-primary" [disabled]="!personForm.valid" *ngIf="!form.id">Registrar</button>
                        <button type="submit" class="btn btn-primary" [disabled]="!personForm.valid" *ngIf="form.id">Actualizar</button>
                        <a href="#" class="float-right btn btn-info" routerLink="/persons">Volver</a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
