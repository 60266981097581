<div class="card" style="margin-left: 1px">
  <div class="card-header header-color">

  </div>
  <ul class="list-group list-group-flush">
    <li class="list-group-item">
      <div class="float-left">
        <h4>Lista de Abogados</h4>
      </div>
      <div class="float-right">
        <a href="#" routerLink="/lawyer" class="btn btn-primary">Registrar Nuevo</a>
      </div>
    </li>
  </ul>
  <div class="card-body">
    <!-- <table datatable [dtOptions]="dtOptions" class="table table-striped table-bordered row-border hover">
      <thead>
        <tr>
          <th>Nombre</th>
          <th>Teléfono</th>
          <th>Firma</th>
          <th style="width: 1px;">Acción</th>
        </tr>
      </thead>
      <tbody *ngIf="lawyers?.length > 0">
        <tr *ngFor="let lawyer of lawyers">
          <td>{{ lawyer.name }}</td>
          <td>{{ lawyer.phone }}</td>
          <td>{{ lawyer.firm }}</td>
          <td>
            <a href="#" routerLink="/lawyer/{{lawyer.id}}"><i class="fas fa-edit"></i></a>
            <a href="#" style="color: red" (click)="deleteUser($event, lawyer.id)"><i
                class="fas fa-minus-circle"></i></a>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="lawyers?.length < 1">
        <tr>
          <td colspan="4" class="no-data-available">No hay datos disponibles!</td>
        </tr>
      <tbody>
    </table> -->
    <nz-input-group [nzSuffix]="suffixIconSearch" [formGroup]="formpol">
      <input type="text" #searchText nz-input placeholder="Buscar coincidencias (Nombre, Teléfono)"
        formControlName="value">
    </nz-input-group>
    <ng-template #suffixIconSearch>
      <i nz-icon nzType="search"></i>
    </ng-template>
    <nz-table [nzData]="listOfData.data" [nzFrontPagination]="false" [nzShowSizeChanger]="true"
      [nzTotal]="listOfData.total" (nzPageIndexChange)="loadData($event)" (nzPageSizeChange)="adjustPageSize($event)"
      nzLoading="{{loading}}">
      <thead>
        <tr>
          <th>Nombre</th>
          <th>Teléfono</th>
          <th>Firma</th>
          <th style="width: 1px;">Acción</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let other of listOfData.data">
          <td>{{ other.name }}</td>
          <td>{{ other.phone }}</td>
          <td>{{ other.firm }}</td>
          <td>
            <a href="#" routerLink="/lawyer/{{other.id}}"><i class="fas fa-edit"></i></a>
            <a href="#" style="color: red" (click)="deleteUser($event, other.id)"><i
                class="fas fa-minus-circle"></i></a>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>
