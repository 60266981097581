import { Component, OnInit } from '@angular/core';
import { RowsDigipatService } from 'src/app/Services/report-accidents/rows-digipat.service';
import { SnotifyService } from 'ng-snotify';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';
import { Coordinator } from 'src/app/models/coordinator';

@Component({
  selector: 'app-form-digipat',
  templateUrl: './form-digipat.component.html',
  styleUrls: ['./form-digipat.component.css']
})
export class FormDigipatComponent implements OnInit {

  // public form = {
  //   id: null, coordinator: <any> '', arrivalTimeDigipat: null, state: '', endTimeDigipat: null,
  //   reportTimeDigipat: null, availabilityDigipat: ''
  // }

  formGroup: FormGroup = new FormGroup({
    id: new FormControl(),
    coordinator: new FormControl(),
    arrivalTimeDigipat: new FormControl(),
    state: new FormControl(),
    endTimeDigipat: new FormControl(),
    reportTimeDigipat: new FormControl(),
    availabilityDigipat: new FormControl()
  })

  public error = {coordinator: null, availabilityDigipat: null};
  public coordinators: Observable<Coordinator[]> = this.DigipatService.coordinators;
  public lawyers: any[];
  public digipat: any;
  constructor(private DigipatService: RowsDigipatService, private Notify: SnotifyService, private route: ActivatedRoute,private datePipe: DatePipe) {
    this.formGroup.controls['id'].setValue(parseInt(route.snapshot.params["id"]))
    this.digipat = route.snapshot.params["service"] == 'digipat' ? true : false;
  }

  ngOnInit() {
    this.sendFill();
    if(this.digipat){
      $("html, body").animate({ scrollTop: $('#digipat').offset().top - 20 }, 1000);
    }
  }

  onSubmit(e: Event){
    e.preventDefault();
    this.Notify.info('Cargando...', {timeout:0});
    this.DigipatService.update(
      {
      ...this.formGroup.getRawValue(),
      arrivalTimeDigipat: this.datePipe.transform(this.formGroup.controls.arrivalTimeDigipat.value, 'YYYY-MM-dd HH:mm:ss'),
      endTimeDigipat: this.datePipe.transform(this.formGroup.controls.endTimeDigipat.value, 'YYYY-MM-dd HH:mm:ss'),
      reportTimeDigipat: this.datePipe.transform(this.formGroup.controls.reportTimeDigipat.value, 'YYYY-MM-dd HH:mm:ss')
      } // se formatea las fechas con datePipe y asi tomando los campos de formAsista y darles un formato amigable.
    ).subscribe(
      data => this.handleResponse(data),
      err => this.handleError(err)
    );
  }

  sendFill(){
    if(this.formGroup.controls['id'].value != null){
      let data = {
        id: this.formGroup.controls['id'].value
      }
      this.DigipatService.select(data).subscribe(
        data => this.fillData(data),
        err => this.fillDataError()
      );
    }
  }

  fillData(data){
    this.Notify.remove();
    if(data.id){
      console.log(data);
      this.formGroup.patchValue(
        {
          ...data,
          reportTimeDigipat: data.reportTimeDigipat ? new Date(data.reportTimeDigipat) : '',
          endTimeDigipat: data.endTimeDigipat ? new Date(data.endTimeDigipat) : '',
          arrivalTimeDigipat: data.arrivalTimeDigipat ? new Date(data.arrivalTimeDigipat) : ''
          //Se crea un nuevo objeto Date para convertirlo de nuevo a fecha de todo lo de data.
        });
    }
  }

  fillDataError(){
    this.Notify.remove();
    this.Notify.error('Algo ha salido mal: err DIGIPAT', {timeout: 5000});
  }

  handleResponse(data){
    this.Notify.remove();
    this.Notify.success('Exito!');
  }

  handleError(err){
    this.Notify.remove();
    this.error = err.error.errors;
    //console.clear();
  }
}
