<div class="card" style="margin-left: 1px">
  <div class="card-header header-color">

  </div>
  <ul class="list-group list-group-flush">
    <li class="list-group-item">
      <div class="float-left">
        <h4>Lista de Personas Naturales</h4>
      </div>
      <div class="float-right">
        <a href="#" routerLink="/person" class="btn btn-primary">Registrar Nuevo</a>
      </div>
    </li>
  </ul>
  <div class="card-body table-responsive">
    <!-- <table datatable [dtOptions]="dtOptions" class="table table-striped table-bordered row-border hover">
                <thead>
                    <tr>
                        <th>Placa</th>
                        <th>Nombre</th>
                        <th>Tipo de Documento</th>
                        <th>Número de Documento</th>
                        <th>Póliza</th>
                        <th>Teléfono</th>
                        <th>Dirección</th>
                        <th style="width: 1px;">Acción</th>
                    </tr>
                </thead>
                <tbody *ngIf="persons?.length > 0">
                    <tr *ngFor="let person of persons">
                        <td>{{ person.plate }}</td>
                        <td>{{ person.name }}</td>
                        <td>{{ person.id_type }}</td>
                        <td>{{ person.document_number }}</td>
                        <td>{{ person.policy }}</td>
                        <td>{{ person.phone }}</td>
                        <td>{{ person.address }}</td>
                        <td>
                            <a href="#" routerLink="/person/{{person.id}}"><i class="fas fa-edit"></i></a>
                            <a href="#" style="color: red" (click)="delete($event, person.id)"><i class="fas fa-minus-circle"></i></a>
                        </td>
                    </tr>
                </tbody>
            </table> -->
    <nz-input-group [nzSuffix]="suffixIconSearch" [formGroup]="formpol">
      <input type="text" #searchText nz-input placeholder="Buscar coincidencias (Placa, Nombre, N° documento)"
        formControlName="value">
    </nz-input-group>
    <ng-template #suffixIconSearch>
      <i nz-icon nzType="search"></i>
    </ng-template>
    <nz-table [nzData]="listOfData.data" [nzFrontPagination]="false" [nzShowSizeChanger]="true"
      [nzTotal]="listOfData.total" (nzPageIndexChange)="loadData($event)" (nzPageSizeChange)="adjustPageSize($event)"
      nzLoading="{{loading}}">
      <thead>
        <tr>
          <th>Placa</th>
          <th>Nombre</th>
          <th>Tipo de Documento</th>
          <th>N° documento</th>
          <th>Póliza</th>
          <th>Teléfono</th>
          <th>Dirección</th>
          <th style="width: 1px;">Acción</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let other of listOfData.data">
          <td>{{ other.plate }}</td>
          <td>{{ other.name }}</td>
          <td>{{ other.id_type }}</td>
          <td>{{ other.document_number }}</td>
          <td>{{ other.policy }}</td>
          <td>{{ other.phone }}</td>
          <td>{{ other.address }}</td>
          <td>
            <a href="#" routerLink="/person/{{other.id}}"><i class="fas fa-edit"></i></a>
            <a href="#" style="color: red" (click)="delete($event, other.id)"><i class="fas fa-minus-circle"></i></a>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>
