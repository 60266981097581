<div class="containerOther">
    <form action="" [formGroup]="formStateInsurance">
        <div class="card-header header-color text-center">
              <h6>SOLICITUD SEGUROS DEL ESTADO</h6>
        </div>
        <div class="card-body">
            <div class="row form-group">
                <div class="col-md-3 offset-1">
                    <label for="nombres">Nombre</label>
                    <input  name="nombres" id="nombres" type="text" formControlName="nombres" class="form-control" required placeholder="Nombre">
                </div>
                <div class="col-md-3">
                    <label for="departament">Apellido</label>
                    <input  name="apellidos" id="apellidos" type="text" class="form-control" formControlName="apellidos" required placeholder="Apellido">
                </div>
                <div class="col-md-3">
                    <label for="">Presupuesto</label>
                    <input  name="presupuesto" id="presupuesto" type="text" class="form-control" formControlName="presupuesto" required placeholder="Presupuesto">
                </div>
                <div class="col-md-3 offset-1">
                    <label for="">Placa</label>
                    <input  name="placa" id="placa" type="text" class="form-control" formControlName="placa" required placeholder="Placa">
                </div>
                <div class="col-md-3">
                    <label for="cliente">Analista</label>
                    <input  name="analista" id="analista" type="text" class="form-control" formControlName="analista" required placeholder="Analista">
                </div>
                <div class="col-md-3">
                    <label for="">Ramo</label> 
                    <select name="ramo_id" id="ramo_id" class="form-control" required formControlName="ramo_id">
                        <option value="">Seleccionar ramo</option>
                        <option *ngFor="let ramo of ramos" value="{{ ramo.id }}">{{ ramo.ramo }}</option> 
                    </select>
                </div>
                <div class="col-sm-12" style="padding-top: 30px;">
                    <div class="row justify-content-md-center align-items-center">
                        <div class="btn-group" role="group" aria-label="Basic example">
                            <a href="#" routerLink="/state-insurance" class="btn btn-secondary">Volver</a>
                            <button class="btn btn-primary form-control"  [disabled]="!formStateInsurance.valid" (click)="CrearSegurosEstado()"> Agregar</button>
                        </div>
                    </div>
                </div>     
            </div>
        </div>
    </form>