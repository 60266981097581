import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenService } from '../token.service';
import { Observable } from 'rxjs/internal/Observable';
import { Client } from 'src/app/models/client';
import { map, mergeAll, shareReplay, switchMap } from 'rxjs/operators';
import { defer, of, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

class DataTablesResponse {
  data: Client[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Injectable()
export class ClientService {

  public baseUrl = environment.url;

  /**
   * Variable para refrescar datos en cache
   *
   * @author Joe Alejandro Sierra <joe.sierra@holdingvml.net> 2022-05-26
   */
  private readonly refreshClientsSubject = new Subject();

  /**
   * Variable para crear observable a partir de unión de observables, con el cual se podrá consultar el api de clientes.
   * Esta varibale será usada como cache para mantener la información de clientes.
   * Para actualizar la información se deberá usar refreshClientsSubject, evitando recargar la página.
   *
   * @author Joe Alejandro Sierra <joe.sierra@holdingvml.net> 2022-05-26
   */
  clients = of(
    this.getClients([]).pipe(
      map(value => value.data)
    ),
    defer(() => this.refreshClientsSubject.pipe(
      switchMap(() => this.getClients([]).pipe(
        map(value => value.data)
      )))
    )
  ).pipe(
    mergeAll(),
    shareReplay(1),
  );

  header(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer `+this.token.get())
    }
  }

  constructor(private http:HttpClient, private token: TokenService) { }

  /**
   * Refrescar variable clientes mediante el Subject de refreshClientsSubject
   */
  refreshClients(): void {
    this.refreshClientsSubject.next();
  }

  getClients(data): Observable<DataTablesResponse>{
    return this.http.post<DataTablesResponse>(`${this.baseUrl}/clients`, data, this.header())
  }

  getClientsngzorro(nextPage, value, pageSize){
    return this.http.get<any>(`${this.baseUrl}/clients?page=${nextPage}&value=${value}&pageSize=${pageSize}`, this.header());
  }

  create(data){
    return this.http.post(`${this.baseUrl}/client/create`, data, this.header());
  }

  update(data){
    return this.http.post(`${this.baseUrl}/client/update`, data, this.header());
  }

  select(data){
    return this.http.post(`${this.baseUrl}/client/select`, data, this.header());
  }

  delete(data){
    return this.http.post(`${this.baseUrl}/client/delete`, data, this.header());
  }

}
