import { Injectable } from '@angular/core';
import { JarwisService } from './jarwis.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { User } from '../models/user';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class TokenService {
  public baseUrl = environment.url;
  private iss = {
    login: `${this.baseUrl}/login`,
    signup: `${this.baseUrl}/signup`
  };
  constructor(private Jarwis: JarwisService, private http:HttpClient, private router: Router) { }

  handle(token){
    this.set(token);
  }

  set(token){
    localStorage.setItem('token', token);
  }

  get(){
    return localStorage.getItem('token');
  }

  remove(){
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  }

  isValid(){
    const token = this.get();
    if(token){
      const payload = this.payload(token);
      if(payload){
        return Object.values(this.iss).indexOf(payload.iss) > -1 ? true : false;
      }
    }
    return false;
  }

  payload(token){
    const payload = token.split('.')[1];

    return this.decode(payload);
  }

  decode(payload){
    return JSON.parse(atob(payload));
  }

  loggedIn(){
    return this.isValid();
  }

  searchUser(){
    let header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer `+this.get())
    }
    return this.http.get(`${this.baseUrl}/user`, header);
  }

  logout(){
    this.remove();
    this.router.navigateByUrl('/login');
    setTimeout(()=>{
      window.location.reload();
    }, 200);
  }

  /**
   * Método para guardar usuario en la sesión
   *
   * @param user usuario a almacenar
   */
  setUser(user: User): void {
    localStorage.setItem('user', JSON.stringify(user));
  }

  /**
   * Método para obtener usuario en sesión
   */
  getUser(): User {
    return JSON.parse(localStorage.getItem('user'))
  }

}
