import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { RowsAsistaService } from 'src/app/Services/report-accidents/rows-asista.service';

@Component({
  selector: 'app-form-asista',
  templateUrl: './form-asista.component.html',
  styleUrls: ['./form-asista.component.css'],
})
export class FormAsistaComponent implements OnInit, OnChanges {

  // public form = {
  //       id:null, firm: <any> '', lawyer: <any> '', arrivalTime: null, typeAsista: '',
  //       endTime: null, phoneLawyer: null, client: $('#selectClient').val(), reportTime: null,
  //       sinister: '', budget: '', consecutive_app: ''
  // }
  @Output() formFilled: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() liveEvent: EventEmitter<void> = new EventEmitter<void>();
  formAsista: FormGroup = new FormGroup({
    id: new FormControl('', [Validators.required]),
    firm: new FormControl('', [Validators.required]),
    lawyer: new FormControl('', [Validators.required]),
    arrivalTime: new FormControl('', []),
    typeAsista: new FormControl('', [Validators.required]),
    endTime: new FormControl('', []),
    phoneLawyer: new FormControl('', [Validators.required]),
    client: new FormControl('', []),
    reportTime: new FormControl('', [Validators.required]),
    sinister: new FormControl('', []),
    budget: new FormControl('', []),
    consecutive_app: new FormControl('', [])
  })

  public error = { lawyer: null, typeAsista: '' };
  public firms: any[];
  public lawyers: any[];
  public asista: any;
  constructor(
    private AsistaService: RowsAsistaService,
    private Notify: SnotifyService,
    private route: ActivatedRoute,
    private datePipe: DatePipe
  ) {
    this.formAsista.controls.id.patchValue(route.snapshot.params["id"])
    this.asista = route.snapshot.params["service"] == 'asista' ? true : false;
  }

  @Input() data: any

  ngOnInit() {
    this.sendFill();
    if (this.asista) {
      $("html, body").animate({ scrollTop: $('#asista').offset().top - 20 }, 1000);
    }
    this.fillFirmsLawyer();
  }

  ngOnChanges(ngChanges: SimpleChanges): void {
    this.loadData(ngChanges.data.currentValue)
  }

  loadData(form): void {
    if (form.client == '21') {
      this.formAsista.controls.sinister.setValidators(Validators.required)
      this.formAsista.controls.budget.setValidators(Validators.required)
      this.formAsista.controls.consecutive_app.setValidators(Validators.required)
    } else {
      this.formAsista.controls.sinister.setValidators(null)
      this.formAsista.controls.budget.setValidators(null)
      this.formAsista.controls.consecutive_app.setValidators(null)
    }
  }

  onSubmit(e: Event) {
    e.preventDefault();
    this.Notify.info('Cargando...', { timeout: 0 });
    this.AsistaService.update(
      {
        ...this.formAsista.getRawValue(),
        arrivalTime: this.datePipe.transform(this.formAsista.controls.arrivalTime.value, 'YYYY-MM-dd HH:mm:ss'),
        endTime: this.datePipe.transform(this.formAsista.controls.endTime.value, 'YYYY-MM-dd HH:mm:ss'),
        reportTime: this.datePipe.transform(this.formAsista.controls.reportTime.value, 'YYYY-MM-dd HH:mm:ss')
      } // se formatea las fechas con datePipe y asi tomando los campos de formAsista y darles un formato amigable.

    ).subscribe(
      data => this.handleResponse(data),
      err => this.handleError(err)
    );
  }

  fillPhoneLawyer(id) {
    for (let i = 0; i < this.lawyers.length; i++) {
      if (this.lawyers[i].id == id) {
        this.formAsista.controls.phoneLawyer.patchValue(this.lawyers[i].phone)
        return false;
      }
    }
  }

  /* sendFill(){
    if(this.formAsista.controls.id.value != null){
      let data = {
        id: this.formAsista.controls.id.value
      }
      this.AsistaService.select(data).subscribe(
        data => this.fillData(data),
        err => this.fillDataError()
      );
    }
  } */

  sendFill() {
    if (this.formAsista.controls.id.value != null) {
      let data = {
        id: this.formAsista.controls.id.value
      }
      this.AsistaService.select(data).subscribe(
        data => {
          this.fillData(data);
        },
        err => this.fillDataError()
      );
    }
  }

  fillData(data) {
    this.Notify.remove();
    if (data.lawyer) {
      this.formAsista.patchValue(
        {
          ...data,
          arrivalTime: data.arrivalTime ? new Date(data.arrivalTime) : '',
          endTime: data.endTime ? new Date(data.endTime) : '',
          reportTime: data.reportTime ? new Date(data.reportTime) : ''
          //Se crea un nuevo objeto Date para convertirlo de nuevo a fecha de todo lo de data.
        });
      // Emitir el evento formFilled cuando los datos se llenan
      this.formFilled.emit(true);
      this.fillLawyers();
    }else{
      this.formFilled.emit(false);
    }
  }

  emitEvent() {
    // Emitir el evento en vivo
    this.liveEvent.emit();
  }

  fillDataError() {
    this.Notify.remove();
    this.Notify.error('Algo ha salido mal', { timeout: 5000 });
  }

  handleResponse(data) {
    this.Notify.remove();
    this.Notify.success('Exito!');
    this.emitEvent()
  }

  handleError(err) {
    this.Notify.remove();
    this.error = err.error.errors;
    //console.clear();
  }

  fillFirmsLawyer() {
    this.AsistaService.getFirms(this.formAsista.getRawValue()).subscribe(
      data => {
        this.firms = data.data;
        // this.fillLawyers();
      }
    );
  }

  fillLawyers() {
    this.AsistaService.getLawyerByFirm(this.formAsista.getRawValue()).subscribe(data => this.lawyers = data.data);
  }

}
