<div class="card" style="margin-left: 1px">
  <!-- modal -->
  <nz-modal [(nzVisible)]="isVisible" nzTitle="Encuesta" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()"
    nzFooter=".">
    <meta charset="utf-8">
    <ng-container *nzModalContent>
      <form nz-form [formGroup]="encuestaForm" class="login-form">
        <nz-form-item>
          <nz-form-control nzErrorTip="Debe Seleccionar">
            <p>{{questions.question1}}</p>
            <nz-select nzPlaceHolder="Seleccionar..." formControlName="pregunta1">
              <nz-option *ngFor="let option  of encuestaOptions" nzLabel="{{option.nombre}}"
                nzValue="{{option.valor}}"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control nzErrorTip="Debe Seleccionar">
            <p>{{questions.question2}}</p>
            <nz-select nzPlaceHolder="Seleccionar..." formControlName="pregunta2">
              <nz-option *ngFor="let option  of encuestaOptions" nzLabel="{{option.nombre}}"
                nzValue="{{option.valor}}"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control>
            <p>En caso de que su selección haya sido&nbsp;<strong><i>regular, malo, no aplica, no califica o no
                  contactado</i></strong>&nbsp;agregue la observación.</p>
            <textarea oninput="this.value = this.value.toUpperCase()" rows="3" nz-input formControlName="observacion"
              placeholder="Agregar Observación"></textarea>
          </nz-form-control>
        </nz-form-item>
        <button (click)="handleCancel()" nz-button nzType="default">Salir</button>
        <button style="float: right;" [disabled]="!encuestaForm.valid" (click)="handleOk()" nz-button
          nzType="primary">Guardar Encuesta</button>
      </form>

    </ng-container>
  </nz-modal>
  <!--end-modal  -->
  <div class="card-header header-color">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item">
        <a style="color: black;" class="nav-link active" id="general-tab" data-toggle="tab" href="#general" role="tab"
          aria-controls="general" aria-selected="true">Concluidos</a>
      </li>
      <li class="nav-item" *ngIf="profile_ID==1">
        <a style="color: black;" class="nav-link" id="calificate-tab" data-toggle="tab" href="#calificate" role="tab"
          aria-controls="calificate" aria-selected="false">Calificados</a>
      </li>
    </ul>
  </div>
  <div class="card-body">
    <div class="tab-content" id="myTabContent">
      <div class="tab-pane fade show active" id="general" role="tabpanel" aria-labelledby="general-tab">
        <div>
          <div class="float-left">
            <h5>Inf. General de casos sin realizar encuesta</h5>
          </div>
        </div>
        <br>
        <hr>
        <!-- <table datatable [dtOptions]="dtOptions" class="table table-striped table-bordered row-border hover">
          <thead>
            <tr>
              <th># Evento</th>
              <th>Placa</th>
              <th>Dirección Actual</th>
              <th>Tiempo Transcurrido</th>
              <th>Tipo Siniestro</th>
              <th>Estado</th>
              <th>Recibio Llamada</th>
              <th>Fecha de Registro</th>
              <th>Fecha de Fin</th>
              <th style="width: 1px;">Acción</th>
            </tr>
          </thead>
          <tbody *ngIf="cases?.length > 0">
            <tr *ngFor="let case of cases">
              <td>{{ case.id }}</td>
              <td>{{ case.plate }}</td>
              <td>{{ case.currentAddress }}</td>
              <td>{{ case.time }}</td>
              <td>{{ case.typeSinister }}</td>
              <td>
                <div class="alert alert-{{case.color}}" role="alert">
                  {{case.case_state}}
                </div>
              </td>
              <td>{{ case.received_call }}</td>
              <td>{{ case.registration_date }}</td>
              <td>{{ case.end_date }}</td>
              <td>
                <div class="row justify-content-md-center align-items-center">
                  <div class="btn-group" role="group" aria-label="Basic example">
                    <button [disabled]="case.allow === 0" id="idCase{{case.idC}}" class="btn btn-info btn-sm"
                      (click)="sendSurvey($event, case.id)" title="Realizar encuesta">
                      <i class="fas fa-check-square"></i>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="cases?.length < 1">
            <tr>
              <td colspan="10" class="no-data-available">No hay datos disponibles!</td>
            </tr>
          <tbody>
        </table> -->
        <nz-input-group [nzSuffix]="suffixIconSearch" [formGroup]="formpol">
          <input type="text" #searchText nz-input placeholder="Buscar coincidencias (Placa, Fecha reg, Nombre agente )"
            formControlName="value">
        </nz-input-group>
        <ng-template #suffixIconSearch>
          <i nz-icon nzType="search"></i>
        </ng-template>
        <nz-table [nzData]="listOfData.data" [nzFrontPagination]="false" [nzShowSizeChanger]="true"
          [nzTotal]="listOfData.total" (nzPageIndexChange)="loadData($event)"
          (nzPageSizeChange)="adjustPageSize($event)" nzLoading="{{loading}}">
          <thead>
            <tr>
              <th># Evento</th>
              <th>Placa</th>
              <th>Dirección Actual</th>
              <th>Tiempo Transcurrido</th>
              <th>Tipo Siniestro</th>
              <th>Estado</th>
              <th>Recibio Llamada</th>
              <th>Fecha de Registro</th>
              <th>Fecha de Fin</th>
              <th style="width: 1px;">Acción</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let case of listOfData.data">
              <td>{{ case.id }}</td>
              <td>{{ case.plate }}</td>
              <td>{{ case.currentAddress }}</td>
              <td>{{ case.time }}</td>
              <td>{{ case.typeSinister }}</td>
              <td>
                <div class="alert alert-{{case.color}}" role="alert">
                  {{case.case_state}}
                </div>
              </td>
              <td>{{ case.received_call }}</td>
              <td>{{ case.registration_date }}</td>
              <td>{{ case.end_date }}</td>
              <td>
                <div class="row justify-content-md-center align-items-center">
                  <div class="btn-group" role="group" aria-label="Basic example">
                    <button [disabled]="case.allow === 0" id="idCase{{case.idC}}" class="btn btn-info btn-sm"
                      (click)="sendSurvey($event, case.id)" title="Realizar encuesta">
                      <i class="fas fa-check-square"></i>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </nz-table>
        <br><br>
        <br><br>
        <br><br>
        <br><br>
        <br><br>
        <small>Pablo Developer.</small>
      </div>
      <div class="tab-pane fade " id="calificate" role="tabpanel" aria-labelledby="calificate-tab">
        <div>
          <div class="float-left">
            <h5>Inf. General de casos calificados</h5>
          </div>
        </div>
        <br>
        <hr>
        <!-- <table datatable [dtOptions]="dtOptions2" class="table table-striped table-bordered row-border hover">
          <thead>
            <tr>
              <th># Evento</th>
              <th>Placa</th>
              <th>Dirección Actual</th>
              <th>Tiempo Transcurrido</th>
              <th>Tipo Siniestro</th>
              <th>Estado</th>
              <th>Recibio Llamada</th>
              <th>Fecha de Registro</th>
              <th>Fecha de Fin</th>
              <th style="width: 1px;">Acción</th>
            </tr>
          </thead>
          <tbody *ngIf="casesCalificate?.length > 0">
            <tr *ngFor="let case of casesCalificate">
              <td>{{ case.id }}</td>
              <td>{{ case.plate }}</td>
              <td>{{ case.currentAddress }}</td>
              <td>{{ case.time }}</td>
              <td>{{ case.typeSinister }}</td>
              <td>
                <div class="alert alert-{{case.color}}" role="alert">
                  {{case.case_state}}/CALIFICADO
                </div>
              </td>
              <td>{{ case.received_call }}</td>
              <td>{{ case.registration_date }}</td>
              <td>{{ case.end_date }}</td>
              <td>
                <div class="row justify-content-md-center align-items-center">
                  <div class="btn-group" role="group" aria-label="Basic example">
                    <button [disabled]="case.allow === 0" id="idCase{{case.idC}}" class="btn btn-info btn-sm"
                      (click)="getAnswerCase($event, case.id)" title="Modificar Encuesta">
                      <i class="fas fa-check-square"></i>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="casesCalificate?.length < 1">
            <tr>
              <td colspan="10" class="no-data-available">No hay datos disponibles!</td>
            </tr>
          <tbody>
        </table> -->
        <nz-input-group [nzSuffix]="suffixIconSearch" [formGroup]="formpolcalificate">
          <input type="text" #searchText nz-input placeholder="Buscar coincidencias (Placa, Fecha reg, Nombre agente )"
            formControlName="value">
        </nz-input-group>
        <ng-template #suffixIconSearch>
          <i nz-icon nzType="search"></i>
        </ng-template>
        <nz-table [nzData]="listOfDataCalificate.data" [nzFrontPagination]="false" [nzShowSizeChanger]="true"
          [nzTotal]="listOfDataCalificate.total" (nzPageIndexChange)="loadDataCalificate($event)"
          (nzPageSizeChange)="adjustPageSizeCalificate($event)" nzLoading="{{loading}}">
          <thead>
            <tr>
              <th># Evento</th>
              <th>Placa</th>
              <th>Dirección Actual</th>
              <th>Tiempo Transcurrido</th>
              <th>Tipo Siniestro</th>
              <th>Estado</th>
              <th>Recibio Llamada</th>
              <th>Fecha de Registro</th>
              <th>Fecha de Fin</th>
              <th style="width: 1px;">Acción</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let case of listOfDataCalificate.data">
              <td>{{ case.id }}</td>
              <td>{{ case.plate }}</td>
              <td>{{ case.currentAddress }}</td>
              <td>{{ case.time }}</td>
              <td>{{ case.typeSinister }}</td>
              <td>
                <div class="alert alert-{{case.color}}" role="alert">
                  {{case.case_state}}/CALIFICADO
                </div>
              </td>
              <td>{{ case.received_call }}</td>
              <td>{{ case.registration_date }}</td>
              <td>{{ case.end_date }}</td>
              <td>
                <div class="row justify-content-md-center align-items-center">
                  <div class="btn-group" role="group" aria-label="Basic example">
                    <button [disabled]="case.allow === 0" id="idCase{{case.idC}}" class="btn btn-info btn-sm"
                      (click)="getAnswerCase($event, case.id)" title="Modificar Encuesta">
                      <i class="fas fa-check-square"></i>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </nz-table>
        <br><br>
        <br><br>
        <br><br>
        <br><br>
        <br><br>
        <small>Pablo Developer.</small>
      </div>
    </div>
  </div>
</div>
