import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenService } from 'src/app/Services/token.service';
import { environment } from 'src/environments/environment';

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private baseUrl = environment.url;

  constructor(private http:HttpClient, private Token: TokenService) { }

  setHeader(): Object {
    return {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer `+this.Token.get())
    }
  }

  getUser(data){
    return this.http.post(`${this.baseUrl}/userbyid`, data.data, this.setHeader());
  }

  getUsers(dataTablesParameters, token){
    return this.http
          .post<DataTablesResponse>(
            `${this.baseUrl}/users`,
            dataTablesParameters, this.setHeader()
          )
  }

  getUsersngzorro(nextPage, value, pageSize){
    return this.http.get<any>(`${this.baseUrl}/users?page=${nextPage}&value=${value}&pageSize=${pageSize}`, this.setHeader());
  }

  userUpdate(token, data){
    return this.http.post(`${this.baseUrl}/user/update`, data, this.setHeader());
  }

  deleteUser(token, data){
    return this.http.post(`${this.baseUrl}/user/delete`, data, this.setHeader());
  }

  resetPassword(data){
    return this.http.post(`${this.baseUrl}/guaRdvsUrrHuouzEczap`, data, this.setHeader());
  }

}
