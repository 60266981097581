import { Component, OnInit } from '@angular/core';
import { TokenService } from 'src/app/Services/token.service';
import { Router } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { OtherServiceService } from 'src/app/Services/otherServiceModule/other-service.service';
import { SwalService } from 'src/app/Services/swal/swal.service';
import { FormControl, FormGroup } from '@angular/forms';


class OtherService {
  id: number;
  name: string;
}

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Component({
  selector: 'app-options',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.css']
})
export class OptionsComponent implements OnInit {


  otherservices: OtherService[];
  dtOptions: DataTables.Settings;
  private idDelete;
  pageSize : number = 10
  listOfData: { data: OtherService[], total: number, current_page:number } = {} as any
  loading: boolean = false;

  formpol: FormGroup = new FormGroup({
    value: new FormControl('')
  })


  constructor(
    private token: TokenService,
    private router: Router,
    private OtherService: OtherServiceService,
    private swalService: SwalService,
    private notify: SnotifyService
  ) { }

  async deleteUser(e: MouseEvent, id){
    e.preventDefault();
    let option = await this.swalService.swalAlert("Advertencia", "¿Está seguro de que quiere eliminar este registro?", 'warning');

    let data = {
      id: id
    }
    this.idDelete = data.id;
    if(option){
      this.notify.info('Espere...', {timeout: 0});
      this.OtherService.delete(data).subscribe(
        res => this.handleResponse(data.id),
        err => this.handleError(err)
      );
    }
  }

  processDelete(){
    let c = 0;
    this.otherservices.forEach((element)=>{
        if(element.id == this.idDelete){
          this.otherservices.splice(c, 1);
          this.idDelete = null;
        }
        c++;
    });
  }

  handleError(err){

  }

  loadTable(){
    const that = this;
    this.dtOptions = {
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
          this.OtherService.getOtherServicesOptions(dataTablesParameters).subscribe(resp => {
            if(!resp.data){
              this.token.remove();
              this.router.navigateByUrl('/login');
              setTimeout(()=>{
                window.location.reload();
              }, 100);
            }
            that.otherservices = resp.data;
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
      },
      order: [1, 'ASC'],
      columns: [{ data: 'name' }, { data: 'action' }],
      "language": {
        "url": "https://cdn.datatables.net/plug-ins/1.10.20/i18n/Spanish.json"
    }
    };
  }

  ngOnInit(): void {
    //this.loadTable();
    this.loadData()
    this.formpol.controls['value'].valueChanges.subscribe(
      data => this.loadData(1, data)
    )
  }
  handleResponse(data){
    this.notify.remove()
    this.ngOnInit()
  }

  loadData(current_page: number = 1, value: string = ''): void {
    this.loading = true
    this.OtherService.getOtherServicesOptionsNgzorro(current_page, value,this.pageSize).subscribe(data => {
      this.listOfData = data;
      this.loading = false
    }
    )
  }
  adjustPageSize(pageSize : number): void{
    this.pageSize = pageSize;
    this.loadData();
  }

}
