import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { RecordingService } from 'src/app/Services/recordings/recording.service';
import { TokenService } from 'src/app/Services/token.service';
import Swal from 'sweetalert2';

class Recording {
  id: number;
  created_at: string;
  accident_date: string;
  name: string;
  plate_insured: string;
  file_analyst: string;
  budget: number;
  sinister: number;
  protection: string;
  city: string;
  home_dealership: string;
  nameManages: string;
}

@Component({
  selector: 'app-recordings',
  templateUrl: './recordings.component.html',
  styleUrls: ['./recordings.component.css']
})
export class RecordingsComponent implements OnInit {

  recordings: Recording[];
  dtOptions: DataTables.Settings;
  private idDelete;


  constructor(
    private token: TokenService,
    private router: Router,
    private RecordingService: RecordingService,
    private notify: SnotifyService
  ) { }

  ngOnInit(): void {
    //this.loadTable();
    this.loadData()
    this.userid()
    this.formpol.controls['value'].valueChanges.subscribe(
      data => this.loadData(1, data)
    )
  }

  loadData(current_page: number = 1, value: string = ''): void {
    this.loading = true
    this.RecordingService.getRecordingsNgzorro(current_page, value,this.pageSize).subscribe(data => {
      this.listOfData = data;
      this.loading = false
    }
    )
  }

  adjustPageSize(pageSize : number): void{
    this.pageSize = pageSize;
    this.loadData();
  }

  pageSize : number = 10
  listOfData: { data: any[], total: number, current_page:number } = {} as any
  loading: boolean = false;

  formpol: FormGroup = new FormGroup({
    value: new FormControl('')
  })

  profile_ID:number
  userid(){
    this.profile_ID=this.token.getUser().profile_ID
  }

  deleteRecording(Event: MouseEvent, id){
    Event.preventDefault();
    Swal.fire({
      allowOutsideClick:false,
      title: '¿Eliminar Grabación?',
      text: "No podrá Revertir!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, Borrar',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        this.RecordingService.deleteRecording(id).subscribe(data=>{
          console.log(data)
          Swal.fire({
            text:'Grabación Eliminada !!!',
            icon:'success',
            allowOutsideClick:false,
          }).then((result) => {
            if(result.isConfirmed){
              window.location.reload()
              //this.loadTable()
            }
          })

        })
      }
    })
  }

  loadTable(){
    const that = this;

    this.dtOptions = {
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.notify.info('Cargando...', {timeout: 0});
        that.RecordingService.getRecordings(dataTablesParameters).subscribe(resp => {
          this.notify.remove();
            if(!resp.data){
              this.token.remove();
              this.router.navigateByUrl('/login');
              setTimeout(()=>{
                window.location.reload();
              }, 100);
            }
            that.recordings = resp.data;
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
      },
      columns: [
            { data: 'id' }, { data: 'created_at_recording' }, { data: 'accident_date' }, { data: 'name' }, { data: 'plate_insured' },
            { data: 'file_analyst' }, { data: 'budget' }, { data: 'sinister' }, {data: 'protection'},
            { data: 'city' }, { data: 'home_dealership' }, { data: 'nameManages' }
          ],
      "order": [[0, 'desc']],
      "language": {
        "url": "https://cdn.datatables.net/plug-ins/1.10.20/i18n/Spanish.json"
    }
    };
  }

}
