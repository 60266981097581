import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { SwalService } from './swal/swal.service';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root'
})
export class AfterLoginService implements CanActivate{

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise <boolean> {
    return this.checkLoggedIn(state.url);
  }

  checkLoggedIn(url: string): boolean {
    
    if (this.Token.loggedIn()) {
      return true;
    }

    // Retain the attempted URL for redirection
    // this.authService.redirectUrl = url;
    this.swalService.error401()
    return false;

  }

  constructor(
    private Token: TokenService, 
    private router: Router, 
    private swalService: SwalService
  ) { }
}
