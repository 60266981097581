<div class="mt-4 col-8 offset-2">
    <div class="card">
        <div class="card-header header-color" *ngIf="!form.id">Register Here</div>
        <div class="card-header header-color" *ngIf="form.id">Update Here</div>
        <div class="card-body">
            <form #clientForm="ngForm" (ngSubmit)="onSubmit()">
                <div class="form-group row">
                    <label for="inputNit" class="col-sm-2 col-form-label">Nit</label>
                    <div class="col-sm-10">
                        <input type="text" name="nit" class="form-control" id="inputNit" 
                            placeholder="Nit" [(ngModel)]="form.nit" required>
                        <div class="text-danger" [hidden]="!error.nit">
                            {{error.nit}}
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="inputName" class="col-sm-2 col-form-label">Nombre</label>
                    <div class="col-sm-10">
                        <input type="text" name="name" class="form-control" id="inputName" 
                            placeholder="Nombre" [(ngModel)]="form.name" required>
                        <div class="text-danger" [hidden]="!error.name">
                            {{error.name}}
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="inputAddress" class="col-sm-2 col-form-label">Dirección</label>
                    <div class="col-sm-10">
                        <input type="text" name="address" class="form-control" id="inputAddress" 
                            placeholder="Dirección" [(ngModel)]="form.address" required>
                        <div class="text-danger" [hidden]="!error.address">
                            {{error.address}}
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="inputPhone" class="col-sm-2 col-form-label">Telefono</label>
                    <div class="col-sm-10">
                        <input type="number" name="phone" class="form-control" id="inputPhone" 
                            placeholder="Teléfono" [(ngModel)]="form.phone" required>
                        <div class="text-danger" [hidden]="!error.phone">
                            {{error.phone}}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-4">
                        <label for="firms">Firmas asociadas</label>
                        <select name="firm" id="firm" class="form-control">
                            <option value="">Choose</option>
                            <option value="{{firm.id}}" *ngFor="let firm of firmsGeneral">{{firm.firm}}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-2">
                        <a href="#" id="addFirm" (click)="addFirm($event)" class="btn btn-info"><i class="far fa-plus-square"></i></a>
                    </div>
                    <div class="form-group col-md-4 col-offset-2">
                        <ul *ngIf="form.firms.length > 0">
                            <table class="table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th>Firmas</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let firmClient of form.firms">
                                        <td>{{firmClient.firm}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </ul>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-10">
                        <button type="submit" class="btn btn-primary" [disabled]="!clientForm.valid" *ngIf="!form.id">Registrar</button>
                        <button type="submit" class="btn btn-primary" [disabled]="!clientForm.valid" *ngIf="form.id">Actualizar</button>
                        <a href="#" class="float-right btn btn-info" routerLink="/clients">Volver</a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>