import { Injectable } from '@angular/core';
import * as fs from 'file-saver';
import { RowsGeneralService } from '../report-accidents/rows-general.service';
import { SnotifyService } from 'ng-snotify';
import { TokenService } from '../token.service';
import * as logoFile from './carlogo.js';
import * as Excel from "exceljs/dist/exceljs.min.js";
import * as ExcelProper from "exceljs";
import * as moment from 'moment';
import { OtherServiceService } from '../otherServiceModule/other-service.service';

@Injectable({
  providedIn: 'root'
})
export class ExcelOtherService {

  private data = [];

  constructor(
    private Report: OtherServiceService, private Notify: SnotifyService, private Token: TokenService
  ) {
  }

  continueExcel(data){
    this.Notify.remove();
    this.data = data;
    if(!data.status){
      this.generate();
    }else{
      this.Token.logout();
    }
  }

  generateExcel(datita) {
    this.Notify.info('Cargando...', {timeout:0});
    this.Report.getDataReport(datita).subscribe(
      data => this.continueExcel(data)
    );
  }

  generate(){
    //Excel: Titulo, Encabezado y Datos
    const title = 'Reporte de Kronos - Llamadas de otra índole';
    const header = [
      "Fecha", "Servicio", "departamento", "ciudad", "Observación", "Recibio"
    ];
    //Creando workbook y worksheet
    let workbook:ExcelProper.Workbook = new Excel.Workbook();
    let worksheet = workbook.addWorksheet('Kronos Hoja 1');
    //Agregar fila y dando estilo
    let titleRow = worksheet.addRow([title]);
    titleRow.font = { name: 'Comic Sans MS', family: 4, size: 16, underline: 'double', bold: true }
    worksheet.addRow([]);
    worksheet.addRow(['Fecha : ' + moment().format('MMMM Do YYYY, h:mm:ss a')]);
    //Para agregar una imagen -- Dede de estar en base64 --
    let logo = workbook.addImage({
      base64: logoFile.logoBase64,
      extension: 'png',
    });
    //
    worksheet.addImage(logo, 'D1:D4');
    worksheet.mergeCells('A1:C2');
    //Agregar una fila en blanco
    worksheet.addRow([]);
    //Agregar la fila con el encabezado
    let headerRow = worksheet.addRow(header);
    
    // Estilo de celda : Rellenar y Bordear
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '04ECB7' },
        bgColor: { argb: 'FF0000FF' }
      }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    })
    // worksheet.addRows(data);
    // Add Data and Conditional Formatting
    this.data.forEach(d => {

      let row = worksheet.addRow([
        d.created_at,
        d.name,
        d.departament,
        d.city,
        d.observation,
        d.user
      ]);
      
    }
    );
    // -- Aumentarle el tamaño a las columnas --
    for(let i = 1; i <= 34; i++){
      worksheet.getColumn(i).width = 30;
    }

    worksheet.addRow([]);
    //Fila del Footer
    let footerRow = worksheet.addRow(['This is system Kronos generated excel sheet.']);
    footerRow.getCell(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFCCFFE5' }
    };
    footerRow.getCell(1).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    //Unir Celdas
    worksheet.mergeCells(`A${footerRow.number}:D${footerRow.number}`);
    //Generar el archivo Excel y dar un nombre
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'KronosReport.xlsx');
    })
  }
}
