<div>
    <div class="float-left">
        <h4>Gestión Digipat</h4>
    </div>
</div>
<br>
<hr>
<table datatable [dtOptions]="dtOptions" class="table table-striped table-bordered row-border hover">
    <thead>
        <tr>
            <th># Evento</th>
            <th>Placa</th>
            <th>Coordinador</th>
            <th>Hora de Contacto</th>
            <th>Hora de Llegada</th>
            <th>Fin de Asistencia</th>
            <th>Estado</th>
            <th style="width: 1px;">Acción</th>
        </tr>
    </thead>
    <tbody *ngIf="digipats?.length > 0">
        <tr *ngFor="let digipat of digipats">
            <td>{{ digipat.idCase }}</td>
            <td>{{ digipat.plate }}</td>
            <td>{{ digipat.coordinator }}</td>
            <td>{{ digipat.contactTime }}</td>
            <td>{{ digipat.arriveTime }}</td>
            <td>{{ digipat.endTime }}</td>
            <td>
                <div class="alert alert-{{digipat.color}}" role="alert">
                    {{ digipat.state }}
                </div>
            </td>
            <td>
                <div class="row justify-content-md-center align-items-center">
                    <div class="btn-group" role="group" aria-label="Basic example">
                        <button [disabled]="digipat.allow === 0" id="idCaseDigipat{{digipat.idC}}" class="btn btn-info btn-sm" routerLink="/report-accident/{{digipat.idCase}}/digipat"><i class="fas fa-edit"></i></button>
                    </div>
                </div>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="digipats?.length < 1">
        <tr>
            <td colspan="9" class="no-data-available">No hay datos disponibles!</td>
        </tr>
    <tbody>
</table>
<br><br>
<br><br>
<br><br>
<br><br>
<br><br>
<small>Joe Developer.</small>
