<div class="card" style="margin-left: 1px">
    <div class="card-header header-color">
        
    </div>
    <ul class="list-group list-group-flush">
        <li class="list-group-item">
            <div class="float-left">
                <h4>Lista de Reclamaciones</h4>
            </div>
            <div class="float-right">
                <a href="#" routerLink="/claim" class="btn btn-primary">Registrar Nueva</a>
            </div>
        </li>
    </ul>
    <div class="card-body table-responsive">
            <table datatable [dtOptions]="dtOptions" class="table table-striped table-bordered row-border hover">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Fecha Reg.</th>
                        <th>Fecha Accidente</th>
                        <th>Nombre</th>
                        <th>Placa Vehiculo Asegurado</th>
                        <th>Placa Tercero</th>
                        <th>Amparo a Afectar</th>
                        <th>Tipo de Solicitud</th>
                        <th>Ciudad</th>
                        <th>Concesionario de origen</th>
                        <th>Taller o concesionario elegido por cliente</th>
                        <th>Agente que recepciona</th>
                        <th>Estado</th>
                        <th>Nombre quien gestiona</th>
                        <th style="width: 1px;">Acción</th>
                    </tr>
                </thead>
                <tbody *ngIf="claims?.length > 0">
                    <tr *ngFor="let claim of claims" class="table-{{claim.color}}">
                        <td>{{ claim.id }}</td>
                        <td>{{ claim.created_at }}</td>
                        <td>{{ claim.accident_date }}</td>
                        <td>{{ claim.name }}</td>
                        <td>{{ claim.plate_insured }}</td>
                        <td>{{ claim.plate_third }}</td>
                        <td>{{ claim.protection }}</td>
                        <td>{{ claim.type_request }}</td>
                        <td>{{ claim.city }}</td>
                        <td>{{ claim.home_dealership }}</td>
                        <td>{{ claim.customer_shop }}</td>
                        <td>{{ claim.agent_receives }}</td>
                        <td>{{ claim.state }}</td>
                        <td>{{ claim.nameManages }}</td>
                        <td>
                            <div class="btn-group btn-group-sm" role="group" aria-label="Button GRoup">
                                <a href="#" class="btn" routerLink="/claim/{{claim.id}}"><i class="fas fa-edit"></i></a>
                                <a href="#" class="btn" (click)="record($event, claim.id)"><i class="fas fa-exchange-alt"></i></a>
                                <a href="#" class="btn" *ngIf="user.permission" style="color: red" (click)="deleteCase($event, claim.id)"><i class="fas fa-minus-circle"></i></a>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="claims?.length < 1">
                    
                <tbody>
            </table>
    </div>
</div>  