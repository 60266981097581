import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { AuthService } from 'src/app/Services/auth.service';
import { RowsGeneralService } from 'src/app/Services/report-accidents/rows-general.service';
import { SwalService } from 'src/app/Services/swal/swal.service';
import { TokenService } from 'src/app/Services/token.service';
import Swal from 'sweetalert2';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';

class Case {
  id: number;
  plate: string;
  company: string;
  currentAddress: string;
  typeSinister: string;
  case_state: string;
  received_call: string;
  registration_date;
  time = 0;
  color: string;
  idC: number;
  userModify: string;
}

@Component({
  selector: 'app-survey-accident',
  templateUrl: './survey-accident.component.html',
  styleUrls: ['./survey-accident.component.css']
})
export class SurveyAccidentComponent implements OnInit {
  profile_ID:number
  userid(){
    this.profile_ID=this.token.getUser().profile_ID
  }
  isVisible = false
  selectedOption: any
  cases: Case[];
  casesCalificate: Case[];
  dtOptions: DataTables.Settings;
  dtOptions2: DataTables.Settings;
  private idDelete;
  public user = { profile: null, permission: false };
  public questions = {
    question1: null, question2: null
  }
  public answer: any;
  public form = { id: null }

  constructor(
    private swalService: SwalService,
    private token: TokenService,
    private router: Router,
    private notify: SnotifyService,
    private GeneralService: RowsGeneralService,
    private Auth: AuthService
  ) { }

  pageSize : number = 10
  listOfData: { data: any[], total: number, current_page:number } = {} as any
  listOfDataCalificate: { data: any[], total: number, current_page:number } = {} as any
  loading: boolean = false;

  formpol: FormGroup = new FormGroup({
    value: new FormControl('')
  })

  formpolcalificate: FormGroup = new FormGroup({
    value: new FormControl('')
  })

  loadData(current_page: number = 1, value: string = ''): void {
    this.loading = true
    this.GeneralService.getCasesConcluded(current_page, value,this.pageSize).subscribe(data => {
      this.listOfData = data;
      console.log(this.listOfData.data)
      this.updateTime();
      this.loading = false
    }
    )
  }

  loadDataCalificate(current_page: number = 1, value: string = ''): void {
    this.loading = true
    this.GeneralService.getCasesCalificated(current_page, value,this.pageSize).subscribe(data => {
      this.listOfDataCalificate = data;
      this.updateTime(1);
      this.loading = false
    }
    )
  }

  adjustPageSize(pageSize : number): void{
    this.pageSize = pageSize;
    this.loadData();
  }

  adjustPageSizeCalificate(pageSize : number): void{
    this.pageSize = pageSize;
    this.loadDataCalificate();
  }

  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.isVisible = false
  }

  async handleOk(): Promise<void> {
    console.log('Button ok clicked!');
    if (this.encuestaForm.get('observacion')?.value == null) {
      this.encuestaForm.controls['observacion'].setValue('SIN OBSERVACIÓN')
    }
    const answers = this.encuestaForm.value;
    let data = {
      caseId: this.form.id,
      answer: answers
    }
    this.sendQuestionAnswers(data);
    this.isVisible = false
    this.notify.success('Encuesta -> '+ data.caseId+' cargada.', { timeout: 0 });
    //this.loadData()
    //this.loadDataCalificate()
  }
  showModal(e: MouseEvent): void {
    e.preventDefault();
    this.isVisible = true
  }

  public encuestaForm: FormGroup = new FormGroup({
    pregunta1: new FormControl('', [Validators.required]),
    pregunta2: new FormControl('', [Validators.required]),
    observacion: new FormControl(''),
  })

  encuestaOptions: any[] = [
    { nombre: 'Excelente', valor: 1 },
    { nombre: 'Bueno', valor: 2 },
    { nombre: 'Regular', valor: 3 },
    { nombre: 'Malo', valor: 4 },
    { nombre: 'No Aplica', valor: 5 },
    { nombre: 'No Califica', valor: 6 },
    { nombre: 'No Contactado', valor: 7 }]

  deleteRow(id) {
    let data = {
      id: id
    }
    this.idDelete = data.id;
    //this.notify.info('Espere...', { timeout: 0 });
    this.processDelete();
  }

  processDelete() {
    let c = 0;
    this.cases.forEach((element) => {
      if (element.id == this.idDelete) {
        this.cases.splice(c, 1);
        this.idDelete = null;
      }
      c++;
    });
  }

  updateTime(vari?: any) {
    var startTime;
    var endTime;
    var duration;
    var time;
    var time_ini;
    var c = 0;
    setInterval(() => {
      if (vari != 1) {
        this.listOfData.data.forEach((element) => {
          time_ini = element.registration_date;
          startTime = moment(time_ini, "YYYY-MM-DD HH:mm:ss");
          endTime = moment(moment().format("YYYY-MM-DD HH:mm:ss"), "YYYY-MM-DD HH:mm:ss");
          duration = moment.duration(endTime.diff(startTime));
          time = duration.hours() + ' h ' + duration.minutes() + ' m ' + duration.seconds() + ' s';
          duration.asDays() >= 1 ? time = parseInt(duration.asHours()) + ' Horas' : time = time;
          element.time = time;
          duration.asMinutes() > 20 ? element.color = 'danger' : '';
          duration.asMinutes() < 20 ? element.color = 'warning' : '';
          element.case_state !== 'PROCESO' ? element.color = 'success' : '';
          c++;
        });
      } else {
        this.listOfDataCalificate.data.forEach((element) => {
          time_ini = element.registration_date;
          startTime = moment(time_ini, "YYYY-MM-DD HH:mm:ss");
          endTime = moment(moment().format("YYYY-MM-DD HH:mm:ss"), "YYYY-MM-DD HH:mm:ss");
          duration = moment.duration(endTime.diff(startTime));
          time = duration.hours() + ' h ' + duration.minutes() + ' m ' + duration.seconds() + ' s';
          duration.asDays() >= 1 ? time = parseInt(duration.asHours()) + ' Horas' : time = time;
          element.time = time;
          duration.asMinutes() > 20 ? element.color = 'danger' : '';
          duration.asMinutes() < 20 ? element.color = 'warning' : '';
          element.case_state !== 'PROCESO' ? element.color = 'success' : '';
          c++;
        })
      }
    }, 1000);

  }

  handleResponse(data) {
    this.notify.remove();
    this.notify.confirm('Done!', {
      buttons: [
        {
          text: 'Okay',
          action: toster => {
            this.notify.remove(toster.id)
          }
        }
      ]
    })
  }
  getAnswerCase(Event: MouseEvent, id: any) {
    Event.preventDefault()
    this.encuestaForm.reset()
    this.form.id = id
    const that = this
    that.GeneralService.getAnswerCase(id).subscribe(
      data => {
        this.encuestaForm.controls['pregunta1'].setValue(data[0].answer)
        this.encuestaForm.controls['pregunta2'].setValue(data[1].answer)
        if (data[2]) {
          this.encuestaForm.controls['observacion'].setValue(data[2].answer)
        }
        this.showModal(Event)
        this.swalService.close()
      }
    )
  }

  loadTableCalificate() {
    const that = this;
    that.notify.info('Cargando...', { timeout: 0 });
    this.dtOptions2 = {
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        that.GeneralService.getCasesCalificatedDep(dataTablesParameters).subscribe(resp => {
          if (!resp.data) {
            this.logout();
          } else {
            this.updateTime(1);
          }
          that.notify.remove();
          that.casesCalificate = resp.data;
          callback({
            recordsTotal: resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data: []
          });
        });
      },
      columns: [
        { data: 'id' }, { data: 'plate' },
        { data: 'currentAddress' }, { data: 'time' }, { data: 'typeSinister' },
        { data: 'state_case' }, { data: 'received_call' },
        { data: 'registration_date' }, { data: 'end_date' },
        { data: 'action' }
      ],
      "columnDefs": [{
        "targets": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
        "orderable": false
      }],
      "language": {
        "url": "https://cdn.datatables.net/plug-ins/1.10.20/i18n/Spanish.json"
      }
    };
  }

  loadTable() {
    const that = this;
    that.notify.info('Cargando...', { timeout: 0 });
    this.dtOptions = {
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        that.GeneralService.getCasesConcludedDep(dataTablesParameters).subscribe(resp => {
          if (!resp.data) {
            this.logout();
          } else {
            this.updateTime();
          }
          that.notify.remove();
          that.cases = resp.data;

          callback({
            recordsTotal: resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data: []
          });
        });
      },
      columns: [
        { data: 'id' }, { data: 'plate' },
        { data: 'currentAddress' }, { data: 'time' }, { data: 'typeSinister' },
        { data: 'state_case' }, { data: 'received_call' },
        { data: 'registration_date' }, { data: 'end_date' },
        { data: 'action' }
      ],
      "columnDefs": [{
        "targets": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
        "orderable": false
      }],
      "language": {
        "url": "https://cdn.datatables.net/plug-ins/1.10.20/i18n/Spanish.json"
      }
    };

  }
  ngOnInit(): void {
    this.userid()
    //this.loadTable();
    this.loadData()
    //this.loadTableCalificate()
    this.loadDataCalificate()
    this.checkUser()
    this.fillQuestion()
    this.formpol.controls['value'].valueChanges.subscribe(
      data => this.loadData(1, data)
    )
    this.formpolcalificate.controls['value'].valueChanges.subscribe(
      data => this.loadDataCalificate(1, data)
    )
  }

  logout() {
    this.token.remove();
    this.Auth.changeAuthStatus(false);
    this.router.navigateByUrl('/login');
    window.location.reload();
  }

  checkUser() {
    this.token.searchUser().subscribe(
      data => this.fillData(data)
    );
  }

  fillData(data) {
    this.user.profile = data.user.profile_ID;
    if (this.user.profile == 1) {
      this.user.permission = true;
    }
  };

  sendSurvey(Event: MouseEvent, id) {
    Event.preventDefault();
    this.form.id = id;
    //this.swalFunction();
    this.encuestaForm.reset()
    this.showModal(Event)
  }

  swalFunction() {
    Swal.mixin({
      input: 'select',
      inputOptions: {
        1: 'Excelente',
        2: "Bueno",
        3: "Regular",
        4: "Malo",
        5: "No Aplica",
        6: "No Califica",
        7: "No Contactado"
      },
      confirmButtonText: 'Next &rarr;',
      progressSteps: ['1', '2', '3']
    }).queue([
      { title: "Encuesta", text: this.questions.question1 },
      { title: "Encuesta", text: this.questions.question2 },
      { title: "Encuesta", text: 'En caso de que se seleccione regular, malo, no aplica, no califica o no contactado agregue la observación', input: 'textarea' }
    ]).then((result: { value }) => {
      if (result.value) {
        const answers = result.value;
        let data = {
          caseId: this.form.id,
          answer: answers
        }
        this.sendQuestionAnswers(data);
      } else {
        Swal.fire({
          text: "Encuesta cancelada"
        });
      }
    });
  }

  sendQuestionAnswers(answer, mod?) {
    let title = 'Encuesta Guardada'
    if (mod) {
      title = 'Encuesta Actualizada'
    }
    this.GeneralService.sendQuestionAnswer(answer).subscribe(
      data => {
        if (data) {
          Swal.fire({
            icon: 'success',
            title: title,
            showConfirmButton: false,
            timer: 1500
          });
          this.loadDataCalificate()
          this.deleteRow(this.form.id);
          this.notify.remove();
          this.form.id = null;
        }
      }
    );
  }

  async fillQuestion(): Promise<void> {
    let data = await this.GeneralService.getQuestions()
    this.questions.question1 = data[0].question
    this.questions.question2 = data[1].question
  }

}
