<div class="card-header header-color"> {{ title }}
</div>
<div class="card " style="margin-left: 2px">
  <div class="card-body">
    <div class="tab-content" id="myTabContent">
      <form nz-form [formGroup]="form" #claimForm="ngForm" (ngSubmit)="onSubmit()">
        <nz-form-item>
          <nz-form-label [nzSpan]="7" nzRequired>Nombres</nz-form-label>
          <nz-form-control [nzSpan]="12" nzHasFeedback nzValidatingTip="Validating..." [nzErrorTip]="userErrorTpl">
            <input nz-input id="name" name="name" placeholder="Nombres" formControlName="nombres"
            (keyup)="form.controls['nombres'].setValue(form.controls['nombres'].value.toUpperCase())"
            required />
            <ng-template #userErrorTpl let-control>
              <ng-container *ngIf="control.hasError('required')">Please input </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSpan]="7" nzRequired>Apellidos</nz-form-label>
          <nz-form-control [nzSpan]="12" nzHasFeedback nzValidatingTip="Validating..." [nzErrorTip]="userErrorTpl">
            <input nz-input id="surname" name="surname" placeholder="Apellidos" formControlName="apellidos"
            (keyup)="form.controls['apellidos'].setValue(form.controls['apellidos'].value.toUpperCase())"required />
            <ng-template #userErrorTpl let-control>
              <ng-container *ngIf="control.hasError('required')">Please input </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSpan]="7" nzRequired>Celular</nz-form-label>
          <nz-form-control [nzSpan]="12" nzHasFeedback nzValidatingTip="Validating..." [nzErrorTip]="userErrorTpl">
            <input type="number" nz-input id="phone" name="phone" placeholder="Celular" formControlName="celular"  required />
            <ng-template #userErrorTpl let-control>
              <ng-container *ngIf="control.hasError('required')">Please input </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSpan]="7" nzRequired>Fecha</nz-form-label>
          <nz-form-control [nzSpan]="12" nzHasFeedback nzValidatingTip="Validating..." [nzErrorTip]="userErrorTpl">
            <input type="date" nz-input id="date_error" name="date_error" placeholder="Fecha" formControlName="fecha" required />
            <ng-template #userErrorTpl let-control>
              <ng-container *ngIf="control.hasError('required')">Please input </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSpan]="7" nzRequired>Placa</nz-form-label>
          <nz-form-control [nzSpan]="12" nzHasFeedback nzValidatingTip="Validating..." [nzErrorTip]="userErrorTpl">
            <input nz-input id="plate" name="plate" placeholder="Placa" formControlName="placa"
            (keyup)="form.controls['placa'].setValue(form.controls['placa'].value.toUpperCase())" required />
            <ng-template #userErrorTpl let-control>
              <ng-container *ngIf="control.hasError('required')">Please input </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSpan]="7" nzRequired>Cobertura</nz-form-label>
          <nz-form-control [nzSpan]="12" nzHasFeedback nzValidatingTip="Validating..." [nzErrorTip]="userErrorTpl">
            <input nz-input id="cobertura" name="cobertura" placeholder="Cobertura" formControlName="cobertura"
            (keyup)="form.controls['cobertura'].setValue(form.controls['cobertura'].value.toUpperCase())" required />
            <ng-template #userErrorTpl let-control>
              <ng-container *ngIf="control.hasError('required')">Please input </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSpan]="7" nzRequired>Descripción</nz-form-label>
          <nz-form-control [nzSpan]="12" nzHasFeedback nzValidatingTip="Validating..." [nzErrorTip]="userErrorTpl">
            <textarea required formControlName="descripcion" nz-input rows="2" placeholder="Descripción del error!"
            (keyup)="form.controls['descripcion'].setValue(form.controls['descripcion'].value.toUpperCase())"></textarea>
            <ng-template #userErrorTpl let-control>
              <ng-container *ngIf="control.hasError('required')">Please input </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSpan]="5" nzRequired>Imagen</nz-form-label>
          <nz-form-control [nzSpan]="20" nzHasFeedback nzValidatingTip="Validating..." [nzErrorTip]="userErrorTpl">
            <input nz-input type="file" (change)="uploadFile($event)" required>
            <ng-template #userErrorTpl let-control>
              <ng-container *ngIf="control.hasError('required')">Please input </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control [nzOffset]="7" [nzSpan]="12">
            <button nz-button [nzType]="'primary'" [disabled]="!claimForm.valid" >Guardar</button>
            <button nz-button [nzType]="'default'" (click)="closeModal()">Cancelar</button>
          </nz-form-control>
        </nz-form-item>
      </form>
    </div>
  </div>
</div>


<!--  *ngIf="!form.controls['id'].value" -->
