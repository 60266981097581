import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JarwisService } from 'src/app/Services/jarwis.service';
import { SnotifyService } from 'ng-snotify';

@Component({
  selector: 'app-response-reset',
  templateUrl: './response-reset.component.html',
  styleUrls: ['./response-reset.component.css']
})
export class ResponseResetComponent {

  public error = {
    email: null,
    password: null
  };

  public form = {
    email : null,
    password: null,
    password_confirmation: null,
    resetToken: null
  }
  constructor(
    private route:ActivatedRoute,
    private Jarwis: JarwisService,
    private router: Router,
    private Notify: SnotifyService
  ) {
    route.queryParams.subscribe(params => {
      this.form.resetToken = params['token']
    });
  }

  onSubmit(){
    this.Notify.info('Sending...', {timeout: 0});
    this.Jarwis.changePassword(this.form).subscribe(
      data => this.handleResponse(data),
      error => this.handleError(error)
    );
  }

  handleResponse(data){
    let _router = this.router;
    this.Notify.remove();
    this.Notify.confirm('Done! Now login with new Password', {
      buttons: [
        {
        text: 'Okay',
        action: toster => {
          _router.navigateByUrl('/login'),
          this.Notify.remove(toster.id)
        }
      }
      ]
    })

  }

  handleError(error){
    this.Notify.remove();
    if(error.error.errors){
      this.error = error.error.errors;
    }
  }

}
