import { Component, OnInit } from '@angular/core';
import { SnotifyService } from 'ng-snotify';
import { TokenService } from 'src/app/Services/token.service';
import { ActivatedRoute } from '@angular/router';
import { RecordingService } from 'src/app/Services/recordings/recording.service';
import { ClaimService } from 'src/app/Services/claim/claim.service';
import * as moment from 'moment';
import * as fieldForm from 'src/assets/js/camposFormularioRecording.js';
import Swal from 'sweetalert2';
import { FormControl, FormGroup } from '@angular/forms';
import { SwalService } from 'src/app/Services/swal/swal.service';
import { Observations } from 'src/app/models/claims/observations';
/**
 * Clase de  observations con los atributos correspondientes.
 * @author Pablo Morales <pablo.morales@holdingvml.net>
 */
 class observations {
  id_recording: number;
  observation: string;
  date: Date;
  id: number;
}
@Component({
  selector: 'app-recordings-form',
  templateUrl: './recordings-form.component.html',
  styleUrls: ['./recordings-form.component.css']
})
export class RecordingsFormComponent implements OnInit {
  obseClaim: Observations[];

  public formGroup: FormGroup = new FormGroup({
    id: new FormControl(),
    created_at_recording: new FormControl(),
    accident_date:  new FormControl(),
    name: new FormControl(),
    plate_insured: new FormControl(),
    plate_third: new FormControl(),
    protection:  new FormControl(),
    type_request:  new FormControl(),
    home_dealership: new FormControl(),
    customer_shop:   new FormControl(),
    agent_receives: new FormControl(),
    state:  new FormControl(),
    budget:  new FormControl(),
    sinister: new FormControl(),
    observations:new FormControl(),
    nameManages:new FormControl(),
    citys_departament_id: new FormControl(),
    citys_cod_mun: new FormControl(),
    phones: new FormControl(),
    duration: new FormControl(),
    accident_zone: new FormControl(),
    file_analyst: new FormControl()
  })

  public error = {
    accident_date: null,  name: null,  plate_insured: null,  plate_third: null,
    protection: null,  type_request: null,  home_dealership: null,  customer_shop: null,  agent_receives: null,
    state: null,  budget: null,  sinister: null,  observations: null,  nameManages: null,
    citys_departament_id: null, citys_cod_mun: null, duration: null, accident_zone: null, file_analyst: null,
    created_at_recording: null, nameManager: null
  }

  obse: observations[];
  //array que se muestra en la plantilla

  observaciones = [];
  //array que se muestra en la plantilla

  observaciones2 = [];
  //array que se inserta en la base de datos

  submitting = false;
  inputValue = '';

  private _originalValue: Object;
  public phonesWrite = null;
  public obsData = {cont: 1, comment: null};
  public basicData = {
    states_claim: null,
    type_request_claim: null,
    protections: null,
    departaments: null,
    citys: null,
    user: null
  };

  public users: any;

  constructor(
    private RecordingService: RecordingService, private Notify: SnotifyService, public swal: SwalService,
    private Token: TokenService, private route: ActivatedRoute, private ClaimService: ClaimService
  ) { }

  ngOnInit() {
    this.swal.loading();
    this.getBasicInformation();
    this.formGroup.get('id').setValue(this.route.snapshot.params["id"]);
    this.sendFill();
    this.selectUsers();
  }

  onSubmit(){
    this.Notify.info('Cargando...', { timeout: 0 });
    this.formGroup.get('nameManages').setValue(this.basicData.user.id);
    this.RecordingService.create(this.formGroup.value).subscribe(
      data => {
        this.handleResponse(data);
      },
      error => {
        this.handleError(error);
      }
    );
    this.swal.update();
    if(this.formGroup.get('id').value != null){
      let form = this.formGroup.getRawValue()
      this.obsData.comment = this.makeObservation(form)
      this.handleSubmit()
      this.saveObservations()
    }
    Swal.fire({
      icon: 'success',
      title: 'Información Actualizada',
      showConfirmButton: false,
      timer: 3500
    })
  }

  /**
   * Método para estructurar el mensaje con los cambios hechos en el formulario.
   * @returns variable message con la información de los cambios
   * @author Esteban Rodriguez <johan.rodriguez@holdingvml.com>
   */
  makeObservation(form): string {
    console.log(form)
    let message = 'SE MODIFICA:'
    Object.keys(form).forEach((value, index, array) => {
      let field
      field = form[value] !== this._originalValue[value] ? fieldForm.object[value] : ''
      field = field !== '' ? ' ' + field + ',' : ''
      message = message + field
    })
    return message !== 'SE MODIFICA:' ? message.slice(0, message.length - 1) : null
  }

  handleResponse(data){
    this.Notify.clear();
    Swal.close();
    data.status ?  this.Token.logout() : '';
    this.Notify.success('Exito!', { timeout: 2000 });
    this._originalValue = this.formGroup.getRawValue()
  }

  handleError(error){
    this.Notify.clear();
    Swal.close();
    this.error = error.error.errors;
  }

  getBasicInformation(){
    this.Notify.info('Cargando...', { timeout: 0 });
    this.RecordingService.getBasicInformation().subscribe(
      data => {
        this.handleResponseBasicInformation(data);
      },
      () => {
        this.handleErrorBasicInformation();
      }
    );
  }

  handleResponseBasicInformation(data){
    this.Notify.clear();
    Swal.close();
    this.basicData = data;
    this.basicData.states_claim.splice(4, 1);
    this.formGroup.get('id').value == undefined ? this.formGroup.get('agent_receives').setValue(this.basicData.user.id) : '';
  }

  handleErrorBasicInformation(){
    this.Notify.clear();
    Swal.close();
    this.Notify.error('Algo ha salido mal');
  }

  loadCitys(){
    const data = {
      citys_departament_id: this.formGroup.get('citys_departament_id').value
    }
    this.RecordingService.getCitys(data).subscribe(
      data => {
        this.basicData.citys = data.data;
      },
      () => {
        this.Notify.error('Ha ocurrido un error en el servidor');
      }
    );
  }

  sendFill(){
    if(this.formGroup.get('id').value != null){
      let data = {
        id: this.formGroup.get('id').value
      }
      this.RecordingService.select(data).subscribe(
        data => this.fillData(data),
        () => this.Notify.error('Algo salio mal en el servidor')
      );
    }
  }
idclaim:number=0
  fillData(data){
    console.log(data)
    this.idclaim=data.claims_id
    data.status ? this.Token.logout() : '';
    this.loadObservations()
    this.loadClaimObservations()
    this.formGroup.patchValue(data);
    this._originalValue = this.formGroup.getRawValue()
    this.loadCitys();
  }
telefonos=[]
  addPhone(Event: MouseEvent){
    Event.preventDefault();
    this.phonesWrite !== '' && this.phonesWrite !== null ? this.telefonos.unshift({phoneNumber: this.phonesWrite}) : '';
    this.formGroup.controls['phones'].setValue(this.telefonos)
    console.log(this.formGroup.get('phones'));
    this.phonesWrite = null;
  }

  selectUsers(){
    this.ClaimService.getUsers().subscribe(
      data => {
        this.fillDataUser(data);
      }
    );
  }

  fillDataUser(data){
    this.users = data.data;
  }

  /**
   * Método para llamar handleSubmit
   * @param e
   * @author Esteban Rodriguez <johan.rodriguez@holdingvml.com>
   */
  addObservation(e: MouseEvent){
    e.preventDefault();
    this.handleSubmit()
  }

  /**
   * Método para agregar observaciones desde la caja de texto y el mensaje de cambios en formulario.
   * El método llena dos arreglos con los mismos datos, uno de esos arreglos se muestra en pantalla
   * para que el ususario vea inmediatamente las observaciones agregadas.
   * El otro arreglo se utiliza para guardar las observaciones en la base de datos
   * @author Esteban Rodriguez <johan.rodriguez@holdingvml.com>
   */
    handleSubmit(): boolean  {
    if(!this.obsData.comment) return false;
          this.observaciones.unshift({
            'observation': this.obsData.comment,
            'date': moment().format('YYYY-MM-DD hh:mm:ss'),
            'name': 'YO',
            'user': this.basicData.user.id,
            'id_recording': this.formGroup.get('id').value
          });
          this.observaciones2.unshift({
            'observation': this.obsData.comment,
            'date': moment().format('YYYY-MM-DD hh:mm:ss'),
            'name': 'YO',
            'id': this.basicData.user.id,
            'id_recording': this.formGroup.get('id').value
          });
          this.obsData.cont++;
          this.obsData.comment = null;
    }

  /**
   * Método para guardar  las observaciones.
   * @returns Mensaje de exito
   * @author Pablo Morales <pablo.morales@holdingvml.net>
   */
    saveObservations() {
      for (let obs of this.observaciones2) {
        this.RecordingService.createObservation(obs).subscribe(
        res => {
          {}
        },err => {
          {}
        }
        )
      }
      //limpia el arreglo
      for (let i = this.observaciones2.length; i > 0; i--) {
        this.observaciones2.pop();
      }
    }

  /**
   * Método para cargar las observaciones.
   * @returns Arreglo con las observaciones que coinciden con el id de la grabación
   * @author Pablo Morales <pablo.morales@holdingvml.net>
   */
    loadObservations(): void {
      this.RecordingService.getObservation(this.formGroup.get('id').value).subscribe(data => {
        this.obse = data.data;
        this.observaciones = this.obse;
      }
      );
    }
      /**
   * Método para cargar las observaciones de reclamación.
   * @returns Arreglo con las observaciones que coinciden con el id de la reclamación
   * @author Pablo Morales <pablo.morales@holdingvml.net>
   */
  loadClaimObservations(): void {
    this.ClaimService.getObservation(this.idclaim).subscribe(data => {
      this.obseClaim = data.data;
      //this.observaciones = this.obse;
    }
    );
  }

}
