import { Component, OnInit, OnDestroy } from '@angular/core';
import * as $ from 'jquery';
import { RowsGeneralService } from 'src/app/Services/report-accidents/rows-general.service';
import { Socket } from 'ngx-socket-io';

@Component({
  selector: 'app-report-accidents',
  templateUrl: './report-accidents.component.html',
  styleUrls: ['./report-accidents.component.css']
})
export class ReportAccidentsComponent implements OnInit, OnDestroy {

  public count;

  constructor(private CaseService: RowsGeneralService, private socket: Socket) { }

  ngOnInit() {
    this.getCountProcessCase();
  }

  ngOnDestroy(){
    $('html')[0].firstElementChild.children[1].innerHTML = `Kronos`;
  }

  getCountProcessCase(){
    this.CaseService.getCountProcessCase().subscribe(data =>{
      console.log(data)
      this.count = data;
      this.count > 0 ? $('html')[0].firstElementChild.children[1].innerHTML = `Kronos (${this.count})` : '';
    });
  }

}
