<div class="card-header header-color">
</div>
<div class="card " style="margin-left: 2px">

  <div class="card-body">
    <div class="tab-content" id="myTabContent">

      <h2>{{ title }}</h2>
      <p>
        <input nz-input placeholder="Nombre" [(ngModel)]="form.firm" required />
      </p>
      <p>
        <button nz-button [nzType]="'primary'" (click)="destroyModal(form)">Guardar</button>
         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <button nz-button [nzType]="'default'" (click)="closeModal()">Cancelar</button>
      </p>

    </div>
  </div>
</div>
