<div class="card col-md-10 col-lg-10 col-xl-12" style="margin-left: 2px">
  <div class="card-header header-color">
  </div>
  <div class="card-body">
    <div class="tab-content" id="myTabContent">
      <div>
        <div class="float-left">
          <h4>Inf. General de Firmas</h4>
        </div>
        <div class="btn-group float-right" role="group" aria-label="Basic example">
          <button nz-button nzType="primary" (click)="addFirm($event)">
            <span>Registrar Nuevo</span>
          </button>
        </div>
      </div>
      <br>
      <hr>
      <nz-input-group [nzSuffix]="suffixIconSearch" [formGroup]="formpol">
        <input type="text" #searchText nz-input placeholder="Buscar coincidencias (Firma)"
          formControlName="value">
      </nz-input-group>
      <ng-template #suffixIconSearch>
        <i nz-icon nzType="search"></i>
      </ng-template>
      <nz-table [nzData]="listOfData.data" [nzFrontPagination]="false" [nzShowSizeChanger]="true"
        [nzTotal]="listOfData.total" (nzPageIndexChange)="loadData($event)" (nzPageSizeChange)="adjustPageSize($event)"
        nzLoading="{{loading}}">
        <thead>
          <tr>
            <th>N°</th>
            <th>Firma</th>
            <th>Acción</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of listOfData.data">
            <td>{{ data.id }}</td>
            <td>{{ data.firm }}</td>
            <td>
              <div class="btn-group" role="group" aria-label="Basic example">
                <button class="btn btn-info btn-sm" (click)="addFirm($event, data.id, data.firm)"><i
                    class="fas fa-edit"></i></button>
                <button *ngIf="user.permission" class="btn btn-light btn-sm" style="color: red"
                  (click)="deletefirm($event, data.id)"><i class="fas fa-minus-circle"></i></button>
              </div>
            </td>
          </tr>
        </tbody>
      </nz-table>
    </div>
  </div>
</div>
