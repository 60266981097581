import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { Rol } from '../models/rol.js';
import { User } from '../models/user.js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JarwisService {

  public baseUrl = environment.url;

  constructor(private http:HttpClient) { }

  signup(data, token){
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer `+token)
    }
    return this.http.post(`${this.baseUrl}/signup`, data, header);
  }

  login(data): Observable<{ token: string, profile: Rol[], user: User }>{
    return this.http.post<{ token: string, profile: Rol[], user: User }>(`${this.baseUrl}/login`, data)
  }

  sendPasswordResetLink(data){
    return this.http.post(`${this.baseUrl}/sendPasswordResetLink`, data);
  }

  changePassword(form: { email: any; password: any; password_confirmation: any; resetToken: any; }) {
    return this.http.post(`${this.baseUrl}/resetPassword`, form);
  }

  isValidToken(token){
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer `+token)
    }
    return this.http.get(`${this.baseUrl}/user`, header);
  }

}
