<div class="col-sm-12" id="movil">
    <div class="card" style="margin-left: 1px">
        <div class="card-header header-color text-center">
            <h6>MOVIL</h6>
        </div>
        <div class="card-body">
            <div class="form-row">
                <div class="form-group col-md-3">
                    <label for="inputReportTimeMovil">Hora de Reporte:</label>
                    <input type="text" class=" datePick form-control form-control-sm" id="inputReportTimeMovil" name="inputReportTimeMovil"
                        [(ngModel)]="form.reportTimeMovil" [owlDateTime]="dt3" [owlDateTimeTrigger]="dt3"> 
                        <owl-date-time #dt3></owl-date-time>
                </div>
                <div class="form-group col-md-3">
                    <label for="inputEndTimeMovil">Hora de Contacto:</label>
                    <input type="text" class=" datePick form-control form-control-sm" id="inputEndTimeMovil" name="inputEndTimeMovil"
                        [(ngModel)]="form.endTimeMovil" [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2"> 
                        <owl-date-time #dt2></owl-date-time>
                </div>
                <div class="form-group col-md-2">
                    <label for="inputDateArrivalMovil">Agente en Sitio:</label>
                    <input type="text" class="datePick form-control form-control-sm" id="inputDateArrivalMovil" name="inputDateArrivalMovil"
                        [(ngModel)]="form.arrivalTimeMovil" [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1">
                        <owl-date-time #dt1></owl-date-time>
                </div>
                <div class="form-group col-md-3">
                    <label for="selectAvailability">Disponibilidad:</label>
                    <select name="selectAvailability" id="selectAvailability" class="form-control form-control-sm" required
                            [(ngModel)]="form.availability">
                        <option value="">Choose...</option>
                        <option value="1">Disponible</option>
                        <option value="2">No Disponible</option>
                        <option value="3">Fuera de cobertura</option>
                        <option value="5">Retiro del lugar de los hechos</option>
                        <option value="4">NA</option>
                    </select>
                    <small class="text-danger" *ngIf="error.availability">{{ error.availability }}</small>
                </div>
                <div class="col-md-1">
                    <label for="none"><hr></label>
                    <a href="#" (click)="onSubmit($event)" class="btn btn-info btn-sm">Guardar</a>
                </div>
            </div>
        </div>
    </div> 
</div>