import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenService } from 'src/app/Services/token.service';
import { environment } from 'src/environments/environment';

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Injectable({
  providedIn: 'root'
})
export class LawyersService {

  public baseUrl = environment.url;

  header(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer `+this.Token.get())
    }
  }

  constructor(private http:HttpClient, private Token: TokenService) { }

  getLawyers(data){
    return this.http.post<DataTablesResponse>(`${this.baseUrl}/lawyers`, data, this.header());
  }

  getLawyersNgzorro(nextPage, value, pageSize){
    return this.http.get<any>(`${this.baseUrl}/lawyers?page=${nextPage}&value=${value}&pageSize=${pageSize}`, this.header());
  }

  getFirms(){
    return this.http.post<any>(`${this.baseUrl}/allFirmsLawyers`, [], this.header());
  }

  getFirmsNgzorro(nextPage, value, pageSize){
    return this.http.get<any>(`${this.baseUrl}/allLawyerFirmEnabled?page=${nextPage}&value=${value}&pageSize=${pageSize}`, this.header());
  }

  create(data){
    return this.http.post(`${this.baseUrl}/lawyer`, data, this.header());
  }

  update(data){
    return this.http.post(`${this.baseUrl}/lawyer/update`, data, this.header());
  }

  select(data){
    return this.http.post(`${this.baseUrl}/lawyer/select`, data, this.header());
  }

  delete(data){
    return this.http.post(`${this.baseUrl}/lawyer/delete`, data, this.header());
  }

  addFirm(data){
    return this.http.post(`${this.baseUrl}/addFirm`, data, this.header());
  }

  deleteFirm(data){
    return this.http.put(`${this.baseUrl}/deleteLawyerFirm?id=${data}`, data,this.header());
  }

  updateFirm(data){
    return this.http.put<DataTablesResponse>(`${this.baseUrl}/updateLawyerFirm?id=${data.id}`, data, this.header());
  }

}
