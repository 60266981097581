import { Component, OnInit } from '@angular/core';
import { TokenService } from 'src/app/Services/token.service';
import { Router } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { ClaimService } from 'src/app/Services/claim/claim.service';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { SwalService } from 'src/app/Services/swal/swal.service';

class Claim {
  id: number;
  created_at: string;
  color: string;
  error_rcl: number;
}

@Component({
  selector: 'app-claims',
  templateUrl: './claims.component.html',
  styleUrls: ['./claims.component.css']
})
export class ClaimsComponent implements OnInit {

  claims: Claim[];
  dtOptions: DataTables.Settings;
  private idDelete;
  public user = { profile: null, permission: false };

  constructor( 
    private token: TokenService,
    private router: Router,
    private ClaimService: ClaimService,
    private swalService: SwalService,
    private notify: SnotifyService
  ) { }

  ngOnInit(): void {
    this.loadTable();
    this.setColor();
    this.checkUser();
  }

  loadTable(){
    const that = this;
    
    this.dtOptions = {
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        Swal.fire({
          icon: 'info',
          title: 'Cargando...',
          showConfirmButton: false,
          allowOutsideClick: false
        });
        that.ClaimService.getClaims(dataTablesParameters).subscribe(resp => {
          Swal.close();
            if(!resp.data){
              this.token.remove();
              this.router.navigateByUrl('/login');
              setTimeout(()=>{
                window.location.reload();
              }, 100);
            }
            that.claims = resp.data;
            this.setColor();
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
      },
      columns: [
            { data: 'id' }, { data: 'created_at' }, { data: 'accident_date' }, { data: 'name' }, { data: 'plate_insured' },
            { data: 'plate_third' }, { data: 'protection' }, { data: 'type_request' }, { data: 'city' }, 
            { data: 'home_dealership' },{ data: 'customer_shop' }, { data: 'agent_receives' }, { data: 'state' }, 
            { data: 'nameManages' }
          ],
      "order": [[0, 'desc']],
      "language": {
        "url": "https://cdn.datatables.net/plug-ins/1.10.20/i18n/Spanish.json"
    }
    };
  }

  checkUser(){
    this.token.searchUser().subscribe(
      data => this.fillData(data)
    );
  }

  fillData(data){
    this.user.profile = data.user.profile_ID;
    if(this.user.profile == 1){
      this.user.permission = true;
    }
  };

  async record(Event: MouseEvent, id){
    Event.preventDefault();
    let option = await this.swalService.swalAlert("Advertencia", "¿Está seguro de que quiere eliminar este registro?", 'warning');
    if(!option){
      return false;
    }
    Swal.fire({
      icon: 'info',
      title: 'Cargando...',
      showConfirmButton: false,
      allowOutsideClick: false
    });
    const data = {
      id: id
    }
    this.idDelete = id;
    this.ClaimService.record(data).subscribe(
      data => {
        this.handleResponseRecord(data);
      },
      () => {
        Swal.close();
        Swal.fire({
          icon: 'error',
          title: 'Ha ocurrido un error en el servidor, contacte con el administrador.',
          timer: 1500
        });
      }
    );
  }

  handleResponseRecord(data){
    Swal.close();
    data.status ? this.token.logout() : '';
    let c = 0;
    this.claims.forEach((element)=>{
        if(element.id == this.idDelete){
          this.claims.splice(c, 1);
          this.idDelete = null;
        }
        c++;
    });
    Swal.fire({
      icon: 'success',
      title: 'Exito!, revisar en grabaciones',
      allowOutsideClick: false
    });
  }

  setColor(){
    var startTime;
    var endTime;
    var duration;
    var time;
    var time_ini;
    var c = 0;
    var error_rcl = 0; //Se le da un valor de cero 
    setInterval(()=>{
      if(this.claims){
        this.claims.forEach((element)=>{
          error_rcl = element.error_rcl;
          time_ini = element.created_at;  
          startTime = moment(time_ini, "YYYY-MM-DD HH:mm:ss");
          endTime = moment(moment().format("YYYY-MM-DD HH:mm:ss"), "YYYY-MM-DD HH:mm:ss");
          duration = moment.duration(endTime.diff(startTime));
          time = duration.hours()+' h '+duration.minutes()+' m '+duration.seconds()+ ' s';
          duration.asDays() >= 1 ? time = parseInt(duration.asHours()) + ' Horas' : time = time;
          duration.asHours() >= 24 ? element.color = 'warning' : '';
          duration.asHours() < 24 ? element.color = 'success' : '';
          error_rcl == 1 ? element.color = 'danger' :''; //se agrega validacion error rcl color rojo
          c++;
        });
      }
    }, 1000);
    
  }

  async deleteCase(e: MouseEvent, id){
    e.preventDefault();
    let data = {
      id: parseInt(id)
    }
    this.idDelete = data.id;
    let option = await this.swalService.swalAlert('Advertencia', '¿Está seguro de querer eliminar este registro?', 'warning');
    if(option){
      Swal.fire({
        icon: 'info',
        title: 'Cargando...',
        showConfirmButton: false,
        allowOutsideClick: false
      });
      this.ClaimService.deleteCase(data).subscribe(
        res => this.handleResponse(data.id),
        err => this.handleError(err)
      );
    }
  }

  handleResponse(data){
    Swal.close();
    this.processDelete(); // Remove row of table.
    Swal.fire({
      icon: 'success',
      title: 'Exito!'
    });
  }

  processDelete(){
    let c = 0;
    this.claims.forEach((element)=>{
        if(element.id == this.idDelete){
          this.claims.splice(c, 1);
          this.idDelete = null;
        }
        c++;
    });
  }

  handleError(err){
    Swal.fire({
      icon: 'error',
      title: 'Ha ocurrido un error en el servidor, contacte con el administrador.'
    });
  }

}
