import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { User } from 'src/app/models/user';

@Injectable({
  providedIn: 'root'
})
export class UserInformationService {

  /**
   * Crear observablea la variable de usuario
   */
  private user = new BehaviorSubject<User>(null);

  /**
   * Observable de la información de usuario
   */
  userStatus = this.user.asObservable();

  constructor(

  ) {}

  /**
   * Método para actualizar la información del usuario
   */
  updateInformation(user: User): void {
    this.user.next(user)
  }


}
