import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenService } from '../token.service';
import { Observable } from 'rxjs/internal/Observable';
import { defer, of, Subject } from 'rxjs';
import { map, mergeAll, shareReplay, switchMap } from 'rxjs/operators';
import { TypeId } from 'src/app/models/type-id';
import { environment } from 'src/environments/environment';

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Injectable({
  providedIn: 'root'
})
export class PersonsService {

  public baseUrl = environment.url;

  private readonly refreshTypeIdSubject = new Subject();

  header(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer `+this.token.get())
    }
  }

  constructor(private http:HttpClient, private token: TokenService) { }

  getPersons(data){
    return this.http.post<DataTablesResponse>(`${this.baseUrl}/persons`, data, this.header());
  }

  getPersonsNgzorro(nextPage, value, pageSize){
    return this.http.get<any>(`${this.baseUrl}/persons?page=${nextPage}&value=${value}&pageSize=${pageSize}`, this.header());
  }

  create(data){
    return this.http.post(`${this.baseUrl}/person/create`, data, this.header());
  }

  update(data){
    return this.http.post(`${this.baseUrl}/person/update`, data, this.header());
  }

  select(data){
    return this.http.post(`${this.baseUrl}/person/select`, data, this.header());
  }

  delete(data){
    return this.http.post(`${this.baseUrl}/person/delete`, data, this.header());
  }

  getTypeId(data): Observable<any> {
    return this.http.post<DataTablesResponse>(`${this.baseUrl}/id_types`, data);
  }

  get typeId(): Observable<TypeId[]> {
    return of(
      this.getTypeId([]).pipe(
        map((e) => e.data)
      ),
      defer(() => this.refreshTypeIdSubject.pipe(
        switchMap(() => this.getTypeId([])))
      )
    ).pipe(
      mergeAll(),
      shareReplay(1),
    );
  }

  getPolicysType(data): Promise<any>{
    return (this.http.post<any>(`${this.baseUrl}/e3lPOa74MaZW98z3gUDx`, data) as Observable<any>).toPromise();
  }

}
