import { saveAs } from 'file-saver';
import { Component, OnInit } from '@angular/core';
import { NewsService } from 'src/app/Services/news/news.service';
import { SwalService } from 'src/app/Services/swal/swal.service';
import { environment2 } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  private url: string = environment2.url
  public news = <any>[];
  constructor(
    private newsService: NewsService,
    private swalService: SwalService
  ) { }

  ngOnInit() {
    this.getNews();
  }

  private async getNews(): Promise<void> {
    try {
      this.swalService.loading()
      let data = await this.newsService.getNews([])
      this.news = data.data;
      this.swalService.close()
    } catch (e) {
      this.swalService.error(e)
    }
  }
  posicionPalabra = -1
  downloadFileById(Event: MouseEvent, id, name) {
    Event.preventDefault()
    this.posicionPalabra = name.indexOf('ws/')
    const nombre = name.substring(this.posicionPalabra + 3); // obtener subcadena desde la posición conocida hasta el final
    this.downloadFile(nombre)
  }

  downloadFile(filename) {
    this.newsService.downloadFile(filename).subscribe(blob => {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = filename;
      link.click();
      window.URL.revokeObjectURL(url);
    }, error => {
      Swal.fire({
        title: 'Error',
        text: "Archivo no encontrado, por favor cárguelo nuevamente.",
        icon: 'error',
      });
    });
  }

}
