import { data } from 'jquery';
import { Component, OnInit } from '@angular/core';
import { TokenService } from 'src/app/Services/token.service';
import { ErrorCaseComponent } from 'src/app/components/error-case/error-case.component';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/Services/auth.service';
import { FormControl, FormGroup } from '@angular/forms';
import { ViewContainerRef } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { RequestService } from 'src/app/Services/request/request.service';
import { insuranceError } from 'src/app/Models/error-seguros/insuranceError';
import { environment } from 'src/environments/environment';
import { environment2 } from 'src/environments/environment';
import Swal from 'sweetalert2';
/**
 * Componente para listar errores del sistema
 * @author Pablo Morales <pablo.morales@holdingvml.net>
 */
@Component({
  selector: 'app-error-list',
  templateUrl: './error-list.component.html',
  styleUrls: ['./error-list.component.css']
})
export class ErrorListComponent implements OnInit {

  private url: string = environment2.url


  listOfData: { data: insuranceError[], total: number, current_page: number } = {} as any
  dtOptions: DataTables.Settings;
  public user = { profile: null, permission: false };
  loading: boolean = false

  form: FormGroup = new FormGroup({
    value: new FormControl(''),
  })

  constructor(
    private token: TokenService,
    private router: Router,
    private Auth: AuthService,
    private RequestService: RequestService,
    private modal: NzModalService, private viewContainerRef: ViewContainerRef
  ) { }

  ngOnInit(): void {
    this.userid()
    this.loadData()
    this.form.controls['value'].valueChanges.subscribe(
      data => this.loadData(1, data)
    )
    this.checkUser();
  }
  /**
   * Método para cargar los errores.
   * @param value
   * @param current_page
   * @returns Arreglo con los errores
   * @author Pablo Morales <pablo.morales@holdingvml.net>
   */
  loadData(current_page: number = 1, value: string = ''): void {
    this.loading = true
    this.RequestService.getErrors(current_page, value).subscribe(data => {
      this.listOfData = data
      console.log(this.listOfData.data)
      this.loading = false
    })
  }
  profile_ID: number
  userid() {
    this.profile_ID = this.token.getUser().profile_ID
  }
  deleteError(e: MouseEvent, value: any) {
    e.preventDefault()
    let array = { 'id': value }
    Swal.fire({
      title: '¿Está Seguro?',
      text: "No podrá Revertir!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, Borralo',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        this.RequestService.deleteError(array).subscribe(data => {
          Swal.fire({
            text: 'Borrado!',
            icon: 'success',
          })
          this.ngOnInit()
        })
      }
    })
  }

  /**
   * Método para registrar nuevos errores.
   * @param event
   * @param value
   * @param name
   * @returns Registro de nuevo error del sistema en base de datos utilizando una componente aparte.
   * @author Pablo Morales <pablo.morales@holdingvml.net>
   */
  addError(e: MouseEvent, value: any = null, name: any = null): void {
    e.preventDefault();
    const id = value;
    const modal = this.modal.create({
      nzMaskClosable: false,
      nzClosable: false,
      nzContent: ErrorCaseComponent,//componente aparte
      nzViewContainerRef: this.viewContainerRef,
      nzComponentParams: {
        title: 'Registro de errores del sistema',
      },
      nzOnOk: () => new Promise(resolve => setTimeout(resolve, 1000)),
      nzFooter: [
      ]
    });
    const instance = modal.getContentComponent();
    // Reemplazando los parametros de la componente aparte por  los parametros ingresados al método
    if (id != null) {
      instance.title = 'Modificar Error'
    };
    modal.afterOpen.subscribe(() => { });
    // Return a result when closed
    modal.afterClose.subscribe(() => this.loadData());
    // delay until modal instance created
    setTimeout(() => {

    }, 2000);
  }

  /**
 * Método para cerrar sesión.
 * Redirige al usuario a la página de inicio.
 * @author
 */
  logout() {
    this.token.remove();
    this.Auth.changeAuthStatus(false);
    this.router.navigateByUrl('/login');
  }

  /**
 * Método para buscar usuario.
 * @returns Parametros de acceso del usuario que son envados al método fillData.
 * @author
 */
  checkUser() {
    this.token.searchUser().subscribe(
      data => this.fillData(data)

    );
  }

  /**
 * Método para asignar permiso al usuario.
 * @param data
 * Cambia el valor de user.permission si se cumple la condición
 * @author
 */
  fillData(data) {
    this.user.profile = data.user.profile_ID;
    if (this.user.profile == 1) {
      this.user.permission = true;
    }
  };
}
