<div class="col-sm-12" id="digipat">
    <div class="card" style="margin-left: 1px">
        <div class="card-header header-color text-center">
            <h6>DIGIPAT</h6>
        </div>
        <div class="card-body">
            <form [formGroup]="formGroup">
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="selectCoordinatorD">Coordinador:</label>
                        <select name="selectCoordinatorD" id="selectCoordinatorD" class="form-control form-control-sm" required
                            formControlName="coordinator">
                            <option value="">Choose...</option>
                            <option *ngFor="let coordinator of coordinators | async" value="{{coordinator.id}}">{{coordinator.name}}</option>
                        </select>
                        <div class="text-danger" [hidden]="!error.coordinator">
                            {{error.coordinator}}
                        </div>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="inputReportTimeDigipat">Hora de Reporte:</label>
                        <input type="text" class=" datePick form-control form-control-sm" id="inputReportTimeDigipat" name="inputReportTimeDigipat"
                            formControlName="reportTimeDigipat" [owlDateTime]="dt3" [owlDateTimeTrigger]="dt3">
                            <owl-date-time #dt3></owl-date-time>
                    </div>
                    <div class="form-group col-md-3">
                        <label for="inputEndTimeDigipat">Hora de Contacto:</label>
                        <input type="text" class=" datePick form-control form-control-sm" id="inputEndTimeDigipat" name="inputEndTimeDigipat"
                            formControlName="endTimeDigipat" [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2">
                            <owl-date-time #dt2></owl-date-time>
                    </div>
                    <div class="form-group col-md-3">
                        <label for="inputDateArrivalDigipat">Agente en Sitio:</label>
                        <input type="text" class="datePick form-control form-control-sm" id="inputDateArrivalDigipat" name="inputDateArrivalDigipat"
                            formControlName="arrivalTimeDigipat" [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1">
                            <owl-date-time #dt1></owl-date-time>
                    </div>
                    <div class="form-group col-md-3">
                        <label for="selectAvailabilityDigipat">Disponibilidad:</label>
                        <select name="selectAvailabilityDigipat" id="selectAvailabilityDigipat" class="form-control form-control-sm" required
                                formControlName="availabilityDigipat">
                            <option value="">Choose...</option>
                            <option value="1">Disponible</option>
                            <option value="2">No Disponible</option>
                            <option value="3">Fuera de cobertura</option>
                            <option value="5">Retiro del lugar de los hechos</option>
                            <option value="6">Cancelado</option>
                            <option value="4">NA</option>
                        </select>
                        <small class="text-danger" *ngIf="error.availabilityDigipat">{{ error.availabilityDigipat }}</small>
                    </div>
                    <div class="col-md-1">
                        <label for="none"><hr></label>
                        <a href="#" (click)="onSubmit($event)" class="btn btn-info btn-sm">Guardar</a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
