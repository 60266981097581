
<div class="content">
  <div class="col-md-10 offset-0">
    <div class="card col-md-10 col-lg-10 col-xl-12" style="margin-left: 2px">
      <div class="card-header header-color">
      </div>
      <div class="card-body">
        <div class="tab-content" id="myTabContent">
          <div>
            <div class="float-left">
              <h4>Inf. General de Errores del sistema</h4>
            </div>
            <div class="btn-group float-right" role="group" aria-label="Basic example">
              <button nz-button nzType="primary" (click)="addError($event)">
                <span>Registrar Nuevo</span>
              </button>
            </div>
          </div>
          <br>
          <hr>
          <nz-input-group [nzSuffix]="suffixIconSearch" [formGroup]="form">
            <input type="text" #searchText nz-input placeholder="Buscar coincidencias (Nombre, Apellido, Placa)"
              formControlName="value">
          </nz-input-group>
          <ng-template #suffixIconSearch>
            <i nz-icon nzType="search"></i>
          </ng-template>

          <nz-table #basicTable [nzTotal]="listOfData.total" [nzFrontPagination]="false" [nzData]="listOfData.data"
            (nzPageIndexChange)="loadData($event)" [nzScroll]="{ x: '1100px', y: '320px' }" [nzLoading]="loading">
            <thead>
              <tr>
                <th *ngIf="profile_ID==1" nzLeft>Acción</th>
                <th nzLeft>Imagen</th>
                <th>Nombre</th>
                <th>Apellido</th>
                <th>Celular</th>
                <th>Fecha</th>
                <th>Placa</th>
                <th>Descripción</th>
                <th nzRight>Cobertura</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of listOfData.data">
                <td *ngIf="profile_ID==1" nzLeft>
                  <button (click)="deleteError($event, data.id)" nz-button nzType="dashed" [nzSize]="size" nzShape="circle">
                    <span nz-icon [nzType]="'delete'" nzTheme="twotone"></span>
                  </button>
                </td>
                <td nzLeft> <img nz-image nz-popover nzPopoverTitle="click sobre la imagen para ver en tamaño real" width="40px"
                    height="40px" nzSrc="{{url + data.img}}" alt="" />
                </td>
                <td>{{ data.nombres }}</td>
                <td>{{ data.apellidos }}</td>
                <td>{{ data.celular }}</td>
                <td>{{ data.fecha }}</td>
                <td>{{ data.placa }}</td>
                <td>
                  <nz-collapse  nzGhost>
                    <nz-collapse-panel>
                      {{ data.descripcion }}
                    </nz-collapse-panel>
                  </nz-collapse>
                </td>
                <td nzRight>{{ data.cobertura }}</td>
              </tr>
            </tbody>
          </nz-table>
        </div>
      </div>
    </div>

  </div>

</div>


