import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenService } from '../token.service';
import { environment } from 'src/environments/environment';


class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Injectable({
  providedIn: 'root'
})
export class RowsMovilService {

  private baseUrl = environment.url;

  header(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer `+this.token.get())
    }
  }

  constructor(private http:HttpClient, private token: TokenService) { }

  getMovil(dataTablesParameters){
    return this.http.post<DataTablesResponse>(`${this.baseUrl}/lRAVHtozwiHnGHB5QKwy`, dataTablesParameters, this.header());
  }

  select(data){
    return this.http.post(`${this.baseUrl}/movil/select`, data, this.header());
  }

  update(data){
    return this.http.post(`${this.baseUrl}/movil/update`, data, this.header());
  }

}

