import { Component, OnInit } from '@angular/core';
import { JarwisService } from 'src/app/Services/jarwis.service';
import { TokenService } from 'src/app/Services/token.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { UserService } from 'src/app/Services/users/user.service';
import Swal from 'sweetalert2';
import { RolService } from 'src/app/Services/users/rol.service';
import { Rol } from 'src/app/models/rol';
import { SwalService } from 'src/app/Services/swal/swal.service';


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  isLoadingForm: boolean = false
  roles: Rol[] = []

  public form = {
    id: null,
    email: null,
    name: null,
    password: null,
    password_confirmation: null,
    profile_ID: '',
    state: '',
  }

  public error = {
    name: null,
    email: null,
    password: null,
    state: null
  };

  constructor(
    private Jarwis: JarwisService,
    private Token: TokenService,
    private UserService: UserService,
    private swalService: SwalService,
    private router: Router,
    private notify: SnotifyService,
    private route:ActivatedRoute,
    private rolService: RolService
  ) {
    this.form.id = route.snapshot.params["id"];
  }

  onSubmit(){
    this.isLoadingForm = true
    this.notify.info('Espere...', {timeout:0});
    if(this.form.id != null){
      this.UserService.userUpdate(this.Token.get(), this.form).subscribe(
        data => this.handleResponse(data),
        err => this.handleError(err)
      );
    }else{
      this.Jarwis.signup(this.form, this.Token.get()).subscribe(
        data => this.handleResponse(data),
        err => this.handleError(err)
      );
    }
  }

  handleResponse(data){
    this.isLoadingForm = false
    this.notify.remove();
    this.notify.success('Exito!', {timeout: 2000});
  }

  handleError(error){
    this.isLoadingForm = false
    this.notify.remove();
    this.error = error.error.errors;
  }

  fillData(data){
    this.notify.remove();
    if(data.name){
      this.form.name = data.name;
      this.form.email = data.email;
      this.form.profile_ID = data.profile_ID;
      this.form.state = data.state
    }else{
      this.fillDataError();
    }
    if(data.status){
      this.logout();
    }
  }

  logout(){
    this.Token.remove();
    this.router.navigateByUrl('/login');
    setTimeout(()=>{
      window.location.reload();
    }, 100);
  }

  fillDataError(){
    this.notify.remove();
    this.notify.error('Algo ha salido mal', {timeout: 5000});
  }

  sendFill(){
    if(this.form.id != null){
      this.notify.info('Espere...', {timeout:0});
      let data = {
        data: {
          id: this.form.id
        },
        token: this.Token.get()
      }
      this.UserService.getUser(data).subscribe(
        data => this.fillData(data),
        err => this.fillDataError()
      );
    }
  }

  ngOnInit() {
    this.sendFill();
    this.rolService.get().subscribe(data => {
      this.roles = data
    })
  }

  async resetPassword(Event: MouseEvent){
    Event.preventDefault();
    let opt = await this.swalService.swalAlert("Advertencia", "¿Está seguro que desea restablecer la contraseña del usuario?", 'warning');
    if(opt){
      this.processResetPassword();
    }
  }

  processResetPassword(){
    Swal.fire({
      title: 'Digite nueva contraseña',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Guardar',
      showLoaderOnConfirm: true,
      preConfirm: (login) => {
        let data = {
          id: this.form.id,
          password: login
        }
        this.UserService.resetPassword(data).subscribe(
          data => {
            Swal.fire({
              title: 'Cambio exitosamente',
              text: 'Recuerde que el usuario también podrá cambiar la contraseña mediante el correo registrado',
              icon: 'success'
            })
          }
        );
      },
      allowOutsideClick: () => !Swal.isLoading()
    });
  }

  compareFn (o1: any, o2: any) {
    return o1 && o2 ? o1 == o2 : o1 == o2;
  }

}
