import { Component, HostListener, OnDestroy, OnInit } from '@angular/core'
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import * as moment from 'moment'
import { SnotifyService } from 'ng-snotify'
import { NzTabPosition } from 'ng-zorro-antd/tabs'
import { Socket } from 'ngx-socket-io'
import { Observable } from 'rxjs'
import { AuthService } from 'src/app/Services/auth.service'
import { BeneficiariesService } from 'src/app/Services/beneficiaries/beneficiaries.service'
import { ClientService } from 'src/app/Services/clients/client.service'
import { PersonsService } from 'src/app/Services/persons/persons.service'
import { RowsGeneralService } from 'src/app/Services/report-accidents/rows-general.service'
import { SwalService } from 'src/app/Services/swal/swal.service'
import { TokenService } from 'src/app/Services/token.service'
import { UserInformationService } from 'src/app/Services/users/user-information.service'
import { Client } from 'src/app/models/client'
import { Departament } from 'src/app/models/departament'
import { pwa } from 'src/app/models/pwa'
import { TypeAccident } from 'src/app/models/type-accident'
import { TypeId } from 'src/app/models/type-id'
import { TypeSinister } from 'src/app/models/type-sinister'
import * as fieldForm from 'src/assets/js/camposFormulario.js'
import Swal from 'sweetalert2'

@Component({
  selector: 'app-form-general',
  templateUrl: './form-general.component.html',
  styleUrls: ['./form-general.component.css']
})

export class FormGeneralComponent implements OnInit, OnDestroy {


  isFilled: boolean = false
  msg: string = ''
  nameCompany: string = ''
  elementPWA = []
  elementPWAthird = []
  interestedDocument: boolean = false
  intermitente = ''
  intermitenteAsista: string = ''
  loading: boolean = false
  selectedCityname: any
  selectedCity: any
  auroraId: string
  position: NzTabPosition = 'top'
  isVisible = false
  isVisible2 = false
  isVisible3 = false
  isvisibleHub = false
  informPoliza = false
  informPolizaHub = false
  color: boolean
  colorhub: boolean
  hub: boolean
  response: string
  cod: string
  placa: string
  fechaIni: string
  fechaFin: string
  estado: string
  ramo: string
  empresa: string
  cliente: string
  docliente: string
  doclienteHub: string
  telefono: string
  ciudad: string
  polizas: any
  deducible: any
  nombre_producto: any
  array_default: pwa[] = []
  array_hub: any = []
  array_hub_table: any = []
  hubRamo: any
  hubestado: any
  hubfechaini: any
  hubfechafin: any
  tabs: any = []
  arraydigipatHUB: any = []
  arraydigipatTecnicar: any = []
  departamentsFinder: Departament[]
  public service = false
  public services = {
    asista: false,
    fcii: false,
    digipat: false,
    pwa: false,
    movil: false
  }
  public formGroup: FormGroup = new FormGroup({
    id: new FormControl(),
    plate: new FormControl(),
    name: new FormControl(),
    id_type: new FormControl(1),
    typePolicy: new FormControl(1),
    document_number: new FormControl(0),
    policy: new FormControl(0),
    phone: new FormControl(),
    address: new FormControl(),
    company: new FormControl(''),
    interestedName: new FormControl(),
    interestedPhone: new FormControl(),
    interestedDocument: new FormControl(),
    hourSinister: new FormControl(null, [Validators.required, this.validatePastTime.bind(this)]),
    hourReportSinister: new FormControl(null, [Validators.required, this.validatePastTime.bind(this)]),
    typeSinister: new FormControl(),
    accidentAddress: new FormControl(),
    currentAddress: new FormControl(),
    citys_departament_id: new FormControl(),
    citys_cod_mun: new FormControl(),
    ramoInput: new FormControl(),
    estadoInput: new FormControl(),
    fechaIniInput: new FormControl(),
    fechaFinInput: new FormControl(),
    observations: new FormControl(),
    client: new FormControl(''),
    case_state: new FormControl(),
    asista: new FormControl(),
    fcii: new FormControl(),
    digipat: new FormControl(),
    pwa: new FormControl(),
    movil: new FormControl(),
    user: new FormControl(),
    typeAccident: new FormControl(),
    nameCaseState: new FormControl(),
    registration_date: new FormControl(),
    end_date: new FormControl(),
    comment: new FormControl()
  })

  public clients: Observable<Client[]> = this.ClientService.clients
  public companys: any[]
  public id_types: Observable<TypeId[]> = this.PersonService.typeId
  public policystype: any[]
  public typesinisters: Observable<TypeSinister[]> = this.GeneralServices.typeSinisters
  public typeaccidents: Observable<TypeAccident[]> = this.GeneralServices.typeAccidents
  public departaments: Observable<Departament[]> = this.GeneralServices.departaments
  public citys: any[]
  public statecases: any[]
  public error: any = {}
  public obsData = { cont: 1, comment: null }
  public isDisabled = false
  public permissionA = false
  public questions = {
    question1: null, question2: null
  }
  public answer: any
  public showForm = true
  public firstSubmit: boolean = false
  public word: string = 'Asistencia Jurídica'
  private _originalValue: Object
  constructor(
    private GeneralServices: RowsGeneralService,
    private Token: TokenService,
    private Notify: SnotifyService,
    private router: Router,
    public route: ActivatedRoute,
    private ClientService: ClientService,
    private BeneficiarieService: BeneficiariesService,
    private PersonService: PersonsService,
    private Auth: AuthService,
    private socket: Socket,
    private swalService: SwalService,
    private userInformationService: UserInformationService
  ) {
    this.formGroup.get('id').setValue(route.snapshot.params["id"])
    this.service = route.snapshot.params["service"]
    this.auroraId = route.snapshot.params["idcase"]
    this.departamentsFind()
  }

  async ngOnInit(): Promise<void> {
    await this.getBasicData()
    if (this.service) {
      this.isDisabled = true
    }
    if (this.formGroup.get('id').value) {
      this.firstSubmit = true
      this.formGroup.get('hourSinister').disable()
      this.formGroup.get('hourReportSinister').disable()
      //this.onEmitAllow(0)
      const idCompany = parseInt(this.formGroup.get('company').value)
      const indice = this.idBeneficiaries.includes(idCompany)
      indice ? this.hadleFilled(idCompany) : this.intermitenteAsista = ''
    } else {
      setTimeout(() => {
        $('#loading-gif').hide()
        $('#opacityDiv').css('opacity', 1)
        Swal.close()
      }, 1000)
    }
    if (this.auroraId) {
      this.auroraMethod(this.route.snapshot.params["idcase"])
    }
    this.userInformationService.userStatus.subscribe(data => this.fillUser(data))
  }

  async getBasicData(): Promise<void> {
    try {
      this.swalService.loading()
      await this.sendFill()
      await this.fillCompanys()
      let promise = Promise.all([
        this.GeneralServices.getCitys(this.formGroup.getRawValue()),
        this.GeneralServices.getStateCases(this.formGroup.getRawValue()).toPromise()
      ])
      let data = await promise
      this.citys = data[0].data
      this.statecases = data[1].data
      this.statecases.splice(4, 1)
      this._originalValue = this.formGroup.getRawValue()
      this.swalService.close()
    } catch (e) {
      this.logout()
    }
  }

  validatePastTime(control: AbstractControl | null) {
    if (control) {
      const selectedTime: string = control.value;
      // Obtén la hora actual
      const currentTime: Date = new Date();
      // Formatea la hora actual como 'HH:mm'
      const currentHour: string = ('0' + currentTime.getHours()).slice(-2);
      const currentMinute: string = ('0' + currentTime.getMinutes()).slice(-2);
      const currentFormattedTime: string = `${currentHour}:${currentMinute}`;
      // Compara las horas
      if (selectedTime > currentFormattedTime) {
        return { invalidTime: true };
      }
    }
    return null;
  }

  modifyDateCase(data: string) {
    const result = data.split(' ')
    return result[1]
  }

  setContact(e?: MouseEvent): void {
    e.preventDefault()
    this.intermitente = ''
    this.formGroup.get('interestedName').setValue(this.formGroup.get('name').value)
    this.formGroup.get('interestedPhone').setValue(this.formGroup.get('phone').value)
    if (this.informPoliza) {
      this.formGroup.get('interestedDocument').setValue(this.docliente)
    } else if (this.informPolizaHub) {
      this.formGroup.get('interestedDocument').setValue(this.doclienteHub)
    }
  }

  async showModal(e?: MouseEvent): Promise<void> {
    e.preventDefault()
    this.companys = []
    this.array_default = []
    this.array_hub = []
    this.array_hub_table = []
    this.informPoliza = false
    this.informPolizaHub = false
    this.isvisibleHub = false
    this.isVisible = true
    let plate = this.formGroup.controls['plate'].value
    const plateData = await this.GeneralServices.findPlate(plate).toPromise()
    this.response = plateData.response
    if (plateData.response === 'CORRECTO') {
      this.isVisible3 = true
      this.intermitente = 'intermitente'
      var arrayDePolizas = plateData.polizas.split('|')
      var arrayDededucible = plateData.deducible.split('|')
      var arrayDenombre_producto = plateData.nombre_producto.split('|')
      var arrayRamo = plateData.ramo.split(' ')
      this.cliente = plateData.cliente
      this.ramo = plateData.ramo
      this.estado = plateData.estado
      this.fechaFin = plateData.fechaFin
      this.fechaIni = plateData.fechaIni
      this.telefono = plateData.telefono
      this.empresa = plateData.empresa
      this.docliente = plateData.docliente
      arrayDePolizas.forEach((currentValue, index, array) => {
        this.array_default.push({
          "id_poliza": currentValue.toString(),
          "cliente": plateData.cliente,
          "placa": plateData.placa,
          "fechaIni": plateData.fechaIni,
          "fechaFin": plateData.fechaFin,
          "estado": plateData.estado,
          "empresa": plateData.empresa,
          "propietario": plateData.cliente,
          "doc_propietario": plateData.docliente,
          "telefono": plateData.telefono,
          "ciudad": plateData.ciudad,
          "deducible": arrayDededucible[index],
          "nombre_producto": arrayDenombre_producto[index],
          "ramo": arrayRamo[index]
        })
      })
      this.array_default.pop()
      this.isVisible2 = true
      this.color = true
      this.colorhub = false

    } else {
      this.formGroup.get('client').setValue('')
      this.formGroup.get('company').setValue('')
      this.array_hub = await this.GeneralServices.findPlateRest(plate).toPromise()
      if (this.array_hub != null) {
        if (this.array_hub.policy_origin == 'NA') {
          this.colorhub = false
          this.isvisibleHub = false
        } else {
          this.doclienteHub = this.array_hub.propietario.id_propietario
          this.intermitente = 'intermitente'
          this.array_hub_table.push(
            {
              "hubRamo": this.array_hub.policy_origin,
              "hubfechafin": this.array_hub.policy_end_date,
              "hubfechaini": this.array_hub.policy_start_date,
              "hubdir_propietario": this.array_hub.propietario.dir_propietario,
              "hubid_propietario": this.array_hub.propietario.id_propietario,
              "hubmail_propietario": this.array_hub.propietario.mail_propietario,
              "hubnom_propietario": this.array_hub.propietario.nom_propietario,
              "hubclase_vehiculo": this.array_hub.vehicle.clase_vehiculo,
              "hubcliente": this.array_hub.vehicle.cliente,
              "hublinea": this.array_hub.vehicle.linea,
              "hubmarca": this.array_hub.vehicle.marca,
              "hubmodelo": this.array_hub.vehicle.modelo,
              "hubplaca": this.array_hub.vehicle.placa,
              "hubtipo": this.array_hub.vehicle.tipo
            }
          )
          this.colorhub = true
          this.isvisibleHub = true

        }
      } else {
        this.colorhub = false
        this.isvisibleHub = false
      }
      this.color = false
    }
    this.isVisible3 = true
  }

  showModaldep(e?: MouseEvent): void {
    e.preventDefault()
    this.companys = []
    this.array_default = []
    this.array_hub = []
    this.array_hub_table = []
    this.informPoliza = false
    this.informPolizaHub = false
    this.isVisible = true
    let data = this.formGroup.controls['plate'].value
    this.GeneralServices.findPlateRest(data).subscribe(datas => {
      this.hub = true
      this.tabs.push({ name: 'HUB', consulta: datas })
      this.array_hub = datas
      if (this.array_hub != null) {
        if (this.array_hub.policy_origin == 'NA') {
          this.colorhub = false
          this.isvisibleHub = false
        } else {
          this.doclienteHub = this.array_hub.propietario.id_propietario
          this.intermitente = 'intermitente'
          this.array_hub_table.push(
            {
              "hubRamo": this.array_hub.policy_origin,
              "hubfechafin": this.array_hub.policy_end_date,
              "hubfechaini": this.array_hub.policy_start_date,
              "hubdir_propietario": this.array_hub.propietario.dir_propietario,
              "hubid_propietario": this.array_hub.propietario.id_propietario,
              "hubmail_propietario": this.array_hub.propietario.mail_propietario,
              "hubnom_propietario": this.array_hub.propietario.nom_propietario,
              "hubclase_vehiculo": this.array_hub.vehicle.clase_vehiculo,
              "hubcliente": this.array_hub.vehicle.cliente,
              "hublinea": this.array_hub.vehicle.linea,
              "hubmarca": this.array_hub.vehicle.marca,
              "hubmodelo": this.array_hub.vehicle.modelo,
              "hubplaca": this.array_hub.vehicle.placa,
              "hubtipo": this.array_hub.vehicle.tipo
            }
          )
          this.colorhub = true
          this.isvisibleHub = true
        }
      }
    })
    this.GeneralServices.findPlate(data).subscribe(datas => {
      this.tabs.push({ name: 'Previcar', consulta: datas })
      this.isVisible3 = true
      this.cliente = datas.cliente
      this.ramo = datas.ramo
      this.estado = datas.estado
      this.fechaFin = datas.fechaFin
      this.fechaIni = datas.fechaIni
      this.telefono = datas.telefono
      this.empresa = datas.empresa
      this.docliente = datas.docliente
      this.response = datas.response
      this.cod = datas.cod
      this.placa = datas.placa
      this.ciudad = datas.ciudad
      this.polizas = datas.polizas
      this.deducible = datas.deducible
      this.nombre_producto = datas.nombre_producto
      if (this.response === 'CORRECTO') {
        this.intermitente = 'intermitente'
        var arrayDePolizas = this.polizas.split('|')
        var arrayDededucible = this.deducible.split('|')
        var arrayDenombre_producto = this.nombre_producto.split('|')
        var arrayRamo = this.ramo.split(' ')
        arrayDePolizas.forEach((currentValue, index, array) => {
          this.array_default.push({
            "id_poliza": currentValue.toString(),
            "cliente": '',
            "placa": this.placa,
            "fechaIni": this.fechaIni,
            "fechaFin": this.fechaFin,
            "estado": this.estado,
            "empresa": this.empresa,
            "propietario": this.cliente,
            "doc_propietario": this.docliente,
            "telefono": this.telefono,
            "ciudad": this.ciudad,
            "deducible": arrayDededucible[index],
            "nombre_producto": arrayDenombre_producto[index],
            "ramo": arrayRamo[index]
          })
        })
        this.array_default.pop()
        this.isVisible2 = true
        this.color = true
      } else {
        this.color = false
        this.formGroup.get('client').setValue('')
        this.formGroup.get('company').setValue('')
      }
    })
  }

  async handleOk(): Promise<void> {
    this.hiddenQuery()
    let rec: number
    console.log('Button ok clicked!')
    this.isVisible = false
    this.isVisible2 = false
    this.isVisible3 = false
    if (this.response === 'CORRECTO') {
      this.informPoliza = true
      this.interestedDocument = true
      this.formGroup.get('interestedDocument').setValue('')
      this.formGroup.get('client').setValue(1)
      this.fillCompanysChange()
      this.formGroup.get('name').setValue(this.cliente)
      this.formGroup.get('ramoInput').setValue(this.ramo)
      this.formGroup.get('estadoInput').setValue(this.estado)
      this.formGroup.get('fechaIniInput').setValue(this.fechaIni)
      this.formGroup.get('fechaFinInput').setValue(this.fechaFin)
      this.formGroup.get('phone').setValue(this.telefono)
      /* if (this.empresa.includes(' S.A.S.') || this.empresa.includes(' S.A.S') || this.empresa.includes(' S.A.') || this.empresa.includes(' S.A') || this.empresa.includes(' SAS.') || this.empresa.includes(' SA') || this.empresa.includes(' SA.')) {
        rec = 5
      }
      this.empresa = this.empresa.substring(0, this.empresa.length - rec)
      this.GeneralServices.getcompanyByname(this.empresa).subscribe(datas => {
        this.formGroup.get('company').setValue(datas.ID)
      })
      let data = await this.BeneficiarieService.getBeneficiariesByClient(this.formGroup.getRawValue())
      this.companys = data.data */
    }
    if (this.array_hub_table.length > 0) {
      if (this.array_hub.policy_origin != 'NA') {
        this.informPolizaHub = true
        this.interestedDocument = true
        this.formGroup.get('interestedDocument').setValue('')
        this.formGroup.get('client').setValue(1)
        //this.formGroup.get('client').disable()
        this.fillCompanysChange()
        this.formGroup.get('name').setValue(this.array_hub.propietario.nom_propietario)
        this.formGroup.get('ramoInput').setValue(this.array_hub.policy_origin)
        this.formGroup.get('estadoInput').setValue('Incluido')
        this.formGroup.get('fechaIniInput').setValue(this.array_hub.policy_start_date)
        this.formGroup.get('fechaFinInput').setValue(this.array_hub.policy_end_date)
        this.formGroup.get('phone').setValue(this.array_hub.propietario.tel_propietario)
      }
    }
    this.response = ''
    this.formGroup.patchValue(this.formGroup.getRawValue())
  }

  async auroraMethod(id): Promise<void> {
    const mouseEvent = new MouseEvent('click', { bubbles: true, cancelable: true, view: window, detail: 1, clientX: 100, clientY: 200, })
    this.GeneralServices.getAuroraCase(id).subscribe(dta => {
      dta.forEach(element => {
        this.formGroup.get('plate').setValue(element.plate)
        this.showModal(mouseEvent)
        this.GeneralServices.getdepartamentbyname(element.departament).subscribe(dep => {
          dep.forEach(async elemento => {
            this.formGroup.get('citys_departament_id').setValue(elemento.id)
            this.citys = await (await this.GeneralServices.getCitys(this.formGroup.getRawValue())).data
            this.citys.forEach(elementcity => {
              if (elementcity.name == element.city) {
                this.formGroup.get('citys_cod_mun').setValue(elementcity.id)
              }
            })
          })
        })
        if (element.typeof_assistance == 'DIGIPAT') {
          this.formGroup.get('digipat').setValue(true)
          this.formGroup.get('typeSinister').setValue(1)
        } else if (element.typeof_assistance == 'FCII') {
          this.formGroup.get('fcii').setValue(true)
          this.formGroup.get('typeSinister').setValue(2)
        }
        this.formGroup.get('accidentAddress').setValue(element.accident_address + ', ' + element.reference_point)
        this.formGroup.get('currentAddress').setValue(element.accident_address + ', ' + element.reference_point)
        this.formGroup.get('observations').setValue(element.observations)
        this.formGroup.get('interestedName').setValue(element.interested_name)
        this.formGroup.get('interestedPhone').setValue(element.interested_phone)
      })
    })
  }

  handleCancel(): void {
    console.log('Button cancel clicked!')
    this.isVisible = false
    this.isVisible2 = false
    this.isVisible3 = false
    this.response = ''
  }

  enviarDatosAlNodeJS() {
    const datos = { mensaje: 'le di volver', id: this.auroraId, nameUser: '' };
    this.socket.emit('volver', datos);
    this.router.navigateByUrl(`aurora-cases`);
  }


  departamentsFind() {
    this.GeneralServices.departaments.subscribe(data => {
      this.departamentsFinder = data
    })
  }

  capitalizar() {
    const campoControl = this.formGroup.get('interestedName')
    const currentValue = campoControl.value
    campoControl.setValue(currentValue.replace(/\b\w/g, (match) => match.toUpperCase()), { emitEvent: false })
  }

  onInput(event: any) {
    const input = event.target
    const value = input.value
    const sanitizedValue = value.replace(/[^0-9]/g, '') // Elimina todos los caracteres no numéricos
    input.value = sanitizedValue // Actualiza el valor del campo de entrada
  }



  fillUser(data) {
    data.profile_ID == 1 ? this.permissionA = true : this.permissionA = true /* poner en false */
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
    this.onEmitAllow(1)
    return false
  }

  ngOnDestroy() {
    this.Notify.remove();
    (this.formGroup.get('id').value) ? this.onEmitAllow(1) : ''
  }

  onEmitAllow(state) {
    let data = {
      id: this.formGroup.get('id').value,
      state: state,
      user: null
    }
    this.GeneralServices.changeStatusCase(data).subscribe(datas => {
      this.socket.emit('case_allow', { idCase: parseInt(this.formGroup.get('id').value), state: state, user: datas })
    })
  }

  async onSubmit() {
    Swal.fire({
      icon: 'info',
      title: 'Cargando...',
      showConfirmButton: false,
      allowOutsideClick: false
    })
    if (this.formGroup.get('id').value != null) {
      let opt = await this.swalService.swalAlert("Advertencia", '¿Está seguro que desea actualizar?', 'warning')
      if (!opt) {
        this.Notify.remove()
        Swal.close()
        return false
      }
      if (this.formGroup.controls['hourSinister'].value == '') {
        this.formGroup.controls['hourSinister'].setValue(null)
      }

      let form = this.formGroup.getRawValue()
      this.obsData.comment = this.makeObservation(form)
      this.structureObservation()
      this.GeneralServices.update(this.formGroup.getRawValue()).subscribe(
        data => this.handleResponse(data),
        err => this.handleError(err)
      )
    } else {
      if (this.auroraId) {
        this.formGroup.addControl('casoAurora', new FormControl('si'))
      }
      this.GeneralServices.create(this.formGroup.getRawValue()).subscribe(async data => {
        const inf = {
          id: data
        }
        try {
          if (this.elementPWA.length || this.elementPWAthird.length) {
            let departamentoEncontrado
            this.departamentsFinder.forEach(element => {
              if (element.id == this.formGroup.controls['citys_departament_id'].value) {
                departamentoEncontrado = element.name
              }
            })
            const selectResult = await this.GeneralServices.select(inf)
            const selectedbeneficiariename = (await this.BeneficiarieService.getBeneficiariesbydigipat(selectResult.case).toPromise())[0].name
            const selectedCityname = (await this.GeneralServices.getCitysbydigipat(selectResult.case).toPromise())[0].name
            const datas = await this.GeneralServices.getReportpwa(selectResult.case.id).toPromise()
            this.insertCasePWA(selectResult, data, departamentoEncontrado, selectedbeneficiariename, selectedCityname, datas)
          } else {
            console.log('error consulta HUB y tecnicar')
            Swal.fire({
              position: 'center',
              icon: 'warning',
              title: 'El Vehículo no Tiene póliza',
              confirmButtonText: 'Aceptar',
              allowOutsideClick: false
            }).then((result) => {
              if (result.isConfirmed) {
                this.handleResponseCreate(data)
              }
            })
          }
        } catch (error) {
          this.handleError(error)
        }
      })
    }
  }



  makeObservation(form): string {
    let message = 'SE MODIFICA:'
    Object.keys(form).forEach((value, index, array) => {
      let field
      field = form[value] !== this._originalValue[value] ? fieldForm.object[value] : ''
      field = field !== '' ? ' ' + field + ',' : ''
      message = message + field
    })
    return message !== 'SE MODIFICA:' ? message.slice(0, message.length - 1) : null
  }

  handleError(error) {
    this.Notify.remove()
    Swal.close()
    this.error = error.error.errors
  }

  handleResponse(data) {
    data.status ? this.logout() : ''
    this.Notify.remove()
    Swal.close()
    this.Notify.success('Exito!', { timeout: 2000 })
    data === 1 ? '' : this.router.navigateByUrl('/report-accident/' + data)
    this._originalValue = this.formGroup.getRawValue()
  }

  handleResponseCreate(data) {
    if (this.auroraId) {
      const data = { 'id': this.auroraId }
      this.GeneralServices.acceptAuroraCase(data).subscribe(dta => {
        dta ? console.log(dta) : 'no llegó nada'
        let datos = { mensaje: 'se creó el caso', id: this.auroraId };
        this.socket.emit('casoCreado', datos);
      })
    }
    data.status ? this.logout() : ''
    this.Notify.remove()
    this.Notify.success('Exito!', { timeout: 2000 })
    data === 1 ? '' : this.router.navigateByUrl('/report-accident/' + data)
  }

  logout() {
    this.onEmitAllow(1)
    this.swalService.close()
    this.Token.remove()
    this.Auth.changeAuthStatus(false)
    this.router.navigateByUrl('/login')
  }

  addObservation(e: MouseEvent) {
    e.preventDefault()
    this.structureObservation()
  }

  structureObservation(): boolean {
    if (!this.obsData.comment) return false
    let observations = this.formGroup.controls['observations'].value
    observations.unshift({
      'comment': this.obsData.comment,
      'date': moment().format('YYYY-MM-DD hh:mm:ss'),
      'name': 'YO',
      'id': false
    })
    this.formGroup.controls['observations'].setValue(observations)
    this.obsData.cont++
    this.obsData.comment = null
  }

  async sendFill(): Promise<void> {
    try {
      if (this.formGroup.get('id').value != null) {
        let data = {
          id: this.formGroup.get('id').value
        }
        this.fillData(await this.GeneralServices.select(data))
      }
    } catch (e) {
      this.fillDataError()
    }
  }

  async hiddenQuery() {
    console.log('hiddenquery')
    this.elementPWA = []
    this.elementPWAthird = []
    const plateData = await this.GeneralServices.findPlate(this.formGroup.controls['plate'].value).toPromise()
    if (plateData.response === 'CORRECTO') {
      this.interestedDocument = true
      this.formGroup.get('interestedDocument').setValue('')
      this.elementPWA.push({
        'placa': plateData.placa,
        'clase': plateData.clase,
        'marca': plateData.marca,
        'linea': plateData.linea,
        'modelo': plateData.modelo,
        'servicio': plateData.servicio,
        'fechaIni': plateData.fechaIni,
        'fechaFin': plateData.fechaFin,
        'estado': plateData.estado,
        'ramo': plateData.ramo,
        'deducible': plateData.deducible,
        'polizas': plateData.polizas,
        'nombre_producto': plateData.nombre_producto
      })
    } else {
      const plateDataThird = await this.GeneralServices.findPlateRest(this.formGroup.controls['plate'].value).toPromise()
      if (plateDataThird) {
        if (plateDataThird.policy_origin == 'NA') {
          console.log('error consulta HUB')
          this.interestedDocument = false
        } else {
          console.log(plateDataThird)
          this.interestedDocument = true
          this.formGroup.get('interestedDocument').setValue('')
          this.elementPWAthird.push({
            'placa': plateDataThird.vehicle.placa,
            'clase': plateDataThird.vehicle.clase_vehiculo,
            'marca': plateDataThird.vehicle.marca,
            'linea': plateDataThird.vehicle.linea,
            'modelo': plateDataThird.vehicle.modelo,
            'servicio': plateDataThird.vehicle.tipo,
            'fechaIni': plateDataThird.policy_start_date,
            'fechaFin': plateDataThird.policy_end_date,
            'estado': 'INCLUIDO',
            'ramo': plateDataThird.policy_origin,
            'deducible': '0',
            'polizas': plateDataThird.policy_number,
            'nombre_producto': plateDataThird.policy_origin
          })
        }
      }
    }
    if (this.elementPWA.length) {
      console.log('consulta tecnicar hecha')
    } else if (this.elementPWAthird.length) {
      console.log('consulta HUB hecha')
    } else {
      console.log('vaciopwa')
      this.formGroup.get('client').enable()
    }
  }

  onInputChange(event: any) {
    this.elementPWAthird.length || this.elementPWA.length ? console.log('la consulta ya se hizo') : this.hiddenQuery()
    //this.elementPWA.length ? console.log('la consulta ya se hizo') : this.hiddenQuery()
  }

  async insertCasePWA(data, dataCase, departamentoEncontrado, selectedbeneficiariename, selectedCityname, datas) {
    try {
      Swal.close()
      this.loading = true
      if (!data.case) {
        this.fillDataError()
        return
      }
      const comprobate = Object.keys(datas).length === 0
      if (comprobate) {
        console.log('arreglo vacío')
        if (this.elementPWA.length) {
          this.elementPWA[0].empresa = selectedbeneficiariename,
            this.elementPWA[0].cliente = this.formGroup.controls['interestedName'].value,
            this.elementPWA[0].docliente = this.formGroup.controls['interestedDocument'].value,
            this.elementPWA[0].telefono = this.formGroup.controls['interestedPhone'].value,
            this.elementPWA[0].departamento = departamentoEncontrado,
            this.elementPWA[0].ciudad = selectedCityname,
            this.elementPWA[0].codigo_divipola = "",
            this.elementPWA[0].direccion = this.formGroup.controls['currentAddress'].value,
            this.elementPWA[0].ciudad_siniestro = selectedCityname,
            this.elementPWA[0].direccion_siniestro = this.formGroup.controls['currentAddress'].value,
            this.elementPWA[0].remitente = "tecnicar",
            this.elementPWA[0].tipo_caso = this.formGroup.controls['typeSinister'].value
          const res: any = await this.GeneralServices.insertpwa(this.elementPWA[0]).toPromise()
          if (res.success) {
            const response = res.message
            if (response.toLowerCase().includes("error")) {
              console.log("La palabra 'error' está presente en el string.")
              Swal.fire({
                //title: 'No se creó caso en Digipats',
                title: 'No se creó caso en Leoncy-fusión',
                text: res.message,
                icon: 'warning',
                confirmButtonText: 'Aceptar',
                allowOutsideClick: false
              }).then((result) => {
                if (result.isConfirmed) {
                  this.handleResponseCreate(dataCase)
                }
              })
            } else {
              console.log("La palabra 'error' no está presente en el string.")
              Swal.fire({
                position: 'center',
                icon: 'success',
                //title: 'Caso Reportado en Digipats PWA',
                title: 'Caso Reportado en Leoncy-fusión',
                text: 'Ramo RCE RCC AP',
                confirmButtonText: 'Aceptar',
                allowOutsideClick: false
              }).then(async (result) => {
                if (result.isConfirmed) {
                  await this.GeneralServices.insertReportpwa(data.case).toPromise()
                  this.handleResponseCreate(dataCase)
                }
              })
            }
          } else {
            Swal.fire({
              //title: 'Error en la solicitud Digipats',
              title: 'Error en la solicitud Leoncy-fusión',
              text: res.message,
              icon: 'error',
              confirmButtonText: 'Aceptar',
              allowOutsideClick: false
            }).then((result) => {
              if (result.isConfirmed) {
                this.handleResponseCreate(dataCase)
              }
            })
          }
        } else if (this.elementPWAthird.length) {
          const depId=this.formGroup.controls['citys_departament_id'].value
          const cod_mun=this.formGroup.controls['citys_cod_mun'].value
          const divipola= this.GeneralServices.formatNumber(depId,cod_mun)
          this.elementPWAthird[0].empresa = selectedbeneficiariename,
            this.elementPWAthird[0].cliente = this.formGroup.controls['interestedName'].value,
            this.elementPWAthird[0].docliente = this.formGroup.controls['interestedDocument'].value,
            this.elementPWAthird[0].telefono = this.formGroup.controls['interestedPhone'].value,
            this.elementPWAthird[0].departamento = departamentoEncontrado,
            this.elementPWAthird[0].ciudad = selectedCityname,
            this.elementPWAthird[0].codigo_divipola = divipola,
            this.elementPWAthird[0].direccion = this.formGroup.controls['currentAddress'].value,
            this.elementPWAthird[0].ciudad_siniestro = selectedCityname,
            this.elementPWAthird[0].direccion_siniestro = this.formGroup.controls['currentAddress'].value,
            this.elementPWAthird[0].remitente = "seguro tercero",
            this.elementPWAthird[0].tipo_caso = this.formGroup.controls['typeSinister'].value
          const res: any = await this.GeneralServices.insertpwa(this.elementPWAthird[0]).toPromise()
          if (res.success) {
            const response = res.message
            if (response.toLowerCase().includes("error")) {
              console.log("La palabra 'error' está presente en el string.")
              Swal.fire({
                //title: 'No se creó caso en Digipats',
                title: 'No se creó caso en Leoncy-fusión',
                text: res.message,
                icon: 'warning',
                confirmButtonText: 'Aceptar',
                allowOutsideClick: false
              }).then((result) => {
                if (result.isConfirmed) {
                  this.handleResponseCreate(dataCase)
                }
              })
            } else {
              Swal.fire({
                position: 'center',
                icon: 'success',
                //title: 'Caso Reportado en Digipats PWA',
                title: 'Caso Reportado en Leoncy-fusión',
                text: 'Ramo Seguro Tercero',
                confirmButtonText: 'Aceptar',
                allowOutsideClick: false
              }).then(async (result) => {
                if (result.isConfirmed) {
                  await this.GeneralServices.insertReportpwa(data.case).toPromise()
                  this.handleResponseCreate(dataCase)
                }
              })
            }
          } else {
            Swal.fire({
              //title: 'Error en la solicitud digipats',
              title: 'Error en la solicitud Leoncy-fusión',
              text: res.message,
              icon: 'error',
              confirmButtonText: 'Aceptar',
              allowOutsideClick: false
            }).then((result) => {
              if (result.isConfirmed) {
                this.handleResponseCreate(dataCase)
              }
            })
          }
        }
      } else {
        console.log('arreglo lleno')
      }
      if (data.status) {
        this.logout()
      }
    } catch (error) {
      console.error('Error en las operaciones asincrónicas: ', error)
      this.fillDataError()
    } finally {
      this.loading = false// Ocultar el indicador de carga después de que termine la operación
    }
  }

  generarNumeroAleatorio(): number {
    const factor = Math.pow(10, 10 - 1) // Factor para obtener un número de 10 dígitos
    return Math.floor(Math.random() * factor)
  }

  fillData(data) {
    this.onEmitAllow(0)
    if (data.case) {
      this.formGroup.patchValue(data.case)
      data.case.interestedDocument != null ? this.interestedDocument = true : this.interestedDocument = false
      this.loadServices()
      this.formGroup.get('company').setValue(data.case.company.toString())
      this.formGroup.controls['observations'].setValue(data.observations)
      if (data.case.hourSinister === "00:00:00") {
        this.formGroup.controls['hourSinister'].setValue('')
      }
    } else {
      this.fillDataError()
    }
    if (data.status) {
      this.logout()
    }
  }

  loadServices() {
    (this.formGroup.controls['fcii'].value) ? this.services.fcii = true : this.services.fcii = false;
    (this.formGroup.controls['asista'].value) ? this.services.asista = true : this.services.asista = false;
    (this.formGroup.controls['digipat'].value) ? this.services.digipat = true : this.services.digipat = false;
    (this.formGroup.controls['pwa'].value) ? this.services.pwa = true : this.services.pwa = false;
    (this.formGroup.controls['movil'].value) ? this.services.movil = true : this.services.movil = false;
  }

  fillDataError() {
    this.Notify.remove()
    Swal.close()
    this.Notify.error('Algo ha salido mal en el servidor', { timeout: 0 })
  }

  async fillCompanys(): Promise<void> {
    try {
      if (parseInt(this.formGroup.controls['client'].value) == 22) {
        this.word = 'Ajusta'
      } else {
        this.word = 'Asistencia Jurídica'
      }
      let data = await this.BeneficiarieService.getBeneficiariesByClient(this.formGroup.getRawValue())
      this.companys = data.data
    } catch (e) {
      this.logout()
    }
  }

  async fillCompanysChange(): Promise<void> {
    try {
      this.swalService.loading()
      if (parseInt(this.formGroup.controls['client'].value) == 22) {
        this.word = 'Ajusta'
      } else {
        this.word = 'Asistencia Jurídica'
      }
      this.formGroup.get('company').setValue('')
      let data = await this.BeneficiarieService.getBeneficiariesByClient(this.formGroup.getRawValue())
      this.companys = data.data
      this.formGroup.patchValue(this.formGroup.getRawValue())
      this.swalService.close()
    } catch (e) {
      this.logout()
    }
  }

  async fillCitys(): Promise<void> {
    try {
      this.swalService.loading()
      this.citys = await (await this.GeneralServices.getCitys(this.formGroup.getRawValue())).data
      this.swalService.close()
    } catch (e) {
      this.swalService.close()
    }
  }

  async onChangeCity(event: any): Promise<void> {
    this.selectedCity = event.value
    this.citys.forEach(element => {
      if (element.id == this.selectedCity) {
        this.selectedCityname = element.name
      }
    })
  }

  //idBeneficiaries = [1079, 1078, 1077, 1076, 1075, 1074]
  idBeneficiaries = [1107, 1061, 1135, 643, 1024, 1048]
  async fillAsistaChangeDeprecated(event: any) {
    console.log(event.target.value)
    this.msg = ''
    this.formGroup.get('asista').setValue(false)
    this.formGroup.get('asista').enable()
    this.idBeneficiaries.forEach(async element => {
      if (event.target.value == element) {
        const nameCompany = await this.GeneralServices.getcompanyByid(event.target.value).toPromise();
        this.msg = 'Recuerde que la empresa ' + nameCompany.name + ' debe contar con seguimiento jurídico.'
        this.formGroup.get('asista').setValue(true)
        this.formGroup.get('asista').disable()
      }
    })
  }
  fillAsistaChange(selectedValue: any): void {
    //this.hiddenQuery()
    this.msg = ''
    this.formGroup.get('asista').setValue(false)
    this.formGroup.get('asista').enable()
    if (selectedValue) {
      this.idBeneficiaries.forEach(async element => {
        if (selectedValue == element) {
          const nameCompany = await this.GeneralServices.getcompanyByid(selectedValue).toPromise();
          this.msg = 'Recuerde que la empresa ' + nameCompany.name + ' debe contar con seguimiento jurídico.'
          this.formGroup.get('asista').setValue(true)
          this.formGroup.get('asista').disable()
        }
      })
    } else {
      console.log('El valor seleccionado es undefined.');
    }
  }


  async showAlertSubmit() {
    const idCompany = parseInt(this.formGroup.get('company').value)
    const indice = this.idBeneficiaries.includes(idCompany)
    if (indice) {
      try {
        if (this.isFilled == false && this.formGroup.get('id').value != null) {
          this.Notify.error('Gestionar Asistencia Jurídica en sitio')
          return false
        }
        const result = await this.swalService.showAsista('¡Usuario!', this.msg);
        if (result) {
          this.onSubmit()
        } else {
          console.log('El usuario hizo clic en "Cancelar". Realizar otra acción...');
        }
      } catch (error) {
        console.error('Error al mostrar la alerta.');
      }
    } else {
      this.onSubmit()
    }

  }


  handleFormFilled(isFilled: boolean) {
    // Hacer algo cuando el formulario del componente hijo esté lleno
    isFilled == true ? (console.log("El formulario del componente hijo está lleno:", isFilled), this.isFilled = isFilled) : console.log("El formulario del componente hijo está vacio:", isFilled)
  }

  async hadleFilled(data) {
    this.isFilled == true ? this.intermitenteAsista = '' : this.intermitenteAsista = 'intermitenteAsista'
    const nameCompany = await this.GeneralServices.getcompanyByid(data).toPromise();
    this.msg = 'Recuerde que la empresa ' + nameCompany.name + ' debe contar con seguimiento jurídico.'
    this.nameCompany = nameCompany.name
  }

  handleLiveEvent() {
    // Hacer algo en respuesta al evento en vivo
    console.log("Evento en vivo recibido!");
    this.intermitenteAsista = ''
    this.isFilled = true
  }
}
