import { Component, OnInit } from '@angular/core';
import { TokenService } from 'src/app/Services/token.service';
import { Router } from '@angular/router';
import { NewsService } from 'src/app/Services/news/news.service';
import Swal from 'sweetalert2';
import { SnotifyService } from 'ng-snotify';
import { SwalService } from 'src/app/Services/swal/swal.service';
import { FormControl, FormGroup } from '@angular/forms';
import { ModalService } from 'src/app/Services/modal/modal.service';
import { NzModalService } from 'ng-zorro-antd/modal';
class News {
  id: number;
  title: string;
  subtitle: string;
  news_point: string;
}
@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {
  modalRegister = new ModalService(this.modalService);
  News: News[];
  dtOptions: DataTables.Settings;
  private idDelete;
  load: boolean = false;
  pageSize: number = 10
  listOfData: { data: News[], total: number, current_page: number } = {} as any
  formpol: FormGroup = new FormGroup({
    value: new FormControl('')
  })

  constructor(
    private modalService: NzModalService,
    private token: TokenService,
    private router: Router,
    private NewService: NewsService,
    private swalService: SwalService,
    private Notify: SnotifyService
  ) { }

  async deleteUser(e: MouseEvent, id) {
    e.preventDefault();
    let data = {
      id: id
    }
    this.idDelete = data.id;
    let option = await this.swalService.swalAlert("Advertencia", "¿Está seguro de que quiere eliminar este registro?", 'warning');
    if (option) {
      this.loading();
      this.NewService.delete(data).subscribe(
        res => this.handleResponse(data.id),
        err => this.handleError(err)
      );
    }
  }

  processDelete() {
    let c = 0;
    this.News.forEach((element) => {
      if (element.id == this.idDelete) {
        this.News.splice(c, 1);
        this.idDelete = null;
      }
      c++;
    });
  }

  handleResponse(data) {
    Swal.close();
    this.ngOnInit()
  }

  handleError(err) {
    this.errorSwal(err.message);
  }

  loadTable() {
    const that = this;
    this.dtOptions = {
      serverSide: true,
      processing: true,
      ajax: async (dataTablesParameters: any, callback) => {
        let resp = await this.NewService.getNews(dataTablesParameters)
        if (!resp.data) {
          this.token.remove();
          this.router.navigateByUrl('/login');
          setTimeout(() => {
            window.location.reload();
          }, 100);
        }
        that.News = resp.data;
        console.log(that.News)
        Swal.close();
        callback({
          recordsTotal: resp.recordsTotal,
          recordsFiltered: resp.recordsFiltered,
          data: []
        });
      },
      order: [0, 'DESC'],
      columns: [
        { data: 'id' }, { data: 'title' }, { data: 'subtitle' }, { data: 'id' }, { data: 'id' }
      ],
      "language": {
        "url": "https://cdn.datatables.net/plug-ins/1.10.20/i18n/Spanish.json"
      }
    };

  }
  ngOnInit(): void {
    this.loadTable();
    this.loadData()
    this.formpol.controls['value'].valueChanges.subscribe(
      data => this.loadData(1, data)
    )
  }

  loadData(current_page: number = 1, value: string = ''): void {
    this.load = true
    this.NewService.getNewsNgzorro(current_page, value, this.pageSize).subscribe(data => {
      this.listOfData = data;
      console.log(this.listOfData.data)
      this.load = false
    }
    )
  }
  adjustPageSize(pageSize: number): void {
    this.pageSize = pageSize;
    this.loadData();
  }

  loading() {
    Swal.fire({
      icon: 'info',
      title: 'Cargando...',
      showConfirmButton: false,
      allowOutsideClick: false
    });
  }

  errorSwal($e) {
    Swal.fire({
      icon: 'error',
      title: 'Error 500!',
      text: $e,
      showConfirmButton: false,
      allowOutsideClick: false
    });
  }





}
