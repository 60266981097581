<div>
    <div class="float-left">
        <h4>Gestión Fcii</h4>
    </div>
</div>
<br>
<hr>
<table datatable [dtOptions]="dtOptions" class="table table-striped table-bordered row-border hover">
    <thead>
        <tr>
            <th># Evento</th>
            <th>Placa</th>
            <th>Coordinador</th>
            <th>Hora de Contacto</th>
            <th>Hora de Llegada</th>
            <th>Fin de Asistencia</th>
            <th>Estado</th>
            <th style="width: 1px;">Acción</th>
        </tr>
    </thead>
    <tbody *ngIf="fciis?.length > 0">
        <tr *ngFor="let asista of fciis">
            <td>{{ asista.idCase }}</td>
            <td>{{ asista.plate }}</td>
            <td>{{ asista.coordinator }}</td>
            <td>{{ asista.contactTime }}</td>
            <td>{{ asista.arriveTime }}</td>
            <td>{{ asista.endTime }}</td>
            <td>
                <div class="alert alert-{{asista.color}}" role="alert">
                    {{ asista.state }}
                </div>
            </td>
            <td>
                <div class="row justify-content-md-center align-items-center">
                    <div class="btn-group" role="group" aria-label="Basic example">
                        <button [disabled]="asista.allow === 0" id="idCaseFcii{{asista.idC}}" class="btn btn-info btn-sm" routerLink="/report-accident/{{asista.idCase}}/fcii"><i class="fas fa-edit"></i></button>
                    </div>
                </div>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="fciis?.length < 1">
        <tr>
            <td colspan="9" class="no-data-available">No hay datos disponibles!</td>
        </tr>
    <tbody>
</table>
<br><br>
<br><br>
<br><br>
<br><br>
<br><br>
<small>Joe Developer.</small>
