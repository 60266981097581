import { Injectable } from '@angular/core';
import * as ExcelProper from "exceljs";
import * as Excel from "exceljs/dist/exceljs.min.js";
import * as fs from 'file-saver';
import * as moment from 'moment';
import { SnotifyService } from 'ng-snotify';
import { RowsGeneralService } from '../report-accidents/rows-general.service';
import { TokenService } from '../token.service';
import * as logoFile from './carlogo.js';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  private data = [];

  constructor(
    private ReportService: RowsGeneralService, private Notify: SnotifyService, private Token: TokenService
  ) {
  }

  continueExcel(data){
    this.Notify.remove();
    this.data = data;
    if(!data.status){
      this.generate();
    }else{
      this.Token.logout();
    }
  }

  generateExcel(datita) {
    this.Notify.info('Cargando...', {timeout:0});
    this.ReportService.getDataReport(datita).subscribe(
      data => {this.continueExcel(data)
      console.log(data)}
    );
  }

  generate(){
    //Excel: Titulo, Encabezado y Datos
    const title = 'Reporte de Kronos';
    const header = [
      "Codigo","Caso Aurora", "Nombre del Conductor", "Día del Evento", "Hora del Evento","Hora del Evento (dada por el asesor)","Hora del Siniestro", "Aseguradora", "Tipo de Documento",
      "Numero de Documento", "Telefono", "Placa del Vehiculo", "# Poliza", "Nombre de la Empresa", "Nombre de la persona que Llama",
      "Numero de Telefono", "Tipo de Evento", "Tipo de Accidente", "SERVICIOS SOLICITADOS", "Hora de reporte FCII", "Hora de contacto FCII", "Hora de llegada FCII",
      "Hora de reporte Dig", "Hora de contacto Dig", "Hora de llegada Dig", "Ciudad del Siniestro",
      "Direccion del accidente", "Abogado", "Tipo de asistencia", "Hora de reporte Ab", "Hora de contacto Ab",
      "Hora de llegada Ab", "Horas de seguimiento", "Fecha de Cierre", "Respuesta de encuesta", "Asesor", "Observaciones", "Empresa del Caso",
      "Estado del Caso", "Siniestro", "Presupuesto", "Consecutivo App"
    ];
    //Creando workbook y worksheet
    let workbook:ExcelProper.Workbook = new Excel.Workbook();
    let worksheet = workbook.addWorksheet('Kronos Hoja 1');
    //Agregar fila y dando estilo
    let titleRow = worksheet.addRow([title]);
    titleRow.font = { name: 'Comic Sans MS', family: 4, size: 16, underline: 'double', bold: true }
    worksheet.addRow([]);
    worksheet.addRow(['Fecha : ' + moment().format('MMMM Do YYYY, h:mm:ss a')]);
    //Para agregar una imagen -- Dede de estar en base64 --
    let logo = workbook.addImage({
      base64: logoFile.logoBase64,
      extension: 'png',
    });
    //
    worksheet.addImage(logo, 'E1:E4');
    worksheet.mergeCells('A1:D2');
    //Agregar una fila en blanco
    worksheet.addRow([]);
    //Agregar la fila con el encabezado
    let headerRow = worksheet.addRow(header);

    // Estilo de celda : Rellenar y Bordear
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '04ECB7' },
        bgColor: { argb: 'FF0000FF' }
      }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    })
    // worksheet.addRows(data);
    // Add Data and Conditional Formatting
    this.data.forEach(d => {
      !d.aurora_case? d.aurora_case='no':d.aurora_case=d.aurora_case
      var services = '';
      if(d.asista_j != null){
        if(d.client == 'OPERADORAS'){
          services = services + '//AJUSTA';
        }else{
          services = services + '//ASISTA';
        }
      }
      (d.fcii != null) ? services = services + ' //FCII' : '';
      (d.digipat != null) ? services = services + ' //DIGIPAT' : '';
      (d.movil != null) ? services = services + ' //MOVIL' : '';
      let typeAsista = '';
      (d.typeAsista == '1') ? typeAsista = 'Teléfonica' : '';
      (d.typeAsista == '2') ? typeAsista = 'Presencial' : '';
      d.plate = d.plate.toUpperCase();
      let row = worksheet.addRow([
        d.id,
        d.aurora_case,
        d.driverName,
        d.dayEvent,
        d.hourEvent,
        d.hourReportSinister,
        d.hourSinister,
        d.client,
        d.typeid,
        d.document_number,
        d.phone,
        d.plate,
        d.policy,
        d.name,
        d.interestedName,
        d.interestedPhone,
        d.typeSinister,
        d.typeaccident,
        services,
        d.reportFcii,
        d.endFcii,
        d.arriveFcii,
        d.reportDigipat,
        d.endDigipat,
        d.arriveDigipat,
        d.city,
        d.currentAddress,
        d.lawyer,
        typeAsista,
        d.reportAsista,
        d.endAsista,
        d.arriveAsista,
        d.hourObs,
        d.end_date,
        d.answer,
        d.received_call,
        d.observation,
        d.Asista,
        d.statecase,
        d.sinisterAsista,
        d.budgetAsista,
        d.consecutive_app
      ]);


      //-- Agregar color a la celda dependiendo del valor --
      let qty = row.getCell(36);
      let color = '';
      (qty.value == 'PROCESO') ? color = 'f8d7da' : color = 'd4edda';

      qty.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: color }
      }

    }
    );
    // -- Aumentarle el tamaño a las columnas --
    for(let i = 1; i <= 36; i++){
      worksheet.getColumn(i).width = 30;
    }

    worksheet.addRow([]);
    //Fila del Footer
    let footerRow = worksheet.addRow(['This is system Kronos generated excel sheet.']);
    footerRow.getCell(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFCCFFE5' }
    };
    footerRow.getCell(1).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    //Unir Celdas
    worksheet.mergeCells(`A${footerRow.number}:F${footerRow.number}`);
    //Generar el archivo Excel y dar un nombre
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'KronosReport.xlsx');
    })
  }
}
