<div class="mt-4 col-8 offset-2">
        <div class="card">
            <div class="card-header header-color" *ngIf="!form.id">Register Here</div>
            <div class="card-header header-color" *ngIf="form.id">Update Here</div>
            <div class="card-body">
                <form #signupForm="ngForm" (ngSubmit)="onSubmit()">
                    <div class="form-group row">
                        <label for="inputName3" class="col-sm-2 col-form-label">Nombre</label>
                        <div class="col-sm-10">
                            <input nz-input placeholder="Nombre" [(ngModel)]="form.name" [ngModelOptions]="{standalone: true}" required/>
                            <div class="alert alert-danger" [hidden]="!error.name">
                                {{error.name}}
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-2 col-form-label">Correo</label>
                        <div class="col-sm-10">
                            <input nz-input placeholder="Email" [(ngModel)]="form.email" [ngModelOptions]="{standalone: true}" required/>
                            <div class="text-danger" [hidden]="!error.email">
                                {{error.email}}
                            </div>
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="!form.id">
                        <label for="inputPassword3" class="col-sm-2 col-form-label">Contraseña</label>
                        <div class="col-sm-10">
                            <input  type="password" nz-input placeholder="Contraseña" [(ngModel)]="form.password" [ngModelOptions]="{standalone: true}" required/>
                            <div class="text-danger" [hidden]="!error.password">
                                {{error.password}}
                            </div>
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="!form.id">
                        <label for="inputPasswordConfirmation3" class="col-sm-2 col-form-label">Confirmar Contraseña</label>
                        <div class="col-sm-10">
                        <input  type="password" nz-input placeholder="Confirmar" [(ngModel)]="form.password_confirmation" [ngModelOptions]="{standalone: true}"/>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="" class="col-sm-2 col-form-label">Perfil</label>
                        <div class="col-sm-10">
                            <nz-select [(ngModel)]="form.profile_ID" nzAllowClear nzPlaceHolder="Choose" [ngModelOptions]="{standalone: true}" [compareWith]="compareFn">
                                <nz-option nzValue="" nzLabel="Seleccionar perfil"></nz-option>
                                <nz-option *ngFor="let rol of roles"  [nzValue]="rol.id" [nzLabel]="rol.name"></nz-option>
                            </nz-select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="" class="col-sm-2 col-form-label">Estado</label>
                        <div class="col-sm-10">
                            <nz-select [(ngModel)]="form.state" nzAllowClear nzPlaceHolder="Choose" [ngModelOptions]="{standalone: true}" [compareWith]="compareFn">
                                <nz-option nzValue="" nzLabel="Seleccionar estado"></nz-option>
                                <nz-option nzValue="1" nzLabel="Activo"></nz-option>
                                <nz-option nzValue="2" nzLabel="Inactivo"></nz-option>
                            </nz-select>
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="form.id">
                        <div class="col-sm-4 offset-8">
                            <a href="#" (click)="resetPassword($event)">Restablecer contraseña</a>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-10 offset-2">
                            <button type="submit" nz-button nzType="primary" [nzLoading]="isLoadingForm" *ngIf="!form.id">Registrar</button>
                            <button type="submit" nz-button nzType="primary" [nzLoading]="isLoadingForm" *ngIf="form.id">Actualizar</button>
                            <a href="#" class="float-right btn btn-info" routerLink="/users">Volver</a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
