import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { TokenService } from 'src/app/Services/token.service';
import Swal from 'sweetalert2';
import { NewsService } from 'src/app/Services/news/news.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as $ from 'jquery';
import { SwalService } from 'src/app/Services/swal/swal.service';

@Component({
  selector: 'app-news-form',
  templateUrl: './news-form.component.html',
  styleUrls: ['./news-form.component.css']
})
export class NewsFormComponent implements OnInit, OnDestroy {

  public error = {
    title: null,
    subtitle: null
  };

  public form = {
    id: null,
    title: null,
    subtitle: null,
    news_point: [],
    point: null
  }
  formImg: FormGroup = new FormGroup({
    img: new FormControl('', [Validators.required])
  })

  formFile: FormGroup = new FormGroup({
    file: new FormControl('', [Validators.required])
  })
  public idDelete = null;

  constructor(
    private route: ActivatedRoute,
    private Notify: SnotifyService,
    private Token: TokenService,
    private router: Router,
    private NewService: NewsService,
    private swalService: SwalService
  ) {
    this.form.id = route.snapshot.params["id"];
  }

  ngOnInit() {
    this.sendFill();
  }

  onSubmit() {
    this.loading();
    if (this.form.id != null) {
      this.NewService.update(this.form).subscribe(
        data => {
          (data.error) ? this.errorSwal(data.error) : this.handleResponseUpdate(data);
        },
        err => this.handleError(err)
      );
    } else {
      this.NewService.create(this.form).subscribe(
        data => {
          (data.error) ? this.errorSwal(data.error) : this.handleResponseCreate(data);
        },
        err => this.handleError(err)
      );
    }
  }
  onSubmitImg() {
    let msg
    const formData = new FormData();
    formData.append('id', this.form.id);
    formData.append('img', this.formImg.get('img').value);
    this.NewService.addImageNew(formData).subscribe(
      data => {
        msg=data
        Swal.fire({
          title: 'Imagen guardada!',
          text: msg,
          icon: 'success',
          showCancelButton: false,
          allowOutsideClick:false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Aceptar'
        }).then((result) => {
          if (result.isConfirmed) {
            this.activeImg=true
            this.formImg.reset()
            this.ngOnInit()
          }
        })
      },
      err => this.errorimg(err)
    )
  }
  onSubmitfile(){
    let msg
    const formData = new FormData();
    formData.append('id', this.form.id);
    formData.append('file', this.formFile.get('file').value);
    this.NewService.addFileNew(formData).subscribe(
      data => {
        msg=data
        Swal.fire({
          title: 'Archivo guardado!',
          text: msg,
          icon: 'success',
          showCancelButton: false,
          allowOutsideClick:false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Aceptar'
        }).then((result) => {
          if (result.isConfirmed) {
            this.activeFile=true
            this.formFile.reset()
            this.ngOnInit()
          }
        })
      },
      err => this.errorfile(err)
    )

  }

  activeFile:boolean=true
  uploadFile(event: MouseEvent) {
    event.preventDefault();
    if(event){
      this.activeFile=false
    }
    const file = (event.target as HTMLInputElement).files[0];
    this.formFile.patchValue({
      file: file,
    });
    this.formFile.get('file').updateValueAndValidity();
  }

  activeImg:boolean=true
  uploadImg(event: MouseEvent) {
    event.preventDefault();
    if(event){
      this.activeImg=false
    }
    const file = (event.target as HTMLInputElement).files[0];
    this.formImg.patchValue({
      img: file,
    });
    this.formImg.get('img').updateValueAndValidity();
  }

  errorimg(error) {
    Swal.fire({
      title: 'Formato no valido',
      text: error.error.errors.img,
      icon: 'error',
      showCancelButton: false,
      allowOutsideClick:false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Aceptar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.activeImg=true
        this.formImg.reset()
        this.ngOnInit()
      }
    })
  }
  errorfile(error) {
    Swal.fire({
      title: 'Formato no valido',
      text: error.error.errors.file,
      icon: 'error',
      showCancelButton: false,
      allowOutsideClick:false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Aceptar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.activeFile=true
        this.formFile.reset()
        this.ngOnInit()
      }
    })
  }

  handleResponseUpdate(data) {
    data.status ? this.logout() : '';
    Swal.close();
    this.Notify.success('Exito!', { timeout: 2000 });
  }

  handleResponseCreate(data) {
    data.status ? this.logout() : '';
    Swal.close();
    $('#formnew').trigger("reset");
    this.form.news_point = [];
    this.Notify.success('Exito!', { timeout: 2000 });
  }

  logout() {
    this.Token.remove();
    this.router.navigateByUrl('/login');
    setTimeout(() => {
      window.location.reload();
    }, 100);
  }

  handleError(error) {
    Swal.close();
    error.status != 0 ? this.errorSwal(error) : '';
    if (error.error.errors) {
      this.error = error.error.errors;
      Swal.close();
    };
  }

  sendFill() {
    if (this.form.id != null) {
      this.loading();
      let data = {
        id: this.form.id
      }
      this.NewService.select(data).subscribe(
        data => this.fillData(data),
        err => this.fillDataError(2)
      );
    }
  }

  fillData(data) {
    Swal.close();
    if (data.title) {
      this.form = data;
    } else {
      this.fillDataError(1);
    }
    if (data.status) {
      this.logout();
    }
  }

  fillDataError(n) {
    Swal.close();
    this.Notify.error('Algo ha salido mal: ' + n, { timeout: 5000 });
  }

  addPoint(Event: MouseEvent) {
    Event.preventDefault();
    this.form.point !== null ? this.form.news_point.push({ point: this.form.point, id: null }) : '';
    this.form.point = null;
  }

  async removePoint(id, Event: MouseEvent) {
    Event.preventDefault();
    let data = {
      id: id
    }
    this.idDelete = data.id;
    let option = await this.swalService.swalAlert("Advertencia", "¿Está seguro de que quiere eliminar este registro?", 'warning');
    if (option) {
      this.loading();
      this.NewService.deletePoint(data).subscribe(
        res => this.handleResponse(data.id),
        err => this.errorSwal(err.message)
      );
    }
  }

  handleResponse(data) {
    Swal.close();
    this.processDelete(); // Remove row of table.
    this.Notify.confirm('Done!', {
      buttons: [
        {
          text: 'Okay',
          action: toster => {
            this.Notify.remove(toster.id)
          }
        }
      ]
    })
  }

  processDelete() {
    let c = 0;
    this.form.news_point.forEach((element) => {
      if (element.id == this.idDelete) {
        this.form.news_point.splice(c, 1);
        this.idDelete = null;
      }
      c++;
    });
  }

  loading() {
    Swal.fire({
      icon: 'info',
      title: 'Cargando...',
      showConfirmButton: false,
      allowOutsideClick: false
    });
  }

  errorSwal($e) {
    Swal.fire({
      icon: 'error',
      title: 'Error 500!',
      text: $e,
      showConfirmButton: false,
      allowOutsideClick: false
    });
  }

  ngOnDestroy() {
    this.Notify.remove();
  }

}
