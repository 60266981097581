<div class="container" [style.width]="informPoliza || informPolizaHub ? '92%' : '100%'"
  [style.marginright]="informPoliza || informPolizaHub ? '50%' : '0%'">
  <!-- modal -->
  <nz-modal [(nzVisible)]="isVisible" nzTitle="Información Placa" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
    <meta charset="utf-8">
    <ng-container *nzModalContent>
      <nz-spin style="margin-left: 240px;" *ngIf="isVisible3==false" nzSimple></nz-spin>
      <nz-tabset [nzTabPosition]="position" nzCentered>
        <nz-tab nzTitle="Técnicar">
          <div class="row">
            <div class="col-sm-3">
              <h6>Respuesta:</h6>
            </div>
            <div class="col-sm-4">
              <strong>
                <p *ngIf="color==true" style="color: rgb(7, 138, 14);">{{response}}</p>
              </strong>
              <strong>
                <p *ngIf="color==false" style="color: rgb(255, 0, 0);">{{response}}</p>
              </strong>
            </div>
            <div class="col-sm-5">
              <strong>
                <p *ngIf="array_hub_table.length>0" style="color: rgb(7, 138, 14);">Verificar la pestaña <i>"HUB"</i>
                </p>
              </strong>
            </div>
          </div>
          <div *ngIf="isVisible2==true" class="card" style="margin-left: 1px">
            <div class="card-header header-color text-center">
              <h6>DATOS DEL CLIENTE</h6>
            </div>
            <div class="card-body">
              <nz-table style="width:100%">
                <tr>
                  <th>Nombre Cliente:</th>
                  <td>{{array_default[0].cliente}}</td>
                </tr>
                <tr>
                  <th>Identificación:</th>
                  <td>{{array_default[0].doc_propietario}}</td>
                </tr>
                <tr>
                  <th>Teléfono:</th>
                  <td>{{array_default[0].telefono}}</td>
                </tr>
                <tr>
                  <th>Ciudad:</th>
                  <td>{{array_default[0].ciudad}}</td>
                </tr>
                <tr>
                  <th>Empresa:</th>
                  <td>{{array_default[0].empresa}}</td>
                </tr>
                <tr>
                  <th>Ramo:</th>
                  <td>{{array_default[0].ramo}}</td>
                </tr>
                <tr>
                  <th>Estado:</th>
                  <td>{{array_default[0].estado}}</td>
                </tr>
                <tr>
                  <th>Fecha Inicio:</th>
                  <td>{{array_default[0].fechaIni}}</td>
                </tr>
                <tr>
                  <th>Fecha Fin:</th>
                  <td>{{array_default[0].fechaFin}}</td>
                </tr>
                <tr>
                  <th>Placa:</th>
                  <td>{{array_default[0].placa}}</td>
                </tr>
              </nz-table>
            </div>
          </div>
          <nz-result *ngIf="color==false" nzStatus="warning"
            nzTitle="No se encontró información para la placa ingresada.">
          </nz-result>
        </nz-tab>
        <nz-tab *ngIf="array_hub_table.length>0" nzTitle="HUB">
          <div class="row">
            <div class="col-sm-3">
              <h6>Respuesta:</h6>
            </div>
            <div class="col-sm-6">
              <strong>
                <p *ngIf="colorhub==true" style="color: rgb(7, 138, 14);">CORRECTO</p>
              </strong>
              <strong>
                <p *ngIf="colorhub==false" style="color: rgb(255, 0, 0);">SIN INFORMACIÓN</p>
              </strong>
            </div>
          </div>
          <div *ngIf="isvisibleHub==true" class="card" style="margin-left: 1px">
            <div class="card-header header-color text-center">
              <h6>DATOS DEL CLIENTE</h6>
            </div>
            <div class="card-body">
              <nz-table style="width:100%">
                <tr>
                  <th>Nombre Cliente:</th>
                  <td>{{array_hub.propietario.nom_propietario}}</td>
                </tr>
                <tr>
                  <th>Identificación:</th>
                  <td>{{array_hub.propietario.id_propietario}}</td>
                </tr>
                <tr>
                  <th>Correo:</th>
                  <td>{{array_hub.propietario.mail_propietario}}</td>
                </tr>
                <tr>
                  <th>Ciudad:</th>
                  <td>{{array_hub.propietario.dir_propietario}}</td>
                </tr>
                <tr>
                  <th>Ramo:</th>
                  <td>{{array_hub.policy_origin}}</td>
                </tr>
                <tr>
                  <th>Estado:</th>
                  <td>Incluido</td>
                </tr>
                <tr>
                  <th>Fecha Inicio:</th>
                  <td>{{array_hub.expedition_date}}</td>
                </tr>
                <tr>
                  <th>Fecha Fin:</th>
                  <td>{{array_hub.policy_end_date}}</td>
                </tr>
                <tr>
                  <th>Placa:</th>
                  <td>{{array_hub.vehicle.placa}}</td>
                </tr>
              </nz-table>
            </div>
          </div>
          <nz-result *ngIf="colorhub==false" nzStatus="warning"
            nzTitle="No se encontró información para la placa ingresada.">
          </nz-result>
        </nz-tab>
      </nz-tabset>
    </ng-container>
  </nz-modal>
  <!--end-modal  -->
  <div class="site-page-header-ghost-wrapper">
    <nz-page-header nzBackIcon [nzGhost]="false">
      <nz-page-header-title>
        Reporte de accidente #{{route.snapshot.params["id"]}}</nz-page-header-title>
      <nz-page-header-tags>
        <nz-tag
          [nzColor]="formGroup.controls['case_state'].value === '1' ? 'red' : 'green'">{{formGroup.controls['nameCaseState'].value}}</nz-tag>
      </nz-page-header-tags>
      <nz-page-header-extra>
        <button *ngIf="!auroraId" nz-button [routerLink]="['/report-accidents']">Volver</button>
        <!-- <button *ngIf="auroraId" nz-button [routerLink]="['/aurora-cases']">Volver</button> -->
        <button *ngIf="auroraId" nz-button (click)="enviarDatosAlNodeJS()">Volver</button>
        <button nz-button nzType="primary">Actualizar</button>
      </nz-page-header-extra>
      <nz-page-header-content>
        <nz-descriptions nzSize="small" [nzColumn]="5">
          <nz-descriptions-item nzTitle="Fecha de creación"
            [nzSpan]="1">{{formGroup.controls['registration_date'].value| date}}</nz-descriptions-item>
          <nz-descriptions-item nzTitle="Hora de reporte"
            [nzSpan]="1">{{formGroup.controls['hourReportSinister'].value!='00:00:00'?
            formGroup.controls['hourReportSinister'].value:''}}</nz-descriptions-item>
          <nz-descriptions-item nzTitle="Hora de accidente"
            [nzSpan]="1">{{formGroup.controls['hourSinister'].value!='00:00:00'?
            formGroup.controls['hourSinister'].value:''}}</nz-descriptions-item>
          <nz-descriptions-item nzTitle="Fecha de cierre" [nzSpan]="1">{{formGroup.controls['end_date'].value
            |date}}</nz-descriptions-item>
          <nz-descriptions-item nzTitle="Hora de cierre" [nzSpan]="1">{{formGroup.controls['end_date'].value?
            modifyDateCase(formGroup.controls['end_date'].value):'' }}</nz-descriptions-item>
        </nz-descriptions>
      </nz-page-header-content>
    </nz-page-header>
  </div>
  <div>
    <form [formGroup]="formGroup" #caseForm="ngForm" (ngSubmit)="showAlertSubmit()" id="forma">
      <div class="row">
        <div class="col-sm-12" *ngIf="showForm">
          <div class="card" style="margin-left: 1px">
            <div class="card-header header-color text-center">
              <h6>DATOS DEL CLIENTE</h6>
            </div>
            <div class="card-body">
              <div class="form-row">
                <div class="form-group col-md-2">
                  <label for="inputPlate">Placa<code style="color: crimson;"> *</code></label>
                  <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
                    <input #inputPlate type="text" class="form-control form-control-sm" id="inputPlate"
                      (keyup)="formGroup.controls['plate'].setValue(formGroup.controls['plate'].value.toUpperCase())"
                      formControlName="plate" [ngModelOptions]="{standalone: true}" placeholder="Placa" required
                      [disabled]="isDisabled">
                  </nz-input-group>
                  <button [disabled]="!formGroup.get('plate').value" style="background-color: rgb(105, 201, 105); color: black;" (click)="showModal($event)"
                    nzTooltipTitle="Consulta Tecnicar y otras bases de datos.  Digite una placa en el campo de arriba"
                    nzTooltipPlacement="bottom" nz-button nz-tooltip>
                    <span nz-icon nzType="search"></span>Mundial de Seguros</button>
                  <div class="text-danger" [hidden]="!error.plate">
                    {{error.plate}}
                  </div>
                </div>
                <div class="form-group col-md-2">
                  <label for="selectClient">Cliente<code style="color: crimson;"> *</code></label>
                  <select id="selectClient" class="form-control form-control-sm" (change)="fillCompanysChange()"
                    formControlName="client" [ngModelOptions]="{standalone: true}" required [disabled]="isDisabled">
                    <option value="">Seleccionar...</option>
                    <option *ngFor="let client of clients | async" value="{{client.id}}">{{client.name}}</option>
                  </select>
                </div>
                <div class="form-group col-md-3">
                  <label for="selectCompany">Empresa<code style="color: crimson;"> *</code></label><br>
                  <nz-select id="selectCompany" formControlName="company" required
                    (ngModelChange)="fillAsistaChange($event)" [ngModelOptions]="{standalone: true}"
                    [disabled]="isDisabled" style="width: 100%;" nzShowSearch nzAllowClear
                    nzPlaceHolder="Seleccione empresa">
                    <nz-option *ngFor="let company of companys" nzLabel="{{company.name}}"
                      nzValue="{{company.id}}"></nz-option>
                  </nz-select>
                  <!-- <select id="selectCompany" formControlName="company" required class="form-control form-control-sm"
                    (change)="fillAsistaChange($event)" [ngModelOptions]="{standalone: true}"
                    [disabled]="isDisabled" >
                      <option value="">Seleccionar...</option>
                      <option *ngFor="let client of companys" value="{{client.id}}">{{client.name}}</option>
                  </select> -->
                  <div class="text-danger" [hidden]="!error.company">
                    {{error.company}}
                  </div>
                </div>
                <div class="form-group col-md-2">
                  <label for="inputPhone">Teléfono<code style="color: crimson;"> *</code></label>
                  <input type="text" class="form-control form-control-sm" id="inputIinputPhonentPhone"
                    placeholder="Número teléfonico" formControlName="phone" [ngModelOptions]="{standalone: true}"
                    required [disabled]="isDisabled" (input)="onInput($event)" pattern="[0-9]*" maxlength="10">
                  <div class="text-danger" [hidden]="!error.phone">
                    {{error.phone}}
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="inputName">Títular<code style="color: crimson;"> *</code></label>
                  <input type="text" class="form-control form-control-sm" id="inputName" placeholder="Títular"
                    formControlName="name" (keyup)="formGroup.controls['name'].setValue(formGroup.controls['name'].value.toUpperCase())"
                    (blur)="onInputChange($event)"
                    [ngModelOptions]="{standalone: true}" [disabled]="isDisabled" required>
                </div>
              </div>
              <div class="form-row" *ngIf="!service">
                <div class="form-group col-md-2">
                  <div class="form-check">
                    <label nz-checkbox formControlName="asista" [ngModelOptions]="{standalone: true}">{{word}}</label>
                  </div>
                </div>
                <div class="form-group col-md-1">
                  <div class="form-check">
                    <label nz-checkbox formControlName="fcii" [ngModelOptions]="{standalone: true}">Fcii</label>
                  </div>
                </div>
                <div class="form-group col-md-2">
                  <div class="form-check">
                    <label nz-checkbox formControlName="digipat" [ngModelOptions]="{standalone: true}">Digipat</label>
                  </div>
                </div>
                <div class="form-group col-md-2">
                  <div class="form-check">
                    <label nz-checkbox formControlName="pwa" [ngModelOptions]="{standalone: true}">Pwa</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr>
        <div class="col-sm-12" *ngIf="informPoliza==true">
          <div class="card" style="margin-left: 1px">
            <div class="card-header  text-center" style="background-color: rgb(255, 255, 193);">
              <h6>DATOS DE LA PÓLIZA</h6>
            </div>
            <div class="card-body">
              <div class="form-row">
                <div class="form-group col-md-3">
                  <label for="ramoInput">Ramo</label>
                  <input type="text" class="form-control form-control-sm" id="ramoInput" placeholder="Ramo"
                    formControlName="ramoInput" [ngModelOptions]="{standalone: true}" required disabled="true">
                </div>
                <div class="form-group col-md-3">
                  <label for="estadoInput">Estado</label>
                  <input type="text" class="form-control form-control-sm" id="estadoInput" placeholder="Estado"
                    formControlName="estadoInput" [ngModelOptions]="{standalone: true}" required disabled="true">
                </div>
                <div class="form-group col-md-3">
                  <label for="fechaIniInput">Fecha Inicio</label>
                  <input type="text" class="form-control form-control-sm" id="fechaIniInput" placeholder="Fecha Inicio"
                    formControlName="fechaIniInput" [ngModelOptions]="{standalone: true}" required disabled="true">
                </div>
                <div class="form-group col-md-3">
                  <label for="fechaFinInput">Fecha Fin</label>
                  <input type="text" class="form-control form-control-sm" id="fechaFinInput" placeholder="Fecha Fin"
                    formControlName="fechaFinInput" [ngModelOptions]="{standalone: true}" disabled="true" required>
                </div>
              </div>
            </div>
            <nz-collapse nzAccordion [nzBordered]="false">
              <nz-collapse-panel nzHeader="Ver Detalles" nzActive="false">
                <nz-table #basicTable [nzData]="array_default" [nzScroll]="{ x: '2500px' }">
                  <thead>
                    <tr>
                      <th style="width: 120px;background-color: rgb(236, 236, 236);" nzLeft>N° Póliza</th>
                      <th style="width: 100px;background-color: rgb(236, 236, 236);" nzLeft>Ramo</th>
                      <th style="width: 460px;background-color: rgb(236, 236, 236);">Nombre Producto</th>
                      <th style="width: 100px;background-color: rgb(236, 236, 236);">Estado</th>
                      <th style="width: 120px;background-color: rgb(236, 236, 236);">Fecha Inclusión</th>
                      <th style="width: 100px;background-color: rgb(236, 236, 236);">Hasta</th>
                      <th style="width: 100px;background-color: rgb(236, 236, 236);">Placa</th>
                      <th style="width: 350px;background-color: rgb(236, 236, 236);">Nombre Cliente</th>
                      <th style="width: 100px;background-color: rgb(236, 236, 236);">Documento</th>
                      <th style="width: 300px;background-color: rgb(236, 236, 236);">Empresa</th>
                      <th style="width: 100px;background-color: rgb(236, 236, 236);">Teléfono</th>
                      <th style="width: 100px;background-color: rgb(236, 236, 236);">Ciudad</th>
                      <th style="width: 140px;background-color: rgb(236, 236, 236);" nzRight>Deducible</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let data of basicTable.data">
                      <td nzLeft>{{data.id_poliza}}</td>
                      <td nzLeft>{{data.ramo}}</td>
                      <td>{{data.nombre_producto}}</td>
                      <td>{{data.estado}}</td>
                      <td>{{data.fecha_ini}}</td>
                      <td>{{data.fecha_fin}}</td>
                      <td>{{data.placa}}</td>
                      <td>{{data.propietario}}</td>
                      <td>{{data.doc_propietario}}</td>
                      <td>{{data.empresa}}</td>
                      <td>{{data.telefono}}</td>
                      <td>{{data.ciudad}}</td>
                      <td nzRight>{{data.deducible}}</td>
                    </tr>
                  </tbody>
                </nz-table>
              </nz-collapse-panel>
            </nz-collapse>
          </div>
        </div>
        <div class="col-sm-12" *ngIf="informPolizaHub==true">
          <div class="card" style="margin-left: 1px">
            <div class="card-header  text-center" style="background-color: rgb(255, 255, 193);">
              <h6>DATOS DE LA PÓLIZA</h6>
            </div>
            <div class="card-body">
              <div class="form-row">
                <div class="form-group col-md-3">
                  <label for="ramoInput">Ramo</label>
                  <input type="text" class="form-control form-control-sm" id="ramoInput" placeholder="Ramo"
                    formControlName="ramoInput" [ngModelOptions]="{standalone: true}" required disabled="true">
                </div>
                <div class="form-group col-md-3">
                  <label for="estadoInput">Estado</label>
                  <input type="text" class="form-control form-control-sm" id="estadoInput" placeholder="Estado"
                    formControlName="estadoInput" [ngModelOptions]="{standalone: true}" required disabled="true">
                </div>
                <div class="form-group col-md-3">
                  <label for="fechaIniInput">Fecha Inicio</label>
                  <input type="text" class="form-control form-control-sm" id="fechaIniInput" placeholder="Fecha Inicio"
                    formControlName="fechaIniInput" [ngModelOptions]="{standalone: true}" required disabled="true">
                </div>
                <div class="form-group col-md-3">
                  <label for="fechaFinInput">Fecha Fin</label>
                  <input type="text" class="form-control form-control-sm" id="fechaFinInput" placeholder="Fecha Fin"
                    formControlName="fechaFinInput" [ngModelOptions]="{standalone: true}" disabled="true" required>
                </div>
              </div>
            </div>
            <nz-collapse nzAccordion [nzBordered]="false">
              <nz-collapse-panel nzHeader="Ver Detalles" nzActive="false">
                <nz-table #basicTable [nzData]="array_hub_table" [nzScroll]="{ x: '2500px' }">
                  <thead>
                    <tr>
                      <th style="width: 100px;background-color: rgb(236, 236, 236);" nzLeft>Ramo</th>
                      <th style="width: 100px;background-color: rgb(236, 236, 236);">Estado</th>
                      <th style="width: 120px;background-color: rgb(236, 236, 236);">Fecha Inclusión</th>
                      <th style="width: 100px;background-color: rgb(236, 236, 236);">Hasta</th>
                      <th style="width: 100px;background-color: rgb(236, 236, 236);">Placa</th>
                      <th style="width: 250px;background-color: rgb(236, 236, 236);">Nombre Cliente</th>
                      <th style="width: 100px;background-color: rgb(236, 236, 236);">Documento</th>
                      <th style="width: 150px;background-color: rgb(236, 236, 236);">Correo</th>
                      <th style="width: 100px;background-color: rgb(236, 236, 236);">Ciudad</th>
                      <th style="width: 100px;background-color: rgb(236, 236, 236);">Clase Vehículo</th>
                      <th style="width: 100px;background-color: rgb(236, 236, 236);">Marca</th>
                      <th style="width: 100px;background-color: rgb(236, 236, 236);" nzRight>Tipo</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let data of basicTable.data">
                      <td nzLeft>{{data.hubRamo}}</td>
                      <td>Incluido</td>
                      <td>{{data.hubfechaini}}</td>
                      <td>{{data.hubfechafin}}</td>
                      <td>{{data.hubplaca}}</td>
                      <td>{{data.hubnom_propietario}}</td>
                      <td>{{data.hubid_propietario}}</td>
                      <td>{{data.hubmail_propietario}}</td>
                      <td>{{data.hubdir_propietario}}</td>
                      <td>{{data.hubclase_vehiculo}}</td>
                      <td>{{data.hubmarca}}</td>
                      <td nzRight>{{data.hubtipo}}</td>
                    </tr>
                  </tbody>
                </nz-table>
              </nz-collapse-panel>
            </nz-collapse>
          </div>
        </div>
        <hr>
        <div class="col-sm-12" *ngIf="showForm">
          <div class="card" style="margin-left: 1px">
            <div class="card-header header-color text-center">
              <h6>DATOS DEL CONTACTO</h6>
            </div>
            <div class="card-body">
              <a nzSize="small" [class]="intermitente" *ngIf="informPoliza || informPolizaHub"
                style="background-color: rgb(241, 241, 241); color: black;" (click)="setContact($event)"
                nzTooltipPlacement="bottom" nz-button nz-tooltip>
                <span nz-icon nzType="copy"></span>Presiona aquí si el cliente es el mismo contacto telefónico</a>
              <div class="form-row">
                <div class="form-group" [ngClass]="{'col-md-4': interestedDocument, 'col-md-6': !(interestedDocument)}">
                  <label for="inputInterestedName">Nombre del Contacto<code style="color: crimson;"> *</code></label>
                  <input type="text" class="form-control form-control-sm" id="inputInterestedName"
                    placeholder="Nombre completo" formControlName="interestedName" (keyup)="capitalizar()"
                    [ngModelOptions]="{standalone: true}" required [disabled]="isDisabled">
                  <div class="text-danger" [hidden]="!error.interestedName">
                    {{error.interestedName}}
                  </div>
                </div>

                <div *ngIf="interestedDocument" class="form-group col-md-4">
                  <label for="inputIntPhone">Identificación<code style="color: crimson;"> *</code></label>
                  <input type="text" class="form-control form-control-sm" id="inputIntPhone"
                    placeholder="Identificación" formControlName="interestedDocument"
                    [ngModelOptions]="{standalone: true}" required [disabled]="isDisabled">
                  <div class="text-danger" [hidden]="!error.interestedPhone">
                    {{error.interestedPhone}}
                  </div>
                </div>

                <div class="form-group" [ngClass]="{'col-md-4': interestedDocument, 'col-md-6': !(interestedDocument)}">
                  <label for="inputIntPhone">Número Teléfonico<code style="color: crimson;"> *</code></label>
                  <input type="text" class="form-control form-control-sm" id="inputIntPhone"
                    placeholder="Número teléfonico" formControlName="interestedPhone" (input)="onInput($event)"
                    pattern="[0-9]*" maxlength="10" [ngModelOptions]="{standalone: true}" required
                    [disabled]="isDisabled">
                  <div class="text-danger" [hidden]="!error.interestedPhone">
                    {{error.interestedPhone}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr>
        <div class="col-sm-12" *ngIf="showForm">
          <div class="card" style="margin-left: 1px">
            <div class="card-header header-color text-center">
              <h6>DATOS DEL SINIESTRO</h6>
            </div>
            <div class="card-body">
              <div class="form-row">
                <div class="form-group col-md-3">
                  <label for="selectTypeSinister">Tipo de Evento<code style="color: crimson;"> *</code></label>
                  <select id="selectTypeSinister" class="form-control form-control-sm" formControlName="typeSinister"
                    [ngModelOptions]="{standalone: true}" required [disabled]="isDisabled">
                    <option value="">Seleccionar...</option>
                    <option *ngFor="let typesinister of typesinisters | async" value="{{typesinister.id}}">
                      {{typesinister.name}}</option>
                  </select>
                  <div class="text-danger" [hidden]="!error.typeSinister">
                    {{error.typeSinister}}
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="selectTypeAccident">Tipo de Accidente<code style="color: crimson;"> *</code></label>
                  <select id="selectTypeAccident" class="form-control form-control-sm" formControlName="typeAccident"
                    [ngModelOptions]="{standalone: true}" required [disabled]="isDisabled">
                    <option value="">Seleccionar...</option>
                    <option *ngFor="let typeaccident of typeaccidents | async" value="{{typeaccident.id}}">
                      {{typeaccident.name}}</option>
                  </select>
                  <div class="text-danger" [hidden]="!error.typeAccident">
                    {{error.typeAccident}}
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="selectDepartament">Departamento<code style="color: crimson;"> *</code></label>
                  <select id="selectDepartament" class="form-control form-control-sm"
                    formControlName="citys_departament_id" [ngModelOptions]="{standalone: true}" required
                    (change)="fillCitys()" [disabled]="isDisabled">
                    <option value="">Seleccionar...</option>
                    <option *ngFor="let departament of departaments | async" value="{{departament.id}}">
                      {{departament.name}}</option>
                  </select>
                  <div class="text-danger" [hidden]="!error.citys_departament_id">
                    {{error.citys_departament_id}}
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="selectCity">Ciudad<code style="color: crimson;"> *</code></label>
                  <select id="selectCity" class="form-control form-control-sm" formControlName="citys_cod_mun"
                    [ngModelOptions]="{standalone: true}" required [disabled]="isDisabled">
                    <option value="">Seleccionar...</option>
                    <option *ngFor="let city of citys" value="{{city.id}}">{{city.name}}</option>
                  </select>
                  <div class="text-danger" [hidden]="!error.citys_cod_mun">
                    {{error.citys_cod_mun}}
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="inputAccAddress">Dirección del Accidente</label>
                  <input type="text" class="form-control form-control-sm" id="inputAccAddress"
                    placeholder="Dirección del Accidente" formControlName="accidentAddress"
                    (keyup)="formGroup.controls['accidentAddress'].setValue(formGroup.controls['accidentAddress'].value.toUpperCase())"
                    [ngModelOptions]="{standalone: true}" [disabled]="isDisabled">
                  <div class="text-danger" [hidden]="!error.accidentAddress">
                    {{error.accidentAddress}}
                  </div>
                </div>
                <div class="form-group col-md-6">
                  <label for="inputActAddress">Dirección Actual<code style="color: crimson;"> *</code></label>
                  <input type="text" class="form-control form-control-sm" id="inputActAddress"
                    placeholder="Dirección Actual" formControlName="currentAddress"
                    (keyup)="formGroup.controls['currentAddress'].setValue(formGroup.controls['currentAddress'].value.toUpperCase())"
                    [ngModelOptions]="{standalone: true}" [disabled]="isDisabled" required>
                  <div class="text-danger" [hidden]="!error.currentAddress">
                    {{error.currentAddress}}
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="hourSinister">Hora del siniestro<code style="color: crimson;"> *</code></label>
                  <input type="time" class="form-control form-control-sm" id="hourSinister"
                    formControlName="hourSinister" [ngModelOptions]="{standalone: true}" required
                    [disabled]="isDisabled">
                  <div class="text-danger" *ngIf="formGroup.get('hourSinister')?.hasError('invalidTime')">
                    La hora no puede ser en el futuro.
                  </div>
                  <label for="hourReportSinister">Hora de reporte (siniestro)<code
                      style="color: crimson;"> *</code></label>
                  <input [nz-tooltip]="'Agente ingrese la hora del reporte de este siniestro'" type="time"
                    class="form-control form-control-sm" id="hourReportSinister" formControlName="hourReportSinister"
                    [ngModelOptions]="{standalone: true}" required [disabled]="isDisabled">
                  <div class="text-danger" *ngIf="formGroup.get('hourReportSinister')?.hasError('invalidTime')">
                    La hora no puede ser en el futuro.
                  </div>
                </div>

              </div>
              <div class="form-row" *ngIf="formGroup.controls['id'].value && !service">
                <div class="offset-md-7 col-md-4">
                  <div class="form-group row">
                    <label for="selectStateCase" class="col-sm-4 col-form-label">Estado del caso</label>
                    <div class="col-sm-8">
                      <select [disabled]="!permissionA" name="" id="" class="form-control form-control-sm"
                        formControlName="case_state" [ngModelOptions]="{standalone: true}">
                        <option value="">Seleccionar...</option>
                        <option *ngFor="let statecase of statecases" value="{{statecase.id}}">{{statecase.name}}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-md-1">
                  <button class="btn btn-info btn-sm" [disabled]="!caseForm.valid"
                    *ngIf="formGroup.controls['id'].value">Guardar</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr>
        <div class="col-sm-12" id="">
          <div [class]="intermitenteAsista">
            <div *ngIf="intermitenteAsista" class="marquee">
              <p><span class="element">{{msg}}</span></p>
            </div>
            <app-form-asista class="row" *ngIf="services.asista" [data]="formGroup.getRawValue()"
              (liveEvent)="handleLiveEvent()" (formFilled)="handleFormFilled($event)"></app-form-asista>
          </div>
        </div>
        <div class="col-sm-12" id="">
          <app-form-fcii class="row" *ngIf="services.fcii"></app-form-fcii>
        </div>
        <div class="col-sm-12" id="">
          <app-form-digipat class="row" *ngIf="services.digipat"></app-form-digipat>
        </div>
        <div class="col-sm-12" id="">
          <app-form-pwa class="row" *ngIf="services.pwa"></app-form-pwa>
        </div>
        <hr>
        <div class="col-sm-12" *ngIf="firstSubmit==false">
          <div *ngIf="showForm">
            <div class="card" style="margin-left: 1px">
              <div class="card-header header-color text-center">
                <h6>OBSERVACIONES DEL CASO</h6>
              </div>
              <div class="card-body">
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for="exampleFormControlTextarea1">Añadir observación</label>
                    <textarea oninput="this.value = this.value.toUpperCase()" formControlName="observations"
                      class="form-control form-control-sm" name="" id="" cols="4" rows="5">
                    </textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12" *ngIf="firstSubmit==true">
          <div class="card" style="margin-left: 1px">
            <div class="card-header header-color text-center">
              <h6>OBSERVACIONES DEL CASO</h6>
            </div>
            <div class="card-body">
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="exampleFormControlTextarea1">Añadir observación</label>
                  <textarea class="form-control" id="exampleFormControlTextarea1" placeholder="Escriba una observación"
                    rows="4" [(ngModel)]="obsData.comment" (keyup)="obsData.comment = obsData.comment.toUpperCase()"
                    [ngModelOptions]="{standalone: true}"></textarea>
                </div>
                <div class="col-md-6 scrollspy-obs">
                  <p>Observaciones agregadas:</p>
                  <div *ngFor="let obs of formGroup.controls['observations'].value" class="row">
                    <div class="col-sm-12">
                      <div class="obs">
                        <div class="col-sm-12">
                          <strong>{{ obs.name }} - </strong><small>{{ obs.date }}</small>
                        </div>
                        <div class="col-sm-12">
                          {{ obs.comment }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <a href="#" class="btn btn-primary" (click)="addObservation($event)">Agregar Observación</a>
            </div>
          </div>
        </div>
        <div class="col-sm-12" style="margin-bottom: 20px">
          <div class="row justify-content-md-center align-items-center">
            <div class="btn-group" role="group" aria-label="Basic example">
              <div *ngIf="!loading">
                <a *ngIf="!auroraId" href="#" routerLink="/report-accidents" class="btn btn-secondary">Volver</a>
                <!-- <a *ngIf="auroraId" href="#" routerLink="/aurora-cases" class="btn btn-secondary">Volver</a> -->
                <button *ngIf="auroraId" href="#" (click)="enviarDatosAlNodeJS()"  class="btn btn-secondary">Volver</button>
              </div>
              <button class="btn btn-info" [disabled]="!caseForm.valid || loading"
                *ngIf="!formGroup.controls['id'].value && showForm">Guardar y Continuar</button>
              <div *ngIf="loading" class="loading-spinner">
                <span class="loadered"></span><br>
                En un momento será redirigido.<br> ¡Espere por favor!<br><br>
                <span class="loader"></span>
              </div>
              <button class="btn btn-info" [disabled]="!caseForm.valid"
                *ngIf="formGroup.controls['id'].value">Actualizar</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
