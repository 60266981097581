import { Component, OnInit } from '@angular/core';
import { ClaimService } from 'src/app/Services/claim/claim.service';
import { SnotifyService } from 'ng-snotify';
import * as moment from 'moment';
import { TokenService } from 'src/app/Services/token.service';
import { SwalService } from 'src/app/Services/swal/swal.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Observations } from 'src/app/models/claims/observations';
import * as fieldForm from 'src/assets/js/camposFormularioClaims.js';
import Swal from 'sweetalert2';
import { FormControl, FormGroup } from '@angular/forms';
@Component({
  selector: 'app-claim-form',
  templateUrl: './claim-form.component.html',
  styleUrls: ['./claim-form.component.css']
})
export class ClaimFormComponent implements OnInit {
  public form: FormGroup = new FormGroup({
    id: new FormControl(),
    created_at: new FormControl(),
    accident_date: new FormControl(),
    name: new FormControl(),
    plate_insured: new FormControl(),
    plate_third: new FormControl(),
    protection: new FormControl(),
    type_request: new FormControl(),
    home_dealership: new FormControl(),
    customer_shop: new FormControl(),
    agent_receives: new FormControl(),
    state: new FormControl(),
    budget: new FormControl(),
    sinister: new FormControl(),
    observations: new FormControl(),
    nameManages: new FormControl(),
    citys_departament_id: new FormControl(),
    citys_cod_mun: new FormControl(),
    phones: new FormControl(),
    error_rcl: new FormControl(),
    checkbox: new FormControl(),
  });

  public error = {
    accident_date: null, name: null, plate_insured: null, plate_third: null,
    protection: null, type_request: null, home_dealership: null, customer_shop: null, agent_receives: null,
    state: null, budget: null, sinister: null, observations: null, nameManages: null,
    citys_departament_id: null, citys_cod_mun: null, created_at: null
  }
  obse: Observations[];
  //array que se muestra en la plantilla
  observaciones = [];
  //array que se inserta en la base de datos
  observaciones2 = [];
  telefonos = [];
  telefonos2 = [];
  private _originalValue: Object;
  public phonesWrite = null;
  public obsData = {cont: 1, comment: null};
  public basicData = {
    states_claim: null,
    type_request_claim: null,
    protections: null,
    departaments: null,
    citys: null,
    user: null
  };
  public users: any;
  constructor(private router: Router, public swal: SwalService,
    private ClaimService: ClaimService, private Notify: SnotifyService, private Token: TokenService, private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.swal.loading();
    this.getBasicInformation();
    this.form.get('created_at').setValue(moment().format('YYYY-MM-DD h:mm:ss'));
    this.form.get('id').setValue(this.route.snapshot.params["id"]);
    this.sendFill();
    this.selectUsers();
  }
bott:boolean=false
  onSubmit() {
    this.swal.loading();
    this.form.get('nameManages').setValue(this.basicData.user.id);
    this.ClaimService.create(this.form.value).subscribe(
      data => {
        this.handleResponse(data);
      },
      error => {
        this.handleError(error);
      }
    );
    this.swal.update();
    if(this.form.get('id').value != null){
      let form = this.form.getRawValue()
      this.obsData.comment = this.makeObservation(form)
      this.handleSubmit()
      this.saveObservations()
    }else{
      this.bott=true
    }
  }

  /**
   * Método para estructurar el mensaje con los cambios hechos en el formulario.
   * @returns variable message con la información de los cambios
   * @author ??
   */
  makeObservation(form): string {
    let message = 'SE MODIFICA:'
    Object.keys(form).forEach((value, index, array) => {
      let field
      field = form[value] !== this._originalValue[value] ? fieldForm.object[value] : ''
      field = field !== '' ? ' ' + field + ',' : ''
      message = message + field
    })
    return message !== 'SE MODIFICA:' ? message.slice(0, message.length - 1) : null
  }

  handleResponse(data) {
    this.Notify.clear();
    Swal.close();
    data.status ? this.Token.logout() : '';
    this.Notify.success('Exito!', { timeout: 0 });
    this._originalValue = this.form.getRawValue()
    //this.form.reset()
    //this.router.navigateByUrl(`/claim/${data.id}`)
  }

  handleError(error) {
    this.Notify.clear();
    Swal.close();
    this.error = error.error.errors;
  }

  getBasicInformation() {
    this.ClaimService.getBasicInformation().subscribe(
      data => {
        this.handleResponseBasicInformation(data);
      },
      () => {
        this.handleErrorBasicInformation();
      }
    );
  }

  handleResponseBasicInformation(data) {
    this.Notify.clear();
    Swal.close();
    this.basicData = data;
    this.basicData.states_claim.splice(4, 1);
    this.form.get('id').value == undefined ? this.form.get('agent_receives').setValue(this.basicData.user.id) : '';
  }

  handleErrorBasicInformation() {
    this.Notify.clear();
    Swal.close();
    this.Notify.error('Algo ha salido mal');
  }

  loadCitys() {
    const data = {
      citys_departament_id: this.form.get('citys_departament_id').value
    }
    this.ClaimService.getCitys(data).subscribe(
      data => {
        this.basicData.citys = data.data;
      },
      () => {
        this.Notify.error('Ha ocurrido un error en el servidor');
      }
    );
  }

  sendFill() {
    if (this.form.get('id').value != null) {
      let data = {
        id: this.form.get('id').value
      }
      this.ClaimService.select(data).subscribe(
        data => this.fillData(data),
        () => this.Notify.error('Algo salio mal en el servidor')
      );
    }
  }

  fillData(data) {
    data.status ? this.Token.logout() : '';
    this.loadObservations()
    this.form.patchValue(data);
    this.form.controls['error_rcl'].setValue(data.error_rcl == 1 ? true : false)
    this._originalValue = this.form.getRawValue()
    for (let datita of data.phones) {
      this.telefonos.unshift(datita)
    }
    this.loadCitys();
  }

  addPhone(Event: MouseEvent) {
    Event.preventDefault();
    this.phonesWrite !== '' && this.phonesWrite !== null ? this.telefonos2.unshift({ phoneNumber: this.phonesWrite }) : '';
    this.form.controls['phones'].setValue(this.telefonos2)
    this.telefonos.unshift({ phoneNumber: this.phonesWrite })
    this.phonesWrite = null;
  }

  selectUsers() {
    this.ClaimService.getUsers().subscribe(
      data => {
        this.fillDataUser(data);
      }
    );
  }

  fillDataUser(data) {
    this.users = data.data;
  }

    /**
   * Método para invocar handleSubmit .
   * @param e
   * @author Pablo Morales <pablo.morales@holdingvml.net>
   */
  addObservation(e: MouseEvent){
    e.preventDefault();
    this.handleSubmit()
  }

  /**
   * Método para agregar observaciones desde la caja de texto y el mensaje de cambios en formulario.
   * El método llena dos arreglos con los mismos datos, uno de esos arreglos se muestra en pantalla
   * para que el ususario vea inmediatamente las observaciones agregadas.
   * El otro arreglo se utiliza para guardar las observaciones en la base de datos
   * @author Pablo Morales <pablo.morales@holdingvml.net>
   */
  handleSubmit(): boolean {
    if(!this.obsData.comment) return false;
    this.observaciones.unshift({
      'observation': this.obsData.comment,
      'registration_date': moment().format('YYYY-MM-DD hh:mm:ss'),
      'name': 'YO',
      'user': this.basicData.user.id,
      'id_claim': this.form.get('id').value
    });
    this.observaciones2.unshift({
      'observation': this.obsData.comment,
      'registration_date': moment().format('YYYY-MM-DD hh:mm:ss'),
      'name': 'YO',
      'user': this.basicData.user.id,
      'id_claim': this.form.get('id').value
    });
    this.obsData.cont++;
    this.obsData.comment = null;
  }
  /**
   * Método para guardar  las observaciones.
   * Se hace un recorrido al arreglo observaciones2 para luego hacer las respectivas inserciones
   * @returns Mensaje de exito
   * @author Pablo Morales <pablo.morales@holdingvml.net>
   */
  saveObservations() {
    for (let obs of this.observaciones2) {
      this.ClaimService.createObservation(obs).subscribe(
        res => {
        }, err => {
        }
      )
    }
    //limpia el arreglo
    for (let i = this.observaciones2.length; i > 0; i--) {
      this.observaciones2.pop();
    }
  }
  /**
   * Método para cargar las observaciones.
   * @returns Arreglo con las observaciones que coinciden con el id de la reclamación
   * @author Pablo Morales <pablo.morales@holdingvml.net>
   */
  loadObservations(): void {
    this.ClaimService.getObservation(this.form.get('id').value).subscribe(data => {
      this.obse = data.data;
      this.observaciones = this.obse;
    }
    );
  }
}
