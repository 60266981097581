import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { Socket } from 'ngx-socket-io'
import { AuthService } from 'src/app/Services/auth.service'
import { ClientService } from 'src/app/Services/clients/client.service'
import { CountAuroraService } from 'src/app/Services/count-aurora.service'
import { NavbarService } from 'src/app/Services/navbar/navbar.service'
import { TokenService } from 'src/app/Services/token.service'
import { UserInformationService } from 'src/app/Services/users/user-information.service'
import { NavItems } from 'src/app/models/nav-items'
import { User } from 'src/app/models/user'
import Swal from 'sweetalert2'

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  profile_ID: number
  flag: boolean = false
  count = 0
  ocultus: boolean = true
  msg: string = 'Ocultar Menú'
  msg2: string = 'double-left'

  hideNav(e: MouseEvent) {
    e.preventDefault()
    if (this.ocultus == true) {
      this.ocultus = false
      this.msg = 'Ver Menú'
      this.msg2 = 'double-right'
    } else {
      this.ocultus = true
      this.msg = 'Ocultar Menú'
      this.msg2 = 'double-left'
    }
  }

  public loggedIn: boolean;
  user: User = {} as User


  constructor(
    private socket: Socket,
    private notification: NzNotificationService,
    private CountAuroraService: CountAuroraService,
    public nav: NavbarService,
    private Auth: AuthService,
    private router: Router,
    private Token: TokenService,
    private userInformationService: UserInformationService,
    private clientService: ClientService
  ) {
    this.webSockets()
  }

  ngOnInit() {
    this.Auth.authStatus.subscribe(value => {
      this.ready(value)
      this.userInformationService.updateInformation(this.Token.getUser())
    });
    this.userInformationService.userStatus.subscribe((data) => {
      if (data) {
        this.profile_ID = data.profile_ID
      }
      this.user = data
    })
  }


  navigator: NavItems[] = [
    {
      name: 'User',
      icon: 'home',
      submenu: true,
      children: [
        {
          name: 'Index',
          route: () => this.router.navigateByUrl('home'),
          submenu: false,
          class: 'fas fa-home',
          role: 'ROLE_CONSULTAR_HOME'
        }
      ]
    },
    {
      name: 'Administración',
      icon: 'key',
      submenu: true,
      children: [
        /* {
          submenu: true,
          name: 'Configuración',
          route: () => {},
          children: [
            {
              name: 'Roles',
              route: () => this.router.navigateByUrl('profiles'),
              class: '',
              role: 'ROLE_CONSULTAR_ROLES'
            },
            {
              name: 'Usuarios',
              route: () => this.router.navigateByUrl('users'),
              class: '',
              role: 'ROLE_CONSULTAR_USUARIOS'
            }
          ]
        }, */
        {
          name: 'Roles',
          route: () => this.router.navigateByUrl('profiles'),
          class: '',
          role: 'ROLE_CONSULTAR_ROLES'
        },
        {
          name: 'Usuarios',
          route: () => this.router.navigateByUrl('users'),
          class: '',
          role: 'ROLE_CONSULTAR_USUARIOS'
        },
        {
          name: 'Clientes',
          route: () => this.router.navigateByUrl('clients'),
          submenu: false,
          class: '',
          role: 'ROLE_CONSULTAR_CLIENTES'
        },
        {
          name: 'Beneficiarios',
          route: () => this.router.navigateByUrl('beneficiaries'),
          submenu: false,
          class: '',
          role: 'ROLE_CONSULTAR_BENEFICIARIOS'
        },
        {
          name: 'Personas',
          route: () => this.router.navigateByUrl('persons'),
          submenu: false,
          class: '',
          role: 'ROLE_CONSULTAR_PERSONAS'
        },

        {
          name: 'Coordinadores',
          submenu: false,
          route: () => this.router.navigateByUrl('coordinators'),
          class: '',
          role: 'ROLE_CONSULTAR_COORDINADORES'
        },
        {
          name: 'Opciones otros servicios',
          submenu: false,
          route: () => this.router.navigateByUrl('otherservices'),
          class: '',
          role: 'ROLE_CONSULTAR_OPCIONES_OTROS'
        },
        {
          name: 'Modificar novedades',
          submenu: false,
          route: () => this.router.navigateByUrl('news'),
          class: '',
          role: 'ROLE_CONSULTAR_NOVEDADES'
        },
        {
          name: 'Jurídico',
          route: () => { },
          submenu: true,
          children: [
            {
              name: 'Abogados',
              route: () => this.router.navigateByUrl('lawyers'),
              class: '',
              role: 'ROLE_CONSULTAR_ABOGADOS'
            },
            {
              name: 'Firmas',
              route: () => this.router.navigateByUrl('list-firm'),
              class: '',
              role: 'ROLE_CONSULTAR_FIRMAS'
            }
          ]
        }
      ]
    },
    {
      name: 'Reporte de accidentes',
      icon: 'car',
      submenu: true,
      children: [
        {
          name: 'Reporte de accidente',
          route: () => this.router.navigateByUrl('report-accidents'),
          submenu: false,
          class: 'fas fa-car-crash',
          role: 'ROLE_CONSULTAR_REPORTE_ACCIDENTE'
        },
        {
          name: 'Registrar encuesta',
          route: () => this.router.navigateByUrl('survey'),
          submenu: false,
          class: 'fas fa-poll-h',
          role: 'ROLE_CONSULTAR_REGISTRAR_ENCUESTA'
        }
      ]
    },
    {
      name: 'Reclamaciones',
      icon: 'menu',
      submenu: true,
      children: [
        {
          name: 'Rcl. pendientes por radicar',
          route: () => this.router.navigateByUrl('claims'),
          submenu: false,
          class: 'fas fa-list-ul',
          role: 'ROLE_CONSULTAR_RECLAMACIONES'
        },
        {
          name: 'Grabación siniestro',
          route: () => this.router.navigateByUrl('recordings'),
          submenu: false,
          class: 'fas fa-clipboard-check',
          role: 'ROLE_CONSULTAR_GRABACIONES'
        }
      ]
    },
    /* {
      name: 'Solicitudes',
      icon: 'solution',
      submenu: true,
      children: [
        {
          name: 'Seguros del estado',
          route: () => this.router.navigateByUrl('state-insurance'),
          submenu: false,
          class: 'fas fa-bandage',
          role: 'ROLE_CONSULTAR_SOLICITUDES'
        },
        {
          name: 'Errores del sistema',
          route: () => this.router.navigateByUrl('list-error'),
          submenu: false,
          class: 'fas fa-bell',
          role: 'ROLE_CONSULTAR_SOLICITUDES_ERRORES'
        }
      ]
    }, */
    {
      name: 'Otros',
      icon: 'eye',
      submenu: true,
      children: [
        {
          name: 'Llamadas de otro índole',
          route: () => this.router.navigateByUrl('other/callOtherNature'),
          submenu: false,
          role: 'ROLE_CONSULTAR_LLAMADAS_OTRO_INDOLE'
        },
        {
          name: 'Informes',
          route: () => this.router.navigateByUrl('other/reports'),
          submenu: false,
          class: 'fas fa-clipboard-check',
          role: 'ROLE_CONSULTAR_INFORMES'
        },
        {
          name: 'Wolkvox',
          route: () => this.router.navigateByUrl('other/wolkvox'),
          submenu: false,
          class: 'fas fa-box-check',

          role: 'ROLE_CONSULTAR_LLAMADAS_OTRO_INDOLE'
        },
        /* {
          name: 'Backups',
          route: () => {},
          submenu: false,
          class: 'fas fa-exclamation',
          role: 'ROLE_CONSULTAR_BACKUP'
        } */
      ]
    }
  ]
  navigator2: NavItems[] = [
    {
      name: 'Aurora boot',
      icon: 'robot',
      submenu: true,
      children: [
        {
          name: 'Casos Aurora',
          route: () => this.router.navigateByUrl('aurora-cases'),
          submenu: false,
          class: 'fas fa-robot',
          role: 'ROLE_CONSULTAR_REGISTRAR_ENCUESTA'
        }
      ]
    }
  ]


  webSockets() {
    this.socket.on('nueva_insercion', (data: any) => {
      console.log('Se ha recibido una nueva inserción:', data);
      const formattedMessage = `
      <div>
        <strong>${data.evento}</strong>
      </div>
      <div>
        Placa: ${data.placa}
      </div>
      <div>
       Contacto: ${data.nombre_afectado}
      </div>
      <div>
       Asistencia: ${data.asistencia}
      </div>`;
      this.notification.create(
        'info',
        formattedMessage,
        data.mensaje,
        { nzPlacement: 'bottomLeft',
        nzDuration: 9000 },
      )
      this.flag = true
      this.count++
    });
  }

  clearNotification() {
    this.router.navigateByUrl('aurora-cases')
    this.flag = false
    this.count = 0
  }

  onNavigate() {
    Swal.fire({
      text: " Administrador, este proceso puede tardar varios minutos ¿Desea Continuar?",
      icon: 'question',
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.isLoading()
        window.open("http://asistacasos.com/C0M4d310YCig5BORUrUW/M4ffpggxlLM2OLMvSNUA.php"/* , "_blank" */)
        /*  Swal.fire(
           'Deleted!',
           'Your file has been deleted.',
           'success'
         ) */
      }
    })

  }


  ready(value) {
    this.loggedIn = value;
  }

  logout(e: MouseEvent) {
    e.preventDefault();
    this.Token.remove();
    this.Auth.changeAuthStatus(false);
    this.router.navigateByUrl('/login');
  }

  changePassword(e: MouseEvent) {
    e.preventDefault();
    this.Token.remove();
    this.Auth.changeAuthStatus(false);
    this.router.navigateByUrl('/request-password-reset');
  }

  /**
   * Método para validar permiso
   *
   * @param menu registro de menu
   * @returns boolean de validacion
   */
  validatePermission(menu: NavItems): boolean {
    if (menu.submenu) {
      let show = false
      this.user?.profile.forEach((e) => {
        menu.children.forEach((sm) => {
          if (e.permiso == sm.role) {
            show = true
          }
        })
      })
      return show
    } else {
      return this.user?.profile.find(e => e.permiso == menu.role) ? true : false
    }
  }

  reloadCacheData(event: MouseEvent): void {
    event.preventDefault()
    this.clientService.refreshClients()
  }

}
