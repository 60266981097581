import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { TokenService } from 'src/app/Services/token.service';

import Swal from 'sweetalert2';
import { CoordinatorsService } from 'src/app/Services/coordinators/coordinators.service';
import { OtherServiceService } from 'src/app/Services/otherServiceModule/other-service.service';

@Component({
  selector: 'app-options-form',
  templateUrl: './options-form.component.html',
  styleUrls: ['./options-form.component.css']
})
export class OptionsFormComponent implements OnInit {

  
  public error = {
    name: null,
    firm: null,
    phone: null
  };

  public form = {
    id: null,
    name: null
  }

  constructor(
    private route: ActivatedRoute,
    private Notify: SnotifyService,
    private Token: TokenService,
    private router: Router,
    private Service: OtherServiceService
  ) { 
    this.form.id = route.snapshot.params["id"];
  }

  ngOnInit() {
    this.sendFill();
  }

  onSubmit(){
    this.Notify.info('Espere...', {timeout:0});
    if(this.form.id != null){
      this.Service.update(this.form).subscribe(
        data => this.handleResponse(data),
        err => this.handleError(err)
      );
    }else{
      this.Service.createOtherService(this.form).subscribe(
        data => this.handleResponse(data),
        err => this.handleError(err)
      );
    }
  }

  handleResponse(data){
    data.status ? this.logout() : '';
    this.Notify.remove();
    this.Notify.success('Exito!', {timeout: 2000});
  }

  logout(){
    this.Token.remove();
    this.router.navigateByUrl('/login');
    setTimeout(()=>{
      window.location.reload();
    }, 100);
  }

  handleError(error){
    this.Notify.remove();
    this.error = error.error.errors;
  }

  sendFill(){
    if(this.form.id != null){
      this.Notify.info('Espere...', {timeout:0});
      let data = {
        id: this.form.id
      }
      this.Service.select(data).subscribe(
        data => this.fillData(data),
        err => this.fillDataError(2)
      );
    }
  }

  fillData(data){
    this.Notify.remove();
    if(data.name){
      this.form.name = data.name;
    }else{
      this.fillDataError(1);
    }
    if(data.status){
      this.logout();
    }
  }

  fillDataError(n){
    this.Notify.remove();
    this.Notify.error('Algo ha salido mal: '+n, {timeout: 5000});
  }
}
