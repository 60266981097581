import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientService } from 'src/app/Services/clients/client.service';
import { SnotifyService } from 'ng-snotify';
import { TokenService } from 'src/app/Services/token.service';
import { BeneficiariesService } from 'src/app/Services/beneficiaries/beneficiaries.service';

@Component({
  selector: 'app-beneficiarie-form',
  templateUrl: './beneficiarie-form.component.html',
  styleUrls: ['./beneficiarie-form.component.css']
})
export class BeneficiarieFormComponent implements OnInit {
  public error = {
    nit: null,
    name: null,
    address: null,
    phone: null
  };
  public form = {
    id:null,
    nit: null,
    name: null,
    address: null,
    phone: null,
    client: ''
  }
  public clients: any[];
  private dataTablesParameters: any;
  constructor(
    private route: ActivatedRoute,
    private Client: ClientService,
    private Notify: SnotifyService,
    private Token: TokenService,
    private router: Router,
    private Beneficiarie: BeneficiariesService
  ) {
    this.form.id = route.snapshot.params["id"];
  }

  ngOnInit() {
    this.sendFill();
    this.loadDataClient();
  }

  onSubmit(){
    this.Notify.info('Espere...', {timeout:0});
    if(this.form.id != null){
      this.Beneficiarie.update(this.form).subscribe(
        data => this.handleResponse(data),
        err => this.handleError(err)
      );
    }else{
      this.Beneficiarie.create(this.form).subscribe(
        data => this.handleResponse(data),
        err => this.handleError(err)
      );
    }
  }

  handleResponse(data){
    data.status ? this.logout() : '';
    this.Notify.remove();
    this.Notify.success('Exito!', {timeout: 2000});
  }

  logout(){
    this.Token.remove();
    this.router.navigateByUrl('/login');
    setTimeout(()=>{
      window.location.reload();
    }, 100);
  }

  handleError(error){
    this.Notify.remove();
    this.error = error.error.errors;
  }

  sendFill(){
    if(this.form.id != null){
      this.Notify.info('Espere...', {timeout:0});
      let data = {
        id: this.form.id
      }
      this.Beneficiarie.select(data).subscribe(
        data => this.fillData(data),
        err => this.fillDataError(2)
      );
    }
  }

  fillData(data){
    this.Notify.remove();
    if(data.name){
      this.form.name = data.name;
      this.form.nit = data.nit;
      this.form.address = data.address;
      this.form.phone = data.phone;
      this.form.client = data.client;
    }else{
      this.fillDataError(1);
    }
    if(data.status){
      this.logout();
    }
  }

  fillDataError(n){
    this.Notify.remove();
    this.Notify.error('Algo ha salido mal: '+n, {timeout: 5000});
  }

  async loadDataClient(): Promise<void>{
    let resp = await this.Client.getClients(this.dataTablesParameters).toPromise()
    this.clients = resp.data;
  }

}
