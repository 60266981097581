import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-view-hub',
  templateUrl: './view-hub.component.html',
  styleUrls: ['./view-hub.component.css']
})
export class ViewHubComponent implements OnInit {
  @Input() pruebitas: []

  constructor() { }

  ngOnInit(): void {
    console.log(this.pruebitas)
  }

}
