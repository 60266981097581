import { Component, OnInit } from '@angular/core';
import { TokenService } from 'src/app/Services/token.service';
import { Router } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { AuthService } from 'src/app/Services/auth.service';
import { OtherServiceService } from 'src/app/Services/otherServiceModule/other-service.service';
import { RowsGeneralService } from 'src/app/Services/report-accidents/rows-general.service';
import { forkJoin } from 'rxjs';
import { ClientService } from 'src/app/Services/clients/client.service';
import { Client } from 'src/app/models/client';
import { FormControl, FormGroup } from '@angular/forms';
import { NzIconModule } from 'ng-zorro-antd/icon';

import Swal from 'sweetalert2';
import { ModalService } from 'src/app/Services/modal/modal.service';
import { NzModalService } from 'ng-zorro-antd/modal';

// class of other services with attributes
class other {
  id: number;
  name: string;
  observation: string;
  created_at: Date;
  received: string;
  departament: string;
  city: string;
  client: string;
  phonenumbers: number;
}

//class of Client with atributes

@Component({
  selector: 'app-calls-other-nature',
  templateUrl: './calls-other-nature.component.html',
  styleUrls: ['./calls-other-nature.component.css']
})
export class CallsOtherNatureComponent implements OnInit {

  modalRegister = new ModalService(this.modalService);
  clients: Client[];
  others: other[];
  dtOptions: DataTables.Settings;
  listOfData: { data: other[], total: number, current_page: number } = {} as any
  loading: boolean = false;
  private idDelete;
  public user = { profile: null, permission: false };
  public error: any[];

  public form = {
    service: '',
    observation: null,
    citys_departament_id: '',
    citys_cod_mun: '',
    id_client: '',
    phonenumbers: ''
  };

  public departaments: any[];
  public citys: any[];
  public otherservices: any;
  formpol: FormGroup = new FormGroup({
    value: new FormControl('')
  })
  constructor(
    private token: TokenService,
    private router: Router,
    private notify: SnotifyService,
    private otherService: OtherServiceService,
    private Auth: AuthService,
    private modalService: NzModalService,
    private GeneralServices: RowsGeneralService,
    private ClienteService: ClientService
  ) { }

  ngOnInit() {
    this.userid()
    this.loadData();
    this.formpol.controls['value'].valueChanges.subscribe(
      data => this.loadData(1, data)
    )
    this.fillOtherServices();
    this.fillDepartaments();
    this.ListarClientes();
  }

  onSubmit() {
    //this.notify.info('Espere...', { timeout: 0 });
    this.formpol.reset();
    this.otherService.create(this.form).subscribe(
      data => this.handleResponse(data),
      err => this.handleError(err)
    );
  }

  profile_ID: number
  userid() {
    this.profile_ID = this.token.getUser().profile_ID
  }
  deleteOtherCall(id) {
    Swal.fire({
      title: '¿Está Seguro?',
      text: "No podrá Revertir!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, Borralo',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        this.otherService.deleteOtherCall(id).subscribe(data => {
          Swal.fire({
            text: 'Borrado!',
            icon: 'success',
          })
          this.ngOnInit()
        })
      }
    })
  }

  /**
   * Método para cargar las llamadas de otro índole.
   * @param value
   * @returns Arreglo con las llamadas de otro índole.
   * @author Pablo Morales <pablo.morales@holdingvml.net>
   */
  loadData(current_page: number = 1, value: string = ''): void {
    this.loading = true
    this.otherService.getotherCall(current_page, value).subscribe(data => {
      this.others = data.data;
      this.listOfData = data;
      this.loading = false
    }
    )
  }

  logout() {
    this.token.remove();
    this.Auth.changeAuthStatus(false);
    this.router.navigateByUrl('/login');
    setTimeout(() => { window.location.reload() }, 200);
  }

  handleResponse(data) {
    data.status ? this.logout() : '';
    this.notify.remove();
    this.others.unshift(data);
    this.notify.success('Exito!', { timeout: 2000 });
    this.form.observation = null;
    this.form.service = '';
    this.form.phonenumbers = '';
    this.form.citys_departament_id = '';
    this.form.citys_cod_mun = '';
    this.form.id_client = '';
    this.ngOnInit()
  }

  handleError(error) {
    this.notify.remove();
    this.error = error.error.errors;
  }

  fillOtherServices() {
    this.otherService.getOtherServicesOptions([]).subscribe(
      data => this.fillDataOtherService(data)
    );
  }

  fillDataOtherService(data) {
    this.otherservices = data.data;
  }

  async fillDepartaments(): Promise<any> {
    this.departaments = await (await this.GeneralServices.getDepartaments([]).toPromise()).data
    this.fillCitys();
  }

  async fillCitys(): Promise<any> {
    this.citys = await (await this.GeneralServices.getCitys(this.form)).data
  }

  /**
     * Listar los clientes
     * @return "recibe el objeto de varios arreglos"
     * @author Esteban Rodriguez <senavcmtech@holdingvml.com> 19/04/2022
     */
  ListarClientes(): void {
    forkJoin([
      this.ClienteService.getClients(this.form)
    ]).subscribe(
      data => {
        this.clients = data[0].data
      }
    )
  }

}
