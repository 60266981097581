import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import { AuthService } from '../auth.service';
import { TokenService } from '../token.service';

@Injectable({
  providedIn: 'root'
})
export class SwalService {

  constructor(
    private tokenService: TokenService,
    private authService: AuthService,
    private router: Router
  ) { }

  /**
   * Mostrar cargando
   *
   * @author Pablo Enrique Morales <pablo.morales@holdingvml.net> 2021-08-26
   */
  loading(): void {
    Swal.fire({
      title: 'Cargando',
      heightAuto: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading()
      }
    })
  }

    /**
   * alerta de seguimiento juridico
   *
   * @author Pablo Enrique Morales <pablo.morales@holdingvml.net> 2021-08-26
   */

    showAsista(title: string, text: string, icon: SweetAlertIcon = 'warning'): Promise<boolean> {
      return Swal.fire({
        position: "bottom",
        title: title,
        text: text,
        icon: icon,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Entendido, guardar',
        cancelButtonText: 'Volver'
      }).then((result) => {
        return result.isConfirmed;
      });
    }



  /** Informar Actualizado
  *
  * @author Pablo Morales <pablo.morales@holdingvml.net>
  */
  update(): void {
    Swal.fire({
      icon: 'success',
      title: 'Información Actualizada',
      showConfirmButton: false,
      timer: 3500
    })
  }

  /**
   * Cerrar los sweet alert
   *
   * @author Pablo Enrique Morales <pablo.morales@holdingvml.net> 2021-08-26
   */
  close(): void {
    Swal.close()
  }

  /**
   * Manejar error del sistema
   *
   * @param e error
   * @author Pablo Enrique Morales <pablo.morales@holdingvml.net> 2021-09-01>
   */
  error(e): void {

    if (e.status == 401) this.error401()
    if (e.status == 404) this.error404()

  }

  /**
   * Manejar error 401
   *
   * @author Pablo Enrique Morales <pablo.morales@holdingvml.net> 2021-08-26
   */
  error401(): void {
    Swal.fire({
      title: 'Cierre de sesión',
      text: 'Se ha superado del tiempo de espera.',
      icon: 'error',
      heightAuto: false,
      allowOutsideClick: false,
    }).then(() => {
      this.logout();
    });
  }
  /**
  * Manejar error 404
  *
  * @author Pablo Enrique Morales <pablo.morales@holdingvml.net> 2022-08-05
  */
  error404(): void {
    Swal.fire({
      title: 'Datos no encontrados',
      text: 'No se encontraron datos con los filtros aplicados.',
      icon: 'warning',
      heightAuto: false,
      allowOutsideClick: false,
    });
  }

  logout() {
    this.authService.changeAuthStatus(false);
    this.tokenService.remove();
    this.router.navigateByUrl('/login');
  }

  //Método que genera alerta de confirmación para eliminar registros
  swalAlert(title: string, message: string, icon: SweetAlertIcon): Promise<boolean> {
    return Swal.fire({
      title: title,
      text: message,
      icon: icon,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Completado',
          'Operación realizada satisfactoriamente.',
          'success'
        )
      }
      return result.isConfirmed;
    });
  }
}
