import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenService } from '../token.service';
import { environment } from 'src/environments/environment';

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Injectable({
  providedIn: 'root'
})
export class OtherServiceService {

  public baseUrl = environment.url;

  header(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer `+this.token.get())
    }
  }

  constructor(private http:HttpClient, private token: TokenService) { }

  getOtherServices(data){
    return this.http.post<DataTablesResponse>(`${this.baseUrl}/other-calls`, data, this.header());
  }

  create(data){
    return this.http.post(`${this.baseUrl}/other-calls/create`, data, this.header());
  }

  getOtherServicesOptions(DataTablesResponse){
    return this.http.get<DataTablesResponse>(`${this.baseUrl}/LjUrSz2Aat6CfLCJn0wV`, this.header());
  }

  getOtherServicesOptionsNgzorro(nextPage, value, pageSize){
    return this.http.get<any>(`${this.baseUrl}/getOtherServicesNgzorro?page=${nextPage}&value=${value}&pageSize=${pageSize}`, this.header());
  }

  select(data){
    return this.http.post(`${this.baseUrl}/XvbXzCNe9PAgRad59YPW`, data, this.header());
  }

  update(data){
    return this.http.post(`${this.baseUrl}/ul4LS8nmAEKRmkY4Q7cA`, data, this.header());
  }

  createOtherService(data){
    return this.http.post(`${this.baseUrl}/CyMKWi3tVV3AggszxjEV`, data, this.header());
  }

  delete(data){
    return this.http.post(`${this.baseUrl}/h5yussQZ4DRgXnrMAx40`, data, this.header())
  }

  getDataReport(data){
    return this.http.post(`${this.baseUrl}/dSvSRzox4tb7MTYPDNrA`, data, this.header());
  }

  getotherCall(nextPage, value){
    return this.http.get<any>(`${this.baseUrl}/other-calls?page=${nextPage}&value=${value}`, this.header());
  }

  deleteOtherCall(value){
    return this.http.post<any>(`${this.baseUrl}/deleteOtherCall/${value}`, this.header());
  }
}
