import { Component, OnInit } from '@angular/core';
import { TokenService } from 'src/app/Services/token.service';
import { Router } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { BeneficiariesService } from 'src/app/Services/beneficiaries/beneficiaries.service';
import { SwalService } from 'src/app/Services/swal/swal.service';
import { FormControl, FormGroup } from '@angular/forms';

class Beneficiaries {
  id: number;
  name: string;
  address: string;
  phone: string;
  client: string;
  nit: string;
}

@Component({
  selector: 'app-beneficiaries',
  templateUrl: './beneficiaries.component.html',
  styleUrls: ['./beneficiaries.component.css']
})
export class BeneficiariesComponent implements OnInit {


  beneficiaries: Beneficiaries[];
  dtOptions: DataTables.Settings;
  loading: boolean = false;
  pageSize : number = 10
  listOfData: { data: Beneficiaries[], total: number, current_page:number } = {} as any
  formpol: FormGroup = new FormGroup({
    value: new FormControl('')
  })
  private idDelete;


  constructor(
    private Token: TokenService,
    private Router: Router,
    private Beneficiarie: BeneficiariesService,
    private swalService: SwalService,
    private Notify: SnotifyService
  ) { }


  ngOnInit(): void {
    //this.loadTable();
    this.loadData()
    this.formpol.controls['value'].valueChanges.subscribe(
      data => this.loadData(1, data)
    )
  }

  loadData(current_page: number = 1, value: string = ''): void {
    this.loading = true
    this.Beneficiarie.getBeneficiariesNgzorro(current_page, value,this.pageSize).subscribe(data => {
      this.listOfData = data;
      this.loading = false
    }
    )
  }
  adjustPageSize(pageSize : number): void{
    this.pageSize = pageSize;
    this.loadData();
  }

  /* loadTable(){
    const that = this;
    this.dtOptions = {
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.Notify.info('Cargando...', {timeout: 0});
        that.Beneficiarie.getBeneficiaries(dataTablesParameters).subscribe(resp => {
          this.Notify.remove();
            if(!resp.data){
              this.Token.remove();
              this.Router.navigateByUrl('/login');
              setTimeout(()=>{
                window.location.reload();
              }, 100);
            }
            that.beneficiaries = resp.data;
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
      },
      columns: [{ data: 'name' }, { data: 'address' }, { data: 'nit' }, { data: 'phone' }, { data: 'id' }, { data: 'client' }],
      "language": {
        "url": "https://cdn.datatables.net/plug-ins/1.10.20/i18n/Spanish.json"
    }
    };
  } */

   async delete(e: MouseEvent, id){
    e.preventDefault();
    let option = await this.swalService.swalAlert("Advertencia", "¿Está seguro de que quiere eliminar este registro?", 'warning');
    if(option){
      //this.Notify.info('Espere...', {timeout: 0});
      const data = {
        id: id
      }
      this.idDelete = data.id;
      this.Beneficiarie.delete(data).subscribe(
        () => this.handleResponse(this.idDelete),
        err => alert()
      );
    }
  }

  handleResponse(data){
    this.Notify.remove()
    this.ngOnInit()
  }

  processDelete(){
    let c = 0;
    this.beneficiaries.forEach((element)=>{
        if(element.id == this.idDelete){
          this.beneficiaries.splice(c, 1);
          this.idDelete = null;
        }
        c++;
    });
  }

}
