<div class="mt-4 col-8 offset-2">
    <div class="card">
        <div class="card-header header-color" *ngIf="!form.id">Register Here</div>
        <div class="card-header header-color" *ngIf="form.id">Update Here</div>
        <div class="card-body">
            <form #lawyerForm="ngForm" (ngSubmit)="onSubmit()">
                <div class="form-group row">
                    <label for="inputName" class="col-sm-3 col-form-label">Nombre</label>
                    <div class="col-sm-9">
                        <input type="text" name="name" class="form-control" id="inputName" 
                            placeholder="Nombre" [(ngModel)]="form.name" required>
                        <div class="text-danger" [hidden]="!error.name">
                            {{error.name}}
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-10 offset-2">
                        <button type="submit" class="btn btn-primary" [disabled]="!lawyerForm.valid" *ngIf="!form.id">Registrar</button>
                        <button type="submit" class="btn btn-primary" [disabled]="!lawyerForm.valid" *ngIf="form.id">Actualizar</button>
                        <a href="#" class="float-right btn btn-info" routerLink="/otherservices">Volver</a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>