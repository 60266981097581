<div>
    <div class="float-left">
        <h4>Gestión Movil</h4>
    </div>
</div>
<br>
<hr>
<table datatable [dtOptions]="dtOptions" class="table table-striped table-bordered row-border hover">
    <thead>
        <tr>
            <th># Evento</th>
            <th>Placa</th>
            <th>Hora de Contacto</th>
            <th>Hora de Llegada</th>
            <th>Fin de Asistencia</th>
            <th>Estado</th>
            <th style="width: 1px;">Acción</th>
        </tr>
    </thead>
    <tbody *ngIf="movils?.length > 0">
        <tr *ngFor="let movil of movils">
            <td>{{ movil.idCase }}</td>
            <td>{{ movil.plate }}</td>
            <td>{{ movil.contactTime }}</td>
            <td>{{ movil.arriveTime }}</td>
            <td>{{ movil.endTime }}</td>
            <td>
                <div class="alert alert-{{movil.color}}" role="alert">
                    {{ movil.state }}
                </div>
            </td>
            <td>
                <div class="row justify-content-md-center align-items-center">
                    <div class="btn-group" role="group" aria-label="Basic example">
                        <button [disabled]="movil.allow === 0" id="idCaseMovil{{movil.idC}}" class="btn btn-info btn-sm"
                                routerLink="/report-accident/{{movil.idCase}}/movil">
                            <i class="fas fa-edit"></i>
                        </button>
                    </div>
                </div>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="movils?.length < 1">
        <tr>
            <td colspan="9" class="no-data-available">No hay datos disponibles!</td>
        </tr>
    <tbody>
</table>
<br><br>
<br><br>
<br><br>
<br><br>
<br><br>
<small>Joe Developer.</small>
