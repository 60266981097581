import { Component, Input, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-news-body',
  templateUrl: './news-body.component.html',
  styleUrls: ['./news-body.component.css']
})
export class NewsBodyComponent implements OnInit {
  @Input() newsPoints: any[];
    constructor(private modalRef: NzModalRef) { }

    ngOnInit(): void {
    }
    close(): void {
      // Puedes cerrar el modal devolviendo algún resultado si es necesario
      this.modalRef.close(/* Puedes pasar datos de vuelta al componente que llamó al modal */);
    }

}
