import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { Rol } from 'src/app/models/rol';
import { Permission } from 'src/app/models/permission';
import { RolService } from 'src/app/Services/users/rol.service';
import { SnotifyService } from 'ng-snotify';
import { ProfilePermission } from 'src/app/models/profile-permission';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  /**
   * Variable para almacenar los roles del sistema
   */
  roles: Rol[] = []

  /**
   * Variable para almacenar los permisos del sistema
   */
  permissions: Permission[] = []

  loading: boolean = true

  /**
   * Formulario de rol, para seleccionar rol
   */
  formRol: FormGroup = new FormGroup({
    role: new FormControl('')
  })

  constructor(
    private rolService: RolService,
    private snotifyService: SnotifyService
  ) { }

  ngOnInit(): void {
    this.getData()
    this.formRol.controls['role'].valueChanges.subscribe(data => {
        this.getPermission(data)
      }
    )
  }

  /**
   * Metodo para consultar permisos y permisos asignados a un rol
   * 
   * @param data 
   * @author Joe Sierra <joe.sierra@holdingvml.net>
   */
  getPermission(data: number): void {
    this.loading = true
    forkJoin([
      this.rolService.getPermissions(),
      this.rolService.getProfilePermission(data)
    ]).subscribe(
      data => {
        this.mapPermission(data)
      }
    )
  }

  /**
   * Mapear permisos y crear un objecto donde especifique los asignados al rol
   * 
   * @param data 
   */
  mapPermission(data: [Permission[], ProfilePermission[]]): void {
    let permissions = data[0].map(e => {
      return {
        ...e,
        check: data[1].find(pp => pp.permissions_id == e.id) ? true : false
      }
    })
    this.permissions = permissions
    this.loading = false
  }

  /**
   * Metodo para consultar datos básicos: roles, permisos y permisos asignados de rol
   * 
   * @author Joe Sierra <joe.sierra@holdingvml.net> 11/05/2022
   */
  getData(): void {
    forkJoin([
      this.rolService.get()
    ]).subscribe(
      response => {
        this.roles = response[0]
      },
      err => {

      }
    )
  }

  /**
   * Método para enviar el cambio de permiso de un rol
   * 
   * @param name nombre de permiso
   * @author Joe Sierra <joe.sierra@holdingmvl.net>
   */
  changePermission(name: string): void {
    this.permissions[this.permissions.findIndex(e => e.permiso == name)].loading = true
    this.rolService.updateRolePermission(
      this.formRol.value.role, 
      {
        data: this.permissions.map(e => {
          if (e.check) {
            return {
              permissions_id: e.id,
              profile_id: this.formRol.value.role
            }
          } else {
            return null
          }
        }).filter(e => e != null)
      }
    ).subscribe(
      response => {
        this.permissions[this.permissions.findIndex(e => e.permiso == name)].loading = false
        this.snotifyService.success(response)
      },
      err => {

      }
    )
  }

}
