import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { TokenService } from 'src/app/Services/token.service';
import { LawyersService } from 'src/app/Services/lawyers/lawyers.service';
import Swal from 'sweetalert2';
import { SwalService } from 'src/app/Services/swal/swal.service';

@Component({
  selector: 'app-lawyer-form',
  templateUrl: './lawyer-form.component.html',
  styleUrls: ['./lawyer-form.component.css']
})
export class LawyerFormComponent implements OnInit {

  public error = {
    name: null,
    firm: null,
    phone: null
  };

  public form = {
    id: null,
    name: null,
    phone: null,
    firm: ''
  }
  public firms: any[];

  constructor(
    private route: ActivatedRoute,
    private Notify: SnotifyService,
    private Token: TokenService,
    private router: Router,
    private swalService: SwalService,
    private Lawyer: LawyersService
  ) { 
    this.form.id = route.snapshot.params["id"];
  }

  ngOnInit() {
    this.sendFill();
    this.loadDataFirms();
  }

  onSubmit(){
    this.Notify.info('Espere...', {timeout:0});
    if(this.form.id != null){
      this.Lawyer.update(this.form).subscribe(
        data => this.handleResponse(data),
        err => this.handleError(err)
      );
    }else{
      this.Lawyer.create(this.form).subscribe(
        data => this.handleResponse(data),
        err => this.handleError(err)
      );
    }
  }

  handleResponse(data){
    data.status ? this.logout() : '';
    this.Notify.remove();
    this.Notify.success('Exito!', {timeout: 2000});
  }

  logout(){
    this.Token.remove();
    this.router.navigateByUrl('/login');
    setTimeout(()=>{
      window.location.reload();
    }, 100);
  }

  handleError(error){
    this.Notify.remove();
    this.error = error.error.errors;
  }

  sendFill(){
    if(this.form.id != null){
      this.Notify.info('Espere...', {timeout:0});
      let data = {
        id: this.form.id
      }
      this.Lawyer.select(data).subscribe(
        data => this.fillData(data),
        err => this.fillDataError(2)
      );
    }
  }

  fillData(data){
    this.Notify.remove();
    if(data.name){
      this.form.name = data.name;
      this.form.phone = data.phone;
      this.form.firm = data.firm;
    }else{
      this.fillDataError(1);
    }
    if(data.status){
      this.logout();
    }
  }

  fillDataError(n){
    this.Notify.remove();
    this.Notify.error('Algo ha salido mal: '+n, {timeout: 5000});
  }

  loadDataFirms(){
    this.Lawyer.getFirms().subscribe(resp => {
      this.firms = resp.data;
    });
  }

  addFirm(Event: MouseEvent){
    Event.preventDefault();
    Swal.fire({
      title: 'Ingresa la nueva firma',
      input: 'text',
      inputValue: '',
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return 'You need to write something!'
        }else{
          this.processFirm(value);
        }
      }
    });
  }

  async processFirm(value){
    let opt = await this.swalService.swalAlert("Advertencia", `Está seguro de agregar la nueva firma: ${value}?`, 'warning');
    let data = {
      firm: value
    }
    if(opt){
      this.Notify.info('Cargando...', { timeout: 0 });
      this.Lawyer.addFirm(data).subscribe(data => {
        this.handleResponse(data);
        this.loadDataFirms();
      },
      () => {
        this.fillDataError(832);
      });
    }
  }

}
