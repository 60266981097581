import { Component, OnInit } from '@angular/core';
import { TokenService } from 'src/app/Services/token.service';
import { Router } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { RowsGeneralService } from 'src/app/Services/report-accidents/rows-general.service';
import { AuthService } from 'src/app/Services/auth.service';
import Swal from 'sweetalert2';


import * as moment from 'moment';
import { Socket } from 'ngx-socket-io';
import { FormControl, FormGroup } from '@angular/forms';
import { SwalService } from 'src/app/Services/swal/swal.service';

class Case {
  id: number;
  plate: string;
  company: string;
  currentAddress: string;
  typeSinister: string;
  case_state: string;
  received_call: string;
  registration_date;
  time = 0;
  color: string;
  idC: number;
  userModify: string;
  aurora_case?:string;
}

@Component({
  selector: 'app-rows-general',
  templateUrl: './rows-general.component.html',
  styleUrls: ['./rows-general.component.css']
})
export class RowsGeneralComponent implements OnInit {

  cases: Case[];
  dtOptions: DataTables.Settings;
  private idDelete;
  pageSize : number = 10;
  public user = { profile: null, permission: false };
  listOfData: { data: Case[], total: number, current_page:number } = {} as any
  loading: boolean = false

  form: FormGroup = new FormGroup({
    value: new FormControl('')
  })

  constructor(
    private token: TokenService,
    private router: Router,
    private notify: SnotifyService,
    private GeneralService: RowsGeneralService,
    private swalService: SwalService,
    private Auth: AuthService,
    private socket: Socket
  ) { }

  ngOnInit(): void {
    this.loadData()
    this.form.controls['value'].valueChanges.subscribe(
      data => this.loadData(1, data)
    )
    this.checkUser();
  }

  loadData(current_page: number = 1, value: string = ''): void {
    this.loading = true
    this.GeneralService.getCases(current_page, value, this.pageSize).subscribe(data => {
      this.updateTime()
      this.listOfData = data
      console.log(this.listOfData.data)
      this.loading = false
    })
  }

  adjustPageSize(pageSize : number): void{
    this.pageSize = pageSize;
    this.loadData();
  }

  async deleteCase(e: MouseEvent, id){
    e.preventDefault();
    let data = {
      id: parseInt(id)
    }
    this.idDelete = data.id;
    let option = await this.swalService.swalAlert("Advertencia", "¿Está seguro de que quiere eliminar este registro?", 'warning');
    if(option){
      this.notify.info('Espere...', {timeout: 0});
      this.GeneralService.deleteCase(data).subscribe(
        res => this.handleResponse(data.id),
        err => this.handleError(err)
      );
    }
  }

  processDelete(){
    let c = 0;
    this.cases.forEach((element)=>{
        if(element.id == this.idDelete){
          this.cases.splice(c, 1);
          this.idDelete = null;
        }
        c++;
    });
  }

  updateTime(){
    var startTime;
    var endTime;
    var duration;
    var time;
    var time_ini;
    var c = 0;
    setInterval(()=>{
      this.listOfData.data.forEach((element)=>{
          time_ini = element.registration_date;
          startTime = moment(time_ini, "YYYY-MM-DD HH:mm:ss");
          endTime = moment(moment().format("YYYY-MM-DD HH:mm:ss"), "YYYY-MM-DD HH:mm:ss");
          duration = moment.duration(endTime.diff(startTime));
          time = duration.hours()+' h '+duration.minutes()+' m '+duration.seconds()+ ' s';
          duration.asDays() >= 1 ? time = parseInt(duration.asHours()) + ' Horas' : time = time;
          element.time = time;
          duration.asMinutes() > 20 ? element.color = 'danger' : '';
          duration.asMinutes() < 20 ? element.color = 'warning' : '';
          element.case_state !== 'PROCESO' ? element.color = 'success' : '';
          c++;
      });
    }, 0);

  }

  handleResponse(data){
    this.notify.remove();
    this.processDelete(); // Remove row of table.
    this.notify.confirm('Done!', {
      buttons: [
        {
        text: 'Okay',
        action: toster => {
          this.notify.remove(toster.id)
        }
      }
      ]
    })
  }

  handleError(err){

  }

  logout(){
    this.token.remove();
    this.Auth.changeAuthStatus(false);
    this.router.navigateByUrl('/login');
  }

  checkUser(){
    this.token.searchUser().subscribe(
      data => this.fillData(data)
    );
  }

  fillData(data){
    this.user.profile = data.user.profile_ID;
    if(this.user.profile == 1){
      this.user.permission = true;
    }
  };

  async allowCases(Event: MouseEvent){
    Event.preventDefault();
    let opt = await this.swalService.swalAlert("Advertencia", "¿Está seguro de querer habilitar los casos?", 'warning');
    if(opt){
      this.GeneralService.allowCases().subscribe(
        data => this.successAllow(data),
        () => this.errorAllow()
      );
    }
  }

  successAllow(data){
    if(data.res == 1){
      data.data.forEach(element => {
        let user = {
          name: null
        }
        this.socket.emit( 'case_allow', { idCase: parseInt(element.id), state: 1, user: user } );
      });
      Swal.fire({
        icon: 'success',
        title: 'Exito!',
        showConfirmButton: false,
        timer: 1500
      });
    }else{
      Swal.fire({
        icon: 'warning',
        title: 'No se encontrarón casos activos!',
        showConfirmButton: false,
        timer: 1500
      });
    }

  }

  errorAllow(){
    Swal.fire({
      icon: 'error',
      title: 'Exito!',
      showConfirmButton: false,
      timer: 1500
    });
  }

}
