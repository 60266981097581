<div class="card" style="margin-left: 1px">
  <div class="card-header header-color">

  </div>
  <ul class="list-group list-group-flush">
    <li class="list-group-item">
      <div class="float-left">
        <h4>Lista de Grabaciones</h4>
      </div>
    </li>
  </ul>
  <div class="card-body table-responsive">
    <!-- <table datatable [dtOptions]="dtOptions" class="table table-striped table-bordered row-border hover">
      <thead>
        <tr>
          <th>#</th>
          <th>Fecha Reg.</th>
          <th>Fecha Accidente</th>
          <th>Nombre</th>
          <th>Placa Vehiculo Asegurado</th>
          <th>Analista de radicación</th>
          <th>Presupuesto</th>
          <th>Siniestro</th>
          <th>Amparo a Afectar</th>
          <th>Ciudad</th>
          <th>Concesionario de origen</th>
          <th>Nombre quien gestiona</th>
          <th style="width: 1px;">Acción</th>
        </tr>
      </thead>
      <tbody *ngIf="recordings?.length > 0">
        <tr *ngFor="let recording of recordings">
          <td>{{ recording.id }}</td>
          <td>{{ recording.created_at_recording }}</td>
          <td>{{ recording.accident_date }}</td>
          <td>{{ recording.name }}</td>
          <td>{{ recording.plate_insured }}</td>
          <td>{{ recording.file_analyst }}</td>
          <td>{{ recording.budget }}</td>
          <td>{{ recording.sinister }}</td>
          <td>{{ recording.protection }}</td>
          <td>{{ recording.city }}</td>
          <td>{{ recording.home_dealership }}</td>
          <td>{{ recording.nameManages }}</td>
          <td>
            <a href="#" routerLink="/recording/{{recording.id}}"><i class="fas fa-edit"></i></a>
            <a *ngIf="profile_ID==1" href="#" style="color: red" (click)="deleteRecording($event, recording.id)"><i
                class="fas fa-minus-circle"></i></a>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="recordings?.length < 1">

      <tbody>
    </table> -->
    <nz-input-group [nzSuffix]="suffixIconSearch" [formGroup]="formpol">
      <input type="text" #searchText nz-input placeholder="Buscar coincidencias (Placa, Fecha reg, Nombre agente )"
        formControlName="value">
    </nz-input-group>
    <ng-template #suffixIconSearch>
      <i nz-icon nzType="search"></i>
    </ng-template>
    <nz-table [nzData]="listOfData.data" [nzFrontPagination]="false" [nzShowSizeChanger]="true"
      [nzTotal]="listOfData.total" (nzPageIndexChange)="loadData($event)" (nzPageSizeChange)="adjustPageSize($event)"
      nzLoading="{{loading}}">
      <thead>
        <tr>
          <th>#</th>
          <th>Fecha Reg.</th>
          <th>Fecha Accidente</th>
          <th>Nombre</th>
          <th>Placa Vehiculo Asegurado</th>
          <th>Analista de radicación</th>
          <th>Presupuesto</th>
          <th>Siniestro</th>
          <th>Amparo a Afectar</th>
          <th>Ciudad</th>
          <th>Concesionario de origen</th>
          <th>Nombre quien gestiona</th>
          <th style="width: 1px;">Acción</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let recording of listOfData.data">
          <td>{{ recording.id }}</td>
          <td>{{ recording.created_at_recording }}</td>
          <td>{{ recording.accident_date }}</td>
          <td>{{ recording.name }}</td>
          <td>{{ recording.plate_insured }}</td>
          <td>{{ recording.file_analyst }}</td>
          <td>{{ recording.budget }}</td>
          <td>{{ recording.sinister }}</td>
          <td>{{ recording.protection }}</td>
          <td>{{ recording.city }}</td>
          <td>{{ recording.home_dealership }}</td>
          <td>{{ recording.nameManages }}</td>
          <td>
            <a href="#" routerLink="/recording/{{recording.id}}"><i class="fas fa-edit"></i></a>
            <a *ngIf="profile_ID==1" href="#" style="color: red" (click)="deleteRecording($event, recording.id)"><i
                class="fas fa-minus-circle"></i></a>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>
