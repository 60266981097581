import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { StateInsuranceService } from 'src/app/Services/state-insurance/state-insurance.service';
import { SwalService } from 'src/app/Services/swal/swal.service';
import Swal from 'sweetalert2';

class ramo{
  id?: any;
  ramo: string;
}

@Component({
  selector: 'app-state-insurance-form',
  templateUrl: './state-insurance-form.component.html',
  styleUrls: ['./state-insurance-form.component.css']
})

export class StateInsuranceFormComponent implements OnInit {

  ramos: ramo[];

  formStateInsurance: FormGroup = new FormGroup({
    id: new FormControl('',),
    nombres: new FormControl('', [Validators.required]),
    apellidos: new FormControl('', [Validators.required]),
    presupuesto: new FormControl('', [Validators.required]),
    placa: new FormControl('', [Validators.required]),
    analista: new FormControl('', [Validators.required]),
    ramo_id: new FormControl('', [Validators.required])
  })

  constructor(
    private swalService: SwalService,
    private StateInsurance: StateInsuranceService,
  ) { }

  ngOnInit(): void {
    this.ListarRamo();
  }
  
  /**
   * Método para crear los seguros del estado con SweetAlert independiente. 
   * @returns Retorna una respuesta de exito o error.
   * @author Esteban Rodriguez <johan.rodriguez@holdingvml.com>
   */
  CrearSegurosEstado(): void {
    this.StateInsurance.postStateInsurance(this.formStateInsurance.value).subscribe(
      data => {
        Swal.fire({
          title: 'Registrado exitosamente.',
          text: 'Exito',
          heightAuto: false,
          allowOutsideClick: false,
          confirmButtonText: 'Ok',
          icon: 'success'
        })
      },
      err => this.swalService.error(err)
      )
    }

    /**
     * Método para listar el Ramo. 
     * @returns Se retorna resultado por parametro data de lo que obtega de ramos.
     * @author Esteban Rodriguez <johan.rodriguez@holdingvml.com>
     */
    ListarRamo(): void {
      this.StateInsurance.obtenerRamo().subscribe(data => {
        this.ramos = data;
        }
      )
    }

}
