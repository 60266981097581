<div class="mt-4 col-9 offset-2">
  <div class="card">
    <div class="card-header header-color" *ngIf="!form.id">Registre Aquí</div>
    <div class="card-header header-color" *ngIf="form.id">Modificar</div>
    <div class="card-body">
      <form #newForm="ngForm" (ngSubmit)="onSubmit()" id="formnew">
        <div class="form-group row">
          <label for="inputTitle" class="col-sm-3 col-form-label">Titulo</label>
          <div class="col-sm-9">
            <input type="text" name="inputTitle" class="form-control" id="inputTitle" placeholder="Titulo"
              [(ngModel)]="form.title" required>
            <div class="text-danger" [hidden]="!error.title">
              {{error.title}}
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="inputSubtitle" class="col-sm-3 col-form-label">Subtitulo</label>
          <div class="col-sm-9">
            <input type="text" name="inputSubtitle" class="form-control" id="inputSubtitle" placeholder="Subtitulo"
              [(ngModel)]="form.subtitle" required>
            <div class="text-danger" [hidden]="!error.subtitle">
              {{error.subtitle}}
            </div>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-5">
            <textarea name="inputPoint" class="form-control" id="inputPoint" rows="2" placeholder="Cuerpo"
              [(ngModel)]="form.point"></textarea>
          </div>
          <div class="col-sm-1">
            <a href="#" (click)="addPoint($event)" class="btn"><i class="fas fa-arrow-right"></i></a>
          </div>
          <div class="col-sm-6">
            <table class="table-sm table-striped table table-bordered">
              <tr *ngIf="form.news_point.length < 1">
                <th>Vacío</th>
              </tr>
              <tr *ngFor="let point of form.news_point">
                <td width="90%">{{ point.point }}</td>
                <td>
                  <a href="#" style="color: red" (click)="removePoint(point.id, $event)" *ngIf="point.id">
                    <i class="fas fa-eraser"></i>
                  </a>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-12">
            <button type="submit" class="btn btn-primary" [disabled]="!newForm.valid"
              *ngIf="!form.id">Registrar</button>
            <button type="submit" class="btn btn-primary" [disabled]="!newForm.valid"
              *ngIf="form.id">Actualizar</button>
            <a href="#" class="float-right btn btn-info" routerLink="/news">Volver</a>
          </div>
        </div>
      </form>
      <hr *ngIf="form.id">
      <nz-collapse nzGhost *ngIf="form.id">
        <nz-collapse-panel nzHeader="Cargar Documento (opcional)">
          <p style="text-align: center;"><strong>En este campo puede agregar Imagen y Archivo a la
              novedad.&nbsp;</strong></p>
          <form [formGroup]="formImg"  (ngSubmit)="onSubmitImg()">
            <div class="form-group row">
              <label for="newsimg" class="col-sm-3 col-form-label">Imagen</label>
              <div class="col-sm-7">
                <input nz-input type="file" (change)="uploadImg($event)" name="newsimg" class="form-control"
                  id="newsimg" placeholder="Imagen" formControlName="img">
              </div>
              <div class="col-sm-2">
                <button type="submit" [disabled]="activeImg" style="background-color: rgb(170, 96, 173);" class="btn btn-primary">Agregar</button>
              </div>
            </div>
          </form>
          <form [formGroup]="formFile" (ngSubmit)="onSubmitfile()">
            <div class="form-group row">
              <label for="newsfile" class="col-sm-3 col-form-label">Archivo</label>
              <div class="col-sm-7">
                <input nz-input type="file" (change)="uploadFile($event)" name="newsfile" class="form-control"
                  id="newsfile" placeholder="Archivo" formControlName="file">
              </div>
              <div class="col-sm-2">
                <button type="submit" [disabled]="activeFile" style="background-color: rgb(170, 96, 173);" class="btn btn-primary">Agregar</button>
              </div>
            </div>
          </form>
        </nz-collapse-panel>
      </nz-collapse>

    </div>
  </div>
</div>
