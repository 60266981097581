<div class="row" style="justify-content: center;">
  <h1>Llamadas de otro índole</h1>
  <button nz-button [nzType]="'primary'" (click)="modalRegister.setVisibility(true)"><span>Registrar
      Llamada</span></button>
  <nz-modal [(nzVisible)]="modalRegister.isVisible" nzTitle="Registrar Llamada"
    (nzOnCancel)="modalRegister.handleCancel()" (nzOnOk)="modalRegister.handleOk()" [nzWidth]="'80%'" [nzFooter]="null"
    >
    <ng-container *nzModalContent>
      <form #otherServiceForm="ngForm" (ngSubmit)="onSubmit()">
        <div class="row form-group">
          <div class="col-md-3 offset-1">
            <label for="">Otro servicio</label>
            <select name="name" id="name" class="form-control" required [(ngModel)]="form.service">
              <option value="">Seleccionar servicio</option>
              <option *ngFor="let otherservice of otherservices" value="{{ otherservice.id }}">{{ otherservice.name }}
              </option>
            </select>
          </div>
          <div class="col-md-3">
            <label for="departament">Departamento</label>
            <select name="departament" id="departament" class="form-control" required (change)="fillCitys()"
              [(ngModel)]="form.citys_departament_id">
              <option value="">Seleccionar departamento</option>
              <option *ngFor="let departament of departaments" value="{{ departament.id }}">{{ departament.name }}
              </option>
            </select>
          </div>
          <div class="col-md-3">
            <label for="city">Ciudad</label>
            <select name="city" id="city" class="form-control" required [(ngModel)]="form.citys_cod_mun">
              <option value="">Seleccionar ciudad</option>
              <option *ngFor="let city of citys" value="{{ city.id }}">{{ city.name }}</option>
            </select>
          </div>
          <div class="col-md-3 offset-1">
            <label for="">Observación</label>
            <textarea required placeholder="Agregar observación" name="obs" id="obs" cols="30" rows="4"
              [(ngModel)]="form.observation" placeholder="Agregar observación" class="form-control">
                        </textarea>
          </div>
          <div class="col-md-3">
            <label for="cliente">Cliente</label>
            <select name="cliente" id="cliente" class="form-control" required [(ngModel)]="form.id_client">
              <option value="">Seleccionar cliente</option>
              <option *ngFor="let cliente of clients" value="{{ cliente.id }}">{{ cliente.name }}</option>
            </select>
          </div>
          <div class="col-md-3">
            <label for="phonenumbers">Teléfono</label>
            <input name="phonenumbers" id="phonenumbers" type="number" [(ngModel)]="form.phonenumbers"
              class="form-control" required placeholder="Teléfono">
          </div>
          <div class="col-md-3 offset-1" style="padding-top: 30px;">
            <label for=""></label>
            <button class="btn btn-primary form-control" [disabled]="!otherServiceForm.valid" (click)="modalRegister.handleCancel();"> Agregar</button>
          </div>
        </div>
      </form>
    </ng-container>
  </nz-modal>
</div>
<div>

  <div class="col-md-6 offset-2" style="justify-content: center;">
    <nz-input-group [nzSuffix]="suffixIconSearch" [formGroup]="formpol">
      <input type="text" #searchText nz-input placeholder="Buscar coincidencias (Servicio, Recibio, Cliente, Teléfono)"
        formControlName="value">
    </nz-input-group>
    <ng-template #suffixIconSearch>
      <i nz-icon nzType="search"></i>
    </ng-template>
  </div>
  <div class="col-md-10 offset-0">

    <nz-table #basicTable id="indexTable" [nzData]="listOfData.data" [nzFrontPagination]="false" [nzTotal]="listOfData.total"
      (nzPageIndexChange)="loadData($event)" [nzScroll]="{ x: '1200px', y: '320px' }" [nzLoading]="loading">
      <thead>
        <tr>
          <th nzLeft nzWidth="70px" *ngIf="profile_ID==1">Borrar</th>
          <th nzLeft nzWidth="90px"># Registro</th>
          <th nzLeft nzWidth="130px">Servicio</th>
          <th>Departamento</th>
          <th>Ciudad</th>
          <th nzWidth="100px">Observación</th>
          <th>Recibio</th>
          <th nzWidth="100px">Fecha</th>
          <th nzWidth="90px" nzRight>Cliente</th>
          <th nzWidth="90px" nzRight>Teléfono</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let other of listOfData.data">
          <td nzLeft *ngIf="profile_ID==1">
            <button (click)="deleteOtherCall(other.id)" nz-button nzType="dashed" [nzSize]="size" nzShape="circle">
              <span nz-icon [nzType]="'delete'" nzTheme="twotone"></span>
            </button>
          </td>
          <td nzLeft>{{ other.id }}</td>
          <td nzLeft>{{ other.name }}</td>
          <td>{{ other.departament }}</td>
          <td>{{ other.city }}</td>
          <td>
            <button nz-button class="buttonGbg" (click)="modalRegister.showModal(other.observation)">></button>
          </td>
          <td>{{ other.received }}</td>
          <td>{{ other.created_at }}</td>
          <td nzRight>{{ other.client }}</td>
          <td nzRight>{{ other.phonenumbers }}</td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>