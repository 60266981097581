<div class="mt-4 col-8 offset-2">
    <div class="card">
        <div class="card-header header-color" *ngIf="!form.id">Register Here</div>
        <div class="card-header header-color" *ngIf="form.id">Update Here</div>
        <div class="card-body">
            <form #beneficiarieForm="ngForm" (ngSubmit)="onSubmit()">
                <div class="form-group row">
                    <label for="inputNit" class="col-sm-2 col-form-label">Nit</label>
                    <div class="col-sm-10">
                        <input type="text" name="nit" class="form-control" id="inputNit" 
                            placeholder="Nit" [(ngModel)]="form.nit" required>
                        <div class="text-danger" [hidden]="!error.nit">
                            {{error.nit}}
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="inputName" class="col-sm-2 col-form-label">Nombre</label>
                    <div class="col-sm-10">
                        <input type="text" name="name" class="form-control" id="inputName" 
                            placeholder="Nombre" [(ngModel)]="form.name" required>
                        <div class="text-danger" [hidden]="!error.name">
                            {{error.name}}
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="inputAddress" class="col-sm-2 col-form-label">Dirección</label>
                    <div class="col-sm-10">
                        <input type="text" name="address" class="form-control" id="inputAddress" 
                            placeholder="Dirección" [(ngModel)]="form.address" required>
                        <div class="text-danger" [hidden]="!error.address">
                            {{error.address}}
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="inputPhone" class="col-sm-2 col-form-label">Telefono</label>
                    <div class="col-sm-10">
                        <input type="number" name="phone" class="form-control" id="inputPhone" 
                            placeholder="Teléfono" [(ngModel)]="form.phone" required>
                        <div class="text-danger" [hidden]="!error.phone">
                            {{error.phone}}
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="selectClient" class="col-sm-2 col-form-label">Cliente</label>
                    <div class="col-sm-10">
                        <select class="form-control" name="selectClient" id="selectClient" [(ngModel)]="form.client" required>
                            <option value="">Seleccionar...</option>
                            <option *ngFor="let client of clients" value={{client.id}}>{{ client.name }}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-10 offset-2">
                        <button type="submit" class="btn btn-primary" [disabled]="!beneficiarieForm.valid" *ngIf="!form.id">Registrar</button>
                        <button type="submit" class="btn btn-primary" [disabled]="!beneficiarieForm.valid" *ngIf="form.id">Actualizar</button>
                        <a href="#" class="float-right btn btn-info" routerLink="/beneficiaries">Volver</a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>