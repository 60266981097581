import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenService } from '../token.service';
import { defer, Observable, of, Subject } from 'rxjs';
import { Coordinator } from 'src/app/models/coordinator';
import { map, mergeAll, shareReplay, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Injectable({
  providedIn: 'root'
})
export class RowsFciiService {
  private baseUrl = environment.url;

  /**
   * Variable para refrescar datos en cache lista de coordinadores
   *
   * @author Joe Alejandro Sierra <joe.sierra@holdingvml.net> 2022-05-26
   */
  private readonly refreshCoordinatorSubject = new Subject();

  /**
   * Variable para crear observable a partir de unión de observables, con el cual se podrá consultar el api de coordinadores.
   * Esta varibale será usada como cache para mantener la información de coordinadores.
   * Para actualizar la información se deberá usar refreshCoordinatorSubject, evitando recargar la página.
   *
   * @author Joe Alejandro Sierra <joe.sierra@holdingvml.net> 2022-05-26
   */
  coordinators: Observable<Coordinator[]> = of(
    this.getCoordinators([]).pipe(
      map((e) => e.data)
    ),
    defer(() => this.refreshCoordinatorSubject.pipe(
      switchMap(() => this.getCoordinators([]).pipe(
        map((e) => e.data)
      )))
    )
  ).pipe(
    mergeAll(),
    shareReplay(1),
  );

  header(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer `+this.token.get())
    }
  }

  constructor(private http:HttpClient, private token: TokenService) { }

  getFcii(dataTablesParameters){
    return this.http.post<DataTablesResponse>(`${this.baseUrl}/fcii`, dataTablesParameters, this.header());
  }

  select(data){
    return this.http.post(`${this.baseUrl}/fcii/select`, data, this.header());
  }

  update(data){
    return this.http.post(`${this.baseUrl}/fcii/update`, data, this.header());
  }

  getCoordinators(data){
    return this.http.post<DataTablesResponse>(`${this.baseUrl}/coordinators`, data, this.header());
  }
  getLawyerByFirm(data){
    return this.http.post<DataTablesResponse>(`${this.baseUrl}/lawyerByFirm`, data, this.header());
  }

}
