import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenService } from '../token.service';
import { environment } from 'src/environments/environment';

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Injectable({
  providedIn: 'root'
})
export class RowsAsistaService {

  private baseUrl = environment.url;

  header(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer `+this.token.get())
    }
  }

  constructor(private http:HttpClient, private token: TokenService) { }

  getAsista(dataTablesParameters){
    return this.http.post<DataTablesResponse>(`${this.baseUrl}/asistas`, dataTablesParameters, this.header());
  }

  select(data){
    return this.http.post(`${this.baseUrl}/asista/select`, data, this.header());
  }

  update(data){
    return this.http.post(`${this.baseUrl}/asista/update`, data, this.header());
  }

  getFirms(data){
    return this.http.post<DataTablesResponse>(`${this.baseUrl}/firmsLawyers`, data, this.header());
  }

 /*  getAllFirms(data){
    return this.http.get<DataTablesResponse>(`${this.baseUrl}/allLawyerFirm`, data, this.header());
  } */
  getAllFirms(){
    return this.http.get<DataTablesResponse>(`${this.baseUrl}/allLawyerFirm`, this.header());
  }

  getLawyerByFirm(data){
    return this.http.post<DataTablesResponse>(`${this.baseUrl}/lawyerByFirm`, data, this.header());
  }

  deleteLawyerFirm(data){
    return this.http.put<DataTablesResponse>(`${this.baseUrl}/deleteLawyerFirm?id=${data}`, this.header());
  }

  updateLawyerFirm(data){
    return this.http.put<DataTablesResponse>(`${this.baseUrl}/updateLawyerFirm?id=${data}`, this.header());
  }



}
