import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenService } from 'src/app/Services/token.service';
import { Router } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { LawyersService } from 'src/app/Services/lawyers/lawyers.service';
import { SwalService } from 'src/app/Services/swal/swal.service';
import { FormControl, FormGroup } from '@angular/forms';
class Lawyer {
  id: number;
  name: string;
  phone: number;
  firm: string;
}

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}
@Component({
  selector: 'app-lawyer-rows',
  templateUrl: './lawyer-rows.component.html',
  styleUrls: ['./lawyer-rows.component.css']
})
export class LawyerRowsComponent implements OnInit {

  lawyers: Lawyer[];
  dtOptions: DataTables.Settings;
  private idDelete;
  pageSize : number = 10
  listOfData: { data: Lawyer[], total: number, current_page:number } = {} as any
  loading: boolean = false;

  formpol: FormGroup = new FormGroup({
    value: new FormControl('')
  })


  constructor(
    private http: HttpClient,
    private token: TokenService,
    private router: Router,
    private LawyerService: LawyersService,
    private swalService: SwalService,
    private notify: SnotifyService
  ) { }

  async deleteUser(e: MouseEvent, id){
    e.preventDefault();
    let data = {
      id: id
    }
    this.idDelete = data.id;
    let option = await this.swalService.swalAlert("Advertencia", "¿Está seguro de que quiere eliminar este registro?", 'warning');
    if(option){
      this.notify.info('Espere...', {timeout: 0});
      this.LawyerService.delete(data).subscribe(
        res => this.handleResponse(data.id),
        err => this.handleError(err)
      );
    }
  }

  processDelete(){
    let c = 0;
    this.lawyers.forEach((element)=>{
        if(element.id == this.idDelete){
          this.lawyers.splice(c, 1);
          this.idDelete = null;
        }
        c++;
    });
  }
  handleResponse(data){
    this.notify.remove()
    this.ngOnInit()
  }



  handleError(err){

  }

  adjustPageSize(pageSize : number): void{
    this.pageSize = pageSize;
    this.loadData();
  }

  /* loadTable(){
    const that = this;
    this.dtOptions = {
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
          this.LawyerService.getLawyers(dataTablesParameters).subscribe(resp => {
            if(!resp.data){
              this.token.remove();
              this.router.navigateByUrl('/login');
              setTimeout(()=>{
                window.location.reload();
              }, 100);
            }
            that.lawyers = resp.data;
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
      },
      columns: [{ data: 'name' }, { data: 'phone' }, { data: 'firm' }, { data: 'action' }],
      "language": {
        "url": "https://cdn.datatables.net/plug-ins/1.10.20/i18n/Spanish.json"
    }
    };
  } */
  ngOnInit(): void {
    //this.loadTable();
    this.loadData()
    this.formpol.controls['value'].valueChanges.subscribe(
      data => this.loadData(1, data)
    )
  }

  loadData(current_page: number = 1, value: string = ''): void {
    this.loading = true
    this.LawyerService.getLawyersNgzorro(current_page, value,this.pageSize).subscribe(data => {
      this.listOfData = data;
      this.loading = false
    }
    )
  }
}
