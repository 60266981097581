<div class="mt-4 col-8 offset-2">
    <div class="card">
        <div class="card-header">Send Email for Reset password</div>
        <div class="card-body">
            <form #requestResetForm="ngForm" (ngSubmit)="onSubmit()">
                <div class="form-group row">
                    <label for="inputEmail3" class="col-sm-2 col-form-label">Email</label>
                    <div class="col-sm-10">
                    <input type="email" name="email" class="form-control" id="inputEmail3" 
                            placeholder="Email" [(ngModel)]="form.email" required>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-10 offset-2">
                        <button type="submit" class="btn btn-primary" [disabled]="!requestResetForm.valid">Send Password Reset Email</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>