export const object = {
    id: '',
    plate: 'PLACA',
    name: 'NOMBRE',
    id_type: 'TIPO DOCUMENTO',
    typePolicy: 'TIPO POLIZA',
    document_number: 'NUMERO DOCUMENTO',
    policy: 'POLIZA',
    phone: 'TELEFONO',
    address: 'DIRECCION',
    company: 'BENEFICIARIO',
    interestedName: 'NOMBRE INTERESADO',
    interestedPhone: 'TELEFONO INTERESADO',
    typeSinister: 'TIPO SINIESTRO',
    accidentAddress: 'DIRECCION ACCIDENTE',
    currentAddress: 'DIRECCION ACTUAL',
    citys_departament_id: 'DEPARTAMENTO',
    citys_cod_mun: 'CIUDAD',
    observations: 'OBSERVACIONES',
    client: 'CLIENTE',
    case_state: 'ESTADO CASO',
    asista: 'ASISTA',
    fcii: 'FCII',
    digipat: 'DIGIPAT',
    pwa: 'PWA',
    movil: 'MOVIL',
    user: 'USUARIO',
    typeAccident: 'TIPO ACCIDENTE',
    nameCaseState: 'ESTADO CASO',
    registration_date: 'FECHA DE REGISTRO',
    end_date: 'FECHA FINAL',
    comment: 'COMENTARIO'
}