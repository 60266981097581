import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TokenService } from './token.service';


@Injectable({
  providedIn: 'root'
})
export class CountAuroraService {
  header(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer `+this.token.get())
    }
  }

  constructor(private http:HttpClient, private token: TokenService) { }
  private baseUrl = environment.url;
  getRowCount()  {
    return this.http.get(`${this.baseUrl}/getRowsaurora`, this.header());

  }
  set(data){
    localStorage.setItem('dtAurora', data);
  }

  get(){
    return localStorage.getItem('dtAurora');
  }
}
