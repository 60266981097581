<div class="col-sm-8">
    <div class="card" style="margin-left: 1px">
        <div class="card-header header-color">
            <h6>REPORTE DE CASOS REGISTRADOS</h6>
        </div>
        <div class="card-body">
            <form #reportForm="ngForm" (ngSubmit)="generateExcel()" class="form-inline">
                <div class="form-group mb-2">
                    <label for="staticDate3" class="sr-only">Date</label>
                    <input type="text" readonly class="form-control-plaintext" id="staticDate3" value="SELECCIONAR RANGO">
                  </div>
                  <div class="form-group mx-sm-3 mb-2">
                    <label for="inputMonth2" class="sr-only">Month</label>
                    <input type="Month" class="form-control" id="inputMonth2" placeholder="Month"
                        [(ngModel)]="form.date" [ngModelOptions]="{standalone: true}" required>
                    <small *ngIf="error.date" class="text-danger">{{ error.date }}</small>
                  </div>
                <button [disabled]="!reportForm.valid" class="btn btn-success mb-2">Generate Excel</button>
            </form>      
        </div>
    </div> 
</div>
<br>
<div class="col-sm-8">
    <div class="card" style="margin-left: 1px">
        <div class="card-header header-color">
            <h6>REPORTE DE LLAMADAS DE OTRA ÍNDOLE</h6>
        </div>
        <div class="card-body">
            <form #reportFormOtherNature="ngForm" (ngSubmit)="generateExcelOtherNature()" class="form-inline">
                <div class="form-group mb-2">
                    <label for="staticDate2" class="sr-only">Date</label>
                    <input type="text" readonly class="form-control-plaintext" id="staticDate2" value="SELECCIONAR RANGO">
                  </div>
                  <div class="form-group mx-sm-3 mb-2">
                    <label for="inputMonth3" class="sr-only">Month</label>
                    <input type="Month" class="form-control" id="inputMonth3" placeholder="Month"
                        [(ngModel)]="formOtherNature.date" [ngModelOptions]="{standalone: true}" required>
                    <small *ngIf="errorOtherNature.date" class="text-danger">{{ errorOtherNature.date }}</small>
                  </div>
                <button [disabled]="!reportFormOtherNature.valid" class="btn btn-info mb-2">Generate Excel</button>
            </form>      
        </div>
    </div> 
</div>
<br>
<div class="col-sm-8">
    <div class="card" style="margin-left: 1px">
        <div class="card-header header-color">
            <h6>REPORTE DE GRABACIONES SINIESTRO</h6>
        </div>
        <div class="card-body">
            <form #reportFormRecordings="ngForm" (ngSubmit)="generateExcelRecordings()" class="form-inline">
                <div class="form-group mb-2">
                    <label for="staticDate1" class="sr-only">Date</label>
                    <input type="text" readonly class="form-control-plaintext" id="staticDate1" value="SELECCIONAR RANGO">
                  </div>
                  <div class="form-group mx-sm-3 mb-2">
                    <label for="inputMonth1" class="sr-only">Month</label>
                    <input type="Month" class="form-control" id="inputMonth1" placeholder="Month"
                        [(ngModel)]="formRecordings.date" [ngModelOptions]="{standalone: true}" required>
                    <small *ngIf="errorRecordings.date" class="text-danger">{{ errorRecordings.date }}</small>
                  </div>
                  <div class="form-group mx-sm-3 mb-2">
                    <label for="type" class="sr-only">Estado</label>
                    <select required class="form-control" name="type" id="type" [(ngModel)]="formRecordings.state" [ngModelOptions]="{standalone: true}">
                        <option value="1" selected>GRABADO</option>
                        <option value="2">PENDIENTE</option>
                    </select>
                </div>
                <button [disabled]="!reportFormRecordings.valid" class="btn btn-warning mb-2">Generate Excel</button>
            </form>      
        </div>
    </div> 
</div>