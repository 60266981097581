import { Injectable } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NewsBodyComponent } from 'src/app/components/news/news-body/news-body.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(private modalService: NzModalService) { }

  isVisible = false;

  showModal(observation: string): void {
    this.modalService.create({
      nzTitle: 'Observación',
      nzContent: observation,
      nzFooter: null
    });
  }
  showModalNewsd(observation: string): void {
    this.modalService.create({
      nzTitle: 'Cuerpo',
      nzContent: observation,

    });
  }

  showModalNews(newsPoints: any[],title:string): void {
    this.modalService.create({
      nzTitle: 'Cuerpo Novedad ->('+title+')',
      nzContent: NewsBodyComponent,
      nzComponentParams: {
        newsPoints: newsPoints
      },
      nzFooter: null,
      nzClosable: false
    });
  }


  setVisibility(visibility: boolean) {
    this.isVisible = visibility;
  }

  handleOk(): void {
    this.isVisible = false;
  }

  handleCancel(): void {
    this.isVisible = false;
  }

}
