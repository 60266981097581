import { Component, OnInit } from '@angular/core';
import { TokenService } from 'src/app/Services/token.service';
import { Router } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { AuthService } from 'src/app/Services/auth.service';

import * as moment from 'moment'; // add this 1 of 4
import { RowsAsistaService } from 'src/app/Services/report-accidents/rows-asista.service';
import { SwalService } from 'src/app/Services/swal/swal.service';

class Asista {
  id: number;
  plate: string;
  company: string;
  currentAddress: string;
  typeSinister: string;
  case_state: string;
  received_call: string;
  registration_date;
  time = 0;
  color: string;
  name: string;
}

@Component({
  selector: 'app-rows-asista',
  templateUrl: './rows-asista.component.html',
  styleUrls: ['./rows-asista.component.css']
})
export class RowsAsistaComponent implements OnInit {

  asistas: Asista[];
  dtOptions: DataTables.Settings;
  private idDelete;


  constructor(
    private token: TokenService,
    private router: Router,
    private notify: SnotifyService,
    private AsistaService: RowsAsistaService,
    private swalService: SwalService,
    private Auth: AuthService
  ) { }

  async deleteUser(e: MouseEvent, id){
    e.preventDefault();
    let data = {
      id: id
    }
    this.idDelete = data.id;
    let option = await this.swalService.swalAlert("Advertencia", "¿Está seguro de que quiere eliminar este registro?", 'warning');
    if(option){
      this.notify.info('Espere...', {timeout: 0});
      /*this.GeneralService.deleteUser(data).subscribe(
        res => this.handleResponse(data.id),
        err => this.handleError(err)
      );*/
    }
  }

  processDelete(){
    let c = 0;
    this.asistas.forEach((element)=>{
        if(element.id == this.idDelete){
          this.asistas.splice(c, 1);
          this.idDelete = null;
        }
        c++;
    });
  }

  updateTime(){
    setInterval(()=>{
      this.asistas.forEach((element)=>{
        element.name !== 'PROCESO' ? element.color = 'success' : element.color = 'danger'; 
      });
    }, 1000);
  }

  handleResponse(data){
    this.notify.remove();
    this.processDelete(); // Remove row of table.
    this.notify.confirm('Done!', {
      buttons: [
        {
        text: 'Okay', 
        action: toster => { 
          this.notify.remove(toster.id)
        }
      }
      ]
    })
  }

  handleError(err){

  }

  loadTable(){
    const that = this;
    this.dtOptions = {
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        that.AsistaService.getAsista(dataTablesParameters).subscribe(resp => {
            if(!resp.data){
              this.logout();
            }else{
              this.updateTime();
            }
            that.asistas = resp.data;
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
      },
      columns: [
        { data: 'idCase' }, { data: 'plate' }, 
        { data: 'firm' }, { data: 'lawyer' },
        { data: 'contactTime' }, { data: 'arrivalTime' }, 
        { data: 'endTime' }, { data: 'name' },
        { data: 'action' }
      ],
      "columnDefs": [ {
        "targets": [0, 1, 2, 3, 4, 5, 6, 7, 8],
        "orderable": false
        } ],
      "language": {
        "url": "https://cdn.datatables.net/plug-ins/1.10.20/i18n/Spanish.json"
    }
    };
  }
  ngOnInit(): void {
    this.loadTable();
  }

  logout(){
    this.token.remove();
    this.Auth.changeAuthStatus(false);
    this.router.navigateByUrl('/login');
  }
}
