import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SwalService } from '../Services/swal/swal.service';

@Injectable()
export class ErrorCatchingInterceptor implements HttpInterceptor {

  constructor(
    private swalService: SwalService
  ) {
  }

  /**
   * Método para interceptar errores de peticiones
   *
   * @param request
   * @param next
   * @returns
   */
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
      let res = next.handle(request).pipe(
        catchError(error => {
          error.status == 401 ? this.swalService.error401() : ''
          return throwError(error)
        })
      )
      return res
  }
}
