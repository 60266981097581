import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenService } from 'src/app/Services/token.service';
import { Router } from '@angular/router';
import { UserService } from 'src/app/Services/users/user.service';
import { SnotifyService } from 'ng-snotify';
import { SwalService } from 'src/app/Services/swal/swal.service';
import { FormControl, FormGroup } from '@angular/forms';

class user{
    id: number;
    name: string;
    email: string;
    profile_ID: string;
    state: number;
    stateName: string
 }

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  dtOptions: DataTables.Settings
  private idDelete
  pageSize : number = 10
  listOfData: { data: user[], total: number, current_page:number } = {} as any
  loading: boolean = false;

  formpol: FormGroup = new FormGroup({
    value: new FormControl('')
  })


  constructor(
    private http: HttpClient,
    private token: TokenService,
    private router: Router,
    private UserService: UserService,
    private swalService: SwalService,
    private notify: SnotifyService
  ) { }

  async deleteUser(e: MouseEvent, id){
    e.preventDefault();
    let data = {
      id: id
    }
    this.idDelete = data.id;
    let option = await this.swalService.swalAlert("Advertencia", "¿Está seguro de inactivar este usuario?", 'warning');
    if(option){
      //this.notify.info('Espere...', {timeout: 0});
      this.UserService.deleteUser(this.token.get(), data).subscribe(
        res => this.handleResponse(data.id),
        err => this.handleError(err)
      );
    }
  }



  handleResponse(data){
    this.notify.remove()
    this.ngOnInit()
  }

  handleError(err){
    console.log(err)
  }

  adjustPageSize(pageSize : number): void{
    this.pageSize = pageSize;
    this.loadData();
  }

  /* loadTable(){
    const that = this;

    this.dtOptions = {
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.UserService.getUsers(dataTablesParameters, this.token.get()).subscribe(resp => {
            that.persons = resp.data;
            console.log(that.persons)
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
      },
      columns: [{ data: 'name' }, { data: 'email' }, { data: 'profile_ID' }, { data: 'stateName' }],
      "columnDefs": [{
        "targets": [0, 1, 2, 3],
        'orderable': false
      }],
      "language": {
        "url": "https://cdn.datatables.net/plug-ins/1.10.20/i18n/Spanish.json"
    }
    };
  } */
  ngOnInit(): void {
    //this.loadTable();
    this.loadData()
    this.formpol.controls['value'].valueChanges.subscribe(
      data => this.loadData(1, data)
    )
  }

  loadData(current_page: number = 1, value: string = ''): void {
    this.loading = true
    this.UserService.getUsersngzorro(current_page, value,this.pageSize).subscribe(data => {
      this.listOfData = data;
      this.loading = false
    }
    )
  }
}
