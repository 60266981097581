<div class="wrapper fadeInDown">
    <div id="formContent">
        <div id="contenido">
            <a class="hiddenanchor" id="signup"></a>
            <a class="hiddenanchor" id="signin"></a>
            <div class="login_wrapper">
                <div class="animate form login_form">
                    <section class="login_content">
                        <form #loginForm="ngForm" (ngSubmit)="onSubmit()">
                            <br>
                            <h2 class="inactive underlineHover">Iniciar sesión - Kronos</h2>
                                <div class="text-danger" [hidden]="!error">
                                    {{error}}
                                </div>
                            <div>
                                <input type="text" class="form-control" autocomplete="username" placeholder="Username" [(ngModel)]="form.email" name="username" required/>
                            </div>
                            <div>
                                <input type="password" class="form-control"  autocomplete="current-password" placeholder="Password"  name="password" [(ngModel)]="form.password" required/>
                            </div>
                            <div>
                                <input type="submit" [disabled]="!loginForm.valid"
                                        value="Ingresar" style="margin: 0px auto; margin-bottom: 20px; margin-top: 20px;">
                                        <small class=float-center>
                                            <a routerLink="/request-password-reset">Recuperar contraseña</a>
                                        </small>
                            </div>
                            <div class="clearfix"></div>
                            <div class="separator">
                                <div class="clearfix"></div>
                                <br/>
                                <div>
                                    <h2><i class="fa fa-home"></i> Sistema de Gestión Asistencial</h2>
                                    <p>©2019 All Rights Reserved.</p>
                                    <p>Development Team Holding Vml</p>
                                </div>
                            </div>
                        </form>
                    </section>
                </div>
            </div>
        </div>
    </div>
</div>
