import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';
import { AfterLoginService } from './Services/after-login.service';
import { AuthService } from './Services/auth.service';
import { BeforeLoginService } from './Services/before-login.service';
import { JarwisService } from './Services/jarwis.service';
import { TokenService } from './Services/token.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ClientsComponent } from './components/clients/clients.component';
import { DirectoryComponent } from './components/directory/directory.component';
import { LoginComponent } from './components/login/login.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { RequestResetComponent } from './components/password/request-reset/request-reset.component';
import { ResponseResetComponent } from './components/password/response-reset/response-reset.component';
import { ProductsComponent } from './components/products/products.component';
import { ReportAccidentsComponent } from './components/report-accidents/report-accidents.component';
import { SignupComponent } from './components/signup/signup.component';
import { UsersComponent } from './components/users/users.component';

import { DataTablesModule } from 'angular-datatables';
import { ClientService } from './Services/clients/client.service';
import { UserService } from './Services/users/user.service';
import { BeneficiarieFormComponent } from './components/admin/beneficiarie-form/beneficiarie-form.component';
import { BeneficiariesComponent } from './components/admin/beneficiaries/beneficiaries.component';
import { ClientFormComponent } from './components/admin/client-form/client-form.component';
import { PersonsFormComponent } from './components/admin/persons-form/persons-form.component';
import { PersonsComponent } from './components/admin/persons/persons.component';
import { FormAsistaComponent } from './components/form-report-accident/form-asista/form-asista.component';
import { FormGeneralComponent } from './components/form-report-accident/form-general/form-general.component';
import { RowsAsistaComponent } from './components/report-accidents/rows-asista/rows-asista.component';
import { RowsDigipatComponent } from './components/report-accidents/rows-digipat/rows-digipat.component';
import { RowsFciiComponent } from './components/report-accidents/rows-fcii/rows-fcii.component';
import { RowsGeneralComponent } from './components/report-accidents/rows-general/rows-general.component';
import { RowsPwaComponent } from './components/report-accidents/rows-pwa/rows-pwa.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { CallsOtherNatureComponent } from './components/calls-other-nature/calls-other-nature.component';
import { FormDigipatComponent } from './components/form-report-accident/form-digipat/form-digipat/form-digipat.component';
import { FormFciiComponent } from './components/form-report-accident/form-fcii/form-fcii.component';
import { FormMovilComponent } from './components/form-report-accident/form-movil/form-movil/form-movil.component';
import { FormPwaComponent } from './components/form-report-accident/form-pwa/form-pwa.component';
import { HomeComponent } from './components/home/home/home.component';
import { MovilComponent } from './components/report-accidents/rows-movil/movil/movil.component';
import { ReportsComponent } from './components/reports/reports.component';

import { DatePipe, registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { NZ_I18N, es_ES } from 'ng-zorro-antd/i18n';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { AuroraCasesComponent } from './components/aurora-cases/aurora-cases.component';
import { ClaimFormComponent } from './components/claims/claim-form/claim-form.component';
import { ClaimsComponent } from './components/claims/claims.component';
import { CoordinatorsComponent } from './components/coordinators/coordinators.component';
import { FormCoordinatorsComponent } from './components/coordinators/form-coordinators/form-coordinators.component';
import { ErrorCaseComponent } from './components/error-case/error-case.component';
import { ErrorListComponent } from './components/error-list/error-list.component';
import { FirmFormComponent } from './components/firm-form/firm-form.component';
import { FirmListComponent } from './components/firm-list/firm-list.component';
import { LawyerFormComponent } from './components/lawyers/lawyer-form/lawyer-form.component';
import { LawyerRowsComponent } from './components/lawyers/lawyer-rows/lawyer-rows.component';
import { NewsBodyComponent } from './components/news/news-body/news-body.component';
import { NewsFormComponent } from './components/news/news-form/news-form.component';
import { NewsComponent } from './components/news/news.component';
import { OptionsFormComponent } from './components/options-form/options-form.component';
import { OptionsComponent } from './components/options/options.component';
import { ProfileComponent } from './components/profile/profile.component';
import { RecordingsFormComponent } from './components/recordings/recordings-form/recordings-form.component';
import { RecordingsComponent } from './components/recordings/recordings.component';
import { StateInsuranceFormComponent } from './components/state-insurance/state-insurance-form/state-insurance-form.component';
import { StateInsuranceComponent } from './components/state-insurance/state-insurance.component';
import { SurveyAccidentComponent } from './components/survey-accident/survey-accident.component';
import { TecniHubComponent } from './components/tecni-hub/tecni-hub.component';
import { ViewHubComponent } from './components/view-hub/view-hub.component';
import { WolkvoxComponent } from './components/wolkvox/wolkvox.component';
import { ErrorCatchingInterceptor } from './interceptors/error-catching.interceptor';
import { HeaderInterceptor } from './interceptors/header.interceptor';
import { NgZorrolModule } from './ngzorro-module';

registerLocaleData(es);
const config: SocketIoConfig = { url: 'https://sockets2.asistacasos.com/', options: {} };
const config2: SocketIoConfig = { url: 'https://sockets.test.asistacasos.com/', options: {} };
//const config2: SocketIoConfig = { url: 'http://localhost:3020/', options: {} };

export const MY_MOMENT_FORMATS = {
  parseInput: 'LL LT',
  fullPickerInput: 'YYYY-MM-DD HH:mm', /* <---- Here i've rewrited the format */
  datePickerInput: 'LL',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MM YYYY',
};

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    LoginComponent,
    SignupComponent,
    RequestResetComponent,
    ResponseResetComponent,
    UsersComponent,
    ProductsComponent,
    ClientsComponent,
    DirectoryComponent,
    ReportAccidentsComponent,
    BeneficiariesComponent,
    ClientFormComponent,
    BeneficiarieFormComponent,
    RowsGeneralComponent,
    RowsAsistaComponent,
    RowsFciiComponent,
    FormPwaComponent,
    RowsPwaComponent,
    RowsDigipatComponent,
    FormGeneralComponent,
    PersonsComponent,
    PersonsFormComponent,
    FormAsistaComponent,
    FormFciiComponent,
    FormDigipatComponent,
    HomeComponent,
    ReportsComponent,
    CallsOtherNatureComponent,
    MovilComponent,
    FormMovilComponent,
    ClaimsComponent,
    ClaimFormComponent,
    LawyerFormComponent,
    LawyerRowsComponent,
    RecordingsComponent,
    RecordingsFormComponent,
    SurveyAccidentComponent,
    CoordinatorsComponent,
    FormCoordinatorsComponent,
    OptionsComponent,
    OptionsFormComponent,
    NewsComponent,
    NewsFormComponent,
    FirmListComponent,
    FirmFormComponent,
    StateInsuranceComponent,
    StateInsuranceFormComponent,
    ErrorCaseComponent,
    ErrorListComponent,
    ProfileComponent,
    ViewHubComponent,
    WolkvoxComponent,
    AuroraCasesComponent,
    NewsBodyComponent,
    TecniHubComponent
    ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    SnotifyModule,
    DataTablesModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    BrowserAnimationsModule,
    OwlDateTimeModule,
    SocketIoModule.forRoot(config),
    SocketIoModule.forRoot(config2),
    ReactiveFormsModule,
    NgZorrolModule
  ],
  providers: [
    JarwisService, TokenService, AuthService, AfterLoginService, BeforeLoginService , ClientService, UserService,
    { provide: 'SnotifyToastConfig', useValue: ToastDefaults },
/*     { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS }, */
    SnotifyService,
    { provide: NZ_I18N, useValue: es_ES },DatePipe, //Formateo de fechas DatePipe
    { provide: HTTP_INTERCEPTORS, useClass: ErrorCatchingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})



export class AppModule { }
