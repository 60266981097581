import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { RequestService } from 'src/app/Services/request/request.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FormGroup, FormBuilder } from '@angular/forms';
@Component({
  selector: 'app-error-case',
  templateUrl: './error-case.component.html',
  styleUrls: ['./error-case.component.css']
  /**
 * Componente para registrar errores del sistema
 * @author Pablo Morales <pablo.morales@holdingvml.net>
 */
})
export class ErrorCaseComponent {
  form: FormGroup;
 //Variable que se recibe desde la componene padre
  @Input() title?: string;

  constructor(
    public fb: FormBuilder,
    private msg: NzMessageService,
    private RequestService: RequestService,
    private modal: NzModalRef
  ) {
    this.form = this.fb.group({
      nombres:[''],
      apellidos:[''],
      celular:[''],
      fecha:[''],
      placa:[''],
      cobertura:[''],
      state:['1'],
      descripcion:[''],
      img:[null],
    });
  }

   /**
   * Método para agregar nuevo error,
   * se crea una constante formData que será llenada por los valores del formulario
   * posteriormente se ejecut el método
   * @param data
   * @returns Modal con la intrucción requerida
   * @author Pablo Morales <pablo.morales@holdingvml.net>
   */
  onSubmit() {
    const formData = new FormData();
    formData.append('nombres', this.form.get('nombres').value);
    formData.append('apellidos', this.form.get('apellidos').value);
    formData.append('celular', this.form.get('celular').value);
    formData.append('fecha', this.form.get('fecha').value);
    formData.append('placa', this.form.get('placa').value);
    formData.append('cobertura', this.form.get('cobertura').value);
    formData.append('descripcion', this.form.get('descripcion').value);
    formData.append('state', this.form.get('state').value);
    formData.append('img', this.form.get('img').value);
    this.RequestService.createError(formData).subscribe();
    this.modal.destroy({ data: formData });
  }
  closeModal(): void {
    this.modal.close();
  }

   /**
   * Método para agregar archivo,
   * se crea una constante file que posteriomente se fija al campo img del formulario
   * posteriormente se ejecut el método
   * @param event
   * @author Pablo Morales <pablo.morales@holdingvml.net>
   */
  uploadFile(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.form.patchValue({
      img: file,
    });
    this.form.get('img').updateValueAndValidity();
  }

}
