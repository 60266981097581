import { data } from 'jquery';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenService } from '../token.service';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  public baseUrl = environment.url;

  header(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer `+this.token.get())
    }
  }

  constructor(private http:HttpClient, private token: TokenService) { }

  getNews(data): Promise<any>{
    return (this.http.post<DataTablesResponse>(`${this.baseUrl}/aXul0vWOitMXUdIjMt5R`, data, this.header()) as Observable<any>).toPromise();
  }

  getNewsNgzorro(nextPage, value, pageSize){
    return this.http.get<any>(`${this.baseUrl}/aXul0vWOitMXUdIjMt5R?page=${nextPage}&value=${value}&pageSize=${pageSize}`, this.header());
  }

  delete(data){
    return this.http.post<any>(`${this.baseUrl}/rsk6ffAXvQp0KQ0Dy4WT`, data, this.header());
  }

  create(data){
    return this.http.post<any>(`${this.baseUrl}/6TEGbKCswu3THnVlyXyW`, data, this.header());
  }

  addImageNew(data){
    return this.http.post(`${this.baseUrl}/addImageNew`, data, this.header());
  }
  addFileNew(data){
    return this.http.post(`${this.baseUrl}/addFileNew`, data, this.header());
  }

  select(data){
    return this.http.post(`${this.baseUrl}/iPf4UwEJCZpfelj8jmdy`, data, this.header());
  }
  downloadFile(filename: string) {
    return this.http.get(`${this.baseUrl}/downloadFile?id=${filename}`, { responseType: 'blob' });
  }
  getFilesDownload(filename: string | undefined): Observable<Blob> {
    return this.http.get(`${this.baseUrl}/planesAccion/download/${filename}`,
    {
      responseType: 'blob'
    });
  }

  update(data){
    return this.http.post<any>(`${this.baseUrl}/WdfEwnXKAdJwUjotrbJ2`, data, this.header());
  }

  deletePoint(data){
    return this.http.post<any>(`${this.baseUrl}/TJmCyDZG8TA3JVD9oPkD`, data, this.header());
  }

}
