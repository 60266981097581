import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { data } from 'jquery';
import { SwalService } from 'src/app/Services/swal/swal.service';
import { WolkvoxService } from 'src/app/Services/wolkvox/wolkvox.service';

@Component({
  selector: 'app-wolkvox',
  templateUrl: './wolkvox.component.html',
  styleUrls: ['./wolkvox.component.css']
})
export class WolkvoxComponent implements OnInit {

  data: any[] = []

  form: FormGroup = new FormGroup({
    code: new FormControl('', Validators.required),
    dateIni: new FormControl('', Validators.required),
    dateEnd: new FormControl('', Validators.required)
  })

  constructor(
    private swalService: SwalService,
    private wolkvoxService: WolkvoxService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {

  }

  find(): void {
    this.swalService.loading()
    console.log(this.form.value)
    this.wolkvoxService.get(this.form.value.code, this.datePipe.transform(this.form.value.dateIni, 'YYYYMMdd000000'), this.datePipe.transform(this.form.value.dateEnd, 'YYYYMMdd235959')).subscribe((data) => {
      console.log(data)
      this.data = data.data
      this.swalService.close()
    }, (err) => {
      this.swalService.error(err)
    })
  }
  /* find(): void {
    this.swalService.loading()
    console.log(this.form.value)
    this.wolkvoxService.getpol().subscribe((data) => {
      console.log(data)
      //this.data = data.data
      this.swalService.close()
    }, (err) => {
      this.swalService.error(err)
    })
  } */

}
